import React from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';

// Material UI Components
import { Tooltip } from '@material-ui/core';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    marginTop: '2px',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({

}));

export default function IconWithTooltip({iconElement, title}) {
  const classes = useStyles();

  return (
    <CustomTooltip title={title} arrow aria-label={title}>
      {iconElement}
    </CustomTooltip>
  );
}
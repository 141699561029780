import React, { useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext, DialogContext } from '../../context';
import DICTIONARY from '../../utils/dictionary';
import { parsedPathname } from '../../utils/utils'
import { APP_NAME } from '../../utils/consts';

// App Components
import PageHeader from "../../components/layout/PageHeader";
import TeamList from '../../components/team/TeamList';
import ComponentLoader from '../../components/shared/loader/ComponentLoader';

// Material UI Components
import { Grid } from '@material-ui/core';

// Icons
import { 
	Add as AddIcon, 
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		padding: 0,
    [theme.breakpoints.up("sm")]: {
			padding: theme.spacing(0, 2),
    }
	},
}));

export default function MyTeams() {
	const classes = useStyles();
	const { user, handleUserUpdate } = useContext(AuthContext);
	const { handleCloseDialog } = useContext(DialogContext);
	const history = useHistory();
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(false);

	//#region Create Team Dialog
  const handleClickOpenCreateTeamDialog = () => {
		handleCloseDialog(handleCloseCreateTeamDialog); // Update handleCloseDialog API context function

		history.push({
			pathname: `${parsedPathname(location.pathname)}/create-team`,
			state: {
				background: location,
				id: 'createTeam',
			},
		});
  };

  const handleCloseCreateTeamDialog = async (isSubmittedSuccessfully) => {
		console.log('MyTeams handleCloseCreateTeamDialog');
	};
	//#endregion

	//#region My Teams Actions
	const ACTIONS = {
		0: {
			actions: [
				{
				key: 'createTeam',
				title: DICTIONARY.TEAM.myTeams.actions.createTeam,
				iconElement: <AddIcon />,
				onClick: handleClickOpenCreateTeamDialog,
			},
		],
		}
	}
	//#endregion

  return (
		<React.Fragment>
		<Helmet>
			<title>My teams | {APP_NAME}</title>
		</Helmet>
			<PageHeader 
				title={DICTIONARY.TEAM.myTeams.title}
				actions={ACTIONS}
			/>

			<Grid container className={classes.root} spacing={2} justifyContent="flex-start">
				<Grid item xs={12}>
					{isLoading 
						? <ComponentLoader /> 
						: <TeamList />
					}
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

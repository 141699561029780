import { useState } from 'react';
import { isVerbose } from '../utils/utils';
import { createEmptyFormFieldErrorMessage } from '../utils/validators';

const useForm = ({initalFormState, onCustomInputChange, alternativeIsValidCallback, onSubmit, onCloseModal}) => {
  const [formState, setFormState] = useState(initalFormState);
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

	const handleInputChange = async (e, payload) => {
		const type = (payload && payload.type) || '';
		let key;
		let val;

		switch(type) {
			case 'select': {
				const { name, value } = e.target;
				key = name;
				val = value
				break;
			}
			case 'checkbox': {
				const { name, checked } = e.target;
				key = name;
				val = checked;
				break;
			}
			case 'radio': {
				const { name, value } = e.target;
				key = name;
				val = value;
				break;
			}
			case 'autocomplete': {
				key = payload.name;
				val = payload.value;
				break;
			}
			case 'date': {
				key = payload.name;
				val = e;
				break;
			}
			case 'image': {
				key = payload.name;
				val = e.target.files[0];
				break;
			}
			default: {
				const { id, value } = e.target;
				key = id ;
				val = value
				break;
			}
		}

		setFormState((prevState) => {
			return { 
				...prevState,
				[key]: {
					...prevState[key],
					value: val,
					isValid: true,
				}
			};
		});

		setFormError("");

		onCustomInputChange && await onCustomInputChange(key, val);
	};

  const isFormValid = (formState) => {
		try {
			// If alternative isValid function exisits, execute only this and skip rest of 'isFormValid' function
			if(alternativeIsValidCallback) {
				try {
					alternativeIsValidCallback();
					return true;
				} catch(e) {
					setIsLoading(false);
					setFormError(e.message);
					isVerbose() && console.error(e.message);
					return false;
				}
			}

			if(formState === undefined) {
				return true;
			}

			let emptyFields = [];
			let clonedFormState = {...formState};

			// Helpers
			const setInvalidField = (field) => {
				try{
					clonedFormState[field].isValid = false;
				} catch (e) {}
			};

			// 1. Empty field accumulative error message
			for (const field in formState) {
				const value = formState[field].value;
				const isRequired = formState[field].required;

				if(isRequired && (value == null || (value !== undefined && value.length === 0))) {
					setInvalidField(field);
					emptyFields.push(`'${clonedFormState[field].label}'`);
				}
			}

			if(emptyFields.length > 0) {
				const formError = createEmptyFormFieldErrorMessage(emptyFields);
				setFormError(formError);
				setFormState(clonedFormState);
				return false;
			}

			// 2. Spesific validation according to field
			for (const field in formState) {
				const validators = formState[field].validators;
				if(validators.length === 0) {
					continue;
				}

				// loop over each validator function of the field
				for (const validator of validators) {
					const validateCallback = validator.validate;
					if(typeof validateCallback === 'function' && !validateCallback(formState[field].value, formState)) {
						setInvalidField(field);
						const formError = typeof validator.errorMessage === 'function' ? validator.errorMessage(formState[field].value) : validator.errorMessage;
						setFormError(formError);
						setFormState(clonedFormState);
						return false;
					}
				};
			}
			return true;
		} catch(e) {
			return false;
		}
	};

  const handleSubmit = async (e) => {
		e && e.preventDefault();

		if (isFormValid(formState)) {
			try {
				setIsLoading(true);
				setFormError("");
				const response = await onSubmit();
        setIsLoading(false);
    		onCloseModal && handleCloseModal(true, response);
			} catch (e) {
				setIsLoading(false);
				setFormError(e.message);
				isVerbose() && console.error(e.message);
			}
		}
	};

  const handleCloseModal = async (isSubmittedSuccessfully, response) => {
    setFormState(initalFormState);
		setFormError("");
    onCloseModal && await onCloseModal(isSubmittedSuccessfully, response);
  };

	const resetFormError = () => {
		setFormError("");
	};

  return {formState, formError, isLoading, handleInputChange, handleSubmit, handleCloseModal, isFormValid, resetFormError};
};

export default useForm;
import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { teamApi } from '../../../api';
import DICTIONARY from '../../../utils/dictionary';

// Material UI Components
import {Card, CardHeader, CardContent, Typography} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

// App Components
import UsersList from '../../shared/userAvatar/UsersList';
import PlayersStatsTable from '../../stats/PlayersStatsTable';

// Icons
import {
  People as PeopleIcon,
  ListRounded as ListRoundedIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
	},
  cardHeader: {
    padding: theme.spacing(2, 2, 1, 2),
  },
  cardActions: {
    margin: 0,
  },
  toggleButton: {
    padding: theme.spacing(0.5),
  },
  cardContent: {
    padding: theme.spacing(0, 2),
	},
}));

const CARD_CONTENT = {
  statistics: {
    label: DICTIONARY.TEAM.team.mambersCard.statistics,
    value: 'statistics',
  },
  members: {
    label: DICTIONARY.TEAM.team.mambersCard.members,
    value: 'members',
  },
};

export default function MembersCard({ members }) {
	const classes = useStyles();
  const [activeCardContent, setActiveCardContent] = useState(CARD_CONTENT.members); // TODO: remove functionality of activeCardContent - it is deprecated here
  const [sortedMembersArray, setSoertedMembersArray] = useState([]);

  
  const handleChangeCardContent = (event, view) => {
    if(view != null) {
      setActiveCardContent(view);
    }
  };

  useEffect(() => {
    const membersArray = Object.keys(members).map(userId => {
      return {
        ...members[userId],
        userId
      }
    });

    const sortedMembersArray = membersArray.length > 0 ? membersArray.slice().sort((a, b) => {
      // First sort by isPrime, and then if needed by name
      let isPrime = b.isPrime - a.isPrime;
      if (isPrime !== 0) {
          return isPrime;
      }
  
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    }) : [];

    setSoertedMembersArray(sortedMembersArray);
  }, [members]);

  const TOGGLE_ACTIONS = (
    <ToggleButtonGroup
      value={activeCardContent}
      exclusive
      size="small"
      onChange={handleChangeCardContent}
      aria-label="diaply mode"
    >
      <ToggleButton 
        value={CARD_CONTENT.statistics} 
        aria-label={CARD_CONTENT.statistics.label}
        classes={{'sizeSmall': classes.toggleButton}}  
      >
        <ListRoundedIcon />
      </ToggleButton>
      <ToggleButton 
        value={CARD_CONTENT.members} 
        aria-label={CARD_CONTENT.members.label}
        classes={{'sizeSmall': classes.toggleButton}}    
      >
        <PeopleIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const MembersEl = () => {
    return (sortedMembersArray && sortedMembersArray.length > 0 
      ? <UsersList 
          participants={sortedMembersArray} 
          avatarSize={80}
        />
      : <Typography>{DICTIONARY.TEAM.team.mambersCard.emptyState}</Typography>)
  };

  const StatsEl = () => {
    return (
      <div>Table</div>
    )
  }

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        titleTypographyProps={{ variant:'h6' }}
        title={activeCardContent.value === CARD_CONTENT.members.value ? `${CARD_CONTENT.members.label} (${sortedMembersArray.length})` : CARD_CONTENT.statistics.label}
        classes={{
          'root': classes.cardHeader,
          'action': classes.cardActions,
        }}
        // action={TOGGLE_ACTIONS} // TODO: remove TOGGLE_ACTIONS
      />
      <CardContent classes={{ 'root': classes.cardContent }}>
        {activeCardContent.value === CARD_CONTENT.statistics.value 
          ? StatsEl()
          : MembersEl()
        }
      </CardContent>
    </Card>
  );
}
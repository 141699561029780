import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { clonedeep } from '../../../utils/utils';
import DICTIONARY from '../../../utils/dictionary';

// Material UI Components
import { useMediaQuery, useTheme } from '@material-ui/core';

// App components
import UserAvatar from './UserAvatar';
import EmptyState from '../EmptyState';
import ComponentLoader from '../loader/ComponentLoader';

// Icons
import { 
	People as PeopleIcon,
} from '@material-ui/icons';

// Colors
import { blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  participantsList: {
    display: 'grid',
    rowGap: '15px',
    width: '100%',
  },
  participantItem: {
    justifySelf: 'center',
    width: '100%',
  }
}));

export default function UsersList({ participants, avatarSize = 80, isLoading, emptyStateTitle = DICTIONARY.EVENT.upcomingEvents.noParticipantsYet, emptyStatePadding = 5, }) {
  const classes = useStyles();
  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [participantsData, setParticipantsData] = useState([]);

  useEffect(() => {
    const participantsData = clonedeep(participants);
    setParticipantsData(participantsData);
  }, [participants])

  return (
    <React.Fragment>
      {isLoading 
        ? <ComponentLoader wrapperHeight={80}/>
        : participantsData && participantsData.length > 0
          ? <ul 
              className={classes.participantsList} 
              style={{
                gridTemplateColumns: isMobile ? `repeat(auto-fill, minmax(${avatarSize - 12}px, 1fr))` : `repeat(auto-fill, minmax(${avatarSize + 10}px, 1fr))`,
                columnGap: isMobile ? `${Math.floor((avatarSize - 5) / 6)}px` : `${Math.floor((avatarSize + 10) / 6)}px`,
              }}
            >
              {participantsData && participantsData.map((participant) => (
                <li 
                  key={participant.userId}
                  className={classes.participantItem}
                >
                  <UserAvatar 
                    user={participant}
                    avatarSize={avatarSize}
                  />
                </li>))
              }
            </ul>
          : <EmptyState 
              icon={<PeopleIcon style={{ fontSize: 60, color: blueGrey[500] }}/>}
              title={emptyStateTitle}
              padding={emptyStatePadding}
            />
      }
    </React.Fragment>
  );
}

    


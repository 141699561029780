import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from '../../../context';
import { adminApi, teamApi } from '../../../api';
import DICTIONARY from '../../../utils/dictionary';
import useForm from '../../../hooks/formHook';

const useStyles = makeStyles((theme) => ({
}));

export default function AssignAdminToTeamDialog({teamId, teamName, member, handleCloseDialog}) {
  const { user, handleUserUpdate } = useContext(AuthContext);
  const classes = useStyles();

  //#region Form Hook
	const onSubmit = async () => {
    const response = await adminApi.team.assignAdminToTeam(teamId, member.userId);

    if (!response.hasOwnProperty("error")) {
      // handleUserUpdate - User Auth context;
      const teamsIds = user.userData.teams || [];
      const updatedTeamsData = await teamApi.getTeamsByIds(teamsIds); // Get teamsData of user from 'teams' collection

      handleUserUpdate({
        teamsData: updatedTeamsData,
      });
    } else {
      throw new Error(response.error);
    };
	}

	const onCloseModal = (isSubmittedSuccessfully) => {
    handleCloseDialog(isSubmittedSuccessfully);
  }

	const {formError, isLoading, handleSubmit, handleCloseModal} = useForm({
		onSubmit, 
		onCloseModal,
	});
  //#endregion

	const dialogContent = (
		<span></span>
  );

  return {
		dialogTitle: `${DICTIONARY.TEAM.assignAdminToTeam.title} ${teamName}`,
		dialogContent,
    dialogContentText: (<span>{DICTIONARY.TEAM.assignAdminToTeam.subtitle.part1} <span style={{fontWeight: 'bold', color: 'black'}}>{member.name}</span> {DICTIONARY.TEAM.assignAdminToTeam.subtitle.part2}</span>),
		sumbitButtonLabel: DICTIONARY.TEAM.assignAdminToTeam.form.submit,
		handleSubmit,
		handleCloseModal,
		formError,
		isLoading,
	};
}
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

// Material UI Components
import { InputAdornment, IconButton, Popover, Typography } from '@material-ui/core';

// Icons
import {Info as InfoIcon} from '@material-ui/icons';

// Colors
import { blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	inputInfoIcon: {
		fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.down("sm")]: {
			fontSize: theme.typography.pxToRem(18),
    },
	},
  icon: {
    height: theme.typography.pxToRem(16),
  },
  infoTextContainer: {
    maxWidth: '250px',
  },
  infoText: {
    padding: theme.spacing(1),
  },
}));

export default function InfoIconTooltip({text, className}) {
  const classes = useStyles();

  // #region Popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  // #endregion

  return (
    <React.Fragment>
      <InputAdornment position="end" classes={{'root': classes.icon}}>
        <IconButton
          aria-label="info"
          size="small"
          style={{
            'color': blueGrey[300],
          }}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          // onClick={handlePopoverOpen}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <InfoIcon classes={{'root': classes.inputInfoIcon}}/>
        </IconButton>
      </InputAdornment>

      <Popover
        id="mouse-over-popover"
        style={{ pointerEvents: 'none'}} 
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        classes={{'paper': classes.infoTextContainer}}
      >
        <Typography 
          variant="body2" 
          display="block" 
          classes={{'root': classes.infoText}}
        >
          {text}
        </Typography>
      </Popover>
    </React.Fragment>
  );
}
import React, { useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../context';
import DICTIONARY from '../../utils/dictionary';

// Material UI Components
import { Button, Box, Paper, Typography, MobileStepper, useTheme } from '@material-ui/core';

// Images
import step1 from "../../assets/images/tutorials/pwa/step-1.jpg";
import step2 from "../../assets/images/tutorials/pwa/step-2.jpg";
import step3 from "../../assets/images/tutorials/pwa/step-3.jpg";
import step4 from "../../assets/images/tutorials/pwa/step-4.jpg";

// Icons
import {
	KeyboardArrowLeft as KeyboardArrowLeftIcon,
	KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons';

// Colors
import { lime } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	stepLabel: {
    display: 'flex',
		justifyContent: 'center',
    alignItems: 'center',
    // height: 40,
    backgroundColor: lime['A400'],
		margin: theme.spacing(0, -3, 2),
		padding: theme.spacing(1.5, 2),
  },
  img: {
    maxWidth: 220,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
		margin: 'auto',
  },
	stepperContainer: {
		backgroundColor: 'white',
	}
}));

const tutorialSteps = [
	{
		label: DICTIONARY.PWA.saveAsPwaExplenationModal.steps.one,
		imgPath: step1,
	},
	{
		label: DICTIONARY.PWA.saveAsPwaExplenationModal.steps.two,
		imgPath: step2,
	},
	{
		label: DICTIONARY.PWA.saveAsPwaExplenationModal.steps.three,
		imgPath: step3,
	},
	{
		label: DICTIONARY.PWA.saveAsPwaExplenationModal.steps.four,
		imgPath: step4,
	},
];

export default function SaveAsPwaTutorialDialog({}) {
	const { user, handleUserUpdate } = useContext(AuthContext);
  const classes = useStyles();
	const theme = useTheme();

	//#region Tutorial steps
	const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

	const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
	//#endregion
	
  const dialogContent = (
		<React.Fragment>
			<Paper square elevation={0} className={classes.stepLabel}>
				<Typography variant="body2" display="block">{tutorialSteps[activeStep].label}</Typography>
			</Paper>
			<img
				className={classes.img}
				src={tutorialSteps[activeStep].imgPath}
				alt={tutorialSteps[activeStep].label}
			/>
			<MobileStepper
				steps={maxSteps}
				position="static"
				variant="dots"
				classes={{'root': classes.stepperContainer}}
				activeStep={activeStep}
				nextButton={
					<Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
						Next
						{theme.direction === 'rtl' ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
					</Button>
				}
				backButton={
					<Button size="small" onClick={handleBack} disabled={activeStep === 0}>
						{theme.direction === 'rtl' ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
						Back
					</Button>
				}
			/>

			<Box mt={5} mb={2}>
				<Typography variant="body2" display="block">{DICTIONARY.PWA.saveAsPwaExplenationModal.subtitle}</Typography> 
			</Box>
    </React.Fragment>
  );

  return {
		dialogTitle: DICTIONARY.PWA.saveAsPwaExplenationModal.title,
		dialogContent,
    shouldHideActions: true,
	};
}
import React, { useState, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import { eventApi } from '../../api';
import { makeStyles } from '@material-ui/core/styles';
import { EVENT_GROUPS_COLORS } from '../../utils/consts';
import DICTIONARY from '../../utils/dictionary';
import { capitalizeFirstLetter } from '../../utils/utils';  
import useForm from '../../hooks/formHook';

// App components
import LightTooltip from '../shared/tooltip/LightTooltip';
import Squircle from '../shared/userAvatar/Squircle';
import ComponentLoader from '../shared/loader/ComponentLoader';
import EmptyState from '../shared/EmptyState';

// Material UI Components
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Input, IconButton, Divider, Box, useMediaQuery, useTheme, } from '@material-ui/core';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

// Colors
import { blueGrey, grey } from '@material-ui/core/colors';

// Icons
import {
	ExpandMore as ExpandMoreIcon,
	Add as AddIcon,
	Remove as RemoveIcon,
	Equalizer as EqualizerIcon,
} from '@material-ui/icons';
import ShirtIcon from '../icon/ShirtIcon';

// #region Custom Accordion
const Accordion = withStyles((theme) => ({
  root: {
		paddingRight: theme.spacing(0),
		[theme.breakpoints.up("sm")]: {
			paddingRight: theme.spacing(2),
    },
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    // marginBottom: -1,
		padding: 0,
    minHeight: 46,
    '&$expanded': {
      minHeight: 46,
    },
  },
  content: {
    '&$expanded': {
      margin: '5px 0',
			
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiAccordionDetails);
// #endregion

// #region Custom table
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
		// textAlign: 'center',
		padding: theme.spacing(1.8, 2, 1.8, 1),
  },
  body: {
		border: 'none',
		padding: theme.spacing(1.8, 2, 1.8, 1.5),
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
// #endregion 

const useStyles = makeStyles((theme) => ({
	dialogContent: {
		paddingTop: theme.spacing(1),
		margin: theme.spacing(0, -1.4),
		overflowX: 'hidden',
		[theme.breakpoints.up("md")]: {
			paddingTop: theme.spacing(0),
			margin: theme.spacing(0),
			minHeight: '486px',
			maxHeight: '486px',
    },
	},
	tableCellHead: {
		textAlign: 'center',
	},
	groupShirtIconContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '100px',
		height: '60px',
		padding: theme.spacing(1.8, 0.5, 1.8, 1),
		[theme.breakpoints.up("sm")]: {
			width: '100%',
			padding: theme.spacing(1.8, 2, 1.8, 1.5),
    },
	},
	shirtIconGroup: {
    width: '24px',
    height: '20px',
    stroke: '#313131', strokeWidth: '2px', paintOrder: 'stroke', strokeLinejoin: 'round',
		marginRight: theme.spacing(1),
  },
	actionsContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	statIconButton: {
		padding: theme.spacing(0),
		"& .MuiSvgIcon-root": { 
			fontSize: '1rem', 
		}
	},
	verticalDivider: {
		height: '20px',
		margin: theme.spacing(0, 1.5),
	},
	statNumber: {
		fontSize: theme.typography.pxToRem(20),
		margin: theme.spacing(0, 1.5),
	},
	playerAvatarContainer: {
		display: 'flex',
		alignItems: 'center',
		maxWidth: '105px',
		height: '60px',
		position: 'relative',
		padding: theme.spacing(1.8, 0.5, 1.8, 1),
		[theme.breakpoints.up("sm")]: {
			position: 'unset',
			maxWidth: 'none',
			padding: theme.spacing(1.8, 2, 1.8, 1.5),
    },
	},
	shirtIconPlayer: {
		position: 'absolute',
		top: '3px',
		left: '3px',
    width: '14px',
    height: '14px',
    stroke: '#313131', strokeWidth: '2px', paintOrder: 'stroke', strokeLinejoin: 'round',
		marginRight: theme.spacing(0.2),
		[theme.breakpoints.up("sm")]: {
			position: 'unset',
			marginRight: theme.spacing(1),
			maxWidth: 'none',
			width: '18px',
			height: '18px',
    },
	},
	playerAvatar: {
		width: '25px',
		height: '25px',
		objectFit: 'cover',
		marginRight: '8px',
	},
	mobileText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '95%',
    display: 'block',
  },
	statAsInputRoot: {
		width: '35px',
		'& .MuiInputBase-input': {
			textAlign: 'center',
			fontSize: theme.typography.pxToRem(18),
			border: `1px solid ${grey[200]}`,
			boxSizing: 'border-box',
			borderRadius: '5px',
			padding: '4px',
			height: '30px',
			backgroundColor: 'white',
		},
		'&:hover, &:active, &:focus': {
			// textDecoration: 'underline',
		},
	},
}));

const CONTAINER_TYPE = {
	groups: 'groupsStats',
	players: 'playersStats',
};

const STATS_ACTIONS = {
	increase: 'increase',
	decrease: 'decrease',
}

export default function UpdateEventStatsDialog({ eventId, teamId, participants, eventGroups, handleCloseDialog }) {
  const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [isLoadingData, setIsLoadingData] = useState(false);
	const [isError, setIsError] = useState(false);

	//#region Accordions
	const [expandedGroups, setExpandedGroups] = useState(true);
	const [expandedPlayers, setExpandedPlayers] = useState(true);

  const handleChangeExpandedGroups = () => {
    setExpandedGroups(!expandedGroups);
  };

	const handleChangeExpandedPlayers = () => {
		setExpandedPlayers(!expandedPlayers);
  };
	//#endregion

	//#region Stats
	const [groupsStats, setGroupsStats] = useState([]);
	const [playersStats, setPlayersStats] = useState([]);

	useEffect(() => {
		(async () => {
			try{
				setIsLoadingData(true);
				const response = await eventApi.stats.get(eventId);

				let { groupsStats: loadedGroupsStats = {}, playersStats: loadedPlayersStats = {} } = response;

				// Groups stats
				let groupsStats = eventGroups.map((group) => {	
					return {
						id: group,
						matchesPlayed: loadedGroupsStats.hasOwnProperty(group) ? loadedGroupsStats[group].matchesPlayed : 0,
						wins: loadedGroupsStats.hasOwnProperty(group) ? loadedGroupsStats[group].wins : 0,
					};
				});
				groupsStats = [...groupsStats].sort((a, b) => a.id > b.id ? -1 : 1);
				
				// Players stats (go over participants because this is our single source of truth - for example if we have new participant that was added)
				let playersStats = participants.map((participant) => {
					const { userId, name, avatar, group } = participant;
					return {
						id: userId,
						name,
						avatar,
						group: group || null,
						goals: loadedPlayersStats.hasOwnProperty(userId) ? loadedPlayersStats[userId].goals : 0,
						assists: loadedPlayersStats.hasOwnProperty(userId) ?loadedPlayersStats[userId].assists : 0,
					};
				});
				playersStats = [...playersStats].sort((a, b) => a.group > b.group ? -1 : 1);

				setGroupsStats(groupsStats);
				setPlayersStats(playersStats);
				setIsLoadingData(false);
			} catch(e) {
				setIsLoadingData(false);
				setIsError(true);
			}
		})();
	}, []);

	const handleStatsAcionClick = (type, id, key, action) => {
		resetFormError();

		let container = type === CONTAINER_TYPE.groups ? groupsStats : playersStats;
		const updatedStats = [...container];

		for(let i = 0; i < updatedStats.length; i++) {
			let item = updatedStats[i];

			if(item.id === id) {
				if(action === STATS_ACTIONS.increase) {
					item[key]++
				} else if(item[key] > 0){ // Dont allow negative numbers
					item[key]--;
				}
				break;
			}
		};

		type === CONTAINER_TYPE.groups ? setGroupsStats(updatedStats) : setPlayersStats(updatedStats);
	};

	const handleStatChange = (type, id, key, event) => {
		resetFormError();

		const { value } = event.target;
		let container = type === CONTAINER_TYPE.groups ? groupsStats : playersStats;
		const updatedStats = [...container];

		for(let i = 0; i < updatedStats.length; i++) {
			let item = updatedStats[i];

			if(item.id === id) {
				item[key] = value;

				break;
			}
		};

		type === CONTAINER_TYPE.groups ? setGroupsStats(updatedStats) : setPlayersStats(updatedStats);
	};

	const handleStatBlur = (type, id, key, event) => {
		const { value } = event.target;
		if(value === '' || value < 0) {
			let container = type === CONTAINER_TYPE.groups ? groupsStats : playersStats;
			const updatedStats = [...container];
	
			for(let i = 0; i < updatedStats.length; i++) {
				let item = updatedStats[i];
	
				if(item.id === id) {
					item[key] = 0;
	
					break;
				}
			};
	
			type === CONTAINER_TYPE.groups ? setGroupsStats(updatedStats) : setPlayersStats(updatedStats);
		}
	}; 

	const StatsContainerEl = (type, id, key) => {
		// find the relevent item (from groupsStats or playersStats)
		let container = type === CONTAINER_TYPE.groups ? groupsStats : playersStats;
		const item = container.find(element => element.id === id);

		return (
			<div className={classes.actionsContainer}>				
				<IconButton
					aria-label="increase"
					onClick={() => handleStatsAcionClick(type, id, key, STATS_ACTIONS.decrease)}
					classes={{'root': classes.statIconButton}}
				>
					<RemoveIcon />
				</IconButton>

				{/* the number (on Desktio it is an input) */}
				{isMobile 
					? <Typography className={classes.statNumber}>{item[key]}</Typography>
					: <Input 
							value={item[key]} 
							type="number"
							onChange={(event) => handleStatChange(type, id, key, event)} 
							onBlur={(event) => handleStatBlur(type, id, key, event)} 
							classes={{'root': classes.statAsInputRoot}}
							disableUnderline 
						/>
				}
				
				<IconButton
					aria-label="increase"
					onClick={() => handleStatsAcionClick(type, id, key, STATS_ACTIONS.increase)}
					classes={{'root': classes.statIconButton}}
				>
					<AddIcon />
				</IconButton>

				{/* <Divider classes={{'root': classes.verticalDivider}} orientation="vertical" /> */}
			</div>
		)
	};
	//#endregion

	//#region Form Hook
	const alternativeIsValidCallback = () => {
		// Chgeck that wins <= matchesPlayed
		groupsStats.forEach((group) => {
			const { id, wins, matchesPlayed } = group;
			if(Number(wins) > Number(matchesPlayed)) {
				throw new Error(`${DICTIONARY.EVENT.updateEventStats.form.error.winsNumber.part1} '${capitalizeFirstLetter(id)}' ${DICTIONARY.EVENT.updateEventStats.form.error.winsNumber.part2}`);
			}
		});

		return true;
	};

	const onSubmit = async () => {
		try {
			let groupsStatsObj = {};
			let playersStatsObj = {};

			groupsStats.forEach((group) => {
				const { id, wins, matchesPlayed } = group;
				groupsStatsObj[id] = {
					wins: Number(wins),
					matchesPlayed,
				};
			});

			playersStats.forEach((player) => {
				const { id, goals, assists, name, avatar, group } = player;
				const groupOfPlayer = groupsStats.find(item => item.id === group);
				playersStatsObj[id] = {
					name,
					avatar,
					group,
					goals,
					assists,
					matchesPlayed: group ? groupOfPlayer.matchesPlayed : 0,
					wins: group ? groupOfPlayer.wins : 0,
				};
			});

			const response = await eventApi.stats.update(eventId, groupsStatsObj, playersStatsObj);

			if (response.hasOwnProperty("error")) {
				throw new Error(DICTIONARY.EVENT.updateEventStats.form.error.generalError);
			}

		} catch(e) {
			throw new Error(DICTIONARY.EVENT.updateEventStats.form.error.generalError);
		}
	}

	const onCloseModal = (isSubmittedSuccessfully) => {
    handleCloseDialog({isSubmittedSuccessfully});
  }

	const {formError, isLoading, handleSubmit, handleCloseModal, resetFormError} = useForm({
		alternativeIsValidCallback,
		onSubmit, 
		onCloseModal,
	});
	//#endregion

	const dialogContent = (isLoadingData 
		? <ComponentLoader wrapperHeight={isMobile ? undefined : 486} />
		: isError 
			? <EmptyState 
					icon={<EqualizerIcon style={{ fontSize: 80, color: blueGrey[500] }}/>}
					title={DICTIONARY.EVENT.updateEventStats.errorLoadingData}
					padding={5}
				/>
			: <div className={classes.dialogContent}>
				{/* Groups section */}
				<Accordion square expanded={expandedGroups} onChange={handleChangeExpandedGroups}>
					<AccordionSummary aria-controls="groups" id="groups" expandIcon={<ExpandMoreIcon />}>
						<Typography>{DICTIONARY.EVENT.updateEventStats.sections.groups}</Typography>
					</AccordionSummary>

					<AccordionDetails>
						<TableContainer>
							<Table className={classes.table} aria-label="groups-table">
								<TableHead>
									<StyledTableRow>
										<StyledTableCell>{DICTIONARY.EVENT.updateEventStats.tableTitles.group}</StyledTableCell>
										<StyledTableCell className={classes.tableCellHead}>{DICTIONARY.EVENT.updateEventStats.tableTitles.mp}</StyledTableCell>
										<StyledTableCell className={classes.tableCellHead}>{DICTIONARY.EVENT.updateEventStats.tableTitles.wins}</StyledTableCell>
									</StyledTableRow>
								</TableHead>

								<TableBody>
									{groupsStats.map((group) => (
										<StyledTableRow key={group.id}>
											<StyledTableCell className={classes.groupShirtIconContainer}>
												<ShirtIcon style={{ color: EVENT_GROUPS_COLORS[group.id].hex }} className={classes.shirtIconGroup} />
													{isMobile 
														? <LightTooltip 
																title={EVENT_GROUPS_COLORS[group.id].label} 
															>
																<span className={classes.mobileText}>
																	{EVENT_GROUPS_COLORS[group.id].label}
																</span>
															</LightTooltip>
														: <span>{EVENT_GROUPS_COLORS[group.id].label}</span>
													}
											</StyledTableCell>
											<StyledTableCell>{StatsContainerEl(CONTAINER_TYPE.groups, group.id, 'matchesPlayed')}</StyledTableCell>
											<StyledTableCell>{StatsContainerEl(CONTAINER_TYPE.groups, group.id, 'wins')}</StyledTableCell>
										</StyledTableRow>
									))}
							</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>

				{/* Players section */}
				<Box mt={3}>
					<Accordion square expanded={expandedPlayers} onChange={handleChangeExpandedPlayers}>
						<AccordionSummary aria-controls="players" id="players" expandIcon={<ExpandMoreIcon />}>
							<Typography>{DICTIONARY.EVENT.updateEventStats.sections.players}</Typography>
						</AccordionSummary>

						<AccordionDetails>
							<TableContainer>
								<Table className={classes.table} aria-label="players-table">
									<TableHead>
										<StyledTableRow>
											<StyledTableCell>{DICTIONARY.EVENT.updateEventStats.tableTitles.player}</StyledTableCell>
											<StyledTableCell className={classes.tableCellHead}>{DICTIONARY.EVENT.updateEventStats.tableTitles.goals}</StyledTableCell>
											<StyledTableCell className={classes.tableCellHead}>{DICTIONARY.EVENT.updateEventStats.tableTitles.assists}</StyledTableCell>
										</StyledTableRow>
									</TableHead>
			
									<TableBody>
										{playersStats.map((player) => (
											<StyledTableRow key={player.id}>
												<StyledTableCell className={classes.playerAvatarContainer}>
													{player.group !== null && <ShirtIcon style={{ color: EVENT_GROUPS_COLORS[player.group].hex }} className={classes.shirtIconPlayer} />}
													<Squircle 
														src={player.avatar}
														alt={player.name}
														className={classes.playerAvatar}
													/>
													{isMobile 
														? <LightTooltip 
																title={player.name} 
															>
																<span className={classes.mobileText}>
																	{player.name}
																</span>
															</LightTooltip>
														: <span>{player.name}</span>
													}
												</StyledTableCell>
												<StyledTableCell>{StatsContainerEl(CONTAINER_TYPE.players, player.id, 'goals')}</StyledTableCell>
												<StyledTableCell>{StatsContainerEl(CONTAINER_TYPE.players, player.id, 'assists')}</StyledTableCell>
											</StyledTableRow>
										))}
								</TableBody>
								</Table>
							</TableContainer>
						</AccordionDetails>
					</Accordion>
				</Box>
			</div>
  );

  return {
		dialogTitle: DICTIONARY.EVENT.updateEventStats.title,
		dialogContent,
		sumbitButtonLabel: DICTIONARY.EVENT.updateEventStats.form.submitButton,
		handleSubmit,
		handleCloseModal,
		formError,
		isLoading,
		shouldHideActions: isError ? true : false
	};
}
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { parseQueryString } from '../../../utils/utils';

// Material UI
import { Tabs, Tab, useMediaQuery, useTheme  } from '@material-ui/core';
import TabPanel from './TabPanel';

// Colors
import { grey, teal, } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	tabsRoot: {
		borderBottom: `1px solid ${grey[400]}`,
		backgroundColor: 'white',
		borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
		margin: theme.spacing(1, 0, 2),
		// boxShadow: '0px 0 1px -1px rgb(0 0 0 / 20%), 0px 0 1px 0px rgb(0 0 0 / 14%), 0px 0 3px 1px rgb(0 0 0 / 10%)',
    boxShadow: '0px 0 1px -1px rgb(0 0 0 / 100%)',
		[theme.breakpoints.up("sm")]: {
			margin: theme.spacing(1, 2, 2),
    }
	},
	tabSeparator: {
    '&:not(:last-child)': {
      '&::after': {
        content: '""',
        display: 'block',
        height: '40%',
        width: '1px',
        backgroundColor: grey[400],
        position: 'absolute',
        right: 0,
      }
    },
	},
	selectedTabText: {
		color: `${teal[500]}`,
	},
}));

export default function TabsContainer({ tabs }) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [activeTab, setActiveTab] = useState(0);
  const tabsValuesRef = useRef({});

  useEffect(() => {
    try {
      tabs.forEach((tab) => {
        const { key, value } = tab;
        tabsValuesRef.current[key] = value;
      });

      const { search } = location; 
      const query = parseQueryString(search);

      if(Object.keys(query).length > 0) {
        const { tab: tabFromQueryParam } = query;
        tabsValuesRef.current.hasOwnProperty(tabFromQueryParam) ? setActiveTab(tabsValuesRef.current[tabFromQueryParam]) : setActiveTab(0);
      }
    } catch(e) {
      setActiveTab(0);
    }
	}, [location]);

  const getKeyFromValue = (value) => {
    return Object.keys(tabsValuesRef.current).find(key => tabsValuesRef.current[key] === value);

  }

  const handleTabChange = (newValue) => {
    try {
      const key = getKeyFromValue(newValue);
      setActiveTab(newValue);
      if(key) {
        history.push({
          search: `?tab=${key}`
        })
      }
    } catch(e) {}
  };

	function a11yProps(index) {
		return {
			id: `tab-${index}`,
			'aria-controls': `tabpanel-${index}`,
		};
	}

  return (
    <React.Fragment>
      <Tabs 
        value={activeTab} 
        onChange={(event, newValue) => handleTabChange(newValue)} 
        aria-label="tabs"
        variant={isMobile ? 'fullWidth' : 'standard'}
        classes={{'root': classes.tabsRoot}}
        TabIndicatorProps={{
          style: {
            backgroundColor: teal['500'],
          }
        }}
      >
        {tabs && tabs.map((tab, index) => (
          <Tab 
            label={tab.label} 
            {...a11yProps(index)} 
            classes={{
              'root': classes.tabSeparator,
              'selected': classes.selectedTabText	
            }} 
            key={tab.key}
          />
        ))}
      </Tabs>

      {tabs && tabs.map((tab, index) => (
        <TabPanel 
          value={activeTab} 
          index={index} 
          key={tab.key}
        >
          {tab.content}
        </TabPanel>
      ))}
    </React.Fragment>
  );
}
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DICTIONARY from '../../../utils/dictionary';

// App Components
import UsersList from '../../shared/userAvatar/UsersList';

// Material UI Components
import { Box, Card, CardHeader, CardContent } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(0, 2),
  },
	participantsBox: {
		padding: theme.spacing(0),
		[theme.breakpoints.up("sm")]: {
			padding: theme.spacing(1),
    }
	},
}));

export default function WaitingListCard({ eventData, isLoading }) {
  const classes = useStyles();

  return (
    <Card elevation={0}>
      <CardHeader
        titleTypographyProps={{ variant:'h6' }}
        title={DICTIONARY.EVENT.event.cards.waitingList}
        classes={{
          'root': classes.cardHeader,
        }}
      />
      <CardContent classes={{ 'root': classes.cardContent }}>
        <Box
          display="flex"
          flexWrap="wrap"
          width="100%"
          classes={{'root': classes.participantsBox}}
          bgcolor="background.paper"
        >
          <UsersList 
            participants={eventData.waitingList} 
            isLoading={isLoading}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from '../../../context';
import DICTIONARY from '../../../utils/dictionary';

// App Components
import SmsNotificationsSettings from './SmsNotificationsSettings';
import EmailNotificationsSettings from './EmailNotificationsSettings';
import PwaMessagingNotificationsSetting from './PwaMessagingNotificationsSetting';

// Material UI Components
import { Card, CardHeader, CardContent, Divider, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 0,
    [theme.breakpoints.up("md")]: {
			padding: theme.spacing(1, 2, 2),
    }
	},
	cardHeader: {
    padding: theme.spacing(2, 2, 1, 2),
  },
  cardContent: {
    "&:last-child": {
			paddingBottom: theme.spacing(1)
		}
	}
}));

export default function UserNotificationsSettingsContainer() {
  const { user } = useContext(AuthContext);
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card className={classes.root} elevation={0}>
        <CardHeader
          titleTypographyProps={{ variant:'h6' }}
          title={DICTIONARY.MY_ACCOUNT.notificationsSettings.title}
					subheader={DICTIONARY.MY_ACCOUNT.notificationsSettings.subTitle}
					subheaderTypographyProps={{variant:'body2' }}
          classes={{
            'root': classes['cardHeader'],
            'action': classes['cardHeaderAction'],
          }}
        />
        <CardContent classes={{'root': classes.cardContent}}>
          {/* SMS */}
					<SmsNotificationsSettings />
          <Divider />

          {/* Email */}
          <Box my={3}>
					  <EmailNotificationsSettings />
          </Box>
          <Divider />

          {/* PWA Messaging */}
          <Box my={3}>
            <PwaMessagingNotificationsSetting />
          </Box>

        </CardContent>
      </Card>
    </React.Fragment>
  );
}
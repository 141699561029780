import { format } from 'date-fns';
import { Timestamp } from "../config/firebase";

//#region General
export const clonedeep = (aObject) => {
  if (!aObject) {
    return aObject;
  }

  let v;
  let bObject = Array.isArray(aObject) ? [] : {};
  for (const k in aObject) {
    v = aObject[k];
    bObject[k] = (typeof v === "object") ? clonedeep(v) : v;
  }

  return bObject;
}

export const parseQueryString = (queryString) => {
  let query = {};
  if(queryString !== '') {
    let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

    for (let i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
  }

  return query;
}

export const isInvitationLinkExists = (queryString) => {
  const { invite: teamId , c: encryptedInvitation } = parseQueryString(queryString);
  if(teamId && encryptedInvitation) {
    return true;
  }
  return false;
}

export const parsedPathname = (pathname) => {
  // If needed --> Remove '/' from the end
  return pathname.charAt(pathname.length - 1) === '/' ? pathname.slice(0, -1) : pathname;
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const containsHebrew = (str) => {
  return (/[\u0590-\u05FF]/).test(str);
}
//#endregion

//#region Admin and members
export const getActiveAdminsAndMembers = (members) => {
  // Admins, members, and primeMembers
  try{
    return clonedeep(Object.fromEntries(Object.entries(members).filter(([key, value]) => value.deletedAt === undefined)));
  } catch(e) {
    return {};
  }
};

export const getActiveMembers = (members) => {
  // Members that are not admin and not prime
  try{
    return clonedeep(Object.fromEntries(Object.entries(members).filter(([key, value]) => value.isAdmin !== true && value.isPrime !== true && value.deletedAt === undefined)));
  } catch(e) {
    return {};
  }
};

export const getActivePrimeMembers = (members) => {
  try{
    return clonedeep(Object.fromEntries(Object.entries(members).filter(([key, value]) => value.isPrime === true && value.deletedAt === undefined)));
  } catch(e) {
    return {};
  }
};

export const getActiveAdmins = (members) => {
  try{
    return clonedeep(Object.fromEntries(Object.entries(members).filter(([key, value]) => value.isAdmin === true && value.deletedAt === undefined)));
  } catch(e) {
    return {};
  }
};

export const getTeamsIdsOfAdmin = (userId, TeamsOfUser) => {
  // return array of teamIds that the admin is managing
  if(Object.keys(TeamsOfUser).length === 0) {
    // response is empty array if the user isnt admin of any team
    return [];
  } else {
    const adminTeams = Object.keys(TeamsOfUser).filter(teamId => {
      const teamUsers = TeamsOfUser[teamId].members;
      return isUserAdminOfTeam(userId, teamUsers);
    })

    return adminTeams;
  }
};

export const isUserAdminOfTeam = (userId, teamUsers) => {
  try{
    const isAdmin = Object.keys(teamUsers).findIndex(id => id === userId && teamUsers[id].isAdmin === true) > -1;
    return isAdmin;
  } catch (e) {
    return false;
  }
};
//#endregion

//#region Dates functions
export const convertNumberToDay = (number) => {
  number = Number(number); 
  let retVal;

  switch(number) {
    case 0: {
      retVal = 'Sunday';
      break;
    }
    case 1: {
      retVal = 'Monday';
      break;
    }
    case 2: {
      retVal = 'Tuesday';
      break;
    }
    case 3: {
      retVal = 'Wednesday';
      break;
    }
    case 4: {
      retVal = 'Thursday';
      break;
    }
    case 5: {
      retVal = 'Friday';
      break;
    }
    case 6: {
      retVal = 'Saturday';
      break;
    }
    default: {
      retVal = '';
      break;
    }
  }

  return retVal;
};

export const formatDate = (date, foramt) => {
  try{
    const retVal = format(date.toDate(), foramt);

    return retVal;
  } catch(e) {
    return 'error formatting the date';
  }

}

export const isTimestampFromThePast = (timestamp) => {
  try{
    const now = Timestamp.fromDate(new Date());

    return timestamp < now;
  } catch(e) {
    return false;
  }
}
//#endregion

//#region Localstorage
export const isVerbose = () => {
  return safeLocalStorage().isUnderDevelopment === 'true';
}

export function safeLocalStorage() {
  try{
    return localStorage ? localStorage : window;
  } catch (e) {
    return window;
  }
}

export const isLocalhost = () => {
  return window.location.hostname === "localhost";
}

export const isDevMode = () => {
  try {
    return process.env.NODE_ENV === "development";
  } catch(e) {
    return false;
  }
}
//#endregion

//#region Phone number and Countries
export const countryToFlag = (isoCode) => {
  // ISO 3166-1 alpha-2
  // No support for IE 11
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

export const buildPhoneNumber = (country, number) => {
  try{
    let parsedNumber = number.replace(/\D/g,''); // Strip all non-numeric characters from string
    return `+${country}${parsedNumber}`;
  } catch(e) {
    return '';
  }
}
//#endregion

//#region Devices and PWA
export const isInstalledAsPwa = () => {
  return window.matchMedia('(display-mode: standalone)').matches; // if true --> pwa is installed on mobile device
}

export const getDisplayMode = () => {
  // Browser / PWA (=standalone)
  try {
    let displayMode = 'browser';

    if (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches) {
      displayMode = 'standalone';
    };

    return displayMode;
  } catch(e) {
    return 'error';
  }
}

export const isMobile = () => {
  try {
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile;
  } catch(e) {
    return false;
  }
}

export const getDeviceType = () => {
  // Desktop / Mobile
  try {
    let deviceType = 'desktop';
    if(isMobile()) {
      deviceType = 'mobile';
    }

    return deviceType;
  } catch(e) {
    return 'error';
  }
}
//#endregion

//#region Features flags
export const isFeatureFlagAllowed = (userFF, ff) => {
  const isAllowedFF = userFF && userFF.findIndex((item) => item === ff) > -1;
  return isAllowedFF;

  // How to use it -->
  // isFeatureFlagAllowed(user.userData.ff, 'shouldShowUpdateEventStatsModal') &&
}
//#endregion
import React, { useEffect } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import ROUTES from '../../routes/index';
import DICTIONARY from '../../utils/dictionary/index';

import { makeStyles } from "@material-ui/core/styles";

// Material UI Components
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Hidden, Paper, BottomNavigation, BottomNavigationAction, useMediaQuery, useTheme } from "@material-ui/core";

// Icons
import {
  Home as HomeIcon,
  Event as EventIcon,
  Security as TeamIcon,
} from '@material-ui/icons';

// Colors
import { grey, teal, blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '95px',
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    backgroundColor: blueGrey[800],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '95px',
  },
  desktopDrawerWrapper: {
    marginTop: '64px',
    width: '100%',
  },
  listItem: {
    padding: theme.spacing(2, 1, 1, 2),
    display: 'flex',
    flexDirection: 'row',
    transition: '0.1s ease',
    [theme.breakpoints.up("sm")]: {
      flexDirection: 'column',
      padding: theme.spacing(2, 1, 1, 1),
    }
  },
	listItemIcon: {
    minWidth: 'auto',
    width: '40px',
    height: '40px',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'inherit',
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(0),
    }
	},
  listItemText: {
    fontSize: theme.typography.pxToRem(14),
  },
  activeItem: {
    color: `${teal['200']} !important`,
    transition: '0.1s ease',
    '& $listItemIcon': {
      backgroundColor: blueGrey[600],
      transition: '0.1s ease',
    }
  },
  link: {
    color: 'white',
    textDecoration: 'none',
  },
  expandIcon: {
    marginBottom: theme.spacing(3),
    transition: '0.2s ease',
  },
  expandIconClose: {
    transform: 'rotate(180deg)',
  },
  logoWrapper: {
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
	},
	logo: {
		height: '30px',
    marginTop: theme.spacing(2),
	},
  mobileBottomNavigation: {
    position: 'fixed', 
    bottom: 0, 
    left: 0, 
    right: 0, 
    zIndex: 3,
    borderBottom: `1px solid ${grey[100]}`,
  },
}));

const MENU_ITEMS = {
  dashboard: {
    key: 'dashboard',
    to: ROUTES.root.path,
    text: DICTIONARY.GENERAL.sidebar.dashboard,
    icon: <HomeIcon style={{ fontSize: 26 }} style={{ color: 'inherit' }}/>,
  },
  myTeams: {
    key: 'myTeams',
    to: ROUTES.myTeams.path,
    text: DICTIONARY.GENERAL.sidebar.myTeams,
    icon: <TeamIcon style={{ fontSize: 26 }} style={{ color: 'inherent' }}/>,
  },
  myEvents: {
    key: 'myEvents',
    to: ROUTES.myEvents.path,
    text: DICTIONARY.GENERAL.sidebar.myEvents,
    icon: <EventIcon style={{ fontSize: 26 }} style={{ color: 'inherent' }}/>,
  }
};

export default function Sidebar(props) {
	const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const desktopMenuItems = [MENU_ITEMS.dashboard, MENU_ITEMS.myTeams, MENU_ITEMS.myEvents];
  const mobileMenuItems = [MENU_ITEMS.myTeams, MENU_ITEMS.dashboard, MENU_ITEMS.myEvents];

  //#region Mobile
  const [currentMobileRoute, setCurrentMobileRoute] = React.useState('dashboard');

  useEffect(() => {
    // listen to the route I'm on - relevent only for mobile
    try {
      if(isMobile) {
        const pathname = location.pathname;
        const pathnameBaseRoute = pathname.substring(1).split('/')[0];
        const route = mobileMenuItems.find(r => r.to === `/${pathnameBaseRoute}`);
  
        if(route) {
          setCurrentMobileRoute(route.key);
        } else {
          setCurrentMobileRoute('');
        }
      }

    } catch(e) {}
  }, [location]);

  const onMobileNavigationClick = (newValue) => {
    setCurrentMobileRoute(newValue);
    const to = MENU_ITEMS[newValue].to;

    history.push({
			pathname: to,
		});
  };

  //#endregion

  //#region Desktop
  const getExactPath = (to) => {
    return to === ROUTES.root.path;
  }
  //#endregion

  const DesktopSidebar = (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{'paper': classes.drawerPaper}}
    >
      <div className={classes.desktopDrawerWrapper}>
        <List className={classes.drawerContainer}>
          {desktopMenuItems && desktopMenuItems.map(item => (
            <NavLink 
              to={item.to} 
              exact={getExactPath(item.to)}
              activeClassName={classes.activeItem} 
              className={classes.link} 
              key={item.key}
            >
              <ListItem 
                button 
                disableRipple
                className={classes.listItem}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} classes={{'primary': classes['listItemText']}}/>
              </ListItem>
            </NavLink>
          ))}
        </List>
      </div>
    </Drawer>
  );

  const MobileBottomNavigation = (
    <Paper className={classes.mobileBottomNavigation} elevation={3}>
      <BottomNavigation
        showLabels
        value={currentMobileRoute}
        onChange={(event, newValue) => {
          onMobileNavigationClick(newValue);
        }}
      >
        {mobileMenuItems && mobileMenuItems.map(item => (
          <BottomNavigationAction 
            key={item.key}
            label={item.text} 
            icon={item.icon} 
            value={item.key}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );

	return (
		<nav aria-label="team up menu">
      {isMobile 
        ? MobileBottomNavigation
        : DesktopSidebar
      }
    </nav>
	);
}

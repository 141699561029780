import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from '../../../context';
import { adminApi, teamApi } from '../../../api';
import DICTIONARY from '../../../utils/dictionary';
import useForm from '../../../hooks/formHook';

// Colors
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
}));

export default function DefinePrimeMemberDialog({teamId, member, handleCloseDialog}) {
  const { user, handleUserUpdate } = useContext(AuthContext);
  const classes = useStyles();

  //#region Form content 
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonStyle, setButtonStyle] = useState('');

  useEffect(() => {
    const isPrime = member.isPrime;

    if(!isPrime) { // Define
      setTitle(DICTIONARY.TEAM.primeMembership.defineMemberAsPrime.title);
      setSubtitle(<span>{DICTIONARY.TEAM.primeMembership.defineMemberAsPrime.subtitle.part1} <span style={{fontWeight: 'bold', color: 'black'}}>{member.name}</span> {DICTIONARY.TEAM.primeMembership.defineMemberAsPrime.subtitle.part2}</span>);
      setButtonText(DICTIONARY.TEAM.primeMembership.defineMemberAsPrime.form.submit);
      setButtonStyle({});
    } else { // Cancel
      setTitle(DICTIONARY.TEAM.primeMembership.cancelPrime.title);
      setSubtitle(<span>{DICTIONARY.TEAM.primeMembership.cancelPrime.subtitle.part1} <span style={{fontWeight: 'bold', color: 'black'}}>{member.name}</span> {DICTIONARY.TEAM.primeMembership.cancelPrime.subtitle.part2}</span>);
      setButtonText(DICTIONARY.TEAM.primeMembership.cancelPrime.form.submit);
      setButtonStyle({backgroundColor: red[400]});
    }
  }, []);
  //#endregion

  //#region Form Hook
	const onSubmit = async () => {
    const response = await adminApi.team.definePrimeMember(teamId, member.userId);

    if (!response.hasOwnProperty("error")) {
      // handleUserUpdate - User Auth context;
      const teamsIds = user.userData.teams || [];
      const updatedTeamsData = await teamApi.getTeamsByIds(teamsIds); // Get teamsData of user from 'teams' collection

      handleUserUpdate({
        teamsData: updatedTeamsData,
      });
    } else {
      throw new Error(response.error);
    };
	}

	const onCloseModal = (isSubmittedSuccessfully) => {
    handleCloseDialog(isSubmittedSuccessfully);
  }

	const {formError, isLoading, handleSubmit, handleCloseModal} = useForm({
		onSubmit, 
		onCloseModal,
	});
  //#endregion


  const dialogContent = (
		<span></span>
  );

  return {
		dialogTitle: title,
		dialogContent,
    dialogContentText: subtitle,
		sumbitButtonLabel: buttonText,
    submitButtonStyle: buttonStyle,
		handleSubmit,
		handleCloseModal,
		formError,
		isLoading,
	};
}
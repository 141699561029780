import React, { useState, useContext, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../../context';
import { userApi } from '../../../api';
import firebase from "../../../config/firebase";
import VALIDATORS from '../../../utils/validators';
import DICTIONARY from '../../../utils/dictionary';
import useForm from '../../../hooks/formHook';
import { COUNTRY_CALLING_CODES, } from '../../../utils/consts';
import { isVerbose, countryToFlag, buildPhoneNumber } from '../../../utils/utils';

// App Components
import SubmitButton from '../../shared/button/SubmitButton';

// Material UI Components
import { TextField, Button, Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Autocomplete, Alert } from "@material-ui/lab";

// Icons
import {
	Close as CloseIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
	},
	dialogContent: {
		padding: theme.spacing(2, 3),
		paddingTop: `${theme.spacing(0)} !important`, // override default paddingTop
	},
	form: {
		width: "100%", // Fix IE 11 issue.
	},
	formControl: {
    marginTop: theme.spacing(2),
  },
	closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
	phoneNumberInputContainer: {
		display: 'flex',
	},
	countriesDropdown: {
		width: '300px !important',
		left: '20px !important',
		[theme.breakpoints.up("sm")]: {
			left: '90px !important',
    }
	}
}));

const PROGRESS_STEPS = {
	phoneNumber: 1,
	verificationCode: 2,
};

export default function EnterUserPhoneNumberDialog(props) {
	const { user, handleUserUpdate } = useContext(AuthContext);
	const captchaRef = useRef(null);
  const classes = useStyles();
  const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const userId = user.userAuth.uid;

	const [progressStep, setProgressStep] = useState(PROGRESS_STEPS.phoneNumber);
	const [verificationCode, setVerificationCode] = useState('');
	const _verificationGeneratorRef = useRef(undefined);
	const [phoneNumberEntered, setPhoneNumberEntered] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [verificationCodeError, setVerificationCodeError] = useState('');

	//#region Verification code
	const handleVerificationCodeChange = async (e) => {
		const { id, value } = e.target;
		setVerificationCode(value);
		setVerificationCodeError('');
	};
	//#endregion

	//#region Country codes
	const [countryCodesArray, setCountryCodesArray] = useState([]);
	const convertCountryCodesToArray = () => {
		 let retval = Object.keys(COUNTRY_CALLING_CODES).map((countryId) => {
				return {
					...COUNTRY_CALLING_CODES[countryId],
					countryId, 
				}
		 	});
		 return retval;
	};
	useEffect(() => {
		const countryCodesArray = convertCountryCodesToArray();
		setCountryCodesArray(countryCodesArray);
	}, [])
	//#endregion

	//#region Form Hook
	const initalFormState = {
		country: {
			label: DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.form.field.country,
			value: user.userData.phoneNumber?.country || '',
			isValid: true,
			required: true,
			validators: [],
		},
		phoneNumber: {
			label: DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.form.field.phoneNumber,
			value: user.userData.phoneNumber?.number || '',
			isValid: true,
			required: true,
			validators: [
				{
					validate: (val, formState) => {
						const countryId = formState.country.value.countryId;
						const isValid = VALIDATORS.isValidPhoneNumber(val, countryId);
						return isValid;
					},
					errorMessage: DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.form.error.invalidPhoneNumber,
				},
			],
		},
	};

	const handleSubmit = async (e) => {
		try{
			e && e.preventDefault();

			// We use generator function because we need to wait fot the user response while are on "provider.verifyPhoneNumber" function
			async function* verificationGenerator () {
				let isVerificationCodeValid = false;
				
				// 1st - Get for the verificationId
				const {verificationId, parsedPhoneNumber} = yield;

				setIsLoading(false);
				// 2nd - Show verification code input field
				setProgressStep(PROGRESS_STEPS.verificationCode); 

				// 3rd - Wait for the user to insert the verificationCode
				while(!isVerificationCodeValid) {
					const verificationCode = yield;
					setIsLoading(true);
					
					// 4th - User entered the verification code
					try{
							const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
							await user.userAuth.updatePhoneNumber(credential);

							const response = await userApi.updatePhoneNumber(userId, parsedPhoneNumber);

							if(!response.hasOwnProperty("error")){
								handleUserUpdate({
									userData: {
										...user.userData,
										phoneNumber: parsedPhoneNumber,
									}
								});
							} else {
								setVerificationCodeError(DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.form.error.generalError);
								setIsLoading(false);
							}

							isVerificationCodeValid = true;
							setIsLoading(false);
							handleCloseModal(true);
							// update 'users' collection with the data that user accept to get sms + phone number
						} catch(error) {
							isVerificationCodeValid = false; // keep going back to to the 'yield' in the while loop until the verification code is verified

							let errorMessage;
							const errorCode = error.code;
			
							switch(errorCode) {
								case 'auth/invalid-verification-code':
									errorMessage = DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.form.error.invalidVerificationCode;
									break;
								case 'auth/credential-already-in-use':
									errorMessage = DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.form.error.phoneAlreadyInUse;
									break;
								default:
									errorMessage = DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.form.error.invalidVerificationCode;
									break;
							}
							isVerbose() && console.error(`Verification code: ${error.message}`);

							setVerificationCodeError(errorMessage);
							setIsLoading(false);
						}
				}
			} 

			if(!_verificationGeneratorRef.current) {
				_verificationGeneratorRef.current = verificationGenerator();
			}

			// Handling the 'submit' button clicks
			switch (progressStep) {
				case PROGRESS_STEPS.phoneNumber: { // Step 1
					if(isFormValid(formState)) {
						handlePhoneNumberSubmitted(_verificationGeneratorRef);
					}
					break;
				}
				case PROGRESS_STEPS.verificationCode: { // Step 2
					_verificationGeneratorRef.current.next(verificationCode);
					break;
				}
				default:
					break;
			}
		} catch(e) {}
	}

	const handlePhoneNumberSubmitted = (_verificationGeneratorRef) => {
		setIsLoading(true);
		_verificationGeneratorRef.current.next();

		var applicationVerifier = new firebase.auth.RecaptchaVerifier(captchaRef.current, {
			'size': 'invisible',
			'callback': (response) => {}
		});
		var provider = new firebase.auth.PhoneAuthProvider();
		const parsedPhoneNumber = buildPhoneNumber(formState.country.value.value, formState.phoneNumber.value);
		setPhoneNumberEntered(parsedPhoneNumber);
		provider.verifyPhoneNumber(parsedPhoneNumber, applicationVerifier)
			.then((verificationId) => {
				_verificationGeneratorRef.current.next({verificationId, parsedPhoneNumber});
			})
			.catch((e) => {
			});
	};

	const handleCloseModal = (isSubmittedSuccessfully) => {
    props.handleClose(isSubmittedSuccessfully);
  }

	const {formState, formError, handleInputChange, isFormValid} = useForm({
		initalFormState, 
	});
	//#endregion

  return (
    <Dialog 
      open={props.open} 
      fullScreen={fullScreen}
      fullWidth={ true } 
			maxWidth={"sm"}
      aria-labelledby="form-dialog-title"
    >
      {/* Dialog Title */}
      <DialogTitle id="form-dialog-title">
				{progressStep === PROGRESS_STEPS.phoneNumber 
					? DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.form.phoneNumberStep.title
					: DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.form.verificationCodeStep.title
				}
				<IconButton 
					aria-label="close" 
					className={classes.closeButton} 
					onClick={(e) => handleCloseModal(false)}>
					<CloseIcon />
				</IconButton>
      </DialogTitle>

			{/* Dialog Content */}
			<form className={classes.form} noValidate>
				<DialogContent className={classes.dialogContent}>
					<Typography variant="caption" display="block" gutterBottom>
						{progressStep === PROGRESS_STEPS.phoneNumber 
							? DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.form.phoneNumberStep.subTitle
							: `${DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.form.verificationCodeStep.subTitle} ${phoneNumberEntered}`
						}
					</Typography>

					{/* Hidden recaptcha */}
					<div id="recaptcha-container" ref={captchaRef}></div>

					{/* Step 1 - Country and phone number */}	
					{progressStep === PROGRESS_STEPS.phoneNumber && <Box display="flex">
						<Autocomplete
							id="country"
							options={countryCodesArray}
							closeIcon={<CloseIcon style={{fontSize: '16px'}}/>}
							value={formState.country.value}
							onChange={(event, newValue) => {
								handleInputChange(event, {
									type: 'autocomplete',
									name: 'country',
									value: newValue,
								});
							}}
							getOptionLabel={(country) => country ? `+${country.value}` : ''}
							filterOptions={(options, state) => {
								const value = state.inputValue.toLowerCase();
								const filteredOptions = options.filter((option) => option.code.toLowerCase().includes(value) || option.label.toLowerCase().includes(value) || option.value.includes(value));
								return filteredOptions;
							}}
							style={{width: '160px', marginRight: '10px'}}
							renderOption={(country) => (
								<React.Fragment>
									<span style={{marginRight: '8px'}}>{countryToFlag(country.code)}</span>
									{country.label} ({country.code}) +{country.value}
								</React.Fragment>
							)}
							renderInput={(params) => <TextField 
								margin='normal'
								autoFocus
								{...params} 
								label={formState.country.label} 
								error={!formState.country.isValid}
								required
								inputProps={{
									...params.inputProps,
									autoComplete: 'new-password', // disable autocomplete and autofill
								}}
							/>}
							classes={{'popper': classes.countriesDropdown}}
						/>
						<TextField
							margin='normal'
							fullWidth
							required
							id='phoneNumber'
							label={formState.phoneNumber.label}
							name='phoneNumber'
							value={formState.phoneNumber.value}
							onChange={handleInputChange}
							autoComplete='phoneNumber'
							error={!formState.phoneNumber.isValid}
						/>
					</Box>}

					{/* Step 2 - verification code from mobile device */}	
					{progressStep === PROGRESS_STEPS.verificationCode && <Box display="flex">
						<TextField
							margin='normal'
							fullWidth
							required
							id='verificationCode'
							label={DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.form.field.verificationCode}
							name='verificationCode'
							value={verificationCode}
							onChange={handleVerificationCodeChange}
							autoComplete='verificationCode'
							error={verificationCodeError !== ''}
						/>
					</Box>}					

					{/* Error message - 1st step */}
					{formError && (
						<Box mt={3}>
							<Alert severity='error'>{formError}</Alert>
						</Box>
					)}

					{/* Error message - 2nd step */}
					{verificationCodeError && (
						<Box mt={3}>
							<Alert severity='error'>{verificationCodeError}</Alert>
						</Box>
					)}
				</DialogContent>

				{/* Dialog Actions */}
				<DialogActions>
					<Button onClick={(e) => handleCloseModal(false)} >
						{DICTIONARY.GENERAL.button.cancel}
					</Button>

					{/* Submit button */}
					<SubmitButton 
            text={
							progressStep === PROGRESS_STEPS.phoneNumber 
								? DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.form.phoneNumberStep.submitButton
								: DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.form.verificationCodeStep.submitButton
						}
            isLoading={isLoading} 
            handleSubmit={handleSubmit}
            disabled={isLoading || formError.length > 0}
          />
				</DialogActions>
			</form>
		</Dialog>
  );
}
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

// App Components

// Material UI Components
import { Divider, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  statsInfoCardContentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  statsInfoCardContentValue: {
    width: '25px',
    fontSize: theme.typography.pxToRem(22),
    textAlign: 'center',
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(30),
      width: '15px',
    }
  },
  verticalDivider: {
    height: '50%',
    margin: theme.spacing(0, 1),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 3),
    }
  },
}));

export default function StatInfoCardContent({entity, value}) {
  const classes = useStyles();

  return (
    <div className={classes.statsInfoCardContentWrapper}>
      {entity}
      <Divider orientation="vertical" classes={{'root': classes.verticalDivider}} />
      <Typography display="block" gutterBottom className={classes.statsInfoCardContentValue}>{value}</Typography>
  </div>
  );
}
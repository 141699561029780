import firebase from "../config/firebase";
import { isVerbose } from '../utils/utils';

const event = {
  get: async function(eventId) {
    try{
      const eventRef = firebase.firestore().collection("events").doc(eventId);
      const event = await eventRef.get();

      if(event.exists) {
        return event.data();
      } else {
        console.log("No such document!");
        return;
      }
    } catch(e) {
      const error = `Error getting event ${eventId}, ${e}`;
      isVerbose() && console.error(error);
      return {
        error
      };
    }
  },
  getEventsByTeamIds: async function({teamsIds, fromDate, sortOrder = "desc", limit = undefined, }) {
    try{
      const eventsData = {};
      let eventsPromises = [];

      if(fromDate !== undefined) {
        eventsPromises = teamsIds.map(id => firebase.firestore().collection("events")
          .where("teamId", "==", id)
          .where("deletedAt", "==", null)
          .where("date", ">", fromDate)
          .orderBy("date", sortOrder)
          .limit(limit)
          .get());
      } else {
        eventsPromises = teamsIds.map(id => firebase.firestore().collection("events")
          .where("teamId", "==", id)
          .where("deletedAt", "==", null)
          .get());
      };
      const [...querySnapshot] = await Promise.all(eventsPromises);

      querySnapshot.forEach(team => {
        let docs = team.docs;

        docs && docs.forEach(doc => {
          eventsData[doc.id] = doc.data();
        });
      });

      return eventsData;
    } catch(e) {
      const error = `Error getting events, ${e}`;
      isVerbose() && console.error(error);
      return [];
    }
  },
  register: async function(eventId, teamId, userData) {
    try{
      const registerToEvent = firebase.functions().httpsCallable('registerToEvent');
      const response = await registerToEvent({
        eventId,
        teamId,
        userData,
      });

      return response.data;
    } catch(e) {
      isVerbose() && console.error(`Error registering to event: ${e}`);
      return {
        error: `Can't register to event. Please try again or contact support.`
      };
    }
  },
  unregister: async function(eventId, teamId, userData) {
    try{
      const unregisterFromEvent = firebase.functions().httpsCallable('unregisterFromEvent');
      const response = await unregisterFromEvent({
        eventId,
        teamId,
        userData,
      });

      return response.data;
    } catch(e) {
      isVerbose() && console.error(`Error unregistering from event: ${e}`);
      return {
        error: `Can't unregister from event. Please try again or contact support.`
      };
    }
  },
  logs: {
    get: async function(eventId) {
      try{
        const logsSnapshot = await firebase.firestore().collection("events").doc(eventId).collection("logs").get();
        const logs = logsSnapshot.docs.map(doc => doc.data());
  
        return {
          data: logs,
        };
      } catch(e) {
        const error = `Error getting event ${eventId}, ${e}`;
        isVerbose() && console.error(error);
        return {
          error
        };
      }
    },
  },
  stats: {
    get: async function(eventId) {
      try{
        const eventStatsRef = firebase.firestore().collection("events_stats").doc(eventId);
        const eventStats = await eventStatsRef.get();

        if(eventStats.exists) {
          return eventStats.data();
        } else {
          console.log("No such document!");
          return;
        }
      } catch(e) {
        const error = `Error getting event stats ${eventId}, ${e}`;
        isVerbose() && console.error(error);
        return {
          error
        };
      }
    },
    update: async function(eventId, groupsStats, playersStats) {
      try{
        const eventStatsRef = firebase.firestore().collection("events_stats").doc(eventId);
        await eventStatsRef.update({
          groupsStats,
          playersStats,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        return {
          success: true,
        };
      } catch(e) {
        isVerbose() && console.error(`Error updaing event stats. event: ${e}`);
        return {
          error: `Can't update event stats. Please try again or contact support.`
        };
      }
    },
  },
}

export default event;
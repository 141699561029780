import React from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';

// Material UI Components
import {
  Tooltip, 
  IconButton
} from '@material-ui/core';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    marginTop: '2px',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({

}));

export default function ButtonIconWithTooltip({ iconElement, title, onClick, color }) {
  const classes = useStyles();

  return (
    <CustomTooltip title={title} arrow>
      <IconButton 
        aria-label={title}
        onClick={onClick}
        color={color || 'default'}
        style={{ 
          width: '30px',
          height: '30px',
          padding: 0,
          margin: '0 4px',
        }}
      >
        {iconElement}
      </IconButton>
    </CustomTooltip>
  );
}
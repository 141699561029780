import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
	centerElement: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

export default function ComponentLoader({wrapperHeight = undefined}) {
	const classes = useStyles();

	return (
		<div style={{ 
			height: wrapperHeight ? wrapperHeight : 'auto', 
			width: '100%',
			position: wrapperHeight ? 'relative' : 'static',
		}}>
			<div className={classes.centerElement}>
				<CircularProgress size={24} className={classes.componentLoader}/> 
			</div>
		</div>
	);
}

import React, { useState, useContext } from "react";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "../../config/firebase";
import { googleSignin, facebookSignin, sendEmailVerification } from '../../utils/helpers/authHelper';
import { DialogContext, SnackbarContext } from '../../context';
import { isInvitationLinkExists, isVerbose, parsedPathname } from '../../utils/utils';
import ROUTES from '../../routes';
import VALIDATORS from '../../utils/validators';
import DICTIONARY from '../../utils/dictionary';
import md5 from "md5";

// App Components
import SubmitButton from '../../components/shared/button/SubmitButton';
import SocialAuthButton from '../../components/shared/button/SocialAuthButton';

// Material UI Components
import {TextField, Link, Container, Grid, Typography, Box, Divider, Button} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

// Icons
import Logo from "../../assets/images/logo/logo.svg";
import GoogleIcon from "../../assets/svgIcons/google.svg";
import FacebookIcon from "../../assets/svgIcons/facebook.svg";

// Colors
import { grey, teal } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	logo: {
		height: '44px',
		marginBottom: theme.spacing(1),
		[theme.breakpoints.up("sm")]: {
      height: '50px',
    }
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		// height: '100%',
		marginTop: theme.spacing(1),
		[theme.breakpoints.up("sm")]: {
      height: 'auto',
    },
	},
	divider: {
		marginTop: theme.spacing(2),
	},
	forgotPasswordButton: {
		padding: 0,
		fontWeight: 400,
		backgroundColor: 'transparent !important',
		color: grey[900],
		'&:hover': {
			textDecoration: 'underline',
		}
	},
	forgotPasswordWrapper: {
		display: 'flex',
		justifyContent: 'center',
		[theme.breakpoints.up("sm")]: {
			justifyContent: 'flex-end'
    },
	},
	dontHaveLineWrapper: {
		display: 'flex',
		justifyContent: 'center',
		[theme.breakpoints.up("sm")]: {
			justifyContent: 'flex-start'
    },
	},
	dontHaveLine: {
		color: grey[900],
		lineHeight: '24px',
		'&:hover': {
			textDecoration: 'none',
			'& $signupLink': {
				textDecoration: 'underline',
			},
		},

	},
	signupLink: {
		marginLeft: '5px',
		color: teal['A700'],
		fontWeight: 500,
		'&:hover': {
			textDecoration: 'underline',
			color: teal['A700'],
		}
	},
}));

export default function Signup(props) {
	const { handleCloseDialog, getDialogProps } = useContext(DialogContext);
	const { handleSnackbarDisplay } = useContext(SnackbarContext);
	const history = useHistory();
	const location = useLocation();
  const classes = useStyles();
  const windowHeight = window.innerHeight;

	//#region Form 
	const initalFormState = {
		name: {
			label: DICTIONARY.AUTH.form.field.name,
			value: '',
			isValid: true,
			required: true,
			validators: [
				{
					validate: (val) => VALIDATORS.isValidString(val, 2),
					errorMessage: DICTIONARY.AUTH.form.error.nameLength,
				},
			],
		},
		email: {
			label: DICTIONARY.AUTH.form.field.email,
			value: '',
			isValid: true,
			validators: [
				{
					validate: (val) => VALIDATORS.isValidEmail(val),
					errorMessage: DICTIONARY.GENERAL.form.badlyFormattedEmail,
				},
			],
		},
		password: {
			label: DICTIONARY.AUTH.form.field.password,
			value: '',
			isValid: true,
			validators: [],
		},
	};

	const [formState, setFormState] = useState(initalFormState);
	const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		let key = id ;
		let val = value;

		setFormState((prevState) => {
			return { 
				...prevState,
				[key]: {
					...prevState[key],
					value: val,
					isValid: true,
				}
			};
		});
		setFormError("");
	};

	const handleSubmit = () => {
		setIsLoading(true);
		firebase.auth().createUserWithEmailAndPassword(formState.email.value, formState.password.value)
			.then(async (firebaseUser ) => {
				firebaseUser.user.updateProfile({
					displayName: formState.name.value,
					photoURL: `https://gravatar.com/avatar/${md5(formState.email.value)}?d=identicon`,
				})
				// .then(() => {
				// 	setIsLoading(false);
				// }).catch((error) => {
				// 	setIsLoading(false);
				// })

				const invitationLink = isInvitationLinkExists(props.location.search) ? props.location.search : undefined;
				await sendEmailVerification(firebaseUser , handleSnackbarDisplay, invitationLink);
			})
			.catch((error) => {
				let errorMessage;
				const errorCode = error.code;

				switch(errorCode) {
					case 'auth/email-already-in-use':
						errorMessage = DICTIONARY.AUTH.form.error.emailInUse;
						break;
					case 'auth/invalid-email':
						errorMessage = DICTIONARY.AUTH.form.error.badlyFormattedEmail;
						break;
					case 'auth/weak-password':
						errorMessage = DICTIONARY.AUTH.form.error.weakPassword;
						break;
					default:
						errorMessage = DICTIONARY.AUTH.form.error.generalError;
						break;
				}
				isVerbose() && console.error(`Login error: ${error.message}`);

				setFormError(errorMessage);
				setIsLoading(false);
			});
	};
	//#endregion

	//#region Social Auth
  const handleSignUpWithGoogle = async (e) => {
		e.preventDefault();
		googleSignin();
	};
	
	const handleSignUpWithFacebook = async (e) => {
		e.preventDefault();
		facebookSignin(handleSnackbarDisplay);
	};
	//#endregion

	//#region Forgot password dialog
	const handleClickForgotPasswordDialog = () => {
		handleCloseDialog(handleCloseForgotPasswordDialog); // Update handleCloseDialog API context function

		history.push({
			pathname: `${parsedPathname(location.pathname)}/forgot-password`,
			state: {
				background: location,
				id: 'forgotPassward',
			},
		});
	};
	
	const handleCloseForgotPasswordDialog = async ({isSubmittedSuccessfully}) => {
		//
	};
	//#endregion

	return (
		<React.Fragment>
			<Container component="main" maxWidth="xs" className={classes.root} style={{height:windowHeight }}>
				<img src={Logo} alt="Team Up" className={classes.logo}/>
				{/* <Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar> */}
				<Typography component='h1' variant='h5' color="secondary">
					{DICTIONARY.AUTH.title.signup}
				</Typography>

				{/* Form */}
				<form className={classes.form} noValidate>

					{/* Name */}
					<TextField
						autoFocus
						color='secondary'
						margin='dense'
						required
						fullWidth
						id='name'
						label={formState.name.label}
						name='name'
						value={formState.name.value}
						onChange={handleInputChange}
						autoComplete='name'
						error={!formState.name.isValid}
					/>

					{/* Email */}
					<TextField
						color='secondary'
						margin='dense'
						required
						fullWidth
						type='email'
						id='email'
						label={formState.email.label}
						name='email'
						value={formState.email.value}
						onChange={handleInputChange}
						autoComplete='email'
						error={!formState.email.isValid}
					/>

					{/* Password */}
					<TextField
						color='secondary'
						margin='dense'
						required
						fullWidth
						label={formState.password.label}
						name='password'
						value={formState.password.value}
						onChange={handleInputChange}
						type='password'
						id='password'
						autoComplete='current-password'
						error={!formState.password.isValid}
					/>

					{/* Error message */}
					{formError && (
						<Box mt={1}>
							<Alert severity='error'>{formError}</Alert>
						</Box>
					)}

					{/* Signup button */}
					<SubmitButton 
						text={DICTIONARY.AUTH.button.signup}
						style={{width: '150px'}}
						color='secondary'
						isLoading={isLoading} 
						handleSubmit={handleSubmit}
						disabled={isLoading || formError.length > 0}
					/>
				</form>

				{/* Forget a password  */}
				<Grid container>
					<Grid item xs={12} sm={7} className={classes.dontHaveLineWrapper}>
						<Link
							component={RouterLink} 
							to={isInvitationLinkExists(props.location.search) ? `${ROUTES.login.path}${props.location.search}` : `${ROUTES.login.path}`}
							variant='body2'
							classes={{'root': classes.dontHaveLine}}
						>
							{DICTIONARY.AUTH.button.alreadyHaveAccount}
							<span className={classes.signupLink}>{DICTIONARY.AUTH.button.login}</span>
						</Link>
					</Grid>
					<Grid item xs={12} sm={5} className={classes.forgotPasswordWrapper}>
						<Button
							onClick={handleClickForgotPasswordDialog}
							classes={{'root': classes.forgotPasswordButton}}
							disableRipple
						>
							{DICTIONARY.AUTH.button.forgotPassword}
						</Button>
					</Grid>
				</Grid>

				{/* Or - sign with external providers */}
				<Grid
					container
					alignItems='center'
					justifyContent='center'
					spacing={2}
					className={classes.divider}
				>
					<Grid item xs={4}>
						<Divider />
					</Grid>
					<Grid item>Or</Grid>
					<Grid item xs={4}>
						<Divider />
					</Grid>
				</Grid>

				{/* Login with google */}
				<Box mt={2} align='center'>
					<SocialAuthButton 
						icon={GoogleIcon}
						text={DICTIONARY.AUTH.button.signupGoogle}
						onClick={handleSignUpWithGoogle}
					/>
				</Box>

				{/* Signup with facebook */}
				<Box mt={2} align='center'>
					<SocialAuthButton 
						icon={FacebookIcon}
						text={DICTIONARY.AUTH.button.signupFacebook}
						onClick={handleSignUpWithFacebook}
					/>
				</Box>
			</Container>
		</React.Fragment>
	);
}

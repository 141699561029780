import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

// Colors
import {teal} from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: teal['A700'],
		// backgroundColor: theme.palette.primary.main,
		backgroundColor: '#fafafa',
	},
}));

export default function AppLoader() {
	const classes = useStyles();

	return (
		<Backdrop className={classes.backdrop} open={true}>
			<CircularProgress color='inherit' />
		</Backdrop>
	);
}

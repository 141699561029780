import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SnackbarContext } from '../../context';
import DICTIONARY from '../../utils/dictionary';
import { showEventRegisterSnackbar } from '../../utils/helpers/eventHelper';

// Material UI Components
import { Button, Tooltip, IconButton, CircularProgress, Typography, Box, Hidden } from '@material-ui/core';

import { 
	PersonAdd as PersonAddIcon,
	PersonAddDisabled as PersonAddDisabledIcon,
} from '@material-ui/icons';

// Colors
import { grey, blueGrey, red, amber, teal, pink } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  registrationDetails: {
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(16),
    color: blueGrey[900],
  },
  disabledEvent: {
    fontSize: theme.typography.pxToRem(12),
    color: grey[400],
    textAlign: 'right',
  },
  submitButtonWrapper: {
		position: "relative",
	},
	registerButton: {
    width: '100px',
    borderRadius: '15px',
    border: `1px solid ${blueGrey[100]}`,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: grey[50],
    },
	},
  mobileRegisterButton:{
    width: '100%',
    minWidth: '66px',
    borderRadius: '18px',
    border: `1px solid ${blueGrey[100]}`,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: grey[50],
    },
  },
  availabaleSpotsText: {
		fontSize: theme.typography.pxToRem(12),
		marginTop: '2px',
	},
	buttonProgress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

export default function RegisterToEventButton({ event, onClick, isLoading }) {
  const { handleSnackbarDisplay } = useContext(SnackbarContext);
  const classes = useStyles();

	const getAvailableSpotsSentence = (event) => {
		const {availableSpotsLeft, isUserRegisterd} = event;

    if(availableSpotsLeft === 1) {
      return `${availableSpotsLeft} spot left`;
    } else if (availableSpotsLeft > 1) {
      return `${availableSpotsLeft} spots left`;
    } else {
      if(isUserRegisterd) { // If i'm singed
        return `Full`;
      } else if(!isUserRegisterd) {
        return `Join waitlist`;
      } else {
        return '';
      }
    }
  };

  const handleButtonClick = async () => {
    let response = await onClick(event);
    if(!event.isUserRegisterd) { // Show snackbar just for 'Register' case (for 'Unregister' we have the dialog)
      showEventRegisterSnackbar(response, handleSnackbarDisplay); // Display a snackbar with the action result
    }
  };

  return (event.parsedIsOpenForRegistration === true ? (
    <React.Fragment>
      {/* Large Screen */}
      <Hidden xsDown implementation="js">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width={1}>
          <Tooltip title={getAvailableSpotsSentence(event)} arrow>
            <Box
              display='flex'
              justifyContent='center'
              className={classes.submitButtonWrapper}
            >
              <Button 
                aria-label="Register"
                variant="outlined" 
                style={{'color': event.isUserRegisterd === true ? red['300'] : event.availableSpotsLeft > 0 ? teal['500'] : amber['600'] }}
                disabled={isLoading}
                onClick={async (e) => {
                  e.stopPropagation();
                  await handleButtonClick();
                }}
                onFocus={(event) => event.stopPropagation()}
                classes={{'root': classes.registerButton}}
                size="small"
                // startIcon={<PersonAddIcon/>}
              >
                {event.isUserRegisterd === true ? `${DICTIONARY.EVENT.registerToEventButton.unregister}` : event.availableSpotsLeft > 0 ? `${DICTIONARY.EVENT.registerToEventButton.register}` : `${DICTIONARY.EVENT.registerToEventButton.waitlist}` }
              </Button>

              {/* Loading spinner inside button */}
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Box>
          </Tooltip>
          <Typography className={classes.availabaleSpotsText}>{getAvailableSpotsSentence(event)}</Typography>
        </Box>
      </Hidden>

      {/* Mobile screen */}
      <Hidden smUp implementation="js">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width={1}>
          <Box
            display='flex'
            justifyContent='center'
            className={classes.submitButtonWrapper}
            width={1}
          >
            <IconButton 
              aria-label="Register"
              variant="contained"
              style={{
                'color': event.isUserRegisterd === true ? red['200'] : event.availableSpotsLeft > 0 ? teal['A700'] : amber['300'],
                'borderColor': event.isUserRegisterd === true ? red['100'] : event.availableSpotsLeft > 0 ? teal['300'] : amber['300'],
              }}
              disabled={isLoading}
              onClick={async (e) => {
                e.stopPropagation();
                await handleButtonClick();
              }}
              onFocus={(event) => event.stopPropagation()}
              classes={{'root': classes.mobileRegisterButton}}
              size="small"
              // startIcon={<PersonAddIcon/>}
            >
              {event.isUserRegisterd === true ? <PersonAddDisabledIcon /> : <PersonAddIcon />}
            </IconButton>

            {/* Loading spinner inside button */}
            {isLoading && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </Box>
          <Typography className={classes.availabaleSpotsText}>{getAvailableSpotsSentence(event)}</Typography>
        </Box>
      </Hidden>
    </React.Fragment>
    ) : (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography className={classes.disabledEvent}>{`${DICTIONARY.EVENT.registerToEventButton.disabled} ${event.parsedOpenRegistrationDateAndTime}`}</Typography>
      </Box>
    )
  );
}
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { eventApi } from '../../../api/index';
import { Timestamp } from '../../../config/firebase';
import { formatDate } from '../../../utils/utils';
import DICTIONARY from '../../../utils/dictionary';
import ROUTES from '../../../routes';

// Material UI Components
import { Card, CardHeader, CardContent, Link } from '@material-ui/core';

// App components
import ComponentLoader from '../../shared/loader/ComponentLoader';
import EmptyState from '../../shared/EmptyState';

// Icons
import EventBusyIcon from '@material-ui/icons/EventBusy';

// Colors
import { blue, blueGrey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'    
  },
  cardHeader: {
    padding: theme.spacing(2, 2, 1, 2),
  },
  cardContent: {
    flex: 1,
    position: 'relative',
    width: '100%',
		padding: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "&:last-child": {
			// paddingBottom: theme.spacing(1)
		}
	},
  eventDate: {
    color: blue[800],
    '&:hover': {
      textDecoration: 'none',
      color: blue[900],
    },
  },
}));

export default function NextEventCard({teamId}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [eventId, setEventId] = useState('');
  const [nextEventDate, setNextEventDate] = useState('');

  useEffect(() => {
    let isSubscribed = true; // is subscribed to useEffect

    (async () => {
      try {
        setIsLoading(true);
        const data = {
          teamsIds: [teamId], 
          fromDate: Timestamp.now(), 
          sortOrder:"asc",
          limit: 1, 
        };
        const eventsData = await eventApi.getEventsByTeamIds(data);

        if(isSubscribed) {
          let nextEventDate;
          if(Object.keys(eventsData).length > 0) {
            const eventId = Object.keys(eventsData)[0];
            setEventId(eventId);
            nextEventDate = formatDate(eventsData[eventId].date, 'MMM dd, hh:mm a');
          } else {
            nextEventDate = '';
          }
          setNextEventDate(nextEventDate);
          setIsLoading(false);
        }
      } catch(e) {
        setIsLoading(false);
      }
    })();

    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        titleTypographyProps={{ variant:'h6' }}
        title={DICTIONARY.TEAM.team.card.title.nextEvent}
        classes={{
          'root': classes['cardHeader'],
        }}
      />
      <CardContent 						
        classes={{
          'root': classes['cardContent'],
        }}>
          {isLoading
            ? <ComponentLoader wrapperHeight={26}/>
            : (nextEventDate !== '' 
              ? <Link 
                  component={RouterLink} 
                  to={`${ROUTES.myEvents.path}/${eventId}`} 
                  variant='h5' 
                  color="inherit"
                  // underline="false"
                  className={classes.eventDate}
                >
                  {nextEventDate}
                </Link>
                : <EmptyState 
                    icon={<EventBusyIcon style={{ fontSize: 40, color: blueGrey[500] }}/>}
                    title={DICTIONARY.TEAM.team.card.noNextEvent}
                  />
              )
          }
      </CardContent>
    </Card>
  );
}
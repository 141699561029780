import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext, SnackbarContext } from '../../../context';
import DICTIONARY from '../../../utils/dictionary';
import { userApi } from '../../../api';
import useIsMount from '../../../hooks/isMountHook';
import { SNACKBAR_SEVERITY } from '../../../utils/consts';

// App Components
import EnterUserPhoneNumberDialog from './EnterUserPhoneNumberDialog';
import HeaderActions from '../../shared/button/HeaderActions';

// Material UI Components
import { Box, Switch, FormControlLabel, Typography, CircularProgress } from '@material-ui/core';

// Icons
import {
	PhoneAndroid as PhoneAndroidIcon
} from '@material-ui/icons';

// Colors
import { grey, blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	loader: {
		position: 'relative',
		top: '3px',
	},
	EnterPhoneNumberButton: {
		margin: theme.spacing(0, 2),
	},
	emptyStatePhoneNumber: {
		color: grey[400],
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.up("sm")]: {
			justifyContent: 'flex-start'
    },
	},
	phoneNumberExist: {
		color: blue[500],
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.up("sm")]: {
			justifyContent: 'flex-start'
    },
	},
}));

export default function SmsNotificationsSettings() {
  const { user } = useContext(AuthContext);
	const { handleSnackbarDisplay } = useContext(SnackbarContext);
  const classes = useStyles();
	const isMount = useIsMount();

	const [smsPermissionStatus, setSmsPermissionStatus] = useState(user.userData.enableSmsNotifications || false);
	const [isLoading, setIsLoading] = useState(false);

	const handleSmsStateChange = async (event) => {
		if(user.userData.phoneNumber) {
			setIsLoading(true);
		} else {
			setSmsPermissionStatus(event.target.checked);
		}
  };

	useEffect(() => {
		if(!isMount && isLoading) {
			(async () => {
				const response = await userApi.updateSmsNotificationPermission(user.userAuth.uid, !smsPermissionStatus);

				if(!response.hasOwnProperty("error")){
					setIsLoading(false);
					setSmsPermissionStatus(!smsPermissionStatus);
				} else {
					handleSnackbarDisplay({
						severity: SNACKBAR_SEVERITY.error,
						message: DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.error,
					});
					setIsLoading(false);
				}
			})();
		}
	}, [isLoading])

  //#region Update phone number dialog
	const [openEnterUserPhoneNumberDialog, setOpenEnterUserPhoneNumberDialog] = useState(false);

  const handleClickOpenEnterUserPhoneNumberDialog = () => {
    setOpenEnterUserPhoneNumberDialog(true);
  };

  const handleCloseEnterUserPhoneNumberDialog = async (isSubmittedSuccessfully) => {
		setOpenEnterUserPhoneNumberDialog(false);
	};
	//#endregion

	const ACTIONS = {
		0: {
			actions: [
				{
					key: 'phoneNumber',
					iconElement: <PhoneAndroidIcon />,
					title: user.userData.phoneNumber ? DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.actions.update : DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.actions.enter,
					onClick: handleClickOpenEnterUserPhoneNumberDialog,
					className: classes.EnterPhoneNumberButton
				},
			],
		}
	};

  return (
    <React.Fragment>
			{/* SMS Toggle */}
			<FormControlLabel
				control={
					<Switch 
						checked={smsPermissionStatus} 
						color="primary"
						onChange={handleSmsStateChange} 
						name="sms" 
					/>
				}
				label={DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.title}
				labelPlacement="end"
			/>
			{isLoading && <CircularProgress size={20} className={classes.loader}/> }

			<Box mb={3} display="flex" flexDirection="column">
				{!smsPermissionStatus 
					? '' 
					: user.userData.phoneNumber
						?	<div className={classes.phoneNumberExist}>
								<Typography variant="body1" display="block">{user.userData.phoneNumber}</Typography> 
								<HeaderActions actions={ACTIONS}/>
							</div> 
						:	<div className={classes.emptyStatePhoneNumber}>
								<Typography variant="body2" display="inline" >{DICTIONARY.MY_ACCOUNT.notificationsSettings.sms.emptyState}</Typography> 
								<HeaderActions actions={ACTIONS}/>
							</div>
				}
			</Box>
      {/* Edit details dialog */}
      {openEnterUserPhoneNumberDialog && <EnterUserPhoneNumberDialog 
        open={openEnterUserPhoneNumberDialog} 
        handleClose={handleCloseEnterUserPhoneNumberDialog} 
      />}
    </React.Fragment>
  );
}
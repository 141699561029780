import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { teamApi } from '../../api';
import DICTIONARY from '../../utils/dictionary';

// App Components
import InfoCard from "../shared/InfoCard";
import UserAvatar from '../shared/userAvatar/UserAvatar';
import PlayersStatsTable from "../stats/PlayersStatsTable";
import GroupsStatsTable from "../stats/GroupsStatsTable";
import StatInfoCardContent from '../stats/StatInfoCardContent';

// Material UI Components
import { Grid, Card, CardHeader, CardContent, Box, } from '@material-ui/core';

// Colors
import { blueGrey } from '@material-ui/core/colors';

// Icons
import {
  PersonOutline as PersonOutlineIcon
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		padding: 0,
    [theme.breakpoints.up("sm")]: {
			padding: theme.spacing(0, 2),
    }
	},
  cardHeader: {
    padding: theme.spacing(2, 2, 1, 2),
  },
  playersStatsCardContent: {
		padding: theme.spacing(0),
		[theme.breakpoints.up("sm")]: {
			padding: theme.spacing(2),
    },
	},
  topCards: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr',
		columnGap: '12px',
		[theme.breakpoints.up("md")]: {
			columnGap: '16px',
			gridTemplateColumns: '1fr 1fr 1fr',
    }
	},
  shirtIconGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  shirtIconGroup: {
    width: '24px',
    height: '19px',
    stroke: '#313131', strokeWidth: '2px', paintOrder: 'stroke', strokeLinejoin: 'round',
    [theme.breakpoints.up("sm")]: {
      width: '36px',
      height: '32px',
    },
  },
  shirtIconGroupEmpty: {
    width: '24px',
    height: '20px',
    stroke: blueGrey[500], strokeWidth: '5px', paintOrder: 'stroke', strokeLinejoin: 'round',
  }
}));

export default function TeamStatsContainer({ teamId, members }) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
	const [playersStats, setPlayersStats] = useState([]);

  useEffect(() => {
    (async () => {
      try{
        setIsLoading(true);
        const response = await teamApi.stats.get(teamId);

        if(response.hasOwnProperty("error")) {
          setIsError(true);
          setIsLoading(false);
        } else {
          // Init aggregatedData with all team's members ids
          const aggregatedData = {};
          Object.keys(members).forEach(userId => aggregatedData[userId] = {
            name: members[userId].name,
            avatar: members[userId].avatar,
            goals: 0,
            assists: 0,
            wins: 0,
            matchesPlayed: 0,
            events: 0, 
          });

          // go over all team members
          Object.keys(aggregatedData).forEach(userId => {
            Object.keys(response).forEach(eventId => {
              const { playersStats } = response[eventId];

              // if the member has participated in the event
              if(playersStats && playersStats.hasOwnProperty(userId)) {
                const { goals, assists, wins, matchesPlayed } = playersStats[userId];
                aggregatedData[userId] = {
                  ...aggregatedData[userId],
                  goals: aggregatedData[userId].goals + goals,
                  assists: aggregatedData[userId].assists + assists,
                  wins: aggregatedData[userId].wins + wins,
                  matchesPlayed: aggregatedData[userId].matchesPlayed + matchesPlayed,
                  events: aggregatedData[userId].events + 1, // Total number of events that user participated in, and the stats data of the event was insterted!
                };
              }
            })
          })

          // Convert aggregatedData object to playersStats
          const playersStats = Object.keys(aggregatedData).map(userId => {
            return {
              id: userId,
              ...aggregatedData[userId],
            }
          });

          setPlayersStats(playersStats);
          setIsLoading(false);
        }
      } catch(e) {
        setIsLoading(false);
        setIsError(true);
      }
    })();
  }, []);

  return (
    <Grid container className={classes.root} spacing={2} justifyContent="flex-start">

      {/* Players table */}
      <Grid item xs={12}>
        <Card elevation={0}>
          <CardHeader
            titleTypographyProps={{ variant:'h6' }}
            title={DICTIONARY.EVENT.event.stats.playersTable.title}
            classes={{
              'root': classes.cardHeader,
            }}
          />
					<CardContent
						classes={{
							'root': classes['playersStatsCardContent'],
						}}
					>
						<Box
							display="flex"
							flexWrap="wrap"
							width="100%"
							p={1}
							bgcolor="background.paper"
						>
              <PlayersStatsTable 
                isLoading={isLoading}
                isError={isError}
                playersStats={playersStats}
                columnsToHide={['group']}
              />
						</Box>
					</CardContent>
				</Card>
      </Grid>
    </Grid>
  );
}
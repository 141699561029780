import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

// Material UI Components
import {Button, Icon} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	providerButton: {
		backgroundColor: "white",
		padding: theme.spacing(1, 3),
		color: "grey",
		width: "245px",
		display: "flex",
		justifyContent: "flex-start",
	},
	providerIconWrapper: {
		width: "30px",
		display: "flex",
		justifyContent: "flex-start",
	},
}));

export default function SocialAuthButton({icon, text, onClick}) {
  const classes = useStyles();

  return (
    <Button
      variant='contained'
      className={classes.providerButton}
      startIcon={
        <Icon className={classes.providerIconWrapper}>
          <img src={icon} height={20} width={20} alt="facebook"/>
        </Icon>
      }
      onClick={onClick}
    >
      {text}
    </Button>
  );
}
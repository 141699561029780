import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { AuthContext, DialogContext } from '../../context';
import { getTeamsIdsOfAdmin, isVerbose, parsedPathname } from '../../utils/utils';
import { getAllEventsData, getSingleEventData, handleRegister } from '../../utils/helpers/eventHelper';
import DICTIONARY from '../../utils/dictionary';
import ROUTES from '../../routes';
import { Timestamp } from '../../config/firebase';

// Material UI Components
import { Card, CardContent, Link, CardHeader, Box, Button, useMediaQuery, useTheme } from '@material-ui/core';

// Material UI Components - Acordion
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// App Components
import HeaderActions from '../shared/button/HeaderActions';
import UsersList from '../shared/userAvatar/UsersList';
import RegisterToEventButton from './RegisterToEventButton';
import ComponentLoader from '../shared/loader/ComponentLoader';
import EmptyState from '../shared/EmptyState';

// Icons
import { 
	Add as AddIcon, 
	EventBusy as EventBusyIcon,
	ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';

// Colors
import {blueGrey, blue, grey, teal} from '@material-ui/core/colors';

//#region Accordion
const Accordion = withStyles({
  root: {
    borderTop: `1px solid ${blueGrey[100]}`,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'white',
		borderBottom: `1px solid ${blueGrey[100]}`,
    marginBottom: -1,
		transition: 'backgroundColor 150ms ease',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
		transition: 'none',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {
		backgroundColor: blueGrey[100],
	},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);
//#endregion

const useStyles = makeStyles((theme) => ({
  cardContainer: {
		[theme.breakpoints.up("md")]: {
			height: 'calc(100vh - 144px)',
			position: 'relative',
			width: '100%',
		},
	},
	componentLoaderWrapper: {
		height: '260px',
	},
	cardHeader: {
		[theme.breakpoints.up("md")]: {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
		},
	},
	cardHeaderAction: {
		zIndex: '2',
		margin: theme.spacing(0, -1),
	},
	cardContent: {
		padding: '0',
		"&:last-child": {
			paddingBottom: 0
		},
		[theme.breakpoints.up("md")]: {
			position: 'relative',
			top: '64px',
			bottom: 0,
			left: 0,
			right: 0,
			height: 'calc(100% - 64px)',
    },
	},
	// accordionRoot: {
		// margin: '0 !important',
	// },
	accordionDisabled: {
		color: grey[400],
		cursor: 'auto',
		'& $accordionSummary': {
			cursor: 'auto',
			margin: '0 -16px',
    	padding: '12px 16px',
			'& $eventSecondaryHeading': {
				color: grey[400],
			},
		},
	},
	accordionWrapper: {
		[theme.breakpoints.up("md")]: {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: '70px',
			overflowY: 'auto',
			overflowX: 'hidden',
			// display: 'flex',
    	// flexDirection: 'column',
			// flexWrap: 'wrap',
			// height: 'calc(100% - 70px)',
			// height: '300px',
			// height: '100vh',
		},
	},
	seeMoreWrapper: {
		justifyContent: 'center',
		alignItems: 'center',
		height: '70px',
		borderTop: `1px solid ${grey[200]}`,
		display: 'none',
		[theme.breakpoints.up("md")]: {
			position: 'absolute',
			bottom: 0,
			left: 0,
			right: 0,
			display: 'flex',
		},
	},
	seeMoreButton: {
		borderRadius: '15px',
		// backgroundColor: blue[300],
		color: 'white',
		boxShadow: 'none',
		padding: 0,
		fontWeight: '400',
	},
	seeMoreButtonLabel: {
		padding: '3px 16px',
	},
	accordionSummaryRoot: {
		"& .MuiIconButton-edgeEnd":{
			padding: '8px',
			marginLeft: '2px',
		},
	},
	accordionSummary: {
		justifyContent: 'space-between',
	},
  eventHeading: {
    fontSize: theme.typography.pxToRem(16),
		marginRight: theme.spacing(2),
  },
  eventSecondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: grey[900],
	},
	registrationContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
    alignItems: 'center',
	},
	accordionDetails: {
		flexDirection: 'column',
		position: 'relative',
		minHeight: '118px',
		[theme.breakpoints.up("md")]: {
      minHeight: '135px',
    },
	},
	waitingListContainer: {
		borderTop: `1px solid ${grey[200]}`,
		marginTop: theme.spacing(1.5),
		padding: theme.spacing(0.5, 0),
		[theme.breakpoints.up("md")]: {
      padding: theme.spacing(1, 0, 1),
    },
	},
	waitingListTitle: {
		padding: theme.spacing(1, 0),
		fontSize: theme.typography.pxToRem(14),
		[theme.breakpoints.up("md")]: {
      padding: theme.spacing(0),
			fontSize: theme.typography.pxToRem(16),
			marginBottom: theme.spacing(0.5),
    },
	},
	emptyStateWrapper: {
		[theme.breakpoints.up("md")]: {
      marginTop: '60px',
    },
	}
}));

export default function UpcomingEventList() {
	const classes = useStyles();
	const { user } = useContext(AuthContext);
	const { handleCloseDialog, getDialogProps } = useContext(DialogContext);
	const history = useHistory();
	const location = useLocation();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [eventListData, setEventListData] = useState([]);
	const [isLoadingAllEvents, setIsLoadingAllEvents] = useState(false); // all events
	const [isLoadingEventParticipants, setIsLoadingEventParticipants] = useState(false); // Event (=inner accordion) and register button 
	const [expandedEvent, setExpandedEvent] = useState(null); 
	const [allowedActions, setAllowedActions] = useState([]);
	const eventDataRef = useRef(); // Selected event - used for unregister dialog

	//#region Load Events Data
	useEffect(() => {
		try{
			setIsLoadingAllEvents(true);

			(async function () {
				const teamsData = user?.teamsData;
				const eventsData = await getAllEventsData(teamsData, user.userAuth.uid, Timestamp.now(), 3); // for each team, get the 3 most closest events

				setEventListData(eventsData);
				setIsLoadingAllEvents(false);

				const expandedEvent = (eventsData.length > 0 && eventsData[0].isOpenForRegistration) ? eventsData[0].id : null;

				setExpandedEvent(expandedEvent); // 1st event is open by default
			})();
		} catch(e) {
			setIsLoadingAllEvents(false);
			console.log("Error getting document:", e);
		}
	}, [getAllEventsData]);
	//#endregion

	//#region Acordion
  const handleEventAccordionChange = (event) => async (e, isExpanded) => {
		try{
			if(event.parsedIsOpenForRegistration) {
				setExpandedEvent(isExpanded ? event.id : false);
			}
		} catch(error) {
			isVerbose() && console.error(`error while oppening event ${event.id}, error: ${error}`);
		}
	};
	//#endregion

	//#region Create Event Dialog
  const handleClickOpenCreateEventDialog = () => {
    handleCloseDialog(handleCloseCreateEventDialog); // Update handleCloseDialog API context function

		history.push({
			pathname: `${parsedPathname(location.pathname)}create-event`,
			state: {
				background: location,
				id: 'createEvent',
			},
		});
  };

  const handleCloseCreateEventDialog = async ({isSubmittedSuccessfully}) => {
		if (isSubmittedSuccessfully) {
			try{
				setIsLoadingAllEvents(true);
				const teamsData = user?.teamsData;
				const eventsData = await getAllEventsData(teamsData, user.userAuth.uid, Timestamp.now());
				setEventListData(eventsData);
				
				const expandedEvent = eventsData.length > 0 ? eventsData[0].id : null;
				setExpandedEvent(expandedEvent); // 1st event is open by default

				setIsLoadingAllEvents(false);
			} catch(e) {
				isVerbose() && console.error("Error getting teamsData:", e);
				setIsLoadingAllEvents(false);
			}
		}
	};
	//#endregion

	//#region Register to Event
	const onRegisterButtonClick = async (event) => {
		try{
			eventDataRef.current = event;

			if(event.isUserRegisterd) { // Show unregister dialog
				handleClickUnregisterDialog();
			} else {
				setExpandedEvent(event.id);
				setIsLoadingEventParticipants(true);
		
				const retVal = await handleRegister(user, event);
				if(!retVal.success) {
					throw new Error();
				}

				// Replace the relevant event with 'updatedEventData'
				const updatedEventData = await getSingleEventData(event.id, user.teamsData[event.teamId], user.userAuth.uid);
				const eventToReplaceIndex = eventListData.findIndex(item => item.id === event.id);
				if (eventToReplaceIndex > -1) {
					eventListData[eventToReplaceIndex] = updatedEventData;
				};

				setEventListData(eventListData);
				setIsLoadingEventParticipants(false);

				return retVal;
			}
		} catch(e) {
			isVerbose() && console.error(`error while register user ${user.userAuth.uid} to event ${event.id}, error: ${e}`);
			setIsLoadingEventParticipants(false);
			return {
				success: false
			}
		}
	}
	//#endregion

	//#region Allowed Actions
	useEffect(() => {
		const userId = user.userAuth.uid;
		const teamsData = user.teamsData;
		const teamsIdsOfAdmin = getTeamsIdsOfAdmin(userId, teamsData);

		if(teamsIdsOfAdmin.length > 0) {
			setAllowedActions(
				{
					0: {
						actions: [
							{
								key: 'createEvent',
								iconElement: <AddIcon />,
								title: DICTIONARY.EVENT.upcomingEvents.actions.createEvent,
								onClick: handleClickOpenCreateEventDialog,
							},
						]
					}
				}
			);
		}
	}, [user.userAuth.uid, user.teamsData]);
	//#endregion

	//#region Member - Unregister Dialog 
  const handleClickUnregisterDialog = () => {
    handleCloseDialog(handleCloseUnregisterDialog); // Update handleCloseDialog API context function
		getDialogProps({
			eventId: eventDataRef.current.id,
			eventDate: eventDataRef.current.parsedDate,
			teamId: eventDataRef.current.teamId,
			userData: { // if member is unregister himself --> he must not be a guest
				userId: user.userAuth.uid,
				name: user.userData.name,
				avatar: user.userData.avatar,
			},
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}unregister-participant`,
			state: {
				background: location,
				id: 'unregisterParticipant',
			},
		});
	};
	
	const handleCloseUnregisterDialog = async ({isSubmittedSuccessfully}) => {
		getDialogProps({});

		if(isSubmittedSuccessfully) {
			try{
				const event = eventDataRef.current;
				setExpandedEvent(event.id);
				setIsLoadingEventParticipants(true);
	
				// Replace the relevant event with 'updatedEventData'
				const updatedEventData = await getSingleEventData(event.id, user.teamsData[event.teamId], user.userAuth.uid);
				const eventToReplaceIndex = eventListData.findIndex(item => item.id === event.id);
				if (eventToReplaceIndex > -1) {
					eventListData[eventToReplaceIndex] = updatedEventData;
				};
	
				setEventListData(eventListData);
				setIsLoadingEventParticipants(false);
			} catch(e) {
			}
		};
	};
	//#endregion

	//#region Inner Components
	const Event = (event) => (
		<Accordion 
		// TransitionProps={{ unmountOnExit: true }}
		// TransitionProps={{
    //   // timeout: 0
		// 	collapsedSize: '1000',
    // }}
			key={event.id}
			expanded={expandedEvent === event.id} 
			onChange={handleEventAccordionChange(event)}
			// classes={{'root': classes.accordionRoot}}
			className={clsx({
				[classes.accordionDisabled]: event.parsedIsOpenForRegistration === false,
			})} 
		>
			<AccordionSummary
				expandIcon={event.parsedIsOpenForRegistration === true ? <ExpandMoreIcon /> : ''}
				aria-controls={`panel-${event.id}-content`}
				id={`panel-${event.id}-header`}
				classes={{
					'root': classes.accordionSummaryRoot,
					'content': classes.accordionSummary,
				}}
			>
				{/* Event meta details */}
				<div>
					<Typography className={classes.eventHeading}>{`${event.teamName}`}</Typography>
					<Typography className={classes.eventSecondaryHeading}>{`${event.parsedDate}`}</Typography>
					<Link 
						component={RouterLink} 
						to={`${ROUTES.myEvents.path}/${event.id}`} 
						variant='caption' 
						style={{color: blue[900]}}
					>
						{DICTIONARY.EVENT.upcomingEvents.seeMoreEvent}
					</Link>
				</div>

				{/* Registration Button */}
				<div className={classes.registrationContainer}>
					<RegisterToEventButton 
						event={event}
						onClick={onRegisterButtonClick}
						isLoading={expandedEvent === event.id && isLoadingEventParticipants}
					/>
				</div>
			</AccordionSummary>

			{/* Participants List */}
			<AccordionDetails className={classes.accordionDetails}>
				{isLoadingEventParticipants 
					? <ComponentLoader/>
					: <React.Fragment>
							<UsersList 
								participants={event.participants} 
								avatarSize={80}
							/>

							{/* Waiting list */}
							{event.waitingList.length > 0 && (
								<React.Fragment>
									<Box className={classes.waitingListContainer} display="flex" flexDirection="column" justifyContent="flex-start">
										<Typography component="p" className={classes.waitingListTitle}>{DICTIONARY.EVENT.upcomingEvents.waitingList}</Typography>
										<UsersList 
											participants={event.waitingList}
											avatarSize={80}
										/>
									</Box>
								</React.Fragment>
							)}
						</React.Fragment> 
				}
			</AccordionDetails>
		</Accordion>
	);
	//#endregion

  return (
		<React.Fragment>
			<Card classes={{'root': classes.cardContainer}} elevation={0}>
				<CardHeader
					titleTypographyProps={{ variant:'h6' }}
					title={DICTIONARY.EVENT.upcomingEvents.title}
					action={
						<HeaderActions actions={allowedActions}/>
					}
					classes={{
						'root': classes['cardHeader'],
						'action': classes['cardHeaderAction'],
					}}
				/>

				{isLoadingAllEvents 
					? <div className={classes.componentLoaderWrapper}>
							<ComponentLoader wrapperHeight={isMobile ? 240 : undefined}/>
						</div> 
					: eventListData.length > 0 
						? (<CardContent classes={{'root': classes.cardContent}}>
								<div className={classes.accordionWrapper}>
									{eventListData.length > 0 && eventListData.map((event, index) => (Event(event)))}
								</div>

								{/* See more events */}
								<div className={classes.seeMoreWrapper}>
									<Link component={RouterLink} to={ROUTES.myEvents.path} variant='body2' style={{color: 'white', textDecoration: 'none'}}>
										<Button 
											variant="contained"
											color="secondary" 
											onClick={(e) => {}}
											classes={{
												'root': classes.seeMoreButton,
												'label': classes.seeMoreButtonLabel,
											}}
											endIcon={<ChevronRightIcon />}
										>
											{DICTIONARY.EVENT.upcomingEvents.seeMore}
										</Button>
									</Link>
								</div>

							</CardContent>)
						: <div className={classes.emptyStateWrapper}>
								<EmptyState 
									icon={<EventBusyIcon style={{ fontSize: 80, color: blueGrey[500] }}/>}
									title={DICTIONARY.EVENT.upcomingEvents.emptyState.title}
									padding={10}
								/>
							</div>
				}
			</Card>
		</React.Fragment>
  );
}
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Material UI Components
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// Material UI Colors
import { teal, grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	submitButtonWrapper: {
		position: "relative",
	},
	submitButton: {
		margin: theme.spacing(2, 0, 2),
		// width: "245px",
	},
	buttonProgress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
    marginLeft: -12,
    color: teal[100]
	},
  disabled: {
    backgroundColor: `${grey[200]} !important`,
  }
}));

export default function SubmitButton({ text, handleSubmit, style, color, isLoading, disabled }) {
  const classes = useStyles();

  return (
    <Box
      display='flex'
      justifyContent='center'
      className={classes.submitButtonWrapper}
    >
      <Button
        type='submit'
        onClick={handleSubmit}
        disabled={disabled}
        variant='contained'
        color={color ? color : 'primary'}
        className={classes.submitButton}
        classes={{'disabled': classes.disabled}}
        style={style ? style : null}
      >
        {text ? text : 'Submit'}
      </Button>
      {isLoading && (
        <CircularProgress
          size={24}
          className={classes.buttonProgress}
        />
      )}
    </Box>
  );
};

// const SubmitButton = React.memo(SubmitButtonFunction);

// export default SubmitButton;
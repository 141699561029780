import { formatDate, getActiveAdminsAndMembers, isUserAdminOfTeam, isVerbose } from '../utils';
import { SNACKBAR_SEVERITY } from '../consts';
import DICTIONARY from '../dictionary'; 
import { Timestamp } from '../../config/firebase';
import { eventApi } from '../../api/index';

export const getSingleEventData = async (eventId, teamData, userId) => {
	const eventData = await eventApi.get(eventId);
	const parsedEventData = parseSingleEventData(eventData, eventId, teamData, userId);

	return parsedEventData;
};

export const getAllEventsData = async (teamsData = {}, userId, fromDate = undefined, limit = undefined) => {
	const teamsIds = Object.keys(teamsData);
	const eventsData = await eventApi.getEventsByTeamIds({teamsIds, fromDate, limit});
	const parsedEventsData = parseEventsData(eventsData, teamsData, userId);

	return parsedEventsData;
};

const parseEventsData = (eventsRawData, teamsData, userId) => {
	const parsedEvents = Object.keys(eventsRawData).map((eventId) => {
		const eventRawData = eventsRawData[eventId];
		const teamData = teamsData[eventRawData.teamId];
		const event = parseSingleEventData(eventsRawData[eventId], eventId, teamData, userId);

		return event;
	});
	const sortedEvents = parsedEvents.slice().sort((a, b) => a.date - b.date);

	return sortedEvents;
};

export const parseSingleEventData = (eventRawData, eventId, teamData, userId) => {
	const activeMembers = getActiveAdminsAndMembers(teamData.members);
	const isAdmin = isUserAdminOfTeam(userId, activeMembers);
	return parseEvent({
		eventRawData, 
		eventId, 
		teamData, 
		userId, 
		isAdmin,
	});
}; 

export const parseEvent = ({eventRawData, eventId, teamData, userId, isAdmin, participantActionsByAdmin}) => {
	try{
		let retVal = eventRawData;

		// Event details
		retVal.id = eventId;
		retVal.teamName = teamData.teamName;
		retVal.parsedDate = formatDate(eventRawData?.date, 'EE, MMM dd, hh:mm a'); // 'EE, MMM dd, yyyy, hh:mm a'

		// is Open For Registration
		if(eventRawData.isOpenForRegistration === true) {
			retVal.parsedIsOpenForRegistration = true
		} else {
			const now = Timestamp.fromDate(new Date());
			retVal.parsedIsOpenForRegistration = eventRawData.openRegistrationDateAndTime < now;
			retVal.parsedOpenRegistrationDateAndTime = formatDate(eventRawData?.openRegistrationDateAndTime, 'EE, MMM dd, yyyy, hh:mm a');
		}

		// Participants
		let participantsArray;
		if(eventRawData.participants) {
			participantsArray = Object.keys(eventRawData.participants).map(participantId => {
				const participant = eventRawData.participants[participantId];
				return {
					userId: participantId,
					name: participant.name,
					avatar: participant.avatar,
					registeredAt: participant.registeredAt.toDate(),
					isPaid: participant.isPaid || false,
					group: participant.group || null,
					...(participant.isGuest && {isGuest: participant.isGuest}),
					...(participantActionsByAdmin && isAdmin && {actions: participantActionsByAdmin}),
				};
			});
		}

		const participantsSorted = participantsArray ? participantsArray.slice().sort((a, b) => a.registeredAt - b.registeredAt) : [];
		const eventParticipantsLength = participantsSorted.length;
		retVal.participants = participantsSorted;

		// Waiting list
		let waitingListArray;
		if(eventRawData.waitingList) {
			waitingListArray = Object.keys(eventRawData.waitingList).map(participantId => {
				const participant = eventRawData.waitingList[participantId];
				return {
					userId: participantId,
					name: participant.name,
					avatar: participant.avatar,
					registeredAt: participant.appliedToWaitingListAt.toDate(),
					isPaid: participant.isPaid || false,
					...(participant.isGuest && {isGuest: participant.isGuest}),
					...(participantActionsByAdmin && isAdmin && {actions: participantActionsByAdmin}),
				};
			});
		}
		const waitingListSorted = waitingListArray ? waitingListArray.slice().sort((a, b) => a.registeredAt - b.registeredAt) : [];
		const waitingListSortedLength = waitingListSorted.length;
		retVal.waitingList = waitingListSorted;

		// if participants doesnt exist yet => it means the user didnt register yet
		retVal.isUserRegisterd = (eventParticipantsLength > 0 && participantsSorted.findIndex(participant => participant.userId === userId) > -1) || 
			(waitingListSortedLength > 0 && waitingListSorted.findIndex(participant => participant.userId === userId) > -1) || 
			false;
		
		// if participants doesnt exist yet => it means no one register yet - so all spots are available
		const maxEventParticipants = Number(eventRawData.maxEventParticipants);
		const availableSpotsLeft = eventParticipantsLength > 0 ? 
			Math.max((maxEventParticipants - eventParticipantsLength), 0) : 
			maxEventParticipants;
		retVal.maxEventParticipants = maxEventParticipants;
		retVal.availableSpotsLeft = availableSpotsLeft;

		return retVal;
	} catch(e) {
		return;
	}
};

export const handleRegister = async (user, event) => {
	// Function for Register / Unregister
	try{
		let retVal;
		
		if(!event.isUserRegisterd) {
			// Register
			const registeredUser = {
				userId: user.userAuth.uid,
				name: user.userData.name,
				avatar: user.userData.avatar,
			};

			const response = await eventApi.register(event.id, event.teamId, registeredUser);
			retVal = {
				...response,
				type: 'register'
			};
		} else {
			// UnRegister - DERECATED: since we have a "are you sure" dialog before unregister --> we will not arrive here...
			const response = await eventApi.unregister(event.id, event.teamId, user.userAuth.uid);
			retVal = {
				...response,
				type: 'unregister'
			};
		}

		return retVal;
	} catch(e) {
		isVerbose() && console.error(`error while register user ${user.userAuth.uid} to event ${event.id}, error: ${e}`);
		return {
			success: false,
		}
	}
};

export const showEventRegisterSnackbar = async (res, handleSnackbarDisplay) => {	
	// Display a snackbar with the action result
	let severity;
	let message;

	if(res.success) {
		severity = SNACKBAR_SEVERITY.info;
		message = DICTIONARY.EVENT.registerToEventButton.status.succeeded[res.type];
	} else {
		severity = SNACKBAR_SEVERITY.error;
		message = DICTIONARY.EVENT.registerToEventButton.status.failed;
	}
	handleSnackbarDisplay({
		severity,
		message,
	});
};

export const showOpenForRegistrationSnackbar = async (res, handleSnackbarDisplay) => {	
	let severity;
	let message;

	if(res.success) {
		severity = SNACKBAR_SEVERITY.info;
		message = DICTIONARY.EVENT.openEventForRegistration.status.succeeded;
	} else {
		severity = SNACKBAR_SEVERITY.error;
		message = DICTIONARY.EVENT.openEventForRegistration.status.failed;
	}
	handleSnackbarDisplay({
		severity,
		message,
	});
};
import { useState } from 'react';
import { isVerbose } from '../utils/utils';

const useNoFieldsForm = ({onSubmit, onCloseModal}) => {
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
		e && e.preventDefault();

		try {
			setIsLoading(true);
			setFormError("");
			await onSubmit();
			setIsLoading(false);
			onCloseModal && handleCloseModal(true);
		} catch (e) {
			setIsLoading(false);
			setFormError(e.message);
			isVerbose() && console.error(e.message);
		}
	};

  const handleCloseModal = async (isSubmittedSuccessfully) => {
    onCloseModal && await onCloseModal(isSubmittedSuccessfully);
  }

  return {formError, isLoading, handleSubmit, handleCloseModal};
};

export default useNoFieldsForm;
import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

// Material UI Components
import {Card, CardHeader, CardContent, Typography, useMediaQuery, useTheme} from '@material-ui/core';

// App Components
import UsersList from '../../shared/userAvatar/UsersList';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
	},
  cardHeader: {
    padding: theme.spacing(2, 2, 1, 2),
  },
  cardContent: {
    padding: theme.spacing(0, 2),
    "&:last-child": {
			paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(2),
		}
	},
}));

export default function AdminsCard({ title, admins }) {
	const classes = useStyles();
  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [sortedAdminsArray, setSoertedAdminsArray] = useState(admins);
  const [avatarSize, setAvatarSize] = useState(0);

  useEffect(() => {
    if(isMobile) {
      setAvatarSize(80);
    } else {
      setAvatarSize(45);
    }
  }, [isMobile]);
  
  useEffect(() => {
    const adminsArray = Object.keys(admins).map(userId => {
      return {
        ...admins[userId],
        userId
      }
    });
    const sortedAdminsArray = adminsArray.length > 0 ? adminsArray.slice().sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0) : [];

    setSoertedAdminsArray(sortedAdminsArray);
  }, [admins]);

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        titleTypographyProps={{ variant:'h6' }}
        title={title}
        classes={{
          'root': classes['cardHeader'],
        }}
      />
      <CardContent
        classes={{
          'root': classes['cardContent'],
        }}
      >
        {sortedAdminsArray && sortedAdminsArray.length > 0 
          ? <UsersList 
              participants={sortedAdminsArray} 
              avatarSize={avatarSize}
            />
          : <Typography>Team has no members yet</Typography>
        }
      </CardContent>
    </Card>
  );
}
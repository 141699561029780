import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { adminApi } from '../../../api/index';
import DICTIONARY from '../../../utils/dictionary/index';
import useNoFieldsForm from '../../../hooks/noFieldsFormHook';

// Colors
import {red} from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
}));

export default function AdminUnregisterParticipantDialog({ eventId, teamId, participant, handleCloseDialog }) {
  const classes = useStyles();

  //#region Form Hook
	const onSubmit = async () => {
    const participantToUnregister = {
      userId: participant.userId,
      name: participant.name,
      avatar: participant.avatar,
      ...(participant.isGuest && {isGuest: participant.isGuest}),
    };
    const response = await adminApi.event.unregisterParticipant(eventId, teamId, participantToUnregister);

    if (response.hasOwnProperty("error")) {
      throw new Error(DICTIONARY.EVENT.unregisterParticipantFromEvent.form.error.generalError);
    }
	}

	const onCloseModal = (isSubmittedSuccessfully) => {
    handleCloseDialog({isSubmittedSuccessfully});
  }

	const {formError, isLoading, handleSubmit, handleCloseModal} = useNoFieldsForm({
		onSubmit, 
		onCloseModal,
	});
  //#endregion

  const dialogContent = (
    <span></span>
  );

  return {
		dialogTitle: DICTIONARY.EVENT.unregisterParticipantFromEvent.title,
		dialogContent,
    dialogContentText: (<span>{DICTIONARY.EVENT.unregisterParticipantFromEvent.subtitle} <span style={{fontWeight: 'bold', color: 'black'}}>{participant.name}</span> ?</span>),
		sumbitButtonLabel: DICTIONARY.EVENT.unregisterParticipantFromEvent.form.submit.unregister,
    submitButtonStyle: {backgroundColor: red[400]},
		handleSubmit,
		handleCloseModal,
		formError,
		isLoading,
	};
}
import firebase from "../config/firebase";
import { isVerbose } from '../utils/utils';
import generalApi from './general';
import { RESIZED_IMAGE_POSTFIX } from '../utils/consts';

const team = {
  get: async function(teamId) {
    try{
      const teamRef = await firebase.firestore().collection("teams").doc(teamId).get();

      if(teamRef.exists) {
        return teamRef.data();
      } else {
        console.log("No such document!");
        return;
      }
    } catch(e) {
      const error = `Error getting team ${teamId}, ${e}`;
      isVerbose() && console.error(error);
      return {
        error
      };
    }
  },
  create: async function(teamData, userId) {
    try{
      const createTeam = firebase.functions().httpsCallable('createTeam');
      const response = await createTeam({teamData, userId});

      return response.data;
    } catch(e) {
      isVerbose() && console.error(`Error creating team: ${e}`);
      return {
        error: `Can't create team. Please try again or contact support.`
      };
    }
  },
  uploadImage: async function(file, teamId, userId) {
    try{
      const storageRef = firebase.storage().ref();
      const path = `images/teams`;
      const imageId = generalApi.generateId();
      let childRef = storageRef.child(`${path}/${teamId}/${imageId}`);
      let metadata = { // needed?
        customMetadata: {
          admin: userId, 
          teamId,
        },
      };

      const snapshot = await childRef.put(file, metadata);
      const downloadUrl = await firebase.storage().ref(`${path}/${teamId}`).child(imageId).getDownloadURL();

      // downloadUrl = `${downloadUrl}${RESIZED_IMAGE_SUFFIX}`;
      return {downloadUrl};
    } catch(e) {
      isVerbose() && console.error(`Error uploading team image: ${e}`);
      return {
        error: `Can't unpload team image. Please try again or contact support.`
      };
    }
  },
  getTeamsByIds: async function(teamsIds) {
    // TODO: Should it be with multuple calls? or by querying documents in a collection 
    // (https://firebase.google.com/docs/firestore/query-data/get-data#get_multiple_documents_from_a_collection)
    
    try{
      const teamsData = {};
      const teamsPromises = teamsIds.map(id => firebase.firestore().collection("teams").doc(id).get());
      const [...querySnapshot] = await Promise.all(teamsPromises);

      querySnapshot.forEach(function(doc) {
        teamsData[doc.id] = doc.data(); // doc.data() is never undefined for query doc snapshots
      });

      return teamsData;
    } catch(e) {
      const error = `Error getting teams data for teams: ${teamsIds}`;
      isVerbose() && console.error(error);
      return {
        error
      };
    }
  },
  leaveTeamMember: async function(teamId, userId) {
    try {
      const leaveTeamMember = firebase.functions().httpsCallable('leaveTeamMember');
      const response = await leaveTeamMember({
        teamId,
        userId,
      });

      return response.data;
    } catch (e) {
      const error = `Error leaving team, ${e}`;
      isVerbose() && console.error(error);
      return {
        error: `Error leaving team. Please try again or contact support.`
      };
    }
  },
  addMemberToTeamByInvitationLink: async function(teamId, encryptedInvitation, userId) {
    try {
      const addMemberToTeamByInvitationLink = firebase.functions().httpsCallable('addMemberToTeamByInvitationLink');
      const response = await addMemberToTeamByInvitationLink({
        teamId,
        encryptedInvitation,
        userId,
      });

      return response.data;
    } catch (e) {

      isVerbose() && console.error(`Error adding user to the team, ${e}`);

      return {
        error: e === 'The user is already a team member.' ? 'The user is already a team member.' : 'Error adding user to the team. Please try again or contact support.'
      };
    }
  },
  stats: {
    get: async function(teamId) {
      try{
        const teamsStatsData = {};

        const eventStatsRef = firebase.firestore().collection("events_stats").where("teamId", "==", teamId);
        const querySnapshot = await eventStatsRef.get();
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          teamsStatsData[doc.id] = data;
        });

        return teamsStatsData;
      } catch(e) {
        const error = `Error getting event stats of teamId ${teamId}, ${e}`;
        isVerbose() && console.error(error);
        return {
          error
        };
      }
    },
  },
}

export default team;
import firebase from "../config/firebase";
import { isVerbose } from '../utils/utils';
import generalApi from './general';

const user = {
  get: async function(userId) {
    try {
      let retVal;
      const userRef = firebase.firestore().collection("users").doc(userId);
      const userDoc = await userRef.get();
      
      if (userDoc.exists) {
        retVal = userDoc.data();
      } else {
        retVal = {};
        isVerbose() && console.log(`No such user document with userId: ${userId}`);
      }

      return retVal;
    } catch (e) {
      const error = `Error getting user data with userId: ${userId}`;
      isVerbose() && console.error(error);
      return {
        error
      };
    }
  },
  create: async function(userId, userParams) {
    try{
      const { displayName, email, photoURL } = userParams;

      // Add firebaseCreatedUser to users collection in cloudfirestore (we use 'set' method beacuse we know the doc id)
      await firebase.firestore().collection("users").doc(userId).set(
        {
          name: displayName,
          email: email,
          avatar: photoURL,
          // createdAt: firebase.firestore.Timestamp.now(),
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          timeZoneOffset: ((new Date().getTimezoneOffset() / 60) * -1), // Should be used in date functionality in the server side. if offset equals -60 then the time zone offset is UTC+01
        });
    } catch (e) {
      const error = `Error creating user`;
      isVerbose() && console.error(error);
      return {
        error
      };
    }
  },
  editBasicInfo: async function(userId, userData) {
    try {
      // Update a user's profile in firebase auth service
      let user = firebase.auth().currentUser;
      const userAuthPromise =  user.updateProfile({
        displayName: userData.name,
        photoURL: userData.avatar
      });

      // Update user in 'users' collection
      const editUserBasicInfo = firebase.functions().httpsCallable('editUserBasicInfo');
      const usersCollectionPromise = editUserBasicInfo({
        userId,
        userData,
      });

      const [userAuthResponse, usersCollectionResponse] = await Promise.all([userAuthPromise, usersCollectionPromise]);

      return usersCollectionResponse.data;
    } catch (e) {
      const error = `Error updating user basic info, ${e}`;
      isVerbose() && console.error(error);
      return {
        error: `Error updating user basic info. Please try again or contact support.`
      };
    }
  },
  uploadImage: async function(file, userId) {
    try{
      const storageRef = firebase.storage().ref();
      const path = 'images/users';
      const imageId = generalApi.generateId();
      let childRef = storageRef.child(`${path}/${userId}/${imageId}`);
      const snapshot = await childRef.put(file);
      const downloadUrl = await firebase.storage().ref(`${path}/${userId}`).child(imageId).getDownloadURL();

      // downloadUrl = `${downloadUrl}${RESIZED_IMAGE_SUFFIX}`;
      return {downloadUrl};
    } catch(e) {
      isVerbose() && console.error(`Error uploading team image: ${e}`);
      return {
        error: `Can't unpload team image. Please try again or contact support.`
      };
    }
  },
  pwaMessagingToken: {
    updateToken: async function(userId, token) {
      try {
        const updateUserPwaMessagingToken = firebase.functions().httpsCallable('updateUserPwaMessagingToken');
        const response = await updateUserPwaMessagingToken({
          userId,
          token,
        });
  
        return response.data;
      } catch (e) {
        const error = `Error updating messaging registration token, ${e}`;
        isVerbose() && console.error(error);
        return {
          error: `Error updating messaging registration token.`
        };
      }
    },
    updateNotificationPermission: async function(userId, enablePwaMessagingNotifications) {
      try {
        const updateUserPwaMessagingNotificationPermission = firebase.functions().httpsCallable('updateUserPwaMessagingNotificationPermission');
        const response = await updateUserPwaMessagingNotificationPermission({
          userId,
          enablePwaMessagingNotifications,
        });
  
        return response.data;
      } catch (e) {
        const error = `Error updating permission for messaging registration token, ${e}`;
        isVerbose() && console.error(error);
        return {
          error: `Error updating permission for messaging registration token.`
        };
      }
    },
  },
  updatePhoneNumber: async function(userId, phoneNumber) {
    try {
      const updateUserPhoneNumber = firebase.functions().httpsCallable('updateUserPhoneNumber');
      const response = await updateUserPhoneNumber({
        userId,
        phoneNumber,
      });

      return response.data;
    } catch (e) {
      const error = `Error updating user phone, ${e}`;
      isVerbose() && console.error(error);
      return {
        error: `Error updating user phone. Please try again or contact support.`
      };
    }
  },
  updateSmsNotificationPermission: async function(userId, enableSmsNotifications) {
    try {
      const updateUserSmsNotificationPermission = firebase.functions().httpsCallable('updateUserSmsNotificationPermission');
      const response = await updateUserSmsNotificationPermission({
        userId,
        enableSmsNotifications,
      });

      return response.data;
    } catch (e) {
      const error = `Error updating user sms notification permissions, ${e}`;
      isVerbose() && console.error(error);
      return {
        error: `Error updating user sms notification permissions. Please try again or contact support.`
      };
    }
  },
  updateEmailNotificationPermission: async function(userId, enableEmailNotifications) {
    try {
      const updateUserEmailNotificationPermission = firebase.functions().httpsCallable('updateUserEmailNotificationPermission');
      const response = await updateUserEmailNotificationPermission({
        userId,
        enableEmailNotifications,
      });

      return response.data;
    } catch (e) {
      const error = `Error updating user email notification permissions, ${e}`;
      isVerbose() && console.error(error);
      return {
        error: `Error updating user email notification permissions. Please try again or contact support.`
      };
    }
  },
};

export default user;
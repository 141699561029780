import { createTheme, responsiveFontSizes }  from '@material-ui/core/styles';
import {blue, blueGrey, teal, amber} from '@material-ui/core/colors';

/*
  Material UI default breakpoints:
    xs, extra-small: 0px
    sm, small: 600px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1920px
*/
let theme = createTheme({
  palette: {
    primary: {
      main: teal['A700'],
      contrastText: '#fff'
    },
    secondary: {
      main: blueGrey[700],
      contrastText: '#fff'
    },
    success: {
      main: teal['A700'],
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    },
  },
});

theme = responsiveFontSizes(theme);

theme.typography.h6 = {
  fontSize: theme.typography.pxToRem(19),
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(21),
  },
};


export default theme;
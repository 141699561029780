import React, {useState, useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';

// Material UI Components
import { Hidden, IconButton, Button, Menu, MenuItem, Divider } from '@material-ui/core';

// Buttons
import OneActionButton from './OneActionButton.js';
import ButtonIconWithTooltip from './ButtonIconWithTooltip.js';

// Icons
import { 
	MoreVert as MoreVertIcon,
} from '@material-ui/icons';

// Colors
import {grey} from '@material-ui/core/colors';

// Actions interface
/*
  const actions = {
    sectionId: {
      title: 'Scetion title',
      actions: [
        {
          key: 'actionKey',
          title: 'Action title',
          iconElement: <iconElement />,
          onClick: callbackFunction
        }
      ]
    }
  }
*/

const ACTIONS_DISPLAY_TYPE = {
  single: 'single',
  multiple: 'multiple',
  multipleDividedBySections: 'multipleDividedBySections',
  none: 'none', // No actions to display
};

const useStyles = makeStyles((theme) => ({
  multipleActions: {
    display: 'flex',
  },
  sectionsContainer: {
    display: 'flex',
  },
  sectionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  verticalDivider: {
    height: '20px',
    margin: '0 4px',
  },
  mobileMenu: {
    padding: 0,
  },
  mobileButtonItem: {
    padding: theme.spacing(0),
  },
  mobileButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(1.5, 3),
    backgroundColor: 'transparent !important',
    color: grey[900],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
  },
  mobileActionsContainer: {
    display: 'flex',
    alignItems: 'center',
  }
}));

export default function HeaderActions({actions = {}}) {
  const classes = useStyles();

  const [actionsDisplayType, setActionsDisplayType] = useState(ACTIONS_DISPLAY_TYPE.single);
  const [singleAction, setSingleAction] = useState({});
  const [multipleActions, setMultipleActions] = useState([]);

  const [mainAction, setMainAction] = useState(null);

  useEffect(() => {
    if(Object.keys(actions).length === 1 && actions[Object.keys(actions)[0]].actions.length === 1) { // Single action
      setActionsDisplayType(ACTIONS_DISPLAY_TYPE.single);

      const action = actions[Object.keys(actions)[0]].actions[0];
      setSingleAction({
        title: action.title,
        iconElement: action.iconElement,
        onClick: action.onClick,
        ...(action.className && { className: action.className }),
        ...(action.color && { color: action.color }),
      });
    } else if(Object.keys(actions).length === 1 && actions[Object.keys(actions)[0]].actions.length > 1) { // multiple actions in 1 section
      setActionsDisplayType(ACTIONS_DISPLAY_TYPE.multiple);
      setMultipleActions(actions[Object.keys(actions)[0]].actions);
    } else if (Object.keys(actions).length > 1) { // multiple sections
      
      // Set mainAction if exists - Just for mobile
      Object.keys(actions).forEach((section) => {
        const actionsList = actions[section].actions;
        for(let i = 0; i < actionsList.length; i++) {
          if(actionsList[i].type === 'main') {
            setMainAction(actionsList[i]);
          };
          break;
        }
      });
      setActionsDisplayType(ACTIONS_DISPLAY_TYPE.multipleDividedBySections);
    } else { // No actions
      setActionsDisplayType(ACTIONS_DISPLAY_TYPE.none);
    }
  }, [actions]);

  //#region Mobile menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //#endregion

  return (
    <React.Fragment>
      {actionsDisplayType !== ACTIONS_DISPLAY_TYPE.none && <React.Fragment>
        {/* Desktop */}
        <Hidden xsDown implementation="js">

          {/* Single action - Desktop */}
          {actionsDisplayType === ACTIONS_DISPLAY_TYPE.single && (
            <OneActionButton {...singleAction}/>
          )}

          {/* Multiple actions - Desktop */}
          {actionsDisplayType === ACTIONS_DISPLAY_TYPE.multiple && <ul className={classes.multipleActions}>
            {multipleActions.map((action) => (
              <li key={action.key}>
                <ButtonIconWithTooltip 
                  iconElement={action.iconElement} 
                  color={action.color}
                  title={action.title}
                  onClick={action.onClick}
                />
              </li>
            ))}
          </ul>}

          {/* Multiple actions by sections - Desktop */}
          {actionsDisplayType === ACTIONS_DISPLAY_TYPE.multipleDividedBySections && <ul className={classes.sectionsContainer}>
            {Object.keys(actions).map((section, idx) => (
              <div key={actions[section].title} className={classes.sectionContainer} >
                {idx > 0 && actions[section].actions.length > 0 && <Divider 
                  orientation="vertical" 
                  classes={{
                    'root': classes.verticalDivider
                  }}/>}
                <li className={classes.section}>
                  <ul className={classes.multipleActions}>
                    {actions[section].actions.map((action) => (
                      <li key={action.key}>
                        <ButtonIconWithTooltip 
                          iconElement={action.iconElement} 
                          color={action.color}
                          title={action.title}
                          onClick={action.onClick}
                        />
                      </li>
                    ))}
                  </ul>
                </li>
              </div>
            ))}
          </ul>}
        </Hidden>

        {/* Mobile */}
        <Hidden smUp implementation="js">
          {actionsDisplayType !== ACTIONS_DISPLAY_TYPE.none && <div className={classes.mobileActionsContainer}>
            {mainAction !== null && (
              <div className={classes.mobileActionsContainer}>
                <ButtonIconWithTooltip 
                  iconElement={mainAction.iconElement} 
                  color={mainAction.color}
                  title={mainAction.title}
                  onClick={mainAction.onClick}
                />
                <Divider 
                orientation="vertical" 
                classes={{
                  'root': classes.verticalDivider
                }}/>
              </div>
            )}

            <IconButton 
              aria-label="more"             
              onClick={handleClick}
              aria-controls="simple-menu" 
              aria-haspopup="true" 
              size="small"
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              classes={{
                'list': classes.mobileMenu
              }}
            >

              {/* Single action - Mobile */}
              {actionsDisplayType === ACTIONS_DISPLAY_TYPE.single && (
                <MenuItem key={singleAction.key} classes={{'root': classes.mobileButtonItem }}>
                  <Button
                    startIcon={singleAction.iconElement}
                    onClick={() => {
                      singleAction.onClick();
                      handleClose();
                    }}
                    classes={{'root': classes.mobileButton, 'label': classes.mobileButtonLabel}}
                  >
                    {singleAction.title}
                  </Button>
                </MenuItem>
              )}

              {/* Multiple actions - Mobile */}
              {actionsDisplayType === ACTIONS_DISPLAY_TYPE.multiple && multipleActions.map((action) => (
                <MenuItem key={action.key} classes={{'root': classes.mobileButtonItem }}>
                  <Button
                    startIcon={action.iconElement}
                    onClick={() => {
                      action.onClick();
                      handleClose();
                    }}
                    classes={{'root': classes.mobileButton, 'label': classes.mobileButtonLabel}}
                  >
                    {action.title}
                  </Button>
                </MenuItem>
              ))}

              {/* Multiple actions by sections - Mobile */}
              {actionsDisplayType === ACTIONS_DISPLAY_TYPE.multipleDividedBySections && Object.keys(actions).map((section, idx) => (
                <div key={actions[section].title}>
                  {idx > 0 && actions[section].actions.length > 0 && <Divider />}
                  {/* <MenuItem disableRipple className={classes.menuSectionHeader}>{actions[section].title}</MenuItem> */}
                  {actions[section].actions.map((action) => 
                    // Exclude main action
                    {return action.type !== 'main' && (
                      <MenuItem key={action.key} classes={{'root': classes.mobileButtonItem }}>
                        <Button
                          startIcon={action.iconElement}
                          onClick={() => {
                            action.onClick();
                            handleClose();
                          }}
                          classes={{'root': classes.mobileButton, 'label': classes.mobileButtonLabel}}
                        >
                          {action.title}
                        </Button>
                      </MenuItem>
                    )}
                  )}
                </div>
              ))}
            </Menu>
          </div>}
        </Hidden>
      </React.Fragment>}
    </React.Fragment>
  );
}
import firebase from "../config/firebase";
import { isVerbose } from '../utils/utils';

const event = {
  create: async function(data) {
    try{
      const createEvent = firebase.functions().httpsCallable('createEvent');
      const response = await createEvent(data);

      return response.data;
    } catch(e) {
      isVerbose() && console.error(`Error creating event: ${e}`);
      return {
        error: `Can't create event. Please try again or contact support.`
      };
    }
  },
  edit: async function(eventId, teamId, eventData) {
    try{
      const editEvent = firebase.functions().httpsCallable('editEvent');
      const response = await editEvent({eventId, teamId, eventData});

      return response.data;
    } catch(e) {
      isVerbose() && console.error(`Error editing event: ${e}`);
      return {
        error: `Can't edit event. Please try again or contact support.`
      };
    }
  },
  delete: async function(data) {
    try{
      const deleteEvent = firebase.functions().httpsCallable('deleteEvent');
      const response = await deleteEvent(data);

      return response.data;
    } catch(e) {
      const error = `Error deleting event ${data.eventId}`;
      isVerbose() && console.error(error);
      return {
        error: `Can't delete event. Please try again or contact support.`
      };
    }
  },
  updateOpenForRegistration: async function(eventId, teamId) {
    try{
      const updateOpenForRegistration = firebase.functions().httpsCallable('updateOpenForRegistration');
      const response = await updateOpenForRegistration({eventId, teamId});

      return response.data;
    } catch(e) {
      isVerbose() && console.error(`Error creating event: ${e}`);
      return {
        error: `Can't update event. Please try again or contact support.`
      };
    }
  },
  markAsPaid: async function(eventId, teamId, userId) {
    try{
      const markAsPaid = firebase.functions().httpsCallable('markAsPaid');
      const response = await markAsPaid({eventId, teamId, userId});

      return response.data;
    } catch(e) {
      isVerbose() && console.error(`Error marking user as paid event: ${e}`);
      return {
        error: `Can't mark user as paid. Please try again or contact support.`
      };
    }
  },
  registerParticipant: async function(eventId, teamId, participants) {
    try{
      const registerToEventByAdmin = firebase.functions().httpsCallable('registerToEventByAdmin');

      const response = await registerToEventByAdmin({
        eventId,
        teamId,
        participants,
      });

      return response.data;
    } catch(e) {
      isVerbose() && console.error(`Error registering to event: ${e}`);
      return {
        error: `Can't register user to event. Please try again or contact support.`
      };
    }
  },
  unregisterParticipant: async function(eventId, teamId, userData) {
    try{
      const unregisterFromEventByAdmin = firebase.functions().httpsCallable('unregisterFromEventByAdmin');
      const response = await unregisterFromEventByAdmin({
        eventId,
        teamId,
        userData,
      });

      return response.data;
    } catch(e) {
      isVerbose() && console.error(`Error unregistering from event: ${e}`);
      return {
        error: `Can't unregister user from event. Please try again or contact support.`
      };
    }
  },
  updateEventLineups: async function(eventId, teamId, participantsByGroups, shouldShowLineups) {
    try{
      const updateEventLineups = firebase.functions().httpsCallable('updateEventLineups');
      const response = await updateEventLineups({
        eventId,
        teamId,
        participantsByGroups,
        shouldShowLineups,
      });

      return response.data;
    } catch(e) {
      isVerbose() && console.error(`Error updaing event lineups. event: ${e}`);
      return {
        error: `Can't update event lineups. Please try again or contact support.`
      };
    }
  },
}

const team = {
  edit: async function(teamId, teamData) {
    try {
      const editTeamDetails = firebase.functions().httpsCallable('editTeamDetails');
      const response = await editTeamDetails({
        teamId,
        teamData,
      });

      return response.data;
    } catch (e) {
      const error = `Error updating team, ${e}`;
      isVerbose() && console.error(error);
      return {
        error: `Error updating team. Please try again or contact support.`
      };
    }
  },
  delete: async function(teamId) {
    try {
      const deleteTeam = firebase.functions().httpsCallable('deleteTeam');
      const response = await deleteTeam({
        teamId,
      });

      return response.data;
    } catch (e) {
      const error = `Error deleting team, ${e}`;
      isVerbose() && console.error(error);
      return {
        error: `Error deleting team. Please try again or contact support.`
      };
    }
  },
  addMembersToTeam: async function(teamId, emails) {
    try {
      const addTeamMember = firebase.functions().httpsCallable('addTeamMember');
      const response = await addTeamMember({
        teamId,
        emails
      });

      return response.data;
    } catch(e) {
      const error = `Error adding users to team, ${e}`;
      isVerbose() && console.error(error);
      return [{
        error
      }];
    }
  },
  assignAdminToTeam: async function(teamId, userId) {
    try {
      const assignTeamAdmin = firebase.functions().httpsCallable('assignTeamAdmin');
      const response = await assignTeamAdmin({
        teamId,
        userId,
      });

      return response.data;
    } catch (e) {
      const error = `Error assiging new team admin, ${e}`;
      isVerbose() && console.error(error);
      return {
        error: `Error assiging new team admin. Please try again or contact support.`
      };
    }
  },
  definePrimeMember: async function(teamId, userId) {
    try {
      const definePrimeMember = firebase.functions().httpsCallable('definePrimeMember');
      const response = await definePrimeMember({
        teamId,
        userId,
      });

      return response.data;
    } catch (e) {
      const error = `Error defining prime member, ${e}`;
      isVerbose() && console.error(error);
      return {
        error: `Error defining prime member. Please try again or contact support.`
      };
    }
  },
  removeMember: async function(teamId, userId) {
    try {
      const removeMember = firebase.functions().httpsCallable('removeMember');
      const response = await removeMember({
        teamId,
        userId,
      });

      return response.data;
    } catch (e) {
      const error = `Error leaving team, ${e}`;
      isVerbose() && console.error(error);
      return {
        error: `Error removing team member. Please try again or contact support.`
      };
    }
  },
  leaveTeamAdmin: async function(teamId, userId) {
    try {
      const leaveTeamAdmin = firebase.functions().httpsCallable('leaveTeamAdmin');
      const response = await leaveTeamAdmin({
        teamId,
        userId,
      });

      return response.data;
    } catch (e) {
      const error = `Error leaving team, ${e}`;
      isVerbose() && console.error(error);
      return {
        error: `Error leaving team. Please try again or contact support.`
      };
    }
  },
  getInvitationLink: async function(teamId) {
    try {
      const getInvitationLink = firebase.functions().httpsCallable('getInvitationLink');
      const response = await getInvitationLink({
        teamId,
      });

      return response.data;
    } catch (e) {
      const error = `Error getting team invitation link, ${e}`;
      isVerbose() && console.error(error);
      return {
        error: `Error getting team invitation link. Please try again or contact support.`
      };
    }
  },
};

const admin = {
  event,
  team,
}

export default admin;
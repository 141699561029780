import React, { useContext } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext, DialogContext } from '../../../context';
import DICTIONARY from '../../../utils/dictionary';
import { parsedPathname } from '../../../utils/utils';

// App Components
import HeaderActions from '../../shared/button/HeaderActions';
import Squircle from '../../shared/userAvatar/Squircle';

// Material UI Components
import { Card, CardHeader, CardContent, Typography, Box } from '@material-ui/core';

// Icons
import {
	Edit as EditIcon
} from '@material-ui/icons';

// Colors
import {grey} from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(0, 0, 1),
    [theme.breakpoints.up("md")]: {
			padding: theme.spacing(1, 2, 2),
    }
	},
	cardHeader: {
    padding: theme.spacing(2, 2, 1, 2),
  },
  cardContent: {
    "&:last-child": {
			paddingBottom: theme.spacing(1)
		}
	},
  userAvatarWrapper: {
    width: '76px',
    height: '81px',
  },
	userAvatar: {
    width: '76px',
    height: '76px',
		objectFit: 'cover',
		marginTop: '5px',
    position: 'absolute',
	},
	detailTitle: {
		color: grey[700],
		fontSize: theme.typography.pxToRem(12),
		// marginBottom: '1px'
	},
}));

export default function UserBasicInfo() {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { handleCloseDialog, getDialogProps } = useContext(DialogContext);
  const history = useHistory();
	const location = useLocation();

  //#region Edit user details dialog
  const handleClickOpenEditUserBasicInfoDialog = () => {
    handleCloseDialog(handleCloseEditUserBasicInfoDialog); // Update handleCloseDialog API context function

		history.push({
			pathname: `${parsedPathname(location.pathname)}/edit-user`,
			state: {
				background: location,
				id: 'editUserBasicInfo',
			},
		});
  };

  const handleCloseEditUserBasicInfoDialog = async ({isSubmittedSuccessfully}) => {
    // 
	};
	//#endregion

	const ACTIONS = {
		0: {
			actions: [
				{
					key: 'editDetails',
					iconElement: <EditIcon />,
					title: DICTIONARY.MY_ACCOUNT.basicInfo.actions.edit,
					onClick: handleClickOpenEditUserBasicInfoDialog,
				},
			],
		}
	};

  return (
    <React.Fragment>
      <Card className={classes.root} elevation={0}>
        <CardHeader
          titleTypographyProps={{ variant:'h6' }}
          title={DICTIONARY.MY_ACCOUNT.basicInfo.title}
          classes={{
            'root': classes['cardHeader'],
            'action': classes['cardHeaderAction'],
          }}
          action={
            <HeaderActions actions={ACTIONS}/>
          }
        />
        <CardContent classes={{'root': classes.cardContent}}>
          <Box mb={3} display="flex" flexDirection="column">
            <Typography variant="body2" display="block" className={classes.detailTitle}>{DICTIONARY.MY_ACCOUNT.basicInfo.email}</Typography>
            <Typography variant="body1" display="block">{user.userData.email}</Typography>
          </Box>
          <Box mb={3} display="flex" flexDirection="column">
            <Typography variant="body2" display="block" className={classes.detailTitle}>{DICTIONARY.MY_ACCOUNT.basicInfo.name}</Typography>
            <Typography variant="body1" display="block">{user.userData.name}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" display="block" className={classes.detailTitle}>{DICTIONARY.MY_ACCOUNT.basicInfo.avatar}</Typography>
            <Box component="div" display="flex" className={classes.userAvatarWrapper}>
              <Squircle 
                src={user.userData.avatar}
                alt={user.userData.name}
                className={classes.userAvatar}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
import React, { useState, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../../context';
import { userApi } from '../../../api';
import VALIDATORS from '../../../utils/validators';
import { IMAGE_PLACEHOLDER } from '../../../utils/consts';
import DICTIONARY from '../../../utils/dictionary';
import useForm from '../../../hooks/formHook';

// Material UI Components
import { Grid, TextField, IconButton, Typography } from '@material-ui/core';
import {
	Publish as PublishIcon,
} from '@material-ui/icons';

// App components
import Squircle from '../../shared/userAvatar/Squircle';

// Colors
import { teal, grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	formControl: {
    marginTop: theme.spacing(2),
  },
	imageContainer: {
		position: 'relative',
    width: '86px',
		height: '86px',
		'& img': {
			position: 'absolute',
			top: 0,
			bottom: 0,
			width: '100%',
			height: '100%',
			objectFit: 'cover',
		},
		'& .MuiIconButton-root': {
			position: 'absolute',
			bottom: '-10px',
			right: '10px',
			backgroundColor: teal['A700'],
			color: 'white',
		},
		'& label': {
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			cursor: 'pointer',
		},
	},
	avatarTitle: {
		color: grey[700],
		fontSize: theme.typography.pxToRem(13),
		marginBottom: theme.spacing(1),
	},
}));

export default function EditUserBasicInfoDialog({handleCloseDialog}) {
	const { user, handleUserUpdate } = useContext(AuthContext);
  const classes = useStyles();
	
	const [avatarPlaceholder, setAvatarPlaceholder] = useState(user.userData.avatar || IMAGE_PLACEHOLDER);

	//#region Form Hook
	const initalFormState = {
		name: {
			label: DICTIONARY.MY_ACCOUNT.basicInfo.form.field.name,
			value: user.userData.name || '',
			isValid: true,
			required: true,
			validators: [
				{
					validate: (val) => VALIDATORS.isValidString(val, 2),
					errorMessage: DICTIONARY.MY_ACCOUNT.basicInfo.form.error.nameLength,
				},
			],
		},
		avatar: {
			label: DICTIONARY.MY_ACCOUNT.basicInfo.form.field.avatar,
			value: user.userData.avatar || '',
			isValid: true,
			required: false,
			validators: [
				{
					validate: (value) => {
						// Check if the value matches the value from the DB. if true --> the image wasnt change and its valid
						const avatarFromDB = user.userData.avatar;

						return avatarFromDB === value || VALIDATORS.isValidimage(value);
					},
					errorMessage: DICTIONARY.MY_ACCOUNT.basicInfo.form.error.imageType,
				},
			],
		},
	};

	const uploadImageIfNeeded = async (userId) => {
		let uploadedImage = formState.avatar.value;
		if(typeof uploadedImage === 'object' && uploadedImage !== null) {
			const uploadImageResponse = await userApi.uploadImage(uploadedImage, userId);

			if(!uploadImageResponse.hasOwnProperty("error")){
				return uploadImageResponse.downloadUrl;
			} else {
				throw new Error(DICTIONARY.MY_ACCOUNT.basicInfo.form.error.imageUpload);
			}
		}
	}

	const onSubmit = async () => {
		const userId = user.userAuth.uid;
		let avatar;

		if (typeof formState.avatar.value === 'string' || formState.avatar.value instanceof String) { // user didnt change avatar
			avatar = formState.avatar.value; 
		} else { // user upload new avatar
			avatar = await uploadImageIfNeeded(userId);
		}

		const updatedUserData = {
			name: formState.name.value,
			avatar,
		};

		const response = await userApi.editBasicInfo(userId, updatedUserData);

		if(!response.hasOwnProperty("error")){
			handleUserUpdate({
				userData: response.userData,
				teamsData: response.teamsData,
			});
		} else {
			throw new Error(DICTIONARY.MY_ACCOUNT.basicInfo.form.error.generalError);
		}
	}

	const onCloseModal = (isSubmittedSuccessfully) => {
    handleCloseDialog({isSubmittedSuccessfully});
  }

	const {formState, formError, isLoading, handleInputChange, handleSubmit, handleCloseModal} = useForm({
		initalFormState, 
		onSubmit, 
		onCloseModal,
	});
	//#endregion

	//#region Avatar image
	const handleAvatarPlaceholcer = (file) => {
		if (file && VALIDATORS.isValidimage(file)) {
			setAvatarPlaceholder(URL.createObjectURL(file));
		}
	}
	//#endregion

	const dialogContent = (
		<React.Fragment>
			{/* Section 1 - Name, Description and Image */}
			<Grid container spacing={3}>

				{/* Team Name Field */}
				<Grid item md={12} xs={12}>
					<TextField
						autoFocus
						margin='normal'
						fullWidth
						required
						id='name'
						label={formState.name.label}
						name='name'
						value={formState.name.value}
						onChange={handleInputChange}
						autoComplete='name'
						error={!formState.name.isValid}
					/>
				</Grid>

				{/* User avatar */}
				<Grid item md={12} xs={12}>
					<Typography variant="body2" display="block" className={classes.avatarTitle}>{formState.avatar.label}<span style={{fontSize: '10px'}}> {DICTIONARY.GENERAL.imageUploadInstructions}</span></Typography>
					<div className={classes.imageContainer}>
          <Squircle 
            src={avatarPlaceholder}
            alt={user.userData.name}
          />
						<IconButton aria-label="upload" size="small">
							<PublishIcon />
							<label htmlFor="avatar"></label>
						</IconButton>
						<input 
							id='avatar'
							name='avatar'
							type="file"
							accept="image/jpeg, image/png"
							style={{display: 'none'}}
							onChange={(e) => {
								handleInputChange(e, {type: 'image', name: 'avatar'});
								handleAvatarPlaceholcer(e.target.files[0]);
							}}
						/>
					</div>
				</Grid>
			</Grid>
		</React.Fragment>
  );

  return {
		dialogTitle: DICTIONARY.MY_ACCOUNT.basicInfo.form.title,
		dialogContent,
		sumbitButtonLabel: DICTIONARY.GENERAL.button.submit,
		handleSubmit,
		handleCloseModal,
		formError,
		isLoading,
	};
}
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DICTIONARY from '../../../../utils/dictionary';
import { containsHebrew } from '../../../../utils/utils';

// App Components
import Squircle from '../../../shared/userAvatar/Squircle';
import LightTooltip from '../../../shared/tooltip/LightTooltip';

// Material UI Components
import { DataGrid } from '@material-ui/data-grid';
import { useMediaQuery, useTheme } from '@material-ui/core';

// Icons
import {
  ArrowRight as ArrowRightIcon,
  ArrowLeft as ArrowLeftIcon
} from '@material-ui/icons';

// Colors
import { pink, teal } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    // border: 'none',
    '&.MuiDataGrid-root': {
      minHeight: '370px !important',
    },
    '& .last-cloumn-header': {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      }
    },
    '& .rendering-zone': {
      width: '100% !important',
    },
    '& .MuiDataGrid-row': {
      width: '100% !important',
    },
    '& .MuiDataGrid-colCell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-colCell': {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 0, 0, 1),
      },
    },
    '& .MuiDataGrid-cell': {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 1),
      },
    },
    '& .MuiDataGrid-columnHeader': {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 0, 0, 0.5),
      },
    },
  },
  mobileTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  mobileText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '95%',
    display: 'block',
  },
}));

export default function ParticipantsLogTable({logs}) {
  const classes = useStyles();
  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // #region DataGrid init
  const [pageSize, setPageSize] = useState(5);
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };
  // #endregion

  //#region Columns
  const columns = [
    {
      // Hide on mobile
      field: 'sign', 
      headerName: DICTIONARY.EVENT.event.participantsLogTable.head.sign, 
      hide: isMobile ? true: false,
      width: isMobile ? 35 : 50, 
      sortable: false,
      renderCell: (params) => {
        const { sign } = params.row;
        const element = sign === 'in' ? <ArrowRightIcon style={{ color: teal['A700'] }} /> : <ArrowLeftIcon style={{ color: pink['A700'] }} />
        return (
          element
        )
      },
    },
    { 
      field: 'date', 
      headerName: DICTIONARY.EVENT.event.participantsLogTable.head.date, 
      type: 'dateTime', 
      flex: 2, 
      // width: 200,
      renderCell: (params) => {
        const { date } = params.row;
  
        const element = isMobile 
          ? <LightTooltip 
              title={date} 
            >
              <span className={classes.mobileText}>
                {date}
              </span>
            </LightTooltip>
          : <span>{date}</span>

        return (
          element
        )
      },
      sortComparator: (v1, v2, param1, param2) => {
        try{
          const param1Row = param1.api.getRow(param1.id);
          const param2Row = param2.api.getRow(param2.id);
          const diff = param1Row.timestamp - param2Row.timestamp ? 1 : -1;
          return diff;
        } catch(e) {
          return true;
        }
      },
    },
    { 
      field: 'name', 
      headerName: DICTIONARY.EVENT.event.participantsLogTable.head.member, 
      type: 'string', 
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        const { name, avatar } = params.row;
        const wrapperStyle = {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        };
        let avatarStyle = {
          width: '28px',
          height: '28px',
          objectFit: 'cover',
          marginRight: '8px',
        };

        avatarStyle = isMobile 
          ? {
              ...avatarStyle,
              width: '20px',
              height: '20px',
            }
          : avatarStyle;
  
        const element = isMobile 
          ? <LightTooltip 
              title={name} 
            >
              <span 
                className={classes.mobileText}
                style={{
                  direction: containsHebrew(name) ? 'rtl' : 'ltr',
                }}
              >
                {name}
              </span>
            </LightTooltip>
          : <span>{name}</span>

        return (
          <div style={wrapperStyle}>
            <Squircle 
              src={avatar}
              alt={name}
              style={avatarStyle}
            />
            {element}
          </div>
        )
      },
    },
    { 
      field: 'action', 
      headerName: DICTIONARY.EVENT.event.participantsLogTable.head.action, 
      type: 'string', 
      flex: 3,
      sortable: false, 
      headerClassName: 'last-cloumn-header',
      renderCell: (params) => {
        const { action, reason, sign } = params.row;
  
        const element = isMobile 
          ? <LightTooltip 
              title={`${action} ${reason ? '(' + reason + ')' : ''}`} 
            >
              <div className={classes.mobileTextWrapper}>
                {sign === 'in' ? <ArrowRightIcon style={{ color: teal['A700'] }} /> : <ArrowLeftIcon style={{ color: pink['A700'] }} />}
                <span className={classes.mobileText}>{action} {reason ? <span style={{fontSize: '11px', color: 'grey'}}>({reason})</span> : ''}</span>
              </div>
            </LightTooltip>
          : <span>{action} {reason ? <span style={{fontSize: '11px', color: 'grey'}}>({reason})</span> : ''}</span>

        return (
          element
        )
      }
    },
    // { field: 'reason', headerName: DICTIONARY.EVENT.event.participantsLogTable.head.reason, type: 'string', flex: 1 },
  ];
  // #endregion

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={logs}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={handlePageSizeChange}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        disableSelectionOnClick
        autoHeight
        className={classes.root}
        disableColumnFilter
        disableColumnMenu
        sortingOrder={['desc', 'asc']}
      />
    </div>
  );
}
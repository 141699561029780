import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext, DialogContext, SnackbarContext } from '../../../context';
import { messaging } from "../../../config/firebase";
import DICTIONARY from '../../../utils/dictionary';
import { userApi } from '../../../api/index';
import useIsMount from '../../../hooks/isMountHook';
import { SNACKBAR_SEVERITY } from '../../../utils/consts';
import { isVerbose, isInstalledAsPwa, parsedPathname } from '../../../utils/utils';

// Material UI Components
import { Box, Switch, FormControlLabel, Typography, CircularProgress, Button } from '@material-ui/core';
import { Alert } from "@material-ui/lab";

// Colors
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	loader: {
		position: 'relative',
		top: '3px',
	},
	seeHowButton: {
		color: blue[600],
		display: 'block',
		width: '175px',
    textAlign: 'left',
		backgroundColor: 'transparent !important',
		padding: 0,
		'text-decoration': 'underline',
		'&:hover': {
			color: blue[800],
			'text-decoration': 'underline',
		}
	}
}));

export default function PwaMessagingNotificationsSetting() {
	const classes = useStyles();
  const { user } = useContext(AuthContext);
	const { handleCloseDialog, getDialogProps } = useContext(DialogContext);
	const { handleSnackbarDisplay } = useContext(SnackbarContext);
	const history = useHistory();
	const location = useLocation();
	const isMount = useIsMount();

	const [pwaMessagingPermissionStatus, setPwaMessagingPermissionStatus] = useState((user.userData?.enablePwaMessagingNotifications && user.userData?.pwaMessagingToken !== '') || false);
	const [isPwa, setIsPwa] = useState(isInstalledAsPwa());
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [shouldShowCheckMobileMessage, setShouldShowCheckMobileMessage] = useState(false);

	const handleMessagingRequestPermission = () => {
		try{
			const userId = user.userAuth.uid;
			Notification.requestPermission()
				.then(() => {
					isVerbose() && console.log('Have permission');
					return messaging.getToken({vapidKey: "BCi8qS867cottnWIAgIsRc2xR1-ih08FyEwk6lemaYh6GyEVp2lXxwInHN73DctJ6dqD7lvL082HAYKUZGX9rrI"});
				})
				.then(async (token) => {
					if (token) {
						isVerbose() && console.log('User token: ', token);
						// API to store the user token in the DB

						const tokenUpdatePromise = userApi.pwaMessagingToken.updateToken(userId, token);
						const pwaNotificationPermissionPromise = userApi.pwaMessagingToken.updateNotificationPermission(userId, true);

						const [tokenResponse, permissionResponse] = await Promise.all([tokenUpdatePromise, pwaNotificationPermissionPromise]);
						// TODO: handle responses
						setShouldShowCheckMobileMessage(false);
						setPwaMessagingPermissionStatus(true);
						setIsLoading(false);
					} else {
						isVerbose() && console.log('No registration token available. Request permission to generate one.');
						setIsLoading(false);
					}
				})
				.catch(async (e) => {
					setIsLoading(false);
					isVerbose() && console.log('Error in Firebase Messaging: ', e);
					setShouldShowCheckMobileMessage(false);
					if (e.code === 'messaging/permission-default') { // The notification permission was not granted and dismissed instead.
						// user dissmised it --> in the future it can be shown again
					} else if (e.code === 'messaging/permission-blocked') { // The notification permission was not granted and blocked instead.
						const res = await userApi.pwaMessagingToken.updateNotificationPermission(userId, false);
						setError(DICTIONARY.MY_ACCOUNT.notificationsSettings.pwaMessaging.error.permissionBlocked);
					} 
				});
		} catch(e) {
			// Notification doesnt being supported on Safari
		}
	};

	const handlePwaNessagingStateChange = async (event) => {
		// Enable only if PWA is installed
		if(isPwa) {
			// Turn on
			if(event.target.checked) {
				if (Notification.permission !== "denied") {
					// User already has token
					if(user.userData.pwaMessagingToken) {
						setIsLoading(true);
					} else {
						setIsLoading(true);
						setShouldShowCheckMobileMessage(true);
						handleMessagingRequestPermission();
					}
				} else {
					// Tell the user that he block it and that he need to change it by him self
					setError(DICTIONARY.MY_ACCOUNT.notificationsSettings.pwaMessaging.error.permissionBlocked);
				}
			} else { //Turn off
				if(user.userData.pwaMessagingToken && Notification.permission !== "denied") {
					// set isLoading
					setIsLoading(true);
				} else {
					// If we dont have token - we can immeditely turn it off
					setPwaMessagingPermissionStatus(event.target.checked);
				}
			}
		} else {
			// Show error
			setError(DICTIONARY.MY_ACCOUNT.notificationsSettings.pwaMessaging.error.notPwa);
		}
  };

	useEffect(() => {
		if(!isMount) {
			(async () => {
				const response = await userApi.pwaMessagingToken.updateNotificationPermission(user.userAuth.uid, !pwaMessagingPermissionStatus);

				if(!response.hasOwnProperty("error")){
					setIsLoading(false);
					setPwaMessagingPermissionStatus(!pwaMessagingPermissionStatus);
				} else {
					handleSnackbarDisplay({
						severity: SNACKBAR_SEVERITY.error,
						message: DICTIONARY.MY_ACCOUNT.notificationsSettings.pwaMessaging.error.generalError,
					});
					setIsLoading(false);
				}
			})();
		}
	}, [isLoading])

	//#region PWA explained dialog
	const handleClickOpenSaveAsPwaTutorialDialog = () => {
		handleCloseDialog(handleCloseSaveAsPwaTutorialDialog); // Update handleCloseDialog API context function

		history.push({
			pathname: `${parsedPathname(location.pathname)}/tutorial-pwa`,
			state: {
				background: location,
				id: 'tutorialPwa',
			},
		});
	};

	const handleCloseSaveAsPwaTutorialDialog = async ({isSubmittedSuccessfully}) => {
		//
	};
	//#endregion

  return (
    <React.Fragment>
			{/* SMS Toggle */}
			<FormControlLabel
				control={
					<Switch 
						checked={pwaMessagingPermissionStatus} 
						color="primary"
						onChange={handlePwaNessagingStateChange} 
						name="sms" 
					/>
				}
				label={DICTIONARY.MY_ACCOUNT.notificationsSettings.pwaMessaging.title}
				labelPlacement="end"
			/>
			{isLoading && <CircularProgress size={20} className={classes.loader}/> }

			{error && <Box mt={1} mb={2}>
				<Alert severity='warning'>{error}</Alert>
			</Box>}

			{!isPwa && <div>
				<Typography variant="body2" display="block" >
					{DICTIONARY.MY_ACCOUNT.notificationsSettings.pwaMessaging.subtitle}
				</Typography> 
				<Button 
					classes={{'root': classes.seeHowButton}} 
					disableRipple
					onClick={handleClickOpenSaveAsPwaTutorialDialog}	
				>
					{DICTIONARY.MY_ACCOUNT.notificationsSettings.pwaMessaging.seeHow}
				</Button>
			</div>}

			{/* isPwa and permission are true */}
			{shouldShowCheckMobileMessage && <div className={classes.phoneNumberExist}>
				<Alert severity='info'>{DICTIONARY.MY_ACCOUNT.notificationsSettings.pwaMessaging.approveNotification}</Alert>
			</div>}
    </React.Fragment>
  );
}
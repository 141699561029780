import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Material UI Components
import { Card, CardHeader, CardContent, useMediaQuery, useTheme } from '@material-ui/core';

// App components
import ComponentLoader from './loader/ComponentLoader';
import LightTooltip from '../shared/tooltip/LightTooltip';
import EmptyState from '../shared/EmptyState';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'    
  },
  cardHeader: {
    padding: theme.spacing(2, 2, 1.5, 2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
    },
  },
  cardHeaderTitle: {
    fontSize: theme.typography.pxToRem(20),
    whiteSpace: 'nowrap',
  	overflow: 'hidden',
  	textOverflow: 'ellipsis',
    width: '80%',
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  cardContent: {
    height: '70px', 
    // flex: 1,
    position: 'relative',
    width: '100%',
		padding: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "&:last-child": {
			paddingBottom: theme.spacing(1)
		},
    [theme.breakpoints.down("sm")]: {
      height: '50px', 
    },
	},
}));

export default function InfoCard({ title, content, isLoading, isEmpty, emptyState }) {
  const classes = useStyles();
  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        title={(
          title.length > 9 
            ? (
                <LightTooltip 
                  title={title} 
                >
                  <span>{title}</span>
                </LightTooltip>
              )
            : <span>{title}</span>
          )}
        classes={{
          'root': classes.cardHeader,
          'title': classes.cardHeaderTitle
        }}
      />
      <CardContent 						
        classes={{
          'root': classes.cardContent,
        }}>
          {isLoading 
            ? <ComponentLoader wrapperHeight={35}/>
            : isEmpty 
              ? <EmptyState 
                  icon={emptyState.icon}
                  title={!isMobile ? emptyState.title : ''}
                />
              : content 
          }
      </CardContent>
    </Card>
  );
}
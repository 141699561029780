import React, { useContext } from "react";
import { AuthContext } from '../../context';
import { makeStyles } from "@material-ui/core/styles";
import { adminApi, userApi, teamApi } from '../../api';
import DICTIONARY from '../../utils/dictionary';
import useDeleteForm from '../../hooks/deleteFormHook';


// Material UI Components
import { TextField } from '@material-ui/core';
// Colors
import {red} from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
}));

export default function LeaveTeamDialog({teamId, teamName, isAdmin, handleCloseDialog}) {
  const { user, handleUserUpdate } = useContext(AuthContext);
  const classes = useStyles();

  //#region Form Hook
  const initalFormState = {
		teamName: {
			label: DICTIONARY.TEAM.leaveTeam.form.field.teamName,
			value: '',
			isValid: true,
      required: true,
			validators: [],
		},
	};

	const onSubmit = async () => {
    const userId = user.userAuth.uid;
    let response;

    // Team Member
    if(!isAdmin) {
      response = await teamApi.leaveTeamMember(teamId, userId); 
    } else {
      // Admin
      response = await adminApi.team.leaveTeamAdmin(teamId, userId); 
    }

    if (response.hasOwnProperty("error")) {
      throw new Error(DICTIONARY.TEAM.leaveTeam.form.error.generalError);
    } else {
      // handleUserUpdate - User Auth context;
      const updatedUserData = await userApi.get(user.userAuth.uid); // Get userData from 'users' collection
      const teamsIds = updatedUserData?.teams || [];
      const updatedTeamsData = await teamApi.getTeamsByIds(teamsIds); // Get teamsData of user from 'teams' collection

      handleUserUpdate({
        userData: updatedUserData, 
        teamsData: updatedTeamsData,
      });
    };
	}

	const onCloseModal = (isSubmittedSuccessfully) => {
    const shouldIgnoreHistoryPushBack = true;
    handleCloseDialog({isSubmittedSuccessfully, shouldIgnoreHistoryPushBack});
  }

	const {formState, formError, isLoading, isValidToDelete, handleInputChange, handleSubmit, handleCloseModal} = useDeleteForm({
		initalFormState, 
    validationValue: teamName,
		onSubmit, 
		onCloseModal,
	});
  //#endregion

  const dialogContent = (
    <React.Fragment>
      {/* TeamName Field */}
      <TextField
        autoFocus
        margin="dense"
        id="teamName"
        label={formState.teamName.label}
        type="text"
        fullWidth
        value={formState.teamName.value}
        onChange={handleInputChange}
        autoComplete='teamName'
      />
    </React.Fragment>
  );

  return {
		dialogTitle: DICTIONARY.TEAM.leaveTeam.title,
		dialogContent,
    dialogContentText: (<span>{DICTIONARY.TEAM.leaveTeam.subtitle} <span style={{fontWeight: 'bold', color: 'black'}}>{teamName}</span></span>),
		sumbitButtonLabel: DICTIONARY.TEAM.leaveTeam.form.submit,
    submitButtonStyle: {backgroundColor: red[400]},
		handleSubmit,
		handleCloseModal,
		formError,
		isLoading,
    isValidToDelete,
	};
}
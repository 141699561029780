import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { adminApi } from '../../../api/index';
import DICTIONARY from '../../../utils/dictionary/index';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { Box, TextField, Popover, Typography, IconButton, useMediaQuery, useTheme } from "@material-ui/core";

import ComponentLoader from '../../shared/loader/ComponentLoader';

import LinkIcon from '@material-ui/icons/Link';

import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  invitationLink: {
    color: blue[800],
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default function InviteUsersToTeamDialog({teamId, teamName, handleCloseDialog}) {
  const classes = useStyles();
  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  //#region Main
  const containerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [invitationLink, setInvitationLink] = useState('');

  const buildInvitationLink = (encryptedInvitation) => {
    return `${window.location.origin}?invite=${teamId}&c=${encryptedInvitation}`;
  };

  useEffect(() => {
      setIsLoading(true);

			(async function () {
        try{
          const invitationLinkData = await adminApi.team.getInvitationLink(teamId);
          const {encryptedInvitation} = invitationLinkData;
          const invitationLink = buildInvitationLink(encryptedInvitation);

          setInvitationLink(invitationLink);
          setIsLoading(false);
        } catch (e) {
          setInvitationLink(DICTIONARY.TEAM.inviteUsersToTeam.error);
          setIsLoading(false);
        }

			})();
  }, [])

  const handleClickCopy = async () => {
    if(isMobile) { // Mobile
			const shareData = {
				title: 'Team UP',
				text: `${DICTIONARY.TEAM.inviteUsersToTeam.mobileShare.part1} ${teamName} ${DICTIONARY.TEAM.inviteUsersToTeam.mobileShare.part2}`,
				url: invitationLink,
			};

      await navigator.share(shareData);
    } else { // Desktop
      handlePopoverOpen(containerRef.current);
    }
  };
  //#endregion

  //#region Popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  //#endregion

  const dialogContent = (
		<React.Fragment>
      {isLoading ? 
        <ComponentLoader wrapperHeight={80}/> : 
        <React.Fragment>
          <Box mb={1} display="flex" justifyContent="center" alignItems="center">
            <TextField 
              id="invitation-link" 
              value={invitationLink} 
              fullWidth
              variant="outlined"
              size="small"
              disabled={true}
              InputProps={{ classes: { disabled: classes.invitationLink } }}
            />
            <CopyToClipboard text={invitationLink} onCopy={handleClickCopy}>
              <IconButton 
                aria-label="copy"         
                onMouseLeave={handlePopoverClose}
                ref={containerRef}
              >
                <LinkIcon />
              </IconButton>
            </CopyToClipboard>
          </Box>

          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={openPopover}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography>{DICTIONARY.TEAM.inviteUsersToTeam.copied}</Typography>
          </Popover>
        </React.Fragment>
      }      
    </React.Fragment>
  );

  return {
		dialogTitle: DICTIONARY.TEAM.inviteUsersToTeam.title,
		dialogContent,
    dialogContentText: (<span>{DICTIONARY.TEAM.inviteUsersToTeam.subtitle.part1} <span style={{fontWeight: 'bold', color: 'black'}}>{teamName}</span> {DICTIONARY.TEAM.inviteUsersToTeam.subtitle.part2}</span>),
    shouldHideActions: true,
	};
}
import React, { useContext } from "react";
import firebase from "../../config/firebase";
import { makeStyles } from "@material-ui/core/styles";
import { SnackbarContext } from '../../context';
import VALIDATORS from '../../utils/validators';
import DICTIONARY from '../../utils/dictionary';
import useForm from '../../hooks/formHook';
import { SNACKBAR_SEVERITY } from '../../utils/consts';
import { isLocalhost } from '../../utils/utils';

// Material UI Components
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
}));

export default function ForgotPasswordDialog({ handleCloseDialog }) {
	const { handleSnackbarDisplay } = useContext(SnackbarContext);
  const classes = useStyles();

  //#region Form Hook
  const initalFormState = {
		email: {
			label: DICTIONARY.AUTH.forgotPassword.form.field.email,
			value: '',
			isValid: true,
      required: true,
			validators: [
				{
					validate: (val) => VALIDATORS.isValidEmail(val),
					errorMessage: DICTIONARY.GENERAL.form.badlyFormattedEmail,
				},
			],
		},
	};

	const onSubmit = () => {
		const auth = firebase.auth();

		const actionCodeSettings = {
			url: isLocalhost() ? 'http://localhost:3000/' : 'https://teamupnow.app/',
		};
		auth.sendPasswordResetEmail(formState.email.value, actionCodeSettings).then(function() {
			// Display a snackbar that email verification sent
			handleSnackbarDisplay({
				severity: SNACKBAR_SEVERITY.info,
				message: DICTIONARY.AUTH.snackbar.resetPassowrd,
			});
		}).catch(function(error) {
			// An error happened.
		});
	}

	const onCloseModal = (isSubmittedSuccessfully) => {
    handleCloseDialog({isSubmittedSuccessfully});
  }

	const {formState, formError, isLoading, handleInputChange, handleSubmit, handleCloseModal} = useForm({
		initalFormState, 
		onSubmit, 
		onCloseModal,
	});
  //#endregion

	const dialogContent = (
		<React.Fragment>
      {/* Email Field */}
      <TextField
        autoFocus
        margin="dense"
        id="email"
        label={formState.email.label}
        type="email"
        fullWidth
        required
        value={formState.email.value}
        onChange={handleInputChange}
        autoComplete='email'
        error={!formState.email.isValid}
      />
		</React.Fragment>
	);

	return {
		dialogTitle: DICTIONARY.AUTH.forgotPassword.title,
		dialogContent,
    dialogContentText: DICTIONARY.AUTH.forgotPassword.subtitle,
		sumbitButtonLabel: DICTIONARY.GENERAL.button.submit,
		handleSubmit,
		handleCloseModal,
		formError,
		isLoading,
	};
}
const EVENT = {
  //#region My Event page
  myEvents: {
    title: 'My events',
    actions: {
      createEvent: 'Create event',
    }
  },
  //#endregion

  //#region Event page
  event: {
    actions: {
      edit: 'Edit event details',
      enableRegistration: 'Open event for registration',
      delete: 'Delete event',
      share: 'Share event page',
      setLineups: 'Set lineups',
      updateEventStats: 'Update event stats',
      registerParticipant: 'Register a participant',
      register: 'Register myself',
      unregister: 'Unregister myself'
    },
    tabs: {
      details: 'Event details',
      statistics: 'Statistics',
    },
    emptyState: `The required event doesn't exist.`,
    notOpenYet: 'The event will be open for registration at',
    cards: {
      registeredNumber: '# Registered',
      waitingListNumber: '# Waiting list',
      paidNumber: '# Paid',
      waitingList: 'Waiting list',
      participantsLog: 'Event registration records',
    },
    participantsCard: {
      registered: 'Participants',
      lineups: 'Lineups',
      unassigned: 'Unassigned',
    },
    participantsLogsCard: {
      registrations: 'Registration records',
      swaps: 'Swaps',
    },
    participantsLogTable: {
      emptyState: 'No records',
      head:{
        sign: ' ',
        date: 'Date',
        member: 'Member',
        action: 'Action',
        reason: 'Reason',
      },
    },
    swapsTable: {
      emptyState: 'No records',
      head:{
        in: 'In',
        out: 'Out',
      },
    },
    stats: {
      topCards: {
        topScorer: 'Top scorer',
        topAssist: 'Top assists',
        winningGroup: 'Most wins',
        emptyState: 'No data available',
      },
      playersTable: {
        title: 'Players stats',
        emptyState: 'No data available',
        head: {
          name: 'Name',
          group: 'Group',
          matchesPlayed: 'Matches played',
          events: 'Events',
          wins: 'Wins',
          goals: 'Goals',
          assists: 'Assists',
        }
      },
      groupsTable: {
        title: 'Groups stats',
        emptyState: 'No groups in event yet',
        head: {
          group: 'Group',
          matchesPlayed: 'MP',
          wins: 'Wins',
          ratio: 'Ratio'
        }
      },
    }
  },
  //#endregion
  upcomingEvents: {
    title: 'Upcoming events',
    seeMore: 'All events',
    seeMoreEvent: 'More details',
    emptyState: {
      title: 'You have no future events',
      subtitle: 'Create a team and share it with your friends.'
    },
    noParticipantsYet: 'Event has no participants yet',
    waitingList: 'Waiting list',
    actions: {
      createEvent: 'Create new event',
    }
  },
  registerToEventButton: {
    register: 'Register',
    unregister: 'Unregister',
    waitlist: 'Waitlist',
    full: 'Full',
    disabled: 'Registration opens at ',
    status: {
      succeeded: {
        register: 'Registered successfully',
        unregister: 'Unregistered successfully',
      },
      failed: 'Action failed. Please try again or contact support.'
    },
  },
  createEvent: {
    title: {
      create: 'Create new event',
      edit: 'Edit event details'
    },
    form: {
      field: {
        teamId: 'Team',
        location: 'Location',
        maxEventParticipants: 'Participants number',
        eventGroups: {
          label: 'Event groups',
          info: `Choose the color for each group in your event. The groups will represent the event's lineups.`,
        },
        isOpenForRegistration: 'Open for registration',
        eventDateAndTime: 'Event date & time',
        openRegistrationDateAndTime: 'Date & time to open registration',
      },
      error: {
        generalError: `Couldn't create event. Please try again or contact support.`,
        generalErrorEdit: `Couldn't edit event. Please try again or contact support.`,
        locationLength: 'Location must contain a least 2 characters.',
        dateInvalid: 'Date is not valid.',
        openRegistrationDateBeforeEventDate: 'Date & time to open registration must be before event.',
        maxParticipantTooSmall: {
          part1: 'Currently there are ',
          part2: 'registered participants',
          part3: 'No option to set smaller participants number.'
        },
      },
    },
  },
  deleteEvent: {
    title: 'Delete event',
    subtitle: 'Please confirm deleting the event by typing your email: ',
    form: {
      field: {
        adminEmail: 'Email',
      },
      error: {
         generalError: `Couldn't delete event. Please try again or contact support.`,
      },
    },
  },
  setLineups: {
    title: 'Set lineups',
    subtitle: '',
    addPlayer: 'Add player',
    emptyState: 'No participants in the event',
    groupEmptyState: 'No players in group yet',
    form: {
      field: {
        shouldShowLineups: 'Display lineups to event participants'
      },
      error: {
        generalError: `Couldn't update event lineups. Please try again or contact support.`,
      },
      submitButton: 'Update',
    },
  },
  updateEventStats: {
    title: 'Update event stats',
    subtitle: '',
    errorLoadingData: 'Error loading data. Please try again or contact support',
    sections: {
      groups: 'Groups',
      players: 'Players',
    },
    tableTitles: {
      group: 'Group',
      player: 'Player',
      mp: 'MP',
      wins: 'Wins',
      goals: 'Goals',
      assists: 'Assists',
    },
    form: {
      field: {
      },
      error: {
        generalError: `Couldn't update event stats. Please try again or contact support.`,
        winsNumber: {
          part1: 'In',
          part2: 'group, wins number can not be bigger than matches played number.'
        },
      },
      submitButton: 'Update',
    },
  },
  registerParticipantToEvent: {
    title: 'Register a participant',
    subtitle: 'Choose participants from your team memebers list or add guest participants',
    form: {
      field: {
        teamMemberToRegister: {
          label: 'Team member',
          info: 'You can select multiple team members.'
        },
        guestUser: {
          label: 'Guest user',
          info: 'To add multiple guests at once, separate their names by commas.'
        },
      },
      error: {
        generalError: `Couldn't register participant to event. Please try again or contact support.`,
        teamMemberToRegisterChosen: 'At least 1 team member must be chosen.',
        guestUserLength: 'Guest name must contain at least 1 char.',
      },
      submit: {
        register: 'Register'
      },
    },
  },
  unregisterParticipantFromEvent: { // Admin making action on a member
    title: 'Unregister a participant',
    subtitle: 'Are you sure you want to unregister',
    form: {
      error: {
        generalError: `Couldn't unregister participant from event. Please try again or contact support.`,
      },
      submit: {
        unregister: 'Unregister'
      }
    },
  },
  unregisterFromEvent: { // Member
    title: 'Unregister from event',
    subtitle: 'Are you sure you want to unregister from the event on',
    form: {
      error: {
        generalError: `Couldn't unregister from event. Please try again or contact support.`,
      },
      submit: {
        unregister: 'Unregister'
      }
    },
  },
  openEventForRegistration: {
    status: {
      succeeded: 'Event is now open for registration',
      failed: 'Action failed. Please try again or contact support.'
    },
  },
  shareEvent: {
    desktopDialog: {
      title: 'Share event page',
      subtitle: 'Event date: '
    },
    mobileNvigatorShare: {
      title: 'Check out the event occurring at',
    },
    copied: 'Copied',
  },
};

export default EVENT;
import React, { useState, useRef, useContext } from "react";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "../../config/firebase";
import { googleSignin, facebookSignin, sendEmailVerification } from '../../utils/helpers/authHelper';
import { DialogContext, SnackbarContext } from '../../context';
import { isInvitationLinkExists, isVerbose, parsedPathname } from '../../utils/utils';
import ROUTES from '../../routes';
import VALIDATORS from '../../utils/validators';
import DICTIONARY from '../../utils/dictionary';

// App Components
import SubmitButton from '../../components/shared/button/SubmitButton';
import SocialAuthButton from '../../components/shared/button/SocialAuthButton';

// Material UI Components
import {TextField, Link, Container, Grid, Typography, Box, Divider, Button} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

// Icons
import Logo from "../../assets/images/logo/logo.svg";
import GoogleIcon from "../../assets/svgIcons/google.svg";
import FacebookIcon from "../../assets/svgIcons/facebook.svg";

// Colors
import {grey, teal, blue} from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	logo: {
		height: '44px',
		marginBottom: theme.spacing(1),
		[theme.breakpoints.up("sm")]: {
      height: '50px',
    }
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		// height: '100%',
		marginTop: theme.spacing(1),
		[theme.breakpoints.up("sm")]: {
      height: 'auto',
    },
	},
	divider: {
		marginTop: theme.spacing(2),
	},
	forgotPasswordButton: {
		padding: 0,
		fontWeight: 400,
		backgroundColor: 'transparent !important',
		color: grey[900],
		'&:hover': {
			textDecoration: 'underline',
		}
	},
	forgotPasswordWrapper: {
		display: 'flex',
		justifyContent: 'center',
		[theme.breakpoints.up("sm")]: {
			justifyContent: 'flex-end'
    },
	},
	dontHaveLineWrapper: {
		display: 'flex',
		justifyContent: 'center',
		[theme.breakpoints.up("sm")]: {
			justifyContent: 'flex-start'
    },
	},
	dontHaveLine: {
		color: grey[900],
		lineHeight: '24px',
		'&:hover': {
			textDecoration: 'none',
			'& $signupLink': {
				textDecoration: 'underline',
			},
		},

	},
	signupLink: {
		marginLeft: '5px',
		color: teal['A700'],
		fontWeight: 500,
		'&:hover': {
			textDecoration: 'underline',
			color: teal['A700'],
		}
	},
	resendEmailVerification: {
		padding: 0,
		color: blue[800],
		backgroundColor: 'transparent !important',
		textDecoration: 'underline !important',
		'&:hover': {
			color: blue[900],
			textDecoration: 'underline !important',
		}
	},
}));

export default function Login(props) {
	const { handleCloseDialog, getDialogProps } = useContext(DialogContext);
	const { handleSnackbarDisplay } = useContext(SnackbarContext);
	const history = useHistory();
	const location = useLocation();
  const classes = useStyles();
	const windowHeight = window.innerHeight;

	//#region Form
	const initalFormState = {
		email: {
			label: DICTIONARY.AUTH.form.field.email,
			value: '',
			isValid: true,
			required: true,
			validators: [
				{
					validate: (val) => VALIDATORS.isValidEmail(val),
					errorMessage: DICTIONARY.GENERAL.form.badlyFormattedEmail,
				},
			],
		},
		password: {
			label: DICTIONARY.AUTH.form.field.password,
			value: '',
			isValid: true,
			validators: [],
		},
	};

	const [formState, setFormState] = useState(initalFormState);
	const [formError, setFormError] = useState("");
	const [shouldShowResendEmailVerificationButton, setShouldShowResendEmailVerificationButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
	const firebaseUserRef = useRef(null);

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		let key = id ;
		let val = value;

		setFormState((prevState) => {
			return { 
				...prevState,
				[key]: {
					...prevState[key],
					value: val,
					isValid: true,
				}
			};
		});
		setFormError("");
		setShouldShowResendEmailVerificationButton(false);
	};

	const handleSubmit = () => {
		setIsLoading(true);
		firebase.auth().signInWithEmailAndPassword(formState.email.value, formState.password.value)
			.then((firebaseUser) => {
				if(!firebaseUser.user.emailVerified) {
					setFormError(DICTIONARY.AUTH.form.error.emailNotVerified);
					setShouldShowResendEmailVerificationButton(true);
					firebaseUserRef.current = firebaseUser;
				}

				setIsLoading(false);
			})
			.catch((error) => {
				let errorMessage;
				const errorCode = error.code;

				switch(errorCode) {
					case 'auth/invalid-email':
						errorMessage = DICTIONARY.AUTH.form.error.incorrectEmailOrPassword; // badly formatted email
						break;
					case 'auth/user-not-found':
						errorMessage = DICTIONARY.AUTH.form.error.incorrectEmailOrPassword; // email doesnt exist in the system
						break;
					case 'auth/wrong-password':
						errorMessage = DICTIONARY.AUTH.form.error.incorrectEmailOrPassword;
						break;
					default:
						errorMessage = DICTIONARY.AUTH.form.error.incorrectEmailOrPassword;
						break;
				}
				isVerbose() && console.error(`Login error: ${error.message}`);

				setFormError(errorMessage);
				setIsLoading(false);
			});
	};
	//#endregion

	//#region Social Auth
  const handleSignInWithGoogle = (e) => {
		e.preventDefault();
		googleSignin();
	}

	const handleSignInWithFacebook = (e) => {
		e.preventDefault();
		facebookSignin(handleSnackbarDisplay);
	}
	//#endregion

	//#region Forgot password dialog
	const handleClickForgotPasswordDialog = () => {
		handleCloseDialog(handleCloseForgotPasswordDialog); // Update handleCloseDialog API context function

		history.push({
			pathname: `${parsedPathname(location.pathname)}/forgot-password`,
			state: {
				background: location,
				id: 'forgotPassward',
			},
		});
	};
	
	const handleCloseForgotPasswordDialog = async ({isSubmittedSuccessfully}) => {
		//
	};
	//#endregion

	const handleResendEmailVerification = async () => {
		await sendEmailVerification(firebaseUserRef.current, handleSnackbarDisplay);

		setFormError("");
		setShouldShowResendEmailVerificationButton(false);
	};

	return (
		<React.Fragment>
			<Container component="main" maxWidth="xs" className={classes.root} style={{height:windowHeight }}>
				<img src={Logo} alt="Team Up" className={classes.logo}/>
				{/* <Avatar className={classes.avatar}>
					<PersonOutlineOutlinedIcon />
				</Avatar> */}
				<Typography component='h1' variant='h5' color="primary">
					{DICTIONARY.AUTH.title.login}
				</Typography>

				{/* Form */}
				<form className={classes.form} noValidate>

					{/* Email */}
					<TextField
						autoFocus
						margin='dense'
						required
						fullWidth
						type='email'
						id='email'
						label={formState.email.label}
						name='email'
						value={formState.email.value}
						onChange={handleInputChange}
						autoComplete='email'
						error={!formState.email.isValid}
					/>

					{/* Password */}
					<TextField
						// variant='outlined'
						margin='dense'
						required
						fullWidth
						label={formState.password.label}
						name='password'
						value={formState.password.value}
						onChange={handleInputChange}
						type='password'
						id='password'
						autoComplete='password'
						error={!formState.password.isValid}
					/>

					{/* Error message */}
					{formError && (
						<Box mt={1}>
							<Alert severity='error'>
								{formError}
								{shouldShowResendEmailVerificationButton && <Button 
									onClick={handleResendEmailVerification}
									classes={{'root': classes.resendEmailVerification}}
									disableRipple>
									{DICTIONARY.AUTH.form.error.resendEmailVerification}
								</Button>}
							</Alert>
						</Box>
					)}

					{/* Login button */}
					<SubmitButton 
						text={DICTIONARY.AUTH.button.login}
						handleSubmit={handleSubmit}
						style={{width: '150px'}}
						isLoading={isLoading} 
						disabled={isLoading || formError.length > 0}
					/>
				</form>

				{/* Forget a password  */}
				<Grid container>
					<Grid item xs={12} sm={7} className={classes.dontHaveLineWrapper}>
						<Link 
							component={RouterLink} 
							to={isInvitationLinkExists(props.location.search) ? `${ROUTES.signup.path}${props.location.search}` : `${ROUTES.signup.path}`} 
							variant='body2'
							classes={{'root': classes.dontHaveLine}}
						>
							{DICTIONARY.AUTH.button.dontHaveAccount}
							<span className={classes.signupLink}>{DICTIONARY.AUTH.button.signup}</span>
						</Link>
					</Grid>
					<Grid item xs={12} sm={5} className={classes.forgotPasswordWrapper}>
						<Button
							onClick={handleClickForgotPasswordDialog}
							classes={{'root': classes.forgotPasswordButton}}
							disableRipple
						>
							{DICTIONARY.AUTH.button.forgotPassword}
						</Button>
					</Grid>
				</Grid>

				{/* Or - sign with external providers */}
				<Grid container alignItems='center' justifyContent='center' spacing={2} className={classes.divider}>
					<Grid item xs={4}>
						<Divider />
					</Grid>
					<Grid item>Or</Grid>
					<Grid item xs={4}>
						<Divider />
					</Grid>
				</Grid>

				{/* Login with google */}
				<Box mt={2} align='center'>
					<SocialAuthButton
						icon={GoogleIcon}
						text={DICTIONARY.AUTH.button.loginGoogle}
						onClick={handleSignInWithGoogle}
					/>
				</Box>

				{/* Login with facebook */}
				<Box mt={2} align='center'>
					<SocialAuthButton
						icon={FacebookIcon}
						text={DICTIONARY.AUTH.button.loginFacebook}
						onClick={handleSignInWithFacebook}
					/>
				</Box>
			</Container>
		</React.Fragment>
	);
}

import firebase from "../config/firebase";
import { isVerbose, isDevMode } from '../utils/utils';

const general = {
  generateId: function() {
    try{
      const generatedId = firebase.firestore().collection("tmp").doc().id; // Pre-Generete 

      return generatedId;
    } catch(e) {
      isVerbose() && console.error(`Error generating ID: ${e}`);
      return {
        error: `Can't generate ID.`
      };
    }
  },
  sendSupportTicket: async function(userId, ticketData) {
      try{
        const supportRef = firebase.firestore().collection("support");
        await supportRef.add({
            userId,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            ...ticketData,
          });
        return {
          success: true,
        };
      } catch (e) {
        const error = `Error sending support ticket.`;
        isVerbose() && console.error(error);
        return {
          error
        };
      }
  },
  getFeauresFlags: async function(userId) {
    try {
      if(isDevMode()) {
        return {
          success: true,
          // ff: ['shouldShowUpdateEventStatsModal',]
          ff: []
        }
      };

      const getUserFeauresFlags = firebase.functions().httpsCallable('getUserFeauresFlags');
      const response = await getUserFeauresFlags({userId});

      return response.data;
    } catch (e) {
      const error = `Error getting FFs, ${e}`;
      isVerbose() && console.error(error);
      return {
        error: `Error gettings FFs.`
      };
    }
  },
  testPwa: async function(userId) {
    try {
      const testPwa = firebase.functions().httpsCallable('testPwa');
      const response = await testPwa({
        userId,
      });

      return response.data;
    } catch (e) {
      const error = `Error testPwa, ${e}`;
      isVerbose() && console.error(error);
      return {
        error: `Error testPwa.`
      };
    }
  },
}

export default general;

const MY_ACCOUNT = {
  title: 'My account',
  basicInfo: {
    title: 'Basic info',
    name: 'Name',
    email: 'Email',
    avatar: 'Profile image',
    actions: {
      edit: 'Edit',
    },
    form: {
      title: 'Edit basic info',
      field: {
        name: 'Name',
        avatar: 'Profile image',
      },
      error: {
        generalError: `Couldn't update user basic info. Please try again or contact support.`,
        nameLength: 'Name must contain a least 2 characters.',
        imageType: 'File must be of an image file type',
        imageUpload: `Couldn't upload image. Please try again or contact support.`,
      },
    },
  },
  notificationsSettings: {
    title: 'Notifications settings',
    subTitle: 'Choose how you would like to receive updates of your teams and events.',
    sms: {
      title: 'SMS',
      emptyState: 'Enter your phone number to enable SMS notifications',
      actions: {
        enter: 'Enter',
        update: 'Update'
      },
      error: `Couldn't update SMS notifications permission. Please contact support ot try again.`,
      form: {
        // Step 1
        phoneNumberStep: {
          title: 'Enter your phone number',
          subTitle: 'By tapping Verify, an SMS may be sent.',
          // subTitle: 'By tapping Verify, an SMS may be sent. Message & data rates may apply.',
          submitButton: 'Verify', 
        },
        // Step 2
        verificationCodeStep: {
          title: 'Verify your phone number',
          subTitle: 'Enter the 6-digit code we sent to',
          submitButton: 'Submit', 
        },
        field: {
          country: 'Country',
          phoneNumber: 'Phone number',
          verificationCode: 'Verification code'
        },
        error: {
          generalError: `Couldn't update phone number. Please try again or contact support.`,
          invalidPhoneNumber: 'Invalid phone number.',
          invalidVerificationCode: 'Wrong code. Please try again.',
          phoneAlreadyInUse: 'Phone number is already in use.'
        },
      },
    },
    email: {
      title: 'Email',
      error: `Couldn't update Email notifications permission. Please contact support ot try again.`,
    },
    pwaMessaging: {
      title: 'Mobile push notifications',
      subtitle: 'Currently available only on Android devices.',
      seeHow: 'See how to apply it',
      error: {
        notPwa: 'To enable mobile notifications, access Team Up via your mobile home screen app.',
        permissionBlocked: 'Notifications permission is blocked. You need to enable it in your mobile settings.', // TODO: add: see how to do it..
        generalError: `Couldn't update push notification permissions. Please contact support ot try again.`,
      },
      approveNotification: 'Check your mobile for the notification approval from Team UP',
    }
  }
};

export default MY_ACCOUNT;
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import DICTIONARY from '../../utils/dictionary';

// App Components
import SubmitButton from '../shared/button/SubmitButton';

// Dialog - General
import SaveAsPwaTutorialDialog from '../shared/SaveAsPwaTutorialDialog';
import ForgotPasswordDialog from '../auth/ForgotPasswordDialog';
import MobileSupportDialog from '../support/MobileSupportDialog';

// Dialog - User
import EditUserBasicInfoDialog from '../user/basicInfo/EditUserBasicInfoDialog';

// Dialogs - Team
import CreateTeamDialog from '../team/CreateTeamDialog';
import LeaveTeamDialog from '../team/LeaveTeamDialog';
import ShareTeamPageDialog from '../team/ShareTeamPageDialog';

// Dialogs - Team admin
import DeleteTeamDialog from '../team/adminActions/DeleteTeamDialog';
import AddMemberToTeamDialog from '../team/adminActions/AddMemberToTeamDialog';
import AssignAdminToTeamDialog from '../team/adminActions/AssignAdminToTeamDialog';
import DefinePrimeMemberDialog from '../team/adminActions/DefinePrimeMemberDialog';
import RemoveMemberFromTeamDialog from '../team/adminActions/RemoveMemberFromTeamDialog';
import InviteUsersToTeamDialog from '../team/adminActions/InviteUsersToTeamDialog';

// Dialog - Event
import UnregisterDialog from '../event/UnregisterDialog';
import ShareEventDialog from '../event/ShareEventDialog';
import UpdateEventStatsDialog from '../event/UpdateEventStatsDialog';

// Dialog - Event admin
import CreateEventDialog from '../event/adminActions/CreateEventDialog';
import DeleteEventDialog from '../event/adminActions/DeleteEventDialog';
import SetLineupsDialog from '../event/adminActions/SetLineupsDialog';
import RegisterParticipantDialog from '../event/adminActions/RegisterParticipantDialog';
import AdminUnregisterParticipantDialog from '../event/adminActions/AdminUnregisterParticipantDialog';

// Material UI Components
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Box, Button, useMediaQuery, useTheme } from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";

// Icons
import {
	Close as CloseIcon,
  ChevronLeft as ChevronLeftIcon,
} from '@material-ui/icons';

// Colors
import { blueGrey, blue, grey, teal } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    [theme.breakpoints.down("sm")]: {
			position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      height: '60px',
      borderBottom: `1px solid ${blueGrey[100]}`,
      paddingLeft: theme.spacing(7),
      
      '& .MuiTypography-h6': {
        lineHeight: '1.5',
      },
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButtonMobile: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(0.75),
    color: theme.palette.grey[500],
  },
	dialogContent: {
		padding: theme.spacing(0, 3, 2),
    "&:first-child": {
			paddingTop: theme.spacing(0),
		},
    [theme.breakpoints.down("sm")]: {
			position: 'fixed',
      top: '60px',
      bottom: '74px',
      left: 0,
      right: 0,
      padding: theme.spacing(0, 3, 3),
    },
	},
  dialogContentText: {
    padding: theme.spacing(1, 0, 2),
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 0, 2),
    },
  },
  dialogActions: {
    [theme.breakpoints.down("sm")]: {
			position: 'fixed',
      bottom: '0',
      left: 0,
      right: 0,
      height: '74px',
      padding: theme.spacing(0, 2),
      borderTop: `1px solid ${blueGrey[100]}`,
    },
  }
}));

const MODALS_IDS = {
  // General
  tutorialPwa: SaveAsPwaTutorialDialog,
  forgotPassward: ForgotPasswordDialog,
  mobileSupport: MobileSupportDialog,
  // User
  editUserBasicInfo: EditUserBasicInfoDialog,
  //Team
  createTeam: CreateTeamDialog, // Serves also as editTeam
  leaveTeam: LeaveTeamDialog,
  shareTeamPage: ShareTeamPageDialog,
  // Team - admin
  deleteTeam: DeleteTeamDialog,
  addMemberToTeam: AddMemberToTeamDialog,
  assignAdminToTeam: AssignAdminToTeamDialog,
  definePrimeMember: DefinePrimeMemberDialog,
  removeMemberFromTeam: RemoveMemberFromTeamDialog,
  shareTeam: InviteUsersToTeamDialog,
  //Event
  unregisterParticipant: UnregisterDialog,
  shareEvent: ShareEventDialog,
  updateEventStats: UpdateEventStatsDialog,
  // Event - Admin
  createEvent: CreateEventDialog, // Serves also as editEvent
  setLineups: SetLineupsDialog,
  deleteEvent: DeleteEventDialog,
  registerParticipant: RegisterParticipantDialog,
  adminUnregisterParticipant: AdminUnregisterParticipantDialog,
};

export default function Modal({ id, onCloseCallback, otherProps }) {
  let history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  //#region openDialog
  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = ({isSubmittedSuccessfully, response, shouldIgnoreHistoryPushBack}) => {
    setOpenDialog(false);
    !shouldIgnoreHistoryPushBack && history.goBack();
    onCloseCallback({isSubmittedSuccessfully, response});
  };

  useEffect(() => {
    setOpenDialog(true);
  }, [])
  //#endregion

  //#region Define dialog
  const modalComponent = MODALS_IDS[id]({ handleCloseDialog, ...otherProps });
  const { 
    dialogTitle, 
    dialogContentText, 
    dialogContent, 
    sumbitButtonLabel, 
    submitButtonStyle, 
    handleSubmit, 
    formError, 
    isLoading, 
    isValidToDelete, 
    shouldHideActions 
  } = modalComponent;
  //#endregion

  //#region isDisabled
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if(shouldHideActions) { // Modal with no actions
      // do nothing
    } else if(isValidToDelete === undefined) { // Modal with regular form
      setIsDisabled(isLoading || formError.length > 0); 
    } else { // Modal for delete purposes 
      setIsDisabled(!isValidToDelete);
    }
  }, [formError, isLoading, isValidToDelete])
  //#endregion

  return (
    <Dialog 
      open={openDialog} 
      fullScreen={isMobile}
      fullWidth={ true } 
      maxWidth={"sm"}
      aria-labelledby="form-dialog-title"
    >
      {/* Dialog title */}
      <DialogTitle 
        id="form-dialog-title"
        classes={{
          'root': classes.dialogTitle,
        }}
      >
        {isMobile && <IconButton 
          aria-label="close" 
          className={classes.closeButtonMobile} 
          onClick={(e) => handleCloseDialog({isSubmittedSuccessfully: false})}
        >
          <ChevronLeftIcon />
        </IconButton>}
        {dialogTitle}
        {!isMobile && <IconButton 
          aria-label="close" 
          className={classes.closeButton} 
          onClick={(e) => handleCloseDialog({isSubmittedSuccessfully: false})}
        >
          <CloseIcon />
        </IconButton>}
      </DialogTitle>

      <form className={classes.form} noValidate>
        <DialogContent 
          classes={{'root': classes.dialogContent}}
        >

          {/* Dialog content text */}
          {dialogContentText && (
            <DialogContentText 
              classes={{
                'root': classes.dialogContentText,
              }}
              variant="body2">
              {dialogContentText}
            </DialogContentText>)
          }

          {/* Dialog content */}
          {dialogContent}

          {/* Error message */}
          {formError && (
            <Box mt={1}>
              <Alert severity='error'>{formError}</Alert>
            </Box>
          )}
        </DialogContent>

        {/* Dialog actions */}
        {!shouldHideActions && (
          <DialogActions 
            classes={{'root': classes.dialogActions}}
          >
          <Button onClick={(e) => handleCloseDialog({isSubmittedSuccessfully: false})} >
            {DICTIONARY.GENERAL.button.cancel}
          </Button>

          <SubmitButton 
            text={sumbitButtonLabel}
            isLoading={isLoading} 
            handleSubmit={handleSubmit}
            disabled={isDisabled}
            style={submitButtonStyle}
          />
        </DialogActions>
        )}
      </form>
    </Dialog>
  );
}
import React, { useState, useContext } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import firebase from "../../config/firebase";
import { AuthContext, DialogContext } from '../../context';
import { makeStyles } from "@material-ui/core/styles";
import { isVerbose, parsedPathname } from '../../utils/utils';
import ROUTES from '../../routes/index'
import DICTIONARY from '../../utils/dictionary';

// Material UI Components
import { AppBar, Toolbar, Box, Menu, MenuItem, Link, Divider, useMediaQuery, useTheme } from "@material-ui/core";

// App components
import Squircle from '../shared/userAvatar/Squircle';

// Colors
import {blueGrey} from '@material-ui/core/colors';

import Logo from "../../assets/images/logo/logo-white.svg";

const useStyles = makeStyles((theme) => ({
	appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
		}),
		backgroundColor: blueGrey[700], // teal-500
  },
  menuButton: {
    marginRight: 5,
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  menuButtonMobile: {
    // marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  hide: {
    display: "none"
	},
	toolBar: {
		display: "flex",
		justifyContent: "space-between",
	},
	accountIcon: {
		color: "#fff",
		height: '40px',
		width: '40px',
		objectFit: 'cover',
		cursor: 'pointer',
		'&:hover': {
			opacity: '0.9'
		}
	},
	logoWrapper: {
    height: '48px',
    display: 'flex',
    alignItems: 'center',
	},
	logo: {
		height: '26px',
	},
	accountMenu: {
		padding: 0,
		[theme.breakpoints.up("sm")]: {
			paddingBottom: theme.spacing(1),
    }
	},
	userName: {
		backgroundColor: `${blueGrey[50]} !important`, 
		padding: '10px 16px',
		cursor: 'default'
	},
	menuItemLink: {
		color: 'inherit',
    fontSize: 'inherit',
		textDecoration: 'none !important',
		display: 'block',
		padding: theme.spacing(1, 2),
		width: '100%',
	},
	menuListItem: {
		padding: 0,
	},
}));

export default function AppHeader() {
	const classes = useStyles();
	const { user } = useContext(AuthContext);
	const { handleCloseDialog, getDialogProps } = useContext(DialogContext);
	const history = useHistory();
	const location = useLocation();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	// #region Account menu
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenAccountMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

  const handleCloseAccountMenu = () => {
		setAnchorEl(null);
  };

  const handleLogout = () => {
		firebase.auth().signOut().then(() => {
			// Sign-out successful.
		}).catch(function(e) {
			isVerbose() && console.error(e);
		});
  };
	// #endregion
	const handleSupportButtonClick = (event) => {
		// Modal part
		handleCloseDialog(() => getDialogProps({})); // Update handleCloseDialog API context function
		history.push({
			pathname: `${parsedPathname(location.pathname)}/support-ticket`,
			state: {
				background: location,
				id: 'mobileSupport',
			},
		});

		handleCloseAccountMenu();
  };

	return (
		<AppBar 
			position='fixed'         
			className={classes.appBar}
			>
			<Toolbar className={classes.toolBar}>

				{/* Left side */}
				<Box display="flex" alignItems="center">
					<RouterLink to={ROUTES.root.path} className={classes.logoWrapper}>
						<img src={Logo} alt="Team Up" className={classes.logo}/>
					</RouterLink>
				</Box>

				{/* Right Side */}
				<Box component="div" display="flex" onClick={handleOpenAccountMenu}>
					{/* {user && user.userAuth.displayName} */}
					<Squircle 
            src={user.userData.avatar}
            alt={user.userData.name}
            className={classes.accountIcon}
          />
				</Box>

				<Menu
					id='menu-appbar'
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleCloseAccountMenu}
					classes={{'list': classes.accountMenu}}
				>
					{/* User name */}
					<MenuItem 
						disableRipple 
						className={classes.userName}
					>
						{user && user.userData.name}
					</MenuItem>

					{/* My account */}
					<MenuItem onClick={handleCloseAccountMenu} classes={{'root': classes.menuListItem}}>
						<Link 
							component={RouterLink} 
							to={`${ROUTES.myAccount.path}`} 
							variant='caption' 
							className={classes.menuItemLink}
						>
							{DICTIONARY.GENERAL.appHeader.myAccount}
						</Link>
					</MenuItem>

					{/* Contact us - Relevant only on mobile (on Desktop the support is from the fav button at the bottom of the page) */}
					{isMobile && 
						<MenuItem
							onClick={handleSupportButtonClick}	
						>
							{DICTIONARY.GENERAL.appHeader.contactUs}
						</MenuItem>
					}
					{isMobile && <Divider />}

					{/* Logout */}
					<MenuItem onClick={handleLogout}>{DICTIONARY.GENERAL.appHeader.logout}</MenuItem>
				</Menu>
			</Toolbar>
		</AppBar>
	);
}

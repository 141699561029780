import dotenv from "dotenv";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import "firebase/messaging";
import "firebase/analytics";
// import "firebase/app-check";

dotenv.config();

var firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.storage();
const analytics = firebase.analytics();

// App Check
// const appCheck = firebase.appCheck();
// appCheck.activate(process.env.REACT_APP_RECAPTCHA_SITE_KEY);

//#region Messaging
let messaging;
try {
	if (firebase.messaging.isSupported()) {
		// console.log('firebase messaging is supported');
		
		messaging = firebase.messaging();	
		// Handle incoming messages. Called when:
		// - a message is received while the app has focus
		// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
		messaging.onMessage((payload) => {
			// Show nofification
			// console.log('Message received. ', payload);
			// ...
		});
	} else {
		// console.log('firebase messaging is NOT supported');
	}
} catch(e) {
	// console.log('firebase messaging error: ', e);
}
//#endregion

const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const Timestamp = firebase.firestore.Timestamp;
const FieldValue = firebase.firestore.FieldValue;

// Activate Emulator to test the Cloud functions locally
if (window.location.hostname === "localhost" && process.env.REACT_APP_EMULATOR === "true") {
	console.log('*** EMULATOR ***');
	firebase.firestore().useEmulator("localhost", 8080);
	firebase.functions().useEmulator("localhost", 5001);
	firebase.storage().useEmulator("localhost", 9199);
	firebase.auth().useEmulator('http://localhost:9099/');
}

export { googleProvider, facebookProvider, Timestamp, FieldValue, messaging, analytics };
export default firebase;

import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from '../../context';
import DICTIONARY from '../../utils/dictionary';
import { EVENT_GROUPS_COLORS } from '../../utils/consts';
import { containsHebrew } from '../../utils/utils';

// App Components
import EmptyState from '../shared/EmptyState';
import ComponentLoader from '../shared/loader/ComponentLoader';
import Squircle from '../shared/userAvatar/Squircle';
import LightTooltip from '../shared/tooltip/LightTooltip';

// Material UI Components
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@material-ui/data-grid';
import { useMediaQuery, useTheme } from '@material-ui/core';

// Icons
import {
  ListRounded as ListRoundedIcon,
} from '@material-ui/icons';
import ShirtIcon from '../icon/ShirtIcon';

// Colors
import { blueGrey, lime } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    // border: 'none',
    '& .MuiDataGrid-toolbarContainer': {
      gap: '10px',
      backgroundColor: 'whitesmoke',
      padding: theme.spacing(0.5, 1, 0.5),

      '& .MuiButton-textPrimary': {
        color: blueGrey[800],
      },
    },
    '& .user-self-highlight': {
      backgroundColor: lime['A200'],
    },
    '& .last-cloumn-header': {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      }
    },
    '& .rendering-zone': {
      width: '100% !important',
    },
    '& .MuiDataGrid-row': {
      width: '100% !important',
    },
    '& .MuiDataGrid-colCell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-colCell': {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 0, 0, 1),
      },
    },
    '& .MuiDataGrid-cell': {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 1),
      },
    },
    '& .MuiDataGrid-columnHeader': {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 0, 0, 0.5),
      },
    },
  },
  mobileTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  mobileText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '95%',
    display: 'block',
  },
  shirtIconGroup: {
    marginRight: theme.spacing(0.5),
    width: '24px',
    height: '19px',
    stroke: '#313131', strokeWidth: '2px', paintOrder: 'stroke', strokeLinejoin: 'round',
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

export default function PlayersStatsTable({ isLoading, isError, playersStats, columnsToHide = [] }) {
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [columns, setColumns] = useState([]);

  // #region DataGrid init
  const [pageSize, setPageSize] = useState(15);
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'goals',
      sort: 'desc',
    },
  ]);
  // #endregion

  const setCellClassName = (params) => {
    const { id } = params.row;
    if(user.userAuth.uid === id) {
      return 'user-self-highlight'
    }
  };

  const setHeaderClassName = (params) => {
    try {
      const lastColumnField = params.api.current.getAllColumns().filter(c => c.hide === false).pop().field;

      if(lastColumnField === params.field) {
        return 'last-cloumn-header'
      }
    } catch(e) {
      return '';
    }
  };

  const allColumns = [
    { 
      field: 'name', 
      headerName: DICTIONARY.EVENT.event.stats.playersTable.head.name, 
      type: 'string', 
      flex: 3,
      renderCell: (params) => {
        const { name, avatar } = params.row;
        const wrapperStyle = {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        };
        let avatarStyle = {
          width: '28px',
          height: '28px',
          objectFit: 'cover',
          marginRight: '8px',
        };

        avatarStyle = isMobile 
          ? {
              ...avatarStyle,
              width: '20px',
              height: '20px',
            }
          : avatarStyle;
  
        const element = isMobile 
          ? <LightTooltip 
              title={name} 
            >
              <span 
                className={classes.mobileText}
                style={{
                  direction: containsHebrew(name) ? 'rtl' : 'ltr',
                }}
              >
                {name}
              </span>
            </LightTooltip>
          : <span>{name}</span>

        return (
          <div style={wrapperStyle}>
            <Squircle 
              src={avatar}
              alt={name}
              style={avatarStyle}
            />
            {element}
          </div>
        )
      },
      cellClassName: (params) => setCellClassName(params),
      headerClassName: (params) => setHeaderClassName(params),
    },
    { 
      field: 'group', 
      hide: true,
      headerName: DICTIONARY.EVENT.event.stats.playersTable.head.group, 
      type: 'string', 
      flex: 2,
      renderCell: function(params) {
        const { group } = params.row;
        const wrapperStyle = {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        };
        let element = <span>-</span>;

        if(group) {
          element = isMobile 
          ? <span style={wrapperStyle}><ShirtIcon style={{ color: EVENT_GROUPS_COLORS[group].hex }} className={classes.shirtIconGroup} /></span>
          : <span style={wrapperStyle}><ShirtIcon style={{ color: EVENT_GROUPS_COLORS[group].hex }} className={classes.shirtIconGroup} /> {group}</span>
        }

        return (
          <div style={wrapperStyle}>
            {element}
          </div>
        )
      },
      sortComparator: (v1, v2, param1, param2) => {
        try{
          const param1Row = param1.api.getRow(param1.id);
          const param2Row = param2.api.getRow(param2.id);
          const diff = param1Row.group - param2Row.group ? 1 : -1;
          return diff;
        } catch(e) {
          return true;
        }
      },
      cellClassName: (params) => setCellClassName(params),
      headerClassName: (params) => setHeaderClassName(params),
    },
    { 
      field: 'events', // Total number of events with stats data (events can have 'empty' stats data so they will not count here)
      headerName: DICTIONARY.EVENT.event.stats.playersTable.head.events, 
      type: 'string', 
      flex: 2,
      cellClassName: (params) => setCellClassName(params),
      headerClassName: (params) => setHeaderClassName(params),
    },
    { 
      field: 'matchesPlayed', 
      headerName: DICTIONARY.EVENT.event.stats.playersTable.head.matchesPlayed, 
      type: 'string', 
      flex: 2,
      cellClassName: (params) => setCellClassName(params),
      headerClassName: (params) => setHeaderClassName(params),
    },
    { 
      field: 'wins', 
      headerName: DICTIONARY.EVENT.event.stats.playersTable.head.wins, 
      type: 'string', 
      flex: 2,
      cellClassName: (params) => setCellClassName(params),
      headerClassName: (params) => setHeaderClassName(params),
    },
    { 
      field: 'goals', 
      headerName: DICTIONARY.EVENT.event.stats.playersTable.head.goals, 
      type: 'string', 
      flex: 2,
      cellClassName: (params) => setCellClassName(params),
      headerClassName: (params) => setHeaderClassName(params), 
    },
    { 
      field: 'assists', 
      headerName: DICTIONARY.EVENT.event.stats.playersTable.head.assists, 
      type: 'string', 
      flex: 2,
      cellClassName: (params) => setCellClassName(params),
      headerClassName: (params) => setHeaderClassName(params),
    },
  ];

  useEffect(() => {
    setPageSize(playersStats.length || 15);
  }, [playersStats])

  useEffect(() => {
    const finalColumns = [...allColumns].filter(column => {
      const { field } = column;
      if(!columnsToHide.includes(field)) {
        return true;
      } else {
        return false;
      }
    });

    setColumns(finalColumns);
  }, [isMobile])

  return (
    <React.Fragment>
      {isLoading
        ? <ComponentLoader wrapperHeight={300}/>
        : (playersStats.length === 0 || isError)
          ? <EmptyState 
              icon={<ListRoundedIcon style={{ fontSize: 80, color: blueGrey[500] }}/>}
              title={DICTIONARY.EVENT.event.stats.playersTable.emptyState}
              padding={5}
            />
          : <DataGrid
              rows={playersStats}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={handlePageSizeChange}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
              disableSelectionOnClick
              autoHeight
              autoPageSize
              className={classes.root}
              loading={isLoading}
              disableColumnFilter
              disableColumnMenu
              sortingOrder={['desc', 'asc']}
              components={{
                Toolbar: CustomToolbar,
              }}
              componentsProps={{
                toolbar: { color: 'secondary' },
              }}
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
            />
        }
    </React.Fragment>
  );
}
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext, DialogContext } from '../../context';

import { makeStyles } from '@material-ui/core/styles';
import { convertNumberToDay, formatDate, isVerbose, parsedPathname } from '../../utils/utils';
import { IMAGE_PLACEHOLDER } from '../../utils/consts';
import ROUTES from '../../routes/index';
import DICTIONARY from '../../utils/dictionary/index';

// Material UI Components
import {Card, CardContent, CardHeader, Typography } from '@material-ui/core';

// App Components
import HeaderActions from '../shared/button/HeaderActions';
import EmptyState from '../shared/EmptyState';
import ComponentLoader from '../shared/loader/ComponentLoader';

// Icons
import { 
	Add as AddIcon,
	Security as SecurityIcon 
} from '@material-ui/icons';

// Colors
import {blueGrey, grey} from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
	},
	cardHeaderAction: {
		zIndex: '2',
		margin: theme.spacing(0, -1),
	},
	cardContent: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1),
    },
	},
	teamsList: {
		display: 'grid',
    rowGap: '15px',
    width: '100%',
		gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
		columnGap: `15px`,
	},
	teamItem: {
		justifySelf: 'center',
    width: '100%',
		cursor: 'pointer',
		'&:hover': {
			'& $teamAvatar': {
				transform: 'scale(1.03)',
				transition: '.2s ease',
			},
			'& $teamInfo': {
				backgroundColor: blueGrey[50],
				borderRadius: '0 0 5px 5px',
				transition: '.2s ease',
			},
		}
	},
	teamAvatarContainer: {
		overflow: 'hidden',
		border: `1px solid ${blueGrey[100]}`,
		borderRadius: '5px 5px 0 0',
	},
	teamAvatar: {
		display: 'block',
		width: '100%',
		height: '150px',
    objectFit: 'cover',
	},
	teamInfo: {
		display: 'block',
		padding: '10px',
	},
	teamName: {
		fontSize: '1rem'
	},
	teamDetails: {
		whiteSpace: 'nowrap',
  	overflow: 'hidden',
  	textOverflow: 'ellipsis',
    width: '100%',
		fontSize: '0.875rem',
		color: grey[800],
	},
}));

export default function TeamListDashboard() {
	const classes = useStyles();
	const { user } = useContext(AuthContext);
	const { handleCloseDialog } = useContext(DialogContext);
	const history = useHistory();
	const location = useLocation();

	// States
	const [teamsData, setTeamsData] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	//#region Load Data
	useEffect(() => {
		try{
			setIsLoading(true); // Do we need the isLoading here? beacuse there is no async process here (teamsData is coming from the context)
			const teamsData = user.teamsData || [];
			setTeamsData(teamsData);
			setIsLoading(false);
		} catch(e) {
			isVerbose() && console.error("Error getting teamsData:", e);
			setIsLoading(false);
		}
	}, [user.teamsData]);
	//#endregion

	//#region Create Team Dialog
	const handleClickOpenCreateTeamDialog = () => {
		handleCloseDialog(handleCloseCreateTeamDialog); // Update handleCloseDialog API context function

		history.push({
			pathname: `${parsedPathname(location.pathname)}create-team`,
			state: {
				background: location,
				id: 'createTeam',
			},
		});
  };

	const handleCloseCreateTeamDialog = async (isSubmittedSuccessfully) => { 
		//
	};
	//#endregion

	//#region Helpers
	const onTeamClicked = (teamId) => {
		history.push({
			pathname: `${ROUTES.myTeams.path}/${teamId}`,
			search: '',
		});
	}

	const ACTIONS = {
		0: {
			actions: [
				{
					key: 'createTeam',
					iconElement: <AddIcon />,
					title: DICTIONARY.TEAM.teamListDashboard.createTeam,
					onClick: handleClickOpenCreateTeamDialog,
				},
			],
		}
	};
	//#endregion

  return (
		<React.Fragment>
			<Card className={classes.root} elevation={0}>
				<CardHeader
					titleTypographyProps={{ variant:'h6' }}
					title={DICTIONARY.TEAM.teamListDashboard.title}
					classes={{
						'action': classes.cardHeaderAction
					}}
					action={
						<HeaderActions actions={Object.keys(teamsData).length >= 1 ? ACTIONS : {}}/>
					}
				/>

				{isLoading 
					? <ComponentLoader/>
					: <CardContent 	
							classes={{
								'root': classes.cardContent
							}}
						>
							<React.Fragment>
								{Object.keys(teamsData).length ? 
									<ul className={classes.teamsList}>
										{Object.keys(teamsData).length > 0 && Object.keys(teamsData).map((teamId) => (
											<li 
												md={3} 
												xs={12} 
												key={teamId}
												onClick={() => onTeamClicked(teamId)}
												className={classes.teamItem}
											>
												<div className={classes.teamAvatarContainer}>
													<object data={teamsData[teamId].avatar} type="image/jpeg" className={classes.teamAvatar}>
														<img src={IMAGE_PLACEHOLDER} alt={teamsData[teamId].teamName} className={classes.teamAvatar} />
													</object>
												</div>
												<div className={classes.teamInfo}>
													<Typography 
														component="h1"
														display="block" 
														className={classes.teamName}
													>
														{teamsData[teamId].teamName}
													</Typography>
													<Typography 
														component="h2"
														display="block" 
														className={classes.teamDetails}
													>
														{`${teamsData[teamId].defaultEvents[0].defaultLocation} | ${convertNumberToDay(teamsData[teamId].defaultEvents[0]?.defaultDay)} | ${formatDate(teamsData[teamId].defaultEvents[0]?.defaultTime, 'hh:mm a')}`}
													</Typography>
												</div>
											</li>
										))}
									</ul>
									: <EmptyState 
										icon={<SecurityIcon style={{ fontSize: 80, color: blueGrey[500] }}/>}
										title={DICTIONARY.TEAM.teamListDashboard.emptyState.title}
										subtitle={DICTIONARY.TEAM.teamListDashboard.emptyState.subtitle}
										action={ACTIONS[0].actions[0]}
										padding={1}
									/>

								}
							</React.Fragment>
						</CardContent>
				}
			</Card>
		</React.Fragment>
  );
}
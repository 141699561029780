import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from "@material-ui/core/styles";
import DICTIONARY from '../utils/dictionary';
import { APP_NAME } from '../utils/consts';

// App Components
import PageHeader from '../components/layout/PageHeader';
import UserBasicInfo from '../components/user/basicInfo/UserBasicInfo';
import UserNotificationsSettingsContainer from '../components/user/notificationsSettings/UserNotificationsSettingsContainer';
import TestPwa from '../components/other/TestPwa';

// Material UI Components
import { Grid } from '@material-ui/core';
import { isLocalhost } from '../utils/utils';

const useStyles = makeStyles((theme) => ({
	section: {
		marginTop: theme.spacing(2, 0, 0)
	}
}));

export default function MyAccount() {
	const classes = useStyles();

  return (
		<React.Fragment>
			<Helmet>
				<title>My account | {APP_NAME}</title>
			</Helmet>

			<PageHeader 
				title={DICTIONARY.MY_ACCOUNT.title}
			/>

			<Grid container spacing={2}>

				{/* Basic info section */}
				<Grid item xs={12} md={8}>
					<UserBasicInfo />
				</Grid>

				{/* Notifications settings section */}
				<Grid item xs={12} md={8}>
					<UserNotificationsSettingsContainer />
				</Grid>
			</Grid>

			{/* {(isLocalhost() && <TestPwa />} */}
		</React.Fragment>
	);
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Material UI Components
import { Box, Typography } from '@material-ui/core';

// App components
import OneActionButton from './button/OneActionButton.js';

const useStyles = makeStyles((theme) => ({
}));

export default function EmptyState({icon, title, subtitle, action, padding = 0}) {
  const classes = useStyles();

  return (
    <Box pt={padding} pb={padding} display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{margin: 'auto', textAlign: 'center'}}>
			{icon}
			<Typography variant="body2" align="center">{title}</Typography>
      {subtitle && <Typography variant="caption">{subtitle}</Typography>}
      {action && <OneActionButton {...action}/>}
		</Box>
  );
}
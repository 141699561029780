import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { eventApi } from '../../api';
import DICTIONARY from '../../utils/dictionary';
import { EVENT_GROUPS_COLORS } from '../../utils/consts';

// App Components
import InfoCard from "../shared/InfoCard";
import UserAvatar from '../shared/userAvatar/UserAvatar';
import PlayersStatsTable from "../stats/PlayersStatsTable";
import GroupsStatsTable from "../stats/GroupsStatsTable";
import StatInfoCardContent from '../stats/StatInfoCardContent';

// Material UI Components
import { Grid, Card, CardHeader, CardContent, Box, } from '@material-ui/core';

// Colors
import { blueGrey } from '@material-ui/core/colors';

// Icons
import {
  PersonOutline as PersonOutlineIcon
} from '@material-ui/icons';
import ShirtIcon from '../icon/ShirtIcon';

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		padding: 0,
    [theme.breakpoints.up("sm")]: {
			padding: theme.spacing(0, 2),
    }
	},
  cardHeader: {
    padding: theme.spacing(2, 2, 1, 2),
  },
  playersStatsCardContent: {
		padding: theme.spacing(0),
		[theme.breakpoints.up("sm")]: {
			padding: theme.spacing(2),
    },
		// '& >div': {
		// 	'& >div': {
		// 		height: '100% !important',
		// 	},
		// },
	},
  topCards: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr',
		columnGap: '12px',
		[theme.breakpoints.up("md")]: {
			columnGap: '16px',
			gridTemplateColumns: '1fr 1fr 1fr',
    }
	},
  shirtIconGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  shirtIconGroup: {
    width: '24px',
    height: '19px',
    stroke: '#313131', strokeWidth: '2px', paintOrder: 'stroke', strokeLinejoin: 'round',
    [theme.breakpoints.up("sm")]: {
      width: '36px',
      height: '32px',
    },
  },
  shirtIconGroupEmpty: {
    width: '24px',
    height: '20px',
    stroke: blueGrey[500], strokeWidth: '5px', paintOrder: 'stroke', strokeLinejoin: 'round',
  }
}));

export default function EventStatsContainer({ eventId, eventGroups, participants }) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [groupsStats, setGroupsStats] = useState([]);
	const [playersStats, setPlayersStats] = useState([]);
  const [topScorer, setTopScorrer] = useState({});
  const [topAssists, setTopAssists] = useState({});
  const [winningGroup, setWinningGroup] = useState({});

  const findMax = (arr, key) => {
    let max = 0;
    let maxObj = {};

    if(arr.length > 0) {
      arr.forEach(obj => {
        if(obj[key] > max) {
          max = obj[key];
          maxObj = obj;
        }
      });
    } else {
      return null;
    }

    return maxObj;
  }

  const initStates = () => {
    setGroupsStats([]);
    setPlayersStats([]);
    setTopScorrer({});
    setTopAssists({});
    setWinningGroup({});
  };

  useEffect(() => {
    (async () => {
      try{
        // Init all states
        setIsLoading(true);
        initStates();

        const response = await eventApi.stats.get(eventId);

        if(!response.hasOwnProperty("error")){
          // Sort by date
          let { groupsStats: loadedGroupsStats = {}, playersStats: loadedPlayersStats = {} } = response;

          // Groups stats
          let groupsStats = eventGroups.map((group) => {	
            return {
              id: group,
              matchesPlayed: loadedGroupsStats.hasOwnProperty(group) ? loadedGroupsStats[group].matchesPlayed : 0,
              wins: loadedGroupsStats.hasOwnProperty(group) ? loadedGroupsStats[group].wins : 0,
              rawRatio: loadedGroupsStats.hasOwnProperty(group) ? loadedGroupsStats[group].matchesPlayed > 0 ? ((loadedGroupsStats[group].wins / loadedGroupsStats[group].matchesPlayed) * 100).toFixed(0) : 0 : 0,
              ratio: loadedGroupsStats.hasOwnProperty(group) ? loadedGroupsStats[group].matchesPlayed > 0 ? ((loadedGroupsStats[group].wins / loadedGroupsStats[group].matchesPlayed) * 100).toFixed(0) + '%' : 0 : 0,
            };
          });
          groupsStats = [...groupsStats].sort((a, b) => a.id > b.id ? -1 : 1);

          // Players stats (go over participants because this is our single source of truth - for example if we have new participant that was added)
          let playersStats = participants.map((participant) => {
            const { userId, name, avatar, group } = participant;
            const groupOfPlayer = groupsStats.find(item => item.id === group);
            return {
              id: userId,
              name,
              avatar,
              group: group || null,
              goals: loadedPlayersStats.hasOwnProperty(userId) ? loadedPlayersStats[userId].goals : 0,
              assists: loadedPlayersStats.hasOwnProperty(userId) ?loadedPlayersStats[userId].assists : 0,
              matchesPlayed: loadedPlayersStats.hasOwnProperty(userId) ? loadedPlayersStats[userId].matchesPlayed : 0,
              wins: loadedPlayersStats.hasOwnProperty(userId) ? loadedPlayersStats[userId].wins : 0,
            };
          });
          playersStats = [...playersStats].sort((a, b) => a.group > b.group ? -1 : 1);

          // Top scorrer
          const topScorer = findMax(playersStats, 'goals');
          if (topScorer !== null && Object.keys(topScorer).length > 0) {
            setTopScorrer({
              name: topScorer.name,
              avatar: topScorer.avatar,
              value: topScorer.goals,
            });
          }

          // Top assists
          const topAssist = findMax(playersStats, 'assists');
          if (topAssist !== null && Object.keys(topAssist).length > 0) {
            setTopAssists({
              name: topAssist.name,
              avatar: topAssist.avatar,
              value: topAssist.assists,
            });
          }

          // Winning group
          const winningGroup = findMax(groupsStats, 'rawRatio');
          if (Object.keys(winningGroup).length > 0) {
            setWinningGroup({
              group: winningGroup.id,
              value: winningGroup.wins,
            });
          }

          setGroupsStats(groupsStats);
          setPlayersStats(playersStats);

          setIsLoading(false);
        } else {
          setIsLoading(false);
          setIsError(true);
        }
      } catch(e) {
        setIsLoading(false);
        setIsError(true);
      }
    })()
  }, [eventId, eventGroups, participants]);

  return (
    <Grid container className={classes.root} spacing={2} justifyContent="flex-start">

      {/* Top cards */}
      <Grid item xs={12}>
				<div className={classes.topCards}>
					<InfoCard 
						title={DICTIONARY.EVENT.event.stats.topCards.topScorer}
						content={(
              <StatInfoCardContent 
                entity={<UserAvatar 
                  user={{name: topScorer.name, avatar: topScorer.avatar}}
                  avatarSize={45}
                />}
                value={topScorer.value}
              />
            )}
						isLoading={isLoading}
            isEmpty={Object.keys(topScorer).length === 0}
            emptyState={{
              icon: (<PersonOutlineIcon style={{ fontSize: 30, color: blueGrey[500] }}/>),
              title: DICTIONARY.EVENT.event.stats.topCards.emptyState,
            }}
					/>
					<InfoCard 
						title={DICTIONARY.EVENT.event.stats.topCards.topAssist}
            content={(
              <StatInfoCardContent 
                entity={<UserAvatar 
                  user={{name: topAssists.name, avatar: topAssists.avatar}}
                  avatarSize={45}
                />}
                value={topAssists.value}
              />
            )}
            isLoading={isLoading}
            isEmpty={Object.keys(topAssists).length === 0}
            emptyState={{
              icon: (<PersonOutlineIcon style={{ fontSize: 30, color: blueGrey[500] }}/>),
              title: DICTIONARY.EVENT.event.stats.topCards.emptyState,
            }}
					/>
					<InfoCard 
						title={DICTIONARY.EVENT.event.stats.topCards.winningGroup}
            content={(
              <StatInfoCardContent 
                entity={<div className={classes.shirtIconGroupContainer}><ShirtIcon style={{ color: winningGroup.group ? EVENT_GROUPS_COLORS[winningGroup.group].hex : 'black' }} className={classes.shirtIconGroup} /> {winningGroup.group}</div>}
                value={winningGroup.value}
              />
            )}
						isLoading={isLoading}
            isEmpty={Object.keys(winningGroup).length === 0}
            emptyState={{
              icon: (<ShirtIcon style={{ color: 'white' }} className={classes.shirtIconGroupEmpty} />),
              title: DICTIONARY.EVENT.event.stats.topCards.emptyState,
            }}
					/>
				</div>
			</Grid>

      {/* Players table */}
      <Grid item xs={12}>
        <Card elevation={0}>
          <CardHeader
            titleTypographyProps={{ variant:'h6' }}
            title={DICTIONARY.EVENT.event.stats.playersTable.title}
            classes={{
              'root': classes.cardHeader,
            }}
          />
					<CardContent
						classes={{
							'root': classes['playersStatsCardContent'],
						}}
					>
						<Box
							display="flex"
							flexWrap="wrap"
							width="100%"
							p={1}
							bgcolor="background.paper"
						>
              <PlayersStatsTable 
                isLoading={isLoading}
                isError={isError}
                playersStats={playersStats}
                columnsToHide={['events']}
              />
						</Box>
					</CardContent>
				</Card>
      </Grid>

      {/* Groups table */}
      <Grid item xs={12} sm={6}>
        <Card elevation={0}>
          <CardHeader
            titleTypographyProps={{ variant:'h6' }}
            title={DICTIONARY.EVENT.event.stats.groupsTable.title}
            classes={{
              'root': classes.cardHeader,
            }}
          />
					<CardContent
						classes={{
							'root': classes['playersStatsCardContent'],
						}}
					>
						<Box
							display="flex"
							flexWrap="wrap"
							width="100%"
							p={1}
							bgcolor="background.paper"
						>
              <GroupsStatsTable 
                isLoading={isLoading}
                isError={isError}
                groupsStats={groupsStats}
              />
						</Box>
					</CardContent>
				</Card>
      </Grid>
    </Grid>
  );
}
import React from "react";
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import { convertNumberToDay, formatDate } from '../../../utils/utils';
import { IMAGE_PLACEHOLDER } from '../../../utils/consts';
import DICTIONARY from '../../../utils/dictionary/index';

// App components
import LightTooltip from '../../shared/tooltip/LightTooltip';
import IconWithTooltip from '../../shared/tooltip/IconWithTooltip';

// Material UI Components
import { Card, CardContent, CardMedia, Typography, Grid, Box, Link } from '@material-ui/core';

// Colors
import { blue } from '@material-ui/core/colors';

// Icons
import {
  LocationOn as LocationOnIcon,
  Today as TodayIcon,
  AccessTime as AccessTimeIcon,
  Payment as PaymentIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.up("md")]: {
      flexDirection: 'row',
    },
  },
  teamAvatar: {
    height: '150px',
    width: '100%',
    objectFit: 'cover',
    [theme.breakpoints.up("md")]: {
      width: '40%',
    },
  },
  cardContent: {
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '0',
    minHeight: '100px',
    "&:last-child": {
			paddingBottom: 0
		},
    [theme.breakpoints.up("md")]: {
      // flexDirection: 'row',
    },
	},
  detailsContainer: {
    height: '150px',
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("md")]: {
      height: '100%',
    },
  },
  detailIcon: {
    marginRight: theme.spacing(1.5),
  },
  paymentLink: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '180px',
    display: 'block',
  },
  detailTitle: {
    // marginRight: '10px',
  }
}));

export default function TeamDetailsCard({teamData}) {
  const classes = useStyles();
  const { teamName, description, paymentLink, avatar, defaultEvents } = teamData;
  const { defaultLocation, defaultDay, defaultTime } = defaultEvents[0];

  const PaymentLinkElement = (truncateText) => {
    if (paymentLink === '' || paymentLink == null) {
      return '-';
    } else {
      return (
        <Link 
          variant="body2" 
          style={{color: blue[500]}}
          href={paymentLink}
          target="_blank"
          rel="noreferrer"
          className={clsx({
            [classes.detailTitle]: true,
            [classes.paymentLink]: truncateText === true,
    
          })} 
        >
          {paymentLink}
        </Link>
      )
    }
  };

  return (
    <Card className={classes.root} elevation={0}>
      <object data={avatar} type="image/jpeg" className={classes.teamAvatar}>
        <CardMedia
          style={{width: '100%', height: '150px'}}
          image={IMAGE_PLACEHOLDER}
          title={teamName}
        />
      </object>
      <CardContent 						
        classes={{
          'root': classes['cardContent'],
        }}>
          <Grid container direction="row" justifyContent="space-between" alignItems='center' classes={{ 'root': classes.detailsContainer }}>
            <Grid item xs={12}>
              <Box display='flex' flexDirection='row' alignItems='center'>
                <IconWithTooltip 
                  iconElement={<LocationOnIcon className={classes.detailIcon}/>}
                  title={DICTIONARY.TEAM.team.card.title.location}
                />
                <Typography variant="body2" component="p" className={classes.detailTitle}>{defaultLocation}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display='flex' flexDirection='row' alignItems='center'>
                <IconWithTooltip 
                  iconElement={<TodayIcon className={classes.detailIcon}/>}
                  title={DICTIONARY.TEAM.team.card.title.eventDay}
                />
                <Typography variant="body2" component="p" className={classes.detailTitle}>{convertNumberToDay(defaultDay)}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display='flex' flexDirection='row' alignItems='center'>
                <IconWithTooltip 
                  iconElement={<AccessTimeIcon className={classes.detailIcon}/>}
                  title={DICTIONARY.TEAM.team.card.title.eventTime}
                />
                <Typography variant="body2" component="p" className={classes.detailTitle}>{formatDate(defaultTime, 'hh:mm a')}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display='flex' flexDirection='row' alignItems='center'>
                <IconWithTooltip 
                  iconElement={<PaymentIcon className={classes.detailIcon}/>}
                  title={DICTIONARY.TEAM.team.card.title.paymentLink}
                />
                {paymentLink.length > 20 
                  ? (
                      <LightTooltip 
                        title={paymentLink} 
                      >
                        {PaymentLinkElement(true)}
                      </LightTooltip>
                    )
                  : (PaymentLinkElement(false))
                }
              </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
import React, { useContext } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { DialogContext } from '../../context';
import { parsedPathname } from '../../utils/utils';

// Material UI
import { Fab, useMediaQuery, useTheme } from '@material-ui/core';

// App Components
import DesktopSupportPopover from './DesktopSupportPopover';

// Icons
import {
  ContactSupport as ContactSupportIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	supportIcon: {
    zIndex: 1,
		position: 'fixed',
		bottom: '20px',
		right: '20px',
    [theme.breakpoints.down("md")]: {
      bottom: '12px',
      right: '12px',
      height: '40px',
      width: '40px',
    },
	},
}));

export default function SupportButton() {
  const { handleCloseDialog, getDialogProps } = useContext(DialogContext);
  const classes = useStyles();
  const history = useHistory();
	const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  //#region Popover 
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onClosePopover = () => {
    setAnchorEl(null);
  };
  //#endregion

  //#region Modal 
  const onCloseModal = () => {
    getDialogProps({});
  };
  //#endregion

  //#region Support button
  const handleSupportButtonClick = (event) => {
    if(!isMobile) { // Desktop - Popover
      setAnchorEl(event.currentTarget);
    } else { // Mobile - Modal 
      handleCloseDialog(onCloseModal); // Update handleCloseDialog API context function
  
      history.push({
        pathname: `${parsedPathname(location.pathname)}/support-ticket`,
        state: {
          background: location,
          id: 'mobileSupport',
        },
      });
    }
  };
  //#endregion

  return (
    <React.Fragment>
      {/* Button - Only on desktop (on mobile the support is from the account's menu */}
      <Fab 
        color="primary" 
        aria-label="support" 
        size="medium" 
        onClick={handleSupportButtonClick}
        className={classes.supportIcon}
      >
        <ContactSupportIcon />
      </Fab>

      {/* Desktop */}
      {!isMobile && <DesktopSupportPopover 
        anchorEl={anchorEl} 
        onClosePopover={onClosePopover}
      />}
    </React.Fragment>
  );
}

import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext, SnackbarContext } from '../../context';
import { generalApi } from '../../api';
import DICTIONARY from '../../utils/dictionary';
import VALIDATORS from '../../utils/validators';
import useForm from '../../hooks/formHook';
import { SUPPORT_SUBJECTS, SNACKBAR_SEVERITY } from '../../utils/consts';

// Material UI
import { Popover, Typography, Button, TextField, Box, Grid, IconButton, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";

// App Components
import SubmitButton from '../shared/button/SubmitButton';

// Icons
import {
  Close as CloseIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  form: {
    padding: theme.spacing(1, 3),
    [theme.breakpoints.up("sm")]: {
      width: 'clamp(250px, 30vw, 350px)',
      padding: theme.spacing(0, 2, 1, 2),
    },
  },
  cancelButton: {
    margin: theme.spacing(2, 1),
    height: '36px',
    backgroundColor: 'transparent !important',
  },
  popoverTitleContainer: {
    position: 'relative',
    padding: theme.spacing(2, 0, 1, 2),
  },
  closeButtonPopover: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
}));

/*
  Pay attention to changes in: "initalFormState" and "onSubmit" - because they are written in both Support components (Mobile and Desktop).
*/

export default function DesktopSupportPopover({ anchorEl, onClosePopover }) {
  const { user } = useContext(AuthContext);
  const { handleSnackbarDisplay } = useContext(SnackbarContext);
  const classes = useStyles();

  //#region Popover 
  const open = Boolean(anchorEl);
  const id = open ? 'support-popover' : undefined;
  //#endregion

  //#region Form Hook
  const initalFormState = {
    name: {
      label: DICTIONARY.SUPPORT.form.field.name,
      value: '',
      isValid: true,
      required: true,
      validators: [
				{
					validate: (val) => VALIDATORS.isValidString(val, 2),
					errorMessage: DICTIONARY.SUPPORT.form.error.nameLength,
				},
			],
    },
    email: {
			label: DICTIONARY.SUPPORT.form.field.email,
			value: '',
			isValid: true,
      required: true,
			validators: [
				{
					validate: (val) => VALIDATORS.isValidEmail(val),
					errorMessage: DICTIONARY.GENERAL.form.badlyFormattedEmail,
				},
			],
		},
		subject: {
			label: DICTIONARY.SUPPORT.form.field.subject,
			value: '',
			isValid: true,
			required: true,
			validators: [],
		},
    content: {
      label: DICTIONARY.SUPPORT.form.field.content,
      value: '',
      isValid: true,
      required: true,
      validators: [
				{
					validate: (val) => VALIDATORS.isValidString(val, 5),
					errorMessage: DICTIONARY.SUPPORT.form.error.contentLength,
				},
			],
    },
  };

  const onSubmit = async () => {
    const ticketData = {
      name: formState.name.value,
      email: formState.email.value,
      subject: formState.subject.value,
      content: formState.content.value,
    };
    const userId = user ? user.userAuth.uid : null;
    const response = await generalApi.sendSupportTicket(userId, ticketData)

    if (response.hasOwnProperty("error")) {
      throw new Error(DICTIONARY.SUPPORT.form.error.generalError);
    } else {
      // Show success snackbar
			handleSnackbarDisplay({
				severity: SNACKBAR_SEVERITY.success,
				message: DICTIONARY.SUPPORT.form.success,
			});
    }
  }

	const {formState, formError, isLoading, handleInputChange, handleSubmit, handleCloseModal} = useForm({
		initalFormState, 
		onSubmit, 
		onCloseModal: onClosePopover,
	});
  //#endregion

	const formContent = (
		<React.Fragment>
      {/* Name Field */}
      <TextField
        autoFocus
        margin="dense"
        size="small"
        id="name"
        label={formState.name.label}
        type="text"
        fullWidth
        required
        value={formState.name.value}
        onChange={handleInputChange}
        autoComplete='name'
        error={!formState.name.isValid}
      />

      {/* Email Field */}
      <TextField
        margin="dense"
        size="small"
        id="email"
        label={formState.email.label}
        type="email"
        fullWidth
        required
        value={formState.email.value}
        onChange={handleInputChange}
        autoComplete='email'
        error={!formState.email.isValid}
      />

      {/* Subject */}
      <FormControl 
        className={classes.formControl} 
        margin='dense'
        size="small"
        required 
        fullWidth 
        error={!formState.subject.isValid}
      >
        <InputLabel id="subject">{formState.subject.label}</InputLabel>
        <Select
          labelId="subject"
          id='subject'
          label={formState.subject.label}
          name='subject'
          value={formState.subject.value}
          onChange={(e) => handleInputChange(e, {type: 'select', name: 'subject'})}
          required
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {Object.keys(SUPPORT_SUBJECTS).map(day => (
            <MenuItem value={SUPPORT_SUBJECTS[day].value} key={SUPPORT_SUBJECTS[day].value}>
              {SUPPORT_SUBJECTS[day].label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Content Field */}
      <TextField
        margin="dense"
        size="small"
        id="content"
        label={formState.content.label}
        type="text"
        fullWidth
        required
        multiline
        rows={3}
        value={formState.content.value}
        onChange={handleInputChange}
        autoComplete='content'
        error={!formState.content.isValid}
      />
		</React.Fragment>
  );

  const PopoverContent = (
    <form className={classes.form} noValidate>
      {formContent}

      {formError && (
        <Box mt={1}>
          <Alert severity='error'>{formError}</Alert>
        </Box>
      )}

      {/* Actions */}
      <Grid container justifyContent="flex-end">
        <Button 
          // size="small" 
          className={classes.cancelButton} 
          onClick={(e) => handleCloseModal(false)}
        >
          {DICTIONARY.GENERAL.button.cancel}
        </Button>
        <SubmitButton 
          text={DICTIONARY.GENERAL.button.submit}
          isLoading={isLoading} 
          handleSubmit={handleSubmit}
          disabled={isLoading || formError.length > 0}
        />
      </Grid>
    </form>
  );

  return (
    <React.Fragment>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        // onClose={handleCloseModal}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Grid container justifyContent="space-between" className={classes.popoverTitleContainer}>
          <Typography variant="h6" component="h1" display="block" className={classes.title}>{DICTIONARY.SUPPORT.title}</Typography>
          <IconButton 
            aria-label="close" 
            className={classes.closeButtonPopover} 
            onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Grid>

        {PopoverContent}
      </Popover>
    </React.Fragment>
  );
}

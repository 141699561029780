import React, { useState, useEffect, useCallback, useRef } from "react";
import { Helmet } from 'react-helmet';
import {
	Route,
	Switch,
	withRouter,
	useLocation
} from "react-router-dom";
import firebase, { messaging, analytics } from './config/firebase';
import { userApi, teamApi, generalApi } from './api/index';

import theme from './theme';
import './App.css';
import { isVerbose, parseQueryString, isInvitationLinkExists, isInstalledAsPwa, parsedPathname, getDisplayMode, getDeviceType } from './utils/utils';
import { APP_NAME, SNACKBAR_SEVERITY } from './utils/consts';
import ROUTES from './routes/index';
import DICTIONARY from './utils/dictionary';
import useIsMount from './hooks/isMountHook';

// Context API
import { AuthContext, DialogContext, SnackbarContext } from './context';

// App Pages
import Signup from "./pages/auth/Signup";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/Dashboard";
import MyTeams from "./pages/team/MyTeams";
import Team from "./pages/team/Team";
import MyEvents from "./pages/event/MyEvents";
import Event from "./pages/event/Event";
import NotFound from './pages/NotFound';
import MyAccount from './pages/MyAccount';

// App Components
import AppHeader from "./components/layout/AppHeader";
import Sidebar from "./components/layout/Sidebar";
import AppLoader from './components/shared/loader/AppLoader';
import SupportButton from './components/support/SupportButton';
import Modal from './components/shared/Modal';

// Material UI
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { Toolbar, Snackbar, useMediaQuery, useTheme } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

// Colors
import {grey} from '@material-ui/core/colors';

const ALLOWED_MODAl_PATHS_WHILE_AUTHENTICATED = ['create', 'edit', 'delete', 'update', 'register', 'unregister', 'add', 'remove', 'leave', 'assign', 'define', 'set', 'share', 'tutorial', 'support'];
const ALLOWED_MODAl_PATHS_WHILE_NOT_AUTHENTICATED = ['forgot-password', 'support'];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		height: '100vh',
	},
	authPageRoot: {
		display: "flex",
		backgroundColor: 'white',
		height: '100vh',
		width: '100vw',
	},
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
	},
	pageLayout: {
		padding: theme.spacing(1, 1, 8, 1),
		marginTop: theme.spacing(1),
		[theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
			marginTop: 0,
    },
	},
}));

function App(props) {
	const classes = useStyles();
	const location = useLocation();
	const muiTheme = useTheme();
	const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
	const [isLoading, setIsLoading] = useState(null);

	/* TODO: No need the code below here- there is the automatic logEvent of "page_view" */
  // useEffect(() => {
  //   props.history.listen(() => {
	// 		try{
	// 			analytics.setCurrentScreen(window.location.pathname);
	// 			analytics.logEvent('screen_view');
	// 		} catch(e) {}
  //   });
  //   return () => {
  //   };
  // }, [props.history]);

	// #region Snackbar
	const [shouldShowSnackbar, setShouldShowSnackbar] = useState(false);
	const [snackbarSeverity, setSnackbarSeverity] = useState(SNACKBAR_SEVERITY.info); // error, warning, info, success 
	const [snackbarMessage, setSnackbarMessage] = useState('');

	const handleSnackbarDisplay = useCallback(({severity, message}) => {
		setSnackbarSeverity(severity);
		setSnackbarMessage(message);
		setShouldShowSnackbar(true);
	}, []);

	const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShouldShowSnackbar(false);
  };
	//#endregion

	// #region Dialog
	const handleCloseDialogRef = useRef(false);
	const dialogPropsRef = useRef(false);

	const handleCloseDialog = (callback) => {
		handleCloseDialogRef.current = callback;
	};

	const getDialogProps = (dialogProps) => {
		dialogPropsRef.current = dialogProps;
	}
	//#endregion

	// #region User Auth
	const [user, setUser] = useState({
		userAuth: null,
		userData: null,
		teamsData: null
	});

	const handleUserUpdate = useCallback(({userAuth, userData, teamsData}) => {
		setUser({
			userAuth: userAuth ? userAuth : user.userAuth,
			userData: userData ? userData : user.userData,
			teamsData: teamsData ? teamsData : user.teamsData,
		});
  }, [user.userAuth, user.userData, user.teamsData]);

	const signupUserIfNeeded = useCallback(async (userAuth) => {
		/* 
			This is neccesary for the case of user signup --> 
			firebase.auth().signups functions are not acting as promises, but as a stream,
			so after 'createUserWithEmailAndPassword' we immediatly getting caught in 'onAuthStateChanged'.
		*/
		const { displayName, email, photoURL, uid } = userAuth;

		const userParams = {
			displayName,
			email,
			photoURL,
		};
		await userApi.create(uid, userParams);
		const userData = await userApi.get(uid);

		return userData;
	}, []);

	const handleAddMemberToTeamByInvitationLink = async (queryString, userId) => {
		if(queryString) {
			const { invite: teamId , c: encryptedInvitation } = parseQueryString(queryString);
			if(teamId && encryptedInvitation) {
				const response = await teamApi.addMemberToTeamByInvitationLink(teamId, encryptedInvitation, userId);

				return response;
			}
		}
		return;
	};

	const handleUrlWithPotentialModalActionInTheRouteIfNeeded = (pathnameStr) => {
		try{
			const lastSlashIndex = pathnameStr.lastIndexOf('/');
			const potentialModalPathParamStr = pathnameStr.substr(lastSlashIndex + 1, pathnameStr.length);
			const firstDashIndex = potentialModalPathParamStr.indexOf('-');

			if(firstDashIndex > -1) {
				const modalActionStr = potentialModalPathParamStr.substr(0, firstDashIndex);

				if(ALLOWED_MODAl_PATHS_WHILE_AUTHENTICATED.includes(modalActionStr)) {
					const pathnameWithoutModalActionStr = pathnameStr.substr(0, lastSlashIndex);
					props.history.push(pathnameWithoutModalActionStr);
				}
			}
		} catch(e) {}
	};

	useEffect(() => {
		const firebaseListener = firebase.auth().onAuthStateChanged(async (userAuth) => {
			analytics.logEvent('display_mode', { value: getDisplayMode()}); 
			analytics.logEvent('device_type', { value: getDeviceType()}); 
			
			try{
				setIsLoading(true);
				const isInvitationLink = isInvitationLinkExists(props.location.search); // props.location.search = url queryParam

				if (userAuth && userAuth.emailVerified) {
					let path = ROUTES.root.path;
					let userData = await userApi.get(userAuth.uid);

					// If needed - Add user to 'users' collection
					if (userData.error !== undefined || Object.keys(userData).length === 0) {
						userData = await signupUserIfNeeded(userAuth);
					}

					// Get user's features flags 
					// const featuresFlagsResponse = await generalApi.getFeauresFlags(userAuth.uid);
					// if(!featuresFlagsResponse.hasOwnProperty("error")) {
					// 	userData.ff = featuresFlagsResponse.ff;
					// }

					// If needed - Update PWA token
					if(isMount) {
						updatePwaMessagingTokenIfNeeded(userAuth.uid, userData);
					}

					// If needed - there is an action (like: 'create' / 'edit' / etc. in the URL --> dont go to 404, but go the the action orginal page)
					handleUrlWithPotentialModalActionInTheRouteIfNeeded(props.location.pathname);

					// If needed - Add user to team by invitation link
					if (isInvitationLink) {
						const response = await handleAddMemberToTeamByInvitationLink(props.location.search, userAuth.uid);
						if(response && response.hasOwnProperty('success')) { // Invitation link is valid and the user has been added to the team by the invitation link
							const { invite: teamId } = parseQueryString(props.location.search);
							userData = await userApi.get(userAuth.uid);
							path = `${ROUTES.myTeams.path}/${teamId}`;
						} else {
							// Display error message
							handleSnackbarDisplay({
								severity: SNACKBAR_SEVERITY.error,
								message: DICTIONARY.TEAM.invitation.userAlreadyInTeam,
							});
						}
					} else {
						const { pathname, search } = props.history.location;
						const currentPath = pathname + search;
						path = (currentPath === ROUTES.login.path || currentPath === ROUTES.signup.path) ? '/' : currentPath;
					}
					
					// Get data of user's teams
					const teamsIds = userData?.teams || [];
					const teamsData = await teamApi.getTeamsByIds(teamsIds);
					handleUserUpdate({userAuth, userData, teamsData});

					setIsLoading(false);
					props.history.push(path);

					return;
				} else {
					let path;
					if(isInvitationLink && (props.history.location.pathname === ROUTES.root.path)) {
						path = `${ROUTES.signup.path}${props.location.search}`; // Direct the user to signup page with the invitationLink queryParam (he can switch to login and it will remain)
					} else {
						path = ROUTES.login.path;
					}					

					props.history.push(path);
					setIsLoading(false);
					handleUserUpdate({ userAuth: null, userData: null, teamsData:null });
				}
			} catch(e) {
				setIsLoading(false);
				isVerbose() && console.error(`Error on onAuthStateChanged: ${e}`);
			}
		});

		return () => firebaseListener();
	}, [props.history]);
	//#endregion

	// #region Routes
	let routes;

	// Relevant for dialogs
	let background = location.state && location.state.background;
	let backgroundPath, id;
	if(location.state) {
		backgroundPath = parsedPathname(background.pathname);
		id = location.state.id;
	}

	const buildModalRouets = (backgroundPath, isAuthenticated) => {
		const paths = isAuthenticated ? ALLOWED_MODAl_PATHS_WHILE_AUTHENTICATED : ALLOWED_MODAl_PATHS_WHILE_NOT_AUTHENTICATED;

		let modalRoutes = paths.map((path) => {
			let parsedPath = `${backgroundPath}/${path}*`;
			return parsedPath;
		});

		return modalRoutes;
	};

	if (user.userAuth) {
		routes = (
			<AuthContext.Provider value={{ user, handleUserUpdate }}>
				<DialogContext.Provider value={{ handleCloseDialog, getDialogProps }}>
					<AppHeader />
					<div className={classes.root}>
						<Sidebar />
							<main className={classes.content}>
							<Toolbar />
							<div className={classes.pageLayout}>
								{!isMobile && <SupportButton />} 
								<Switch location={background || location}>
									<Route exact path={ROUTES.root.path} component={Dashboard} />
									<Route exact path={ROUTES.myAccount.path} component={MyAccount} />
									<Route exact path={ROUTES.myTeams.path} component={MyTeams} />
									<Route exact path={ROUTES.myEvents.path} component={MyEvents} />
						
									<Route exact path={ROUTES.myTeams.team.path} component={Team} />
									<Route exact path={ROUTES.myEvents.event.path} component={Event} />

									<Route component={NotFound} />
								</Switch>

								{/* Show the modal when a background page is set */}
								{background && <Route path={buildModalRouets(backgroundPath, true)} 
									children={
										<Modal 
											id={id}
											onCloseCallback={handleCloseDialogRef.current}
											otherProps={dialogPropsRef.current}
										/>
									} 
								/>}
							</div>
						</main>
					</div>
				</DialogContext.Provider>
			</AuthContext.Provider>
		);
	} else {
		routes = (
			<div className={classes.authPageRoot}>
				<DialogContext.Provider value={{ handleCloseDialog, getDialogProps }}>
					<Switch location={background || location}>
						<Route path={ROUTES.login.path} component={Login} />
						<Route path={ROUTES.signup.path} component={Signup} />
					</Switch>

					{/* Show the modal when a background page is set */}
					{background && <Route path={buildModalRouets(backgroundPath, false)} 
						children={
							<Modal 
								id={id}
								onCloseCallback={handleCloseDialogRef.current}
								otherProps={dialogPropsRef.current}
							/>
						} 
					/>}

					<SupportButton />
				</DialogContext.Provider>
			</div>
		);
	}
	// #endregion

	// #region PWA messaging
	const isMount = useIsMount();

	const updatePwaMessagingTokenIfNeeded = (userId, userData) => {
		try{
			// Check that we have the most updated token
			if(firebase.messaging.isSupported()) {
				const isPwa = isInstalledAsPwa();
				if (Notification.permission === "granted" && isPwa) {
					isVerbose() && console.log('Notification permission granted');
					messaging.getToken({ vapidKey: 'BNREApYY85406fdY7fnfU3w9evlbIrl_1N9nLCi0F6-D6Q-AuFmIBIauvxSoF98_1J3SyFyItR3-gapptXyYTfA' })
						.then(async(currentToken) => {
							if (currentToken) {
								// If the user token is not the same as in the DB --> update the token
								const tokenInDb = userData?.pwaMessagingToken;
								if(currentToken !== tokenInDb) {
									isVerbose() && console.log('New token is available', currentToken);
									const tokenUpdatePromise = userApi.pwaMessagingToken.updateToken(userId, currentToken);
									const pwaNotificationPermissionPromise = userApi.pwaMessagingToken.updateNotificationPermission(userId, true);
									const [tokenResponse, permissionResponse] = await Promise.all([tokenUpdatePromise, pwaNotificationPermissionPromise]);

									/* 
										We need also to update the user context with 'pwaMessagingToken' and 'enablePwaMessagingNotifications' (base on the promise responses) --> 
									 	For a case when the Notification.permission is granted by default by the user's browser app
									 	and first we will pass in this function and not in the 'PwaMessagingNotificationsSetting' component.
									*/
									handleUserUpdate({
										userData: {
											...userData,
											enablePwaMessagingNotifications: true,
											pwaMessagingToken: currentToken,
										}
									});
								} else {
									isVerbose() && console.log('The token is most updated', currentToken);
								}
							} else {
								isVerbose() && console.log('No registration token available.');
							}
						}).catch((err) => {
							isVerbose() && console.log('An error occurred while retrieving token. ', err);
						});
				}
			}
		} catch(e) {
			isVerbose() && console.error(e);
		}
	};
	//#endregion 

	return (
		<React.Fragment>
			<Helmet>
				<title>{APP_NAME}</title>
			</Helmet>

			<ThemeProvider theme={theme}>
				<CssBaseline />
				<SnackbarContext.Provider value={{ handleSnackbarDisplay }}>
					{isLoading ? <AppLoader/> : routes}

					<Snackbar 
						open={shouldShowSnackbar} 
						autoHideDuration={5000} 
						onClose={handleCloseSnackbar}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
							{snackbarMessage}
						</Alert>
					</Snackbar>
				</SnackbarContext.Provider>
			</ThemeProvider>
		</React.Fragment>
	);
}

export default withRouter(App);

import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { AuthContext } from '../context';
import { makeStyles } from "@material-ui/core/styles";
import { APP_NAME } from '../utils/consts';

// App Components
import UpcomingEventList from '../components/event/UpcomingEventList';
import TeamListDashboard from '../components/team/TeamListDashboard';
import MessagingRequestPermissionBanner from '../components/banner/MessagingRequestPermissionBanner';

// Material UI Components
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: 'column-reverse',
		padding: 0,
    [theme.breakpoints.up("md")]: {
      flexDirection: 'row',
			padding: theme.spacing(2),
    }
	},
}));

export default function Dashboard() {
	const { user, handleUserUpdate } = useContext(AuthContext);
	const classes = useStyles();

  return (
		<React.Fragment>
			<Helmet>
				<title>Home | {APP_NAME}</title>
			</Helmet>

			{/* Notification banner */}
			{/* <MessagingRequestPermissionBanner /> */}

			<Grid container className={classes.root} spacing={2} justifyContent="flex-start" wrap="nowrap">
				<Grid item xs={12} md={6}>
					<TeamListDashboard />
				</Grid>
				<Grid item xs={12} md={6}>
					<UpcomingEventList />
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const createEmptyFormFieldErrorMessage = (invalidFields) => {
  let commaSepareted = invalidFields.join(', ');
  var indexOfLastComma = commaSepareted.lastIndexOf(",");
  commaSepareted = indexOfLastComma > -1 ? commaSepareted.substring(0, indexOfLastComma) + ' &' + commaSepareted.substring(indexOfLastComma + 1) : commaSepareted;
  const formError = `Please fill ${commaSepareted} ${invalidFields.length === 1 ? 'field' : 'fields'}.`;

  return formError;
};

const VALIDATORS = {
  // #region String
  isNotEmptyString: (str) => {
    return str.length > 0;
  },
  isValidString: (str, len) => {
    // more then 'len' chars
    return str.length >= len;
  },
  isStringExceeded: (str, len) => {
    // between range of o and 'len'
    return str === '' || str.length <= len;
  },
  // #endregion
  // #region URL
  isValidHttpsUrl: (url) => {
    return url === '' || url.startsWith('https://');
  },
  // #endregion 
  // #region Number
  isInRange: (val, min, max) => {
    const parsedVal = Number(val);
    return parsedVal >= min && parsedVal <= max;
  },
  isLargerThan: (val, num) => {
    return val > num;
  },
  isSmallerThan: (val, num) => {
    return val < num;
  },
  // #endregion
  // #region Email
  isValidEmail: (email) => {
    // eslint-disable-next-line
    return emailRegex.test(String(email).toLowerCase());
  },
  areValidEmails: (emails) => {
    const emailsArray = emails.replace(/\s/g, '').split(',');
    const areAllEmailsValid = emailsArray.every(email => emailRegex.test(String(email).toLowerCase()));

    return areAllEmailsValid;
  },
  // #endregion
  // #region Date
  isValidDate: (date) => {
    return date instanceof Date && !isNaN(date);
  },
  // #endregion
  // #region Image
  isValidimage: (file) => {
    if(file !== '' && file != null) {
			return file.type.startsWith('image/');
		}
		return true;
  },
  //#endregion
  // #region Phone number
  isValidPhoneNumber: (phoneNumber, countryId) => {
    switch(countryId) {
      case 'usa':
        // Ignore chars like dash and parenthesize - only check that the input contain 10 digits
        const isTenDigits = phoneNumber.match(/\d/g).length === 10;
        return isTenDigits;
      case 'israel':
        // If input has '0' at the start --> remove it
        let parsedNumber = phoneNumber;
        if(parsedNumber.charAt(0) === '0') {
          parsedNumber = phoneNumber.slice(1);
        }
        const isNineDigits = parsedNumber.match(/\d/g).length === 9;
        return isNineDigits;
      default:
        return false;
    }
  },
  //#endregion
};

export default VALIDATORS;
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import VALIDATORS from '../../../utils/validators';
import { adminApi } from '../../../api/index';
import DICTIONARY from '../../../utils/dictionary/index';
import useForm from '../../../hooks/formHook';

// Material UI Components
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
}));

export default function AddMemberToTeamDialog({ teamId, teamName, handleCloseDialog }) {
  const classes = useStyles();

  //#region Helpers
  const parseEmails = (emails) => {
    try{
      const emailsArray = emails.replace(/\s/g, '').split(',');

      return emailsArray;
    } catch(e) {
      return [];
    }
  };
  //#endregion

  //#region Form Hook
  const initalFormState = {
		emails: {
			label: DICTIONARY.TEAM.addMemberToTeam.form.field.emails,
			value: '',
			isValid: true,
      required: true,
			validators: [
				{
					validate: (val) => VALIDATORS.areValidEmails(val),
					errorMessage: (val) => {
            const emails = parseEmails(val);
            return emails.length === 1 ? DICTIONARY.TEAM.addMemberToTeam.form.error.invalidEmail : DICTIONARY.TEAM.addMemberToTeam.form.error.invalidEmails;
          },
				},
			],
		},
	};

	const onSubmit = async () => {
    const emails = parseEmails(formState.emails.value);
    const response = await adminApi.team.addMembersToTeam(teamId, emails);

    if (response.hasOwnProperty("error")) {
      let errorMessage = '';
      errorMessage = response.error.map(email => email.error);
      errorMessage = `Invalid emails: ${errorMessage.join(', ')}`;
      throw new Error(errorMessage);
    }
	}

	const onCloseModal = (isSubmittedSuccessfully) => {
    handleCloseDialog({isSubmittedSuccessfully});
  }

	const {formState, formError, isLoading, handleInputChange, handleSubmit, handleCloseModal} = useForm({
		initalFormState, 
		onSubmit, 
		onCloseModal,
	});
  //#endregion

	const dialogContent = (
		<React.Fragment>
      {/* Emails Field */}
      <TextField
        autoFocus
        margin="dense"
        id="emails"
        label={formState.emails.label}
        type="email"
        fullWidth
        value={formState.emails.value}
        onChange={handleInputChange}
        autoComplete='email'
        error={!formState.emails.isValid}
      />
		</React.Fragment>
  );

  return {
		dialogTitle: `${DICTIONARY.TEAM.addMemberToTeam.title}`,
		dialogContent,
    dialogContentText: DICTIONARY.TEAM.addMemberToTeam.subtitle,
		sumbitButtonLabel: DICTIONARY.GENERAL.button.submit,
		handleSubmit,
		handleCloseModal,
		formError,
		isLoading,
	};
}
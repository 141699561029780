import firebase, { googleProvider, facebookProvider } from "../../config/firebase";
import { isVerbose, isLocalhost } from '../../utils/utils';
import { SNACKBAR_SEVERITY } from '../../utils/consts';
import DICTIONARY from '../../utils/dictionary';

// login with googleProvider firebase auth service
export const googleSignin = async () => {
  firebase.auth().signInWithPopup(googleProvider)
    .then(async firebaseCreatedUser => {
      await firebaseCreatedUser.user.updateProfile({
        displayName: firebaseCreatedUser.additionalUserInfo.profile.name,
        photoURL: firebaseCreatedUser.additionalUserInfo.profile.picture,
      });
    })			
    .catch((e) => {
      isVerbose() && console.error(e);
    });
};

// login with facebookProvider firebase auth service
export const facebookSignin = async (handleSnackbarDisplay) => {
  firebase.auth().signInWithPopup(facebookProvider)
    .then(async firebaseCreatedUser => {

      await firebaseCreatedUser.user.updateProfile({
        displayName: firebaseCreatedUser.additionalUserInfo.profile.name,
        photoURL: firebaseCreatedUser.additionalUserInfo.profile.picture.data.url,
      });

      // Auth with facebook doesn't verified the email authmatically --> need to send email verification..
      if(firebaseCreatedUser.user.emailVerified === false) {
        await sendEmailVerification(firebaseCreatedUser, handleSnackbarDisplay);
      }
    })			
    .catch((e) => {
      isVerbose() && console.error(e);
    });
};

const sendEmailVerification = (firebaseCreatedUser, handleSnackbarDisplay, invitationLink) => {
  return new Promise((resolve, reject) => {
    let continueUrl = isLocalhost() ? 'http://localhost:3000/' : 'https://teamupnow.app/';
    continueUrl = invitationLink ? `${continueUrl}${invitationLink}` : continueUrl;
    const actionCodeSettings = {
      url: continueUrl,
      handleCodeInApp: true,
    };
    firebaseCreatedUser.user.sendEmailVerification(actionCodeSettings)
      .then(() => {
        // Display a snackbar that email verification sent
        resolve(handleSnackbarDisplay({
          severity: SNACKBAR_SEVERITY.info,
          message: DICTIONARY.AUTH.snackbar.emailVerification,
        }));
      })
      .catch((e) => {
        reject(e);
      });
  });
};


export {sendEmailVerification};
import React, { useState, useContext } from "react";
import md5 from "md5";
import { AuthContext } from '../../context';
import { teamApi, userApi, adminApi, generalApi } from '../../api';
import { makeStyles } from '@material-ui/core/styles';
import firebase, { Timestamp, analytics } from "../../config/firebase";
import VALIDATORS from '../../utils/validators';
import { WEEK_DAYS, EVENT_GROUPS_COLORS, MAX_EVENT_PARTICIPANTS_LIMIT, MAX_EVENT_GROUPS_NUMBER_LIMIT, IMAGE_PLACEHOLDER } from '../../utils/consts';
import DICTIONARY from '../../utils/dictionary';
import useForm from '../../hooks/formHook';

// App components
import InfoIconTooltip from '../shared/tooltip/InfoIconTooltip';

// Material UI Components
import { Grid, TextField, Box, IconButton, Typography, FormControl, Select, InputLabel, MenuItem, Checkbox } from '@material-ui/core';

// Time input
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';

// Colors
import { grey, teal } from '@material-ui/core/colors';

// Icons
import {
	Publish as PublishIcon
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	formControl: {
    marginTop: theme.spacing(2),
  },
	imageContainer: {
		position: 'relative',
		width: '100%',
		height: '150px',
		marginTop: '8px',
		'& img, & object': {
			position: 'absolute',
			top: 0,
			bottom: 0,
			width: '100%',
			height: '100%',
			objectFit: 'cover',
			border: `1px solid ${grey[400]}`,
			borderRadius: '0px',
		},
		'& img': {
			border: '0',
		},
		'& .MuiIconButton-root': {
			position: 'absolute',
			bottom: '-10px',
			right: '10px',
			backgroundColor: teal['A700'],
			color: 'white',
		},
		'& label': {
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			cursor: 'pointer',
		},
	},
	innerSectionTitle: {
		fontSize: theme.typography.pxToRem(17),
		fontWeight: theme.typography.fontWeightBold,
		// marginBottom: -theme.spacing(3),
	},
	defaultEventHeaderContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	defaultEventInfo: {
		fontSize: theme.typography.pxToRem(13),
		color: grey[700],
	},
	defaultEventInfoIconTooltip: {
		[theme.breakpoints.down("sm")]: {
			marginTop: '-20px',
    },
	},
	groupColorCheckbox: {
		padding: theme.spacing(0.5, 1, 0.5, 0)
	},
	eventGroupsSelectIcon: {
		right: '20px',
	},
	eventGroupsColorBox: {
		display: 'inline-block',
		height: '16px',
		width: '16px',
		borderRadius: '10px',
		border: `1px solid ${grey[700]}`,
		marginRight: '6px',
	},
}));

export default function CreateTeamDialog({handleCloseDialog, teamData = {}, isEditMode = false, teamId = undefined, }) {
	const { user, handleUserUpdate } = useContext(AuthContext);
  const classes = useStyles();
	
	const [avatarPlaceholder, setAvatarPlaceholder] = useState(teamData?.avatar || IMAGE_PLACEHOLDER);

	//#region Form Hook
	const initalFormState = {
		teamName: {
			label: DICTIONARY.TEAM.createTeam.form.field.teamName,
			value: teamData?.teamName || '',
			isValid: true,
			required: true,
			validators: [
				{
					validate: (val) => VALIDATORS.isValidString(val, 3),
					errorMessage: DICTIONARY.TEAM.createTeam.form.error.teamNameLength,
				},
			],
		},
		description: {
			label: DICTIONARY.TEAM.createTeam.form.field.description,
			value: teamData?.description || '',
			isValid: true,
			required: false,
			validators: [
				{
					validate: (val) => VALIDATORS.isStringExceeded(val, 50),
					errorMessage: DICTIONARY.TEAM.createTeam.form.error.teamDescriptionLength,
				},
			],
		},
		paymentLink: {
			label: DICTIONARY.TEAM.createTeam.form.field.paymentLink.label,
			info: DICTIONARY.TEAM.createTeam.form.field.paymentLink.info,
			value: teamData?.paymentLink || '',
			isValid: true,
			required: false,
			validators: [
				{
					validate: (val) => VALIDATORS.isValidHttpsUrl(val),
					errorMessage: DICTIONARY.TEAM.createTeam.form.error.paymentLink,
				},
			],
		},
		avatar: {
			label: DICTIONARY.TEAM.createTeam.form.field.avatar,
			value: teamData?.avatar || '',
			isValid: true,
			required: false,
			validators: [
				{
					validate: (value) => {
						// Check if the value matches the value from the DB. if true --> the image wasnt change and its valid
						const avatarFromDB = teamData?.avatar;

						return avatarFromDB === value || VALIDATORS.isValidimage(value);
					},
					errorMessage: DICTIONARY.TEAM.createTeam.form.error.imageType,
				},
			],
		},
		defaultLocation: {
			label: DICTIONARY.TEAM.createTeam.form.field.defaultLocation,
			value: teamData?.defaultEvents ? teamData?.defaultEvents[0].defaultLocation : '',
			isValid: true,
			required: true,
			validators: [
				{
					validate: (val) => VALIDATORS.isValidString(val, 2),
					errorMessage: DICTIONARY.TEAM.createTeam.form.error.defaultLocationLength,
				},
			],
		},
		defaultDay: {
			label: DICTIONARY.TEAM.createTeam.form.field.defaultDay,
			value: teamData?.defaultEvents ? teamData?.defaultEvents[0].defaultDay : '',
			isValid: true,
			required: true,
			validators: [],
		},
		maxEventParticipants: {
			label: DICTIONARY.TEAM.createTeam.form.field.maxEventParticipants.label,
			info: DICTIONARY.TEAM.createTeam.form.field.maxEventParticipants.info,
			value: teamData?.defaultEvents ? teamData?.defaultEvents[0].maxEventParticipants : '',
			isValid: true,
			required: true,
			validators: [
				{
					validate: (val) => VALIDATORS.isInRange(val, 1, MAX_EVENT_PARTICIPANTS_LIMIT),
					errorMessage: `${DICTIONARY.TEAM.createTeam.form.error.maxEventParticipantsRange} ${MAX_EVENT_PARTICIPANTS_LIMIT}`,
				},
			],
		},
		eventGroups: {
			label: DICTIONARY.TEAM.createTeam.form.field.eventGroups.label,
			info: DICTIONARY.TEAM.createTeam.form.field.eventGroups.info,
			value: (teamData?.defaultEvents && teamData?.defaultEvents[0].eventGroups) ? teamData?.defaultEvents[0].eventGroups : [],
			isValid: true,
			required: true,
			validators: [
				{
					validate: (val) => VALIDATORS.isInRange(val.length, 1, MAX_EVENT_GROUPS_NUMBER_LIMIT),
					errorMessage: `${DICTIONARY.TEAM.createTeam.form.error.maxEventGroups} ${MAX_EVENT_GROUPS_NUMBER_LIMIT}`,
				},
			],
		},
		defaultTime: {
			label: DICTIONARY.TEAM.createTeam.form.field.defaultTime,
			value: (teamData?.defaultEvents && teamData?.defaultEvents[0].defaultTime) ? teamData?.defaultEvents[0].defaultTime.toDate() : new Date(),
			isValid: true,
			required: true,
			validators: [
				{
					validate: (value) => VALIDATORS.isValidDate(value),
					errorMessage: DICTIONARY.TEAM.createTeam.form.error.defaultTimeInvalid,
				},
			],
		},
	};

	const uploadImageIfNeeded = async (teamId, userId) => {
		let uploadedImage = formState.avatar.value;
		if(typeof uploadedImage === 'object' && uploadedImage !== null) {
			const uploadImageResponse = await teamApi.uploadImage(uploadedImage, teamId, userId);

			if(!uploadImageResponse.hasOwnProperty("error")){
				return uploadImageResponse.downloadUrl;
			} else {
				throw new Error(DICTIONARY.TEAM.createTeam.form.error.imageUpload);
			}
		}
	}

	const chooseRandomImage = async (generatedTeamIdIfOnError) => {
		try{
			let rndInt = Math.floor(Math.random() * 20) + 1;
			rndInt = rndInt < 10 ? '0' + rndInt : '' + rndInt;
			rndInt = rndInt + '.jpg';
			const downloadUrl = await firebase.storage().ref(`images/placeholders/teams`).child(rndInt).getDownloadURL();
			return downloadUrl;
		} catch(e) {
			return `https://gravatar.com/avatar/${md5(generatedTeamIdIfOnError)}?d=identicon&size=400`;
		}
	};

	const onSubmit = async () => {
		const userId = user.userAuth.uid;

		// Create Team
		if(isEditMode !== true) {
			try {
				analytics.logEvent('create_team', { status: 'start'}); 

				const generatedTeamId = generalApi.generateId(); // Pre-Generete teamId 
				const avatar = await uploadImageIfNeeded(generatedTeamId, userId);

				// TODO: in the future, we would like to support having several default events - so we use array of objects here
				const defaultEvent = {
					defaultLocation: formState.defaultLocation.value,
					defaultDay: formState.defaultDay.value,
					defaultTime: Timestamp.fromDate(formState.defaultTime.value),
					maxEventParticipants: formState.maxEventParticipants.value,
					eventGroups: formState.eventGroups.value,
				};

				const teamData = {
					teamId: generatedTeamId,
					teamName: formState.teamName.value,
					description: formState.description.value,
					avatar: avatar ? avatar : await chooseRandomImage(generatedTeamId),
					// avatar: avatar ? avatar : `https://gravatar.com/avatar/${md5(generatedTeamId)}?d=identicon&size=400`,
					paymentLink: formState.paymentLink.value,
					createdByUserId: userId,
					defaultEvent,
				};

				const response = await teamApi.create(teamData, userId);

				if(!response.hasOwnProperty("error")){
					analytics.logEvent('create_team', { status: 'success'});

					const { teamId } = response;
					const teamData = await teamApi.get(teamId);

					// Updated user.teamsData API context
					const updatedTeamsData = {
						...user.teamsData, // all current teams
						[teamId]: teamData, // the new team
					};

					// Updated user.userData API context
					let userTeamsArray = [];
					if (user.userData.teams) {
						userTeamsArray = user.userData.teams;
					}
					const updatedUserData = {
						...user.userData,
						teams: [...userTeamsArray, teamId],
					};

					handleUserUpdate({
						userData: updatedUserData,
						teamsData: updatedTeamsData
					});
				} else {
					analytics.logEvent('create_team', { status: 'failed'});
					throw new Error(DICTIONARY.TEAM.createTeam.form.error.generalError);
				}
			} catch(e) {
				analytics.logEvent('create_team', { status: 'failed'});
				throw new Error(DICTIONARY.TEAM.createTeam.form.error.generalError);
			}
		} else {
			// Edit Team
			try {
				analytics.logEvent('edit_team', { status: 'start'});

				let avatar;
				
				if (typeof formState.avatar.value === 'string' || formState.avatar.value instanceof String) { // user didnt change avatar
					avatar = formState.avatar.value; 
				} else { // user upload new avatar
					avatar = await uploadImageIfNeeded(teamId);
				}

				const defaultEvent = {
					defaultLocation: formState.defaultLocation.value,
					defaultDay: formState.defaultDay.value,
					defaultTime: Timestamp.fromDate(formState.defaultTime.value),
					maxEventParticipants: formState.maxEventParticipants.value,
					eventGroups: formState.eventGroups.value,
					defaultEventId: teamData?.defaultEvents[0].defaultEventId, // TODO: support not only first defaultEvent.. but anywat the event details section will not stay here..
				};

				const updatedTeamData = {
					teamName: formState.teamName.value,
					description: formState.description.value,
					avatar,
					paymentLink: formState.paymentLink.value,
					defaultEvent,
				};
				const response = await adminApi.team.edit(teamId, updatedTeamData);

				if(!response.hasOwnProperty("error")){
					analytics.logEvent('edit_team', { status: 'success'});

					const teamData = await teamApi.get(teamId);

					const updatedTeamsData = {
						...user.teamsData,
						[teamId]: teamData
					};
					handleUserUpdate({
						teamsData: updatedTeamsData
					});
				} else {
					analytics.logEvent('edit_team', { status: 'failed'});
					throw new Error(DICTIONARY.TEAM.createTeam.form.error.generalErrorEdit);
				}
			} catch(e) {
				analytics.logEvent('edit_team', { status: 'failed'});
				throw new Error(DICTIONARY.TEAM.createTeam.form.error.generalErrorEdit);
			}
		}
	}

	const onCloseModal = (isSubmittedSuccessfully) => {
    handleCloseDialog({isSubmittedSuccessfully});
  }

	const {formState, formError, isLoading, handleInputChange, handleSubmit, handleCloseModal} = useForm({
		initalFormState, 
		onSubmit, 
		onCloseModal,
	});
	//#endregion

	//#region Avatar image
	const handleAvatarPlaceholcer = (file) => {
		if (file && VALIDATORS.isValidimage(file)) {
			setAvatarPlaceholder(URL.createObjectURL(file));
		}
	}
	//#endregion

	const dialogContent = (
		<React.Fragment>
			{/* Section 1 - Name, Description and Image */}
			<Grid container spacing={3}>
				<Grid item container md={6} xs={12}>
					<Grid item md={12} xs={12}>
						{/* Team Name Field */}
						<TextField
							autoFocus
							margin='normal'
							required
							fullWidth
							id='teamName'
							label={formState.teamName.label}
							name='teamName'
							value={formState.teamName.value}
							onChange={handleInputChange}
							autoComplete='teamName'
							error={!formState.teamName.isValid}
						/>
					</Grid>

					<Grid item md={12} xs={12}>
						{/* Description Field */}
						<TextField
							margin='normal'
							fullWidth
							id='description'
							label={formState.description.label}
							name='description'
							value={formState.description.value}
							onChange={handleInputChange}
							autoComplete='description'
							error={!formState.description.isValid}
						/>
					</Grid>


					<Grid item md={12} xs={12}>
						{/* Payment Link Field */}
						<TextField
							margin='normal'
							fullWidth
							id='paymentLink'
							label={formState.paymentLink.label}
							name='paymentLink'
							value={formState.paymentLink.value}
							onChange={handleInputChange}
							autoComplete='paymentLink'
							error={!formState.paymentLink.isValid}
							InputProps={{
								endAdornment: <InfoIconTooltip text={formState.paymentLink.info}/>
							}}
						/>
					</Grid>

				</Grid>
				<Grid item md={6} xs={12}>
					{/* Team avatar */}
					<Box pt={{sm: 4}}>
						<InputLabel id="teamAvatar">{formState.avatar.label}<span style={{fontSize: '10px', marginLeft: '4px'}}>{DICTIONARY.GENERAL.imageUploadInstructions}</span></InputLabel>
						<div className={classes.imageContainer}>
							<object data={avatarPlaceholder} type="image/jpeg" className={classes.teamAvatar}>
								<img src={IMAGE_PLACEHOLDER} alt="Team image"/>	
							</object>	
							<IconButton aria-label="upload" size="small">
								<PublishIcon />
								<label htmlFor="avatar"></label>
							</IconButton>
							<input 
								id='avatar'
								name='avatar'
								type="file"
								accept="image/jpeg, image/png"
								style={{display: 'none'}}
								onChange={(e) => {
									handleInputChange(e, {type: 'image', name: 'avatar'});
									handleAvatarPlaceholcer(e.target.files[0]);
								}}
							/>
						</div>
					</Box>
				</Grid>
			</Grid>

			<Box pt={5} mb={-2}>
				<Typography component="h3" className={classes.innerSectionTitle}>{DICTIONARY.TEAM.createTeam.innerSection.title}</Typography>

				<div className={classes.defaultEventHeaderContainer}>
					<Typography component="p" className={classes.defaultEventInfo}>{DICTIONARY.TEAM.createTeam.innerSection.subtitle.lineOne}</Typography>
					<InfoIconTooltip 
						text={DICTIONARY.TEAM.createTeam.innerSection.subtitle.lineTwo} 
						className={classes.defaultEventInfoIconTooltip}
					/>
				</div>
			</Box>

			{/* Section 2 - Location, Nubmer of participants and Event groups */}
			<Grid container spacing={3}>
				<Grid item md={4} xs={12}>
					{/* Default location Field */}
					<TextField
						margin='normal'
						required
						fullWidth
						id='defaultLocation'
						label={formState.defaultLocation.label}
						name='defaultLocation'
						value={formState.defaultLocation.value}
						onChange={handleInputChange}
						autoComplete='defaultLocation'
						error={!formState.defaultLocation.isValid}
					/>
				</Grid>
				<Grid item md={4} xs={12}>
					{/* Max Event Participants Field */}
					<TextField
						margin='normal'
						required
						fullWidth
						type="number"
						InputProps={{
							inputProps: { 
								max: MAX_EVENT_PARTICIPANTS_LIMIT, // no special reason to restrict to max 25 --> buisness wise - maybe it can be the limit for freemium users(teams)
								min: 0 
							},
							endAdornment: <InfoIconTooltip text={formState.maxEventParticipants.info}/>
						}}
						id='maxEventParticipants'
						label={formState.maxEventParticipants.label}
						name='maxEventParticipants'
						value={formState.maxEventParticipants.value}
						onChange={handleInputChange}
						autoComplete='maxEventParticipants'
						error={!formState.maxEventParticipants.isValid}
					/>
				</Grid>

				<Grid item md={4} xs={12}>
					{/* Groups colors */}
					<FormControl 
						className={classes.formControl} 
						margin='normal'
						required 
						fullWidth 
						error={!formState.eventGroups.isValid}
					>
						<InputLabel id="eventGroups">{formState.eventGroups.label}</InputLabel>
						<Select
							labelId="eventGroups"
							id='eventGroups'
							multiple
							classes={{'icon': classes.eventGroupsSelectIcon}}
							label={formState.eventGroups.label}
							name='eventGroups'
							value={formState.eventGroups.value}
							renderValue={(selected) => {
								let retVal = selected.map((color) => {
									return (<div key={EVENT_GROUPS_COLORS[color].value} className={classes.eventGroupsColorBox} style={{backgroundColor: EVENT_GROUPS_COLORS[color].hex}} />)
								});
								return retVal;
							}}
							onChange={(e) => handleInputChange(e, {type: 'select', name: 'eventGroups'})}
							required
							endAdornment={
								<InfoIconTooltip  text={formState.eventGroups.info}/>
							}
						>
							{Object.keys(EVENT_GROUPS_COLORS).map(color => (
								<MenuItem 
									value={EVENT_GROUPS_COLORS[color].value} 
									key={EVENT_GROUPS_COLORS[color].value}
								>
									<Checkbox 
										classes={{'root': classes.groupColorCheckbox}}
										checked={formState.eventGroups.value.indexOf(color) > -1} 
									/>
									<div className={classes.eventGroupsColorBox} style={{backgroundColor: EVENT_GROUPS_COLORS[color].hex}} />
									{EVENT_GROUPS_COLORS[color].label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			</Grid>

			{/* Section 3 - Default Day and Time */}
			<Grid container spacing={3}>
				<Grid item md={6} xs={12}>
					<FormControl 
						className={classes.formControl} 
						margin='normal'
						required 
						fullWidth 
						error={!formState.defaultDay.isValid}
					>
						<InputLabel id="defaultDay">{formState.defaultDay.label}</InputLabel>
						<Select
							labelId="defaultDay"
							id='defaultDay'
							label={formState.defaultDay.label}
							name='defaultDay'
							value={formState.defaultDay.value}
							onChange={(e) => handleInputChange(e, {type: 'select', name: 'defaultDay'})}
							required
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{Object.keys(WEEK_DAYS).map(day => (
								<MenuItem value={WEEK_DAYS[day].value} key={WEEK_DAYS[day].value}>
									{WEEK_DAYS[day].label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item md={6} xs={12}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardTimePicker
							margin="normal"
							style={{width: '100%'}}
							id="time-picker"
							label={formState.defaultTime.label}
							value={formState.defaultTime.value}
							onChange={(e) => handleInputChange(e, {type: 'date', name: 'defaultTime'})}
							minutesStep={15}
							format="hh:mm a"
							required
							KeyboardButtonProps={{
								'aria-label': 'change time',
							}}
							error={!formState.defaultTime.isValid}
						/>
					</MuiPickersUtilsProvider>
				</Grid>
			</Grid>
		</React.Fragment>
  );

  return {
		dialogTitle: !isEditMode ? DICTIONARY.TEAM.createTeam.title.create : DICTIONARY.TEAM.createTeam.title.edit,
		dialogContent,
		sumbitButtonLabel: DICTIONARY.GENERAL.button.submit,
		handleSubmit,
		handleCloseModal,
		formError,
		isLoading,
	};
}
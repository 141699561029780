import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import firebase, { messaging } from "../../config/firebase";
import { AuthContext } from '../../context';
import { userApi } from '../../api/index';
import { isLocalhost, isVerbose } from '../../utils/utils';
import DICTIONARY from '../../utils/dictionary';

// Material UI Components
import { Button, Typography, Hidden } from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';

// Colors
import { blue, grey } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
	notificationAlertContainer: {
		marginBottom: theme.spacing(2),
	},
	notificationAlertClose: {
		alignItems: 'flex-start !important',
		marginTop: '5px',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: theme.spacing(-3),
		marginTop: theme.spacing(1),
	},
	approveButton: {
		color: theme.palette.info.main,
		backgroundColor: 'white',
		'&:hover': {
			color: blue[800],
			backgroundColor: 'white',
		},
	},
	rejectButton: {
		color: 'white',
		backgroundColor: 'transparent !important',
		'&:hover': {
			color: grey[200],
		},
	}
}));

// --------------------------------------------------------------------------------------------------------------
// ------------------------------------------------- DEPRECATED -------------------------------------------------
// --------------------------------------------------------------------------------------------------------------

export default function MessagingRequestPermissionBanner() {
	const { user, handleUserUpdate } = useContext(AuthContext);
	const classes = useStyles();

	const [showAlert, setShowAlert] = useState(false);
	const [step, setStep] = useState(1);

	useEffect(() => {
		try {
			const isInstalledAsPwa = isInstalledAsPwa();
			const isFirebaseMessagingSuppoerted =  firebase.messaging.isSupported();

			if ((isInstalledAsPwa || isLocalhost()) && isFirebaseMessagingSuppoerted && !user.userData?.pwaMessaging?.permissionStatus) {
				console.log('display-mode is standalone');
				setShowAlert(true);
			} else {
				// We do this check in App.js
				// updateTokenIfNeeded();
			}
		} catch(e) {}
	}, []);

	const handleMessagingRequestPermission = () => {
		setStep(2);
		const userId = user.userAuth.uid;
		Notification.requestPermission()
		.then(() => {
			isVerbose() && console.log('Have permission');
			return messaging.getToken({vapidKey: "BNREApYY85406fdY7fnfU3w9evlbIrl_1N9nLCi0F6-D6Q-AuFmIBIauvxSoF98_1J3SyFyItR3-gapptXyYTfA"});
		})
		.then(async (token) => {
			if (token) {
				isVerbose() && console.log('User token: ', token);
				// API to store the user token in the DB
				const res = await userApi.createMessagingRegistrationToken(userId, token, 'GRANTED');
			} else {
				isVerbose() && console.log('No registration token available. Request permission to generate one.');
			}

			setShowAlert(false);
		})
		.catch(async (e) => {
			isVerbose() && console.log('Error in Firebase Messaging: ', e);
			if (e.code === 'messaging/permission-default') { // The notification permission was not granted and dismissed instead.
				// user dissmised it --> in the future it can be shown again
				setShowAlert(false);
			} else if (e.code === 'messaging/permission-blocked') { // The notification permission was not granted and blocked instead.
				const res = await userApi.createMessagingRegistrationToken(userId, null, 'BLOCKED');
				setShowAlert(false);
			}
		});
	};

	const handleBlockNotifications = async () => {
		const userId = user.userAuth.uid;
		const res = await userApi.createMessagingRegistrationToken(userId, null, 'BLOCKED');
		setShowAlert(false);
	};

	const handleCloseAlert = () => {
		// No need to send it to DB - just update the userData
		const userData = user.userData;
		userData.pwaMessaging = {
			token: null,
			status: 'DISMISSED'
		};
		handleUserUpdate({
			userData
		});
		setShowAlert(false);
	};

	// Step 1
	const WelcomeMessage = (
		<React.Fragment>
			<AlertTitle>{DICTIONARY.GENERAL.banners.requestMessagingPermission.title}</AlertTitle>
			<Typography variant="body2" component="p">{DICTIONARY.GENERAL.banners.requestMessagingPermission.subtitle}</Typography>
			<div className={classes.actionsContainer}>
				<Button 
					size="small" 
					onClick={handleMessagingRequestPermission}
					className={classes.approveButton}
				>
					{DICTIONARY.GENERAL.banners.requestMessagingPermission.action.approve}
				</Button>
				<Button 
					size="small" 
					onClick={handleBlockNotifications}
					className={classes.rejectButton}
				>
					{DICTIONARY.GENERAL.banners.requestMessagingPermission.action.reject}
				</Button>
			</div>
		</React.Fragment>
	);

  return (
		<React.Fragment>
			{/* Notification alert */}
			{showAlert && <Hidden smUp implementation="js">
				<Alert 
					// variant="outlined"
					variant="filled" 
					severity="info" 
					onClose={handleCloseAlert}
					classes={{'root': classes.notificationAlertContainer, 'action': classes.notificationAlertClose}}
				>
					{step === 1 
						? WelcomeMessage 
						: <Typography variant="body2" component="p">{DICTIONARY.GENERAL.banners.requestMessagingPermission.info}</Typography>
					}
				</Alert>
			</Hidden>}
		</React.Fragment>
	);
}

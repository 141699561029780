
const SUPPORT = { // Support popover
  button: 'Support',
  title: 'Contact us',
  form: {
    field: {
      name: 'Name',
      email: 'Email',
      subject: 'Subject',
      content: 'Content'
    },
    error: {
      generalError: `Couldn't send ticket. Please try again.`,
      nameLength: 'Name must contain a least 2 characters.',
      contentLength: 'Content must contain a least 5 characters.',
    },
    success: `Ticket sent successfully. We'll contact you back soon!`
  }
};

export default SUPPORT;
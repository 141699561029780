import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext, DialogContext, SnackbarContext } from '../../context';
import { eventApi, adminApi } from '../../api';
import { isTimestampFromThePast, isUserAdminOfTeam, getActiveAdminsAndMembers, clonedeep, isVerbose, parsedPathname, isFeatureFlagAllowed } from '../../utils/utils';
import { APP_NAME } from '../../utils/consts';
import { parseEvent, handleRegister, showEventRegisterSnackbar, showOpenForRegistrationSnackbar } from '../../utils/helpers/eventHelper';
import ROUTES from '../../routes';
import DICTIONARY from '../../utils/dictionary';

// App Components
import PageHeader from "../../components/layout/PageHeader";
import EmptyState from '../../components/shared/EmptyState';
import InfoCard from '../../components/shared/InfoCard';
import ComponentLoader from '../../components/shared/loader/ComponentLoader';
import ParticipantsCard from '../../components/event/cards/ParticipantsCard';
import WaitingListCard from '../../components/event/cards/WaitingListCard';
import ParticipantsLogsCard from '../../components/event/cards/ParticipantsLogsCard';
import TabsContainer from '../../components/shared/tab/TabsContainer';
import EventStatsContainer from '../../components/event/EventStatsContainer';

// Material UI Components
import { Grid, Card, CardHeader, CardContent, Box, Typography, }  from "@material-ui/core";

// Icons
import ShirtIcon from '../../components/icon/ShirtIcon';
import { 
	AssignmentTurnedIn as AssignmentTurnedInIcon,
	Delete as DeleteIcon,
	AssignmentInd as AssignmentIndIcon,
	PersonAdd as PersonAddIcon,
	PersonAddDisabled as PersonAddDisabledIcon,
	NotInterested as NotInterestedIcon,
	HourglassEmpty as HourglassEmptyIcon,
	Share as ShareIcon,
	Edit as EditIcon, 
	Equalizer as EqualizerIcon,
} from '@material-ui/icons';

// Colors
import { blueGrey, } from '@material-ui/core/colors';

const ADMIN_ACTIONS_TEMPLATE = {
	me: { // my actions as a member
		title: 'Me',
		actions: []
	},
	participants: {
		title: 'Participants',
		actions: []
	},
	event: {
		title: 'Event',
		actions: []
	},
};

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		padding: 0,
    [theme.breakpoints.up("sm")]: {
			padding: theme.spacing(0, 2),
    }
	},
	cardHeader: {
    padding: theme.spacing(2, 2, 1, 2),
  },
	topCards: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr',
		columnGap: '12px',
		// rowGap: '12px',
		[theme.breakpoints.up("md")]: {
			columnGap: '16px',
			gridTemplateColumns: '1fr 1fr 1fr',
    }
	},
	teamDetailsCard: {
		// height: '100px',
	},
	participantsBox: {
		padding: theme.spacing(0),
		[theme.breakpoints.up("sm")]: {
			padding: theme.spacing(1),
    }
	},
	participantsLogCardContent: {
		padding: theme.spacing(0),
		[theme.breakpoints.up("sm")]: {
			padding: theme.spacing(2),
    },
		'& >div': {
			'& >div': {
				height: '100% !important',
			},
		},
	},
	shirtIconActionButton: {
    height: '22px',
  },
}));

export default function Event(props) {
  const { user } = useContext(AuthContext);
	const { handleCloseDialog, getDialogProps } = useContext(DialogContext);
	const { handleSnackbarDisplay } = useContext(SnackbarContext);
	const history = useHistory();
	const location = useLocation();
  const classes = useStyles();

  const [isEventAllowed, setIsEventAllowed] = useState(false);
  const [isLoadingEventData, setIsLoadingEventData] = useState(false);
	const [eventId, setEventId] = useState('');
	const [numberOfPaidParticipants, setNumberOfPaidParticipants] = useState(0);
	
	const [eventData, setEventData] = useState({});
	const eventDataRef = useRef();

	const [allowedActions, setAllowedActions] = useState([]);

	const participantToUnregisterRef = useRef();
	const teamDataRef = useRef();

	const [isFirstLoad, setIsFirstLoad] = useState(true);
	useEffect(() => {
		setIsFirstLoad(true);
	}, [])

  // #region Load Event Data
	const parseSingleEventData = useCallback((eventRawData, eventId) => {
		const teamData = user?.teamsData[eventRawData.teamId];
		const activeMembers = getActiveAdminsAndMembers(teamData.members);
		const isAdmin = isUserAdminOfTeam(user.userAuth.uid, activeMembers);
		return parseEvent({
			eventRawData, 
			eventId, 
			teamData, 
			userId: user.userAuth.uid, 
			isAdmin, 
			participantActionsByAdmin
		});
	}, [user.teamsData, user.userAuth.uid]); 
	
	const onRegisterButtonClick = useCallback(async () => {
		try{
			const eventData = eventDataRef.current;
			setIsLoadingEventData(true);

			const retVal = await handleRegister(user, eventData);
			if(!retVal.success) {
				throw new Error();
			}

			const eventRawData = await eventApi.get(eventData.id);
			const parsedSingleEventData = parseSingleEventData(eventRawData, eventData.id);
			setEventDataAndEventDataRef(parsedSingleEventData);
			handleAllowedActions();

			setIsLoadingEventData(false);

			return retVal;
		} catch(e) {
			isVerbose() && console.error(`error while register user ${user.userAuth.uid} to event ${eventData.id}, error: ${e}`);
			setIsLoadingEventData(false);

			return {
				success: false
			}
		}
	}, [user.userAuth, parseSingleEventData, eventData.id]);

	const handleOpenForRegistration = useCallback(async () => {
		try{
			const eventData = eventDataRef.current;
			setIsLoadingEventData(true);

			let retVal;
			if(!eventData.parsedIsOpenForRegistration) {
				retVal = await adminApi.event.updateOpenForRegistration(eventData.id, eventData.teamId);
			}

			if(retVal && !retVal.success) {
				throw new Error();
			}

			const eventRawData = await eventApi.get(eventData.id);
			const parsedSingleEventData = parseSingleEventData(eventRawData, eventData.id);
			setEventDataAndEventDataRef(parsedSingleEventData);
			handleAllowedActions();

			setIsLoadingEventData(false);

			return retVal;
		} catch(e) {
			isVerbose() && console.error(`error while open event ${eventData.id} for registration, error: ${e}`);
			setIsLoadingEventData(false);

			return {
				success: false
			}
		}
	}, [parseSingleEventData, eventData.id])
	//#endregion

	// #region Admin allowed actions
	const handleAllowedActions = useCallback(() => {
		const eventData = eventDataRef.current;
		const teamData = user?.teamsData[eventData.teamId];
		teamDataRef.current = teamData;
		const activeMembers = getActiveAdminsAndMembers(teamData.members);
		const isAdmin = isUserAdminOfTeam(user.userAuth.uid, activeMembers);
		const isEventFromPast = isTimestampFromThePast(eventData.date);

		const ACTIONS_LIST = {
			ENABLE_REGISTRATION: {
				key: 'enableRegistration',
				title: DICTIONARY.EVENT.event.actions.enableRegistration,
				iconElement: <AssignmentTurnedInIcon />,
				color: 'secondary',
				onClick: async () => {
					let response = await handleOpenForRegistration();
					showOpenForRegistrationSnackbar(response, handleSnackbarDisplay); // Display a snackbar with the action result
				},
			},
			EDIT_EVENT: {
				key: 'editEvent',
				title: DICTIONARY.EVENT.event.actions.edit,
				iconElement: <EditIcon />,
				color: 'secondary',
				onClick: handleClickOpenCreateEventDialog,
			},
			SET_LINEUPS: {
				key: 'setLineups',
				title: DICTIONARY.EVENT.event.actions.setLineups,
				iconElement: <ShirtIcon className={classes.shirtIconActionButton}/>,
				color: 'secondary',
				onClick: handleClickSetLineupsDialog,
			},
			UPDATE_EVENT_STATS: {
				key: 'updateEventStats',
				title: DICTIONARY.EVENT.event.actions.updateEventStats,
				iconElement: <EqualizerIcon />,
				color: 'secondary',
				onClick: handleClickUpdateEventStatsDialog,
			},
			SHARE_EVENT: {
				key: 'shareEvent',
				title: DICTIONARY.EVENT.event.actions.share,
				iconElement: <ShareIcon />,
				color: 'secondary',
				onClick: handleClickShareEvent,
			},
			DELETE_EVENT: {
				key: 'deleteEvent',
				title: DICTIONARY.EVENT.event.actions.delete,
				iconElement: <DeleteIcon />,
				color: 'secondary',
				onClick: handleClickDeleteEventDialog,
			},
			REGISTER_PARTICIPANT_BY_ADMIN: {
				key: 'registerParticipant',
				title: DICTIONARY.EVENT.event.actions.registerParticipant,
				iconElement: <AssignmentIndIcon />,
				color: 'secondary',
				onClick: handleClickRegisterParticipantDialog,
			},
			REGISTER: {
				type: 'main', // On mobile - this action should be outside of the actions menu
				key: eventData.isUserRegisterd ? 'unregisterMyself' : 'registerMyself',
				title: eventData.isUserRegisterd ? DICTIONARY.EVENT.event.actions.unregister : DICTIONARY.EVENT.event.actions.register,
				iconElement: eventData.isUserRegisterd ? <PersonAddDisabledIcon /> : <PersonAddIcon />,
				color: eventData.isUserRegisterd ? 'secondary' : 'primary',
				onClick: async () => {
					if(eventData.isUserRegisterd) { // Show unregister dialog
						handleClickUnregisterDialog();
					} else {
						let response = await onRegisterButtonClick();
						showEventRegisterSnackbar(response, handleSnackbarDisplay); // Display a snackbar with the action result
					}
				},
			},
		};

		if(isAdmin) {
			// Admin actions
			let adminActions = clonedeep(ADMIN_ACTIONS_TEMPLATE);

			if(!isEventFromPast) {
				!eventData.parsedIsOpenForRegistration &&	adminActions['event'].actions.push(ACTIONS_LIST.ENABLE_REGISTRATION);
				adminActions['event'].actions.push(ACTIONS_LIST.EDIT_EVENT);
				eventData.participants.length > 0 && adminActions['event'].actions.push(ACTIONS_LIST.SET_LINEUPS);
				eventData.participants.length > 0 && adminActions['event'].actions.push(ACTIONS_LIST.UPDATE_EVENT_STATS);
				adminActions['event'].actions.push(ACTIONS_LIST.SHARE_EVENT);
				adminActions['participants'].actions.push(ACTIONS_LIST.REGISTER_PARTICIPANT_BY_ADMIN);
				adminActions['me'].actions.push(ACTIONS_LIST.REGISTER);
				adminActions['event'].actions.push(ACTIONS_LIST.DELETE_EVENT);
			} else {
				adminActions = {
					event: {
						title: 'Event',
						actions: [ACTIONS_LIST.SHARE_EVENT]
					}
				};
				eventData.participants.length > 0 && adminActions.event.actions.push(ACTIONS_LIST.SET_LINEUPS);
				eventData.participants.length > 0 && adminActions.event.actions.push(ACTIONS_LIST.UPDATE_EVENT_STATS);
				adminActions.event.actions.push(ACTIONS_LIST.DELETE_EVENT);
			}

			setAllowedActions(adminActions);
		} else {
			// Member actions
			let memberActions = {};

			if(!isEventFromPast) {
				memberActions = {
					me: {
						title: 'Me',
						actions: [ACTIONS_LIST.REGISTER]
					},
					event: {
						title: 'Event',
						actions: [ACTIONS_LIST.SHARE_EVENT],
					}
				};
				eventData.participants.length > 0 && memberActions['event'].actions.push(ACTIONS_LIST.UPDATE_EVENT_STATS);
			} else {
				memberActions = {
					event: {
						title: 'Event',
						actions: [ACTIONS_LIST.SHARE_EVENT],
					}
				};
				eventData.participants.length > 0 && memberActions['event'].actions.push(ACTIONS_LIST.UPDATE_EVENT_STATS);
			}

			setAllowedActions(memberActions);
		}
	}, [user?.teamsData, user.userAuth.uid, handleOpenForRegistration, onRegisterButtonClick]);
	//#endregion

	// #region Load event data
	useEffect(() => {
		setIsLoadingEventData(true);
		// Check if the requested the user belongs to this teamId
		const eventIdParam = props?.match?.params.eventId || '';
		setEventId(eventIdParam);
		// const eventData = props?.location?.state?.eventData;

		(async function () {
			try{
				const eventRawData = await eventApi.get(eventIdParam);
				setIsFirstLoad(false);
				if(eventRawData !== undefined) {
					// Validate - Event was not deleted
					if(eventRawData.deletedAt !== null) {
						setIsLoadingEventData(false);
						setIsEventAllowed(false);
					};

					// Validate - Team of the event does exists in user's teams
					const teamId = eventRawData.teamId;
					const userTeamsIds = user?.userData?.teams || [];
					const teamIndex = userTeamsIds.indexOf(teamId);
					if(teamIndex === -1) {
						setIsLoadingEventData(false);
						setIsEventAllowed(false);
						return;
					}

					const parsedSingleEventData = parseSingleEventData(eventRawData, eventIdParam);
					setEventDataAndEventDataRef(parsedSingleEventData);

					handleAllowedActions();
					setIsLoadingEventData(false);
					setIsEventAllowed(true);
					
				} else {
					setIsLoadingEventData(false);
					setIsEventAllowed(false);						
				}
			} catch(e) {
				setIsEventAllowed(false);
				setIsLoadingEventData(false);
				setIsFirstLoad(false);
			}
		})();
	}, [])
	//#endregion Event Data
	
	// #region Admin - Create(edit) Event Dialog
	const handleClickOpenCreateEventDialog = () => {
		const eventData = {
			location: eventDataRef.current.location, 
			maxEventParticipants: eventDataRef.current.maxEventParticipants, 
			eventGroups: eventDataRef.current.eventGroups, 
			currentParticipantsLength: eventDataRef.current.participants.length, 
		};

		handleCloseDialog(handleCloseCreateEventDialog);
		getDialogProps({
			teamId: eventDataRef.current.teamId,
			eventId: eventDataRef.current.id,
			eventData,
			isEditMode: true,
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}/edit-event`,
			state: {
				background: location,
				id: 'createEvent',
			},
		});
	};

	const handleCloseCreateEventDialog = async ({isSubmittedSuccessfully, response}) => {
		getDialogProps({});

		if (isSubmittedSuccessfully) {
			setIsLoadingEventData(true);
			const eventRawData = await eventApi.get(eventDataRef.current.id);
			const parsedSingleEventData = parseSingleEventData(eventRawData, eventDataRef.current.id);
			setEventDataAndEventDataRef(parsedSingleEventData);
			handleAllowedActions();
			setIsLoadingEventData(false);
		}
	};
	//#endregion Create Event Dialog

	// #region Admin - Delete Event Dialog
  const handleClickDeleteEventDialog = () => {
		handleCloseDialog(handleCloseDeleteEventDialog); // Update handleCloseDialog API context function
		getDialogProps({
			eventId: eventDataRef.current.id,
			adminEmail: user.userData.email,
			teamId: eventDataRef.current.teamId,
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}/delete-event`,
			state: {
				background: location,
				id: 'deleteEvent',
			},
		});
	};
	
	const handleCloseDeleteEventDialog = async ({isSubmittedSuccessfully}) => {
		getDialogProps({});

		if(isSubmittedSuccessfully) {
      history.push({
        pathname: ROUTES.myEvents.path,
        search: '',
      });
    }
	};
	//#endregion
	
	// #region Admin - Set lineups Dialog
  const handleClickSetLineupsDialog = () => {
		handleCloseDialog(handleCloseSetlineupsDialog); // Update handleCloseDialog API context function

		getDialogProps({
			eventId: eventDataRef.current.id,
			teamId: eventDataRef.current.teamId,
			eventGroups: eventDataRef.current.eventGroups,
			participants: eventDataRef.current.participants,
			shouldShowLineups: eventDataRef.current.shouldShowLineups,
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}/set-lineups`,
			state: {
				background: location,
				id: 'setLineups',
			},
		});
	};
	
	const handleCloseSetlineupsDialog = async ({isSubmittedSuccessfully}) => {
		getDialogProps({});

		// Reload event page
		if(isSubmittedSuccessfully) {
			setIsLoadingEventData(true);
			const eventRawData = await eventApi.get(eventDataRef.current.id);
			const parsedSingleEventData = parseSingleEventData(eventRawData, eventDataRef.current.id);
			setEventDataAndEventDataRef(parsedSingleEventData);
			handleAllowedActions();
			setIsLoadingEventData(false);
		};
	};
	//#endregion

	// #region - Share event page (Dialog for desktop and native behavior for mobile)
	const handleClickShareEvent = async () => {
		try {
			if(navigator.share) { // Mobile
				const shareData = {
					title: 'Team UP',
					text: `${DICTIONARY.EVENT.shareEvent.mobileNvigatorShare.title} ${eventDataRef.current.parsedDate}`,
					url: window.location.href,
				};

				await navigator.share(shareData);
			} else { // Desktop
				handleCloseDialog(handleCloseShareEventDialog); // Update handleCloseDialog API context function
				getDialogProps({
					eventLink: window.location.href,
					eventDate: eventDataRef.current.parsedDate
				});
		
				history.push({
					pathname: `${parsedPathname(location.pathname)}/share-event`,
					state: {
						background: location,
						id: 'shareEvent',
					},
				});
			}
		} catch(e) {
		}
	};

	const handleCloseShareEventDialog = async ({isSubmittedSuccessfully}) => {
		getDialogProps({});
	};
	//#endregion
		
	// #region Admin - Register Participant Dialog
  const handleClickRegisterParticipantDialog = () => {
		handleCloseDialog(handleCloseRegisterParticipantDialog); // Update handleCloseDialog API context function
		getDialogProps({
			eventId: eventDataRef.current.id,
			teamId: eventDataRef.current.teamId,
			participants: eventDataRef.current.participants,
			waitingList: eventDataRef.current.waitingList,
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}/register-participant`,
			state: {
				background: location,
				id: 'registerParticipant',
			},
		});
	};
	
	const handleCloseRegisterParticipantDialog = async ({isSubmittedSuccessfully}) => {
		getDialogProps({});

		if(isSubmittedSuccessfully) {
			setIsLoadingEventData(true);
			const eventRawData = await eventApi.get(eventDataRef.current.id);
			const parsedSingleEventData = parseSingleEventData(eventRawData, eventDataRef.current.id);
			setEventDataAndEventDataRef(parsedSingleEventData);
			handleAllowedActions();
			setIsLoadingEventData(false);
		};
	};
	//#endregion

	// #region Admin - Unregister Participant Dialog (by admin)
  const handleClickAdminUnregisterParticipantDialog = () => {
		handleCloseDialog(handleCloseAdminUnregisterParticipantDialog); // Update handleCloseDialog API context function

		getDialogProps({
			eventId: eventDataRef.current.id,
			teamId: eventDataRef.current.teamId,
			participant: participantToUnregisterRef.current,
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}/unregister-participant-by-admin`,
			state: {
				background: location,
				id: 'adminUnregisterParticipant',
			},
		});
	};
	
	const handleCloseAdminUnregisterParticipantDialog = async ({isSubmittedSuccessfully}) => {
		getDialogProps({});

		if(isSubmittedSuccessfully) {
			setIsLoadingEventData(true);
			const eventRawData = await eventApi.get(eventDataRef.current.id);
			const parsedSingleEventData = parseSingleEventData(eventRawData, eventDataRef.current.id);
			setEventDataAndEventDataRef(parsedSingleEventData);
			handleAllowedActions();
			setIsLoadingEventData(false);
		};
	};
	//#endregion

	// #region Member - Unregister Dialog 
  const handleClickUnregisterDialog = () => {
		handleCloseDialog(handleCloseUnregisterDialog); // Update handleCloseDialog API context function
		getDialogProps({
			eventId: eventDataRef.current.id,
			eventDate: eventDataRef.current.parsedDate,
			teamId: eventDataRef.current.teamId,
			userData: { // if member is unregister himself --> he must not be a guest
				userId: user.userAuth.uid,
				name: user.userData.name,
				avatar: user.userData.avatar,
			},
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}/unregister-participant`,
			state: {
				background: location,
				id: 'unregisterParticipant',
			},
		});
	};
	
	const handleCloseUnregisterDialog = async ({isSubmittedSuccessfully}) => {
		getDialogProps({});

		if(isSubmittedSuccessfully) {
			setIsLoadingEventData(true);
			const eventRawData = await eventApi.get(eventDataRef.current.id);
			const parsedSingleEventData = parseSingleEventData(eventRawData, eventDataRef.current.id);
			setEventDataAndEventDataRef(parsedSingleEventData);
			handleAllowedActions();
			setIsLoadingEventData(false);
		};
	};
	//#endregion

	// #region Admin - Mark participant as paid
  const handleMarkParticipantAsPaid = async (userId, eventDataRef) => {
		const eventData = clonedeep(eventDataRef.current);
		const updatedEventData = updateParticipantIsLoading(eventData, userId, true); // set user avatar as start loading
		setEventDataAndEventDataRef(updatedEventData);

		const response = await adminApi.event.markAsPaid(eventData.id, eventData.teamId, userId);

		if(!response.hasOwnProperty('error')) {
			const eventRawData = await eventApi.get(eventData.id);
			const parsedSingleEventData = parseSingleEventData(eventRawData, eventData.id);
			const updatedEventData = updateParticipantIsLoading(parsedSingleEventData, userId, false); // stop loading

			setEventDataAndEventDataRef(updatedEventData);
		} else {
			const updatedEventData = updateParticipantIsLoading(eventData, userId, false); // stop loading

			setEventDataAndEventDataRef(updatedEventData);
		}
	};

	const updateParticipantIsLoading = (eventData, userId, isLoading) => {
		const {participants, waitingList} = eventData;
		const participantsIndex = participants.findIndex(participant => participant.userId === userId);
		const updatedParticipants = [...participants];
		const updatedWaitingList = [...waitingList];

		// User in participants
		if(participantsIndex > -1) {
			updatedParticipants[participantsIndex].isLoading = isLoading;
		} else { // User is in waitingList
			const waitingListIndex = waitingList.findIndex(participant => participant.userId === userId);
			updatedWaitingList[waitingListIndex].isLoading = isLoading;
		}

		return {
			...eventData,
			participants: updatedParticipants,
			waitingList: updatedWaitingList,
		};
	};
	//#endregion
	
	// #region Admin - Spesific Participant actions for admin
	const participantActionsByAdmin = [
    {
      title: 'Unregister',
      onClick: (user) => {		
				participantToUnregisterRef.current = user;
				handleClickAdminUnregisterParticipantDialog();
			},
    },
    {
      title: (user) => {
				return !user.isPaid ? 'Mark as paid' : 'Mark as unpaid';
			},
      onClick: async (user) => {
				const userId = user.userId;
				await handleMarkParticipantAsPaid(userId, eventDataRef);
			}
    },
  ];
	//#endregion

	// #region Update statistics
	const handleClickUpdateEventStatsDialog = () => {
		handleCloseDialog(handleCloseUpdateEventStatsDialog);

		getDialogProps({
			eventId: eventDataRef.current.id,
			teamId: eventDataRef.current.teamId,
			eventGroups: eventDataRef.current.eventGroups,
			participants: eventDataRef.current.participants,
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}/update-stats`,
			state: {
				background: location,
				id: 'updateEventStats',
			},
		});
	};
	
	const handleCloseUpdateEventStatsDialog = async ({isSubmittedSuccessfully}) => {
		getDialogProps({});

		// Reload event page
		if(isSubmittedSuccessfully) {
			setIsLoadingEventData(true);
			const eventRawData = await eventApi.get(eventDataRef.current.id);
			const parsedSingleEventData = parseSingleEventData(eventRawData, eventDataRef.current.id);
			setEventDataAndEventDataRef({...parsedSingleEventData});
			handleAllowedActions();
			setIsLoadingEventData(false);
		};
	};
	//#endregion

	// #region Helpers
	const handleBackClick = () => {
		if (props.history.location.state !== undefined) {
			props.history.goBack();
		} else {
			props.history.push('/my-events')
		}
	};

	const setEventDataAndEventDataRef = (eventData) => {
		setEventData(eventData);
		eventDataRef.current = eventData;

		const numberOfPaidParticipants = eventData.participants.filter(participant => participant.isPaid).length;
		setNumberOfPaidParticipants(numberOfPaidParticipants);
	};
	//#endregion Helpers

	const EventPageContentEl = (eventData) => (
		<Grid container className={classes.root} spacing={2} justifyContent="flex-start">
			{/* Top cards */}
			<Grid item xs={12}>
				<div className={classes.topCards}>
					<InfoCard 
						title={DICTIONARY.EVENT.event.cards.registeredNumber}
						content={(<Typography variant="h5" display="block" gutterBottom>{`${eventData.participants.length} / ${eventData.maxEventParticipants}`}</Typography>)}
						isLoading={isLoadingEventData}
					/>
					<InfoCard 
						title={DICTIONARY.EVENT.event.cards.waitingListNumber}
						content={(<Typography variant="h5" display="block" gutterBottom>{eventData.waitingList.length}</Typography>)}
						isLoading={isLoadingEventData}
					/>
					<InfoCard 
						title={DICTIONARY.EVENT.event.cards.paidNumber}
						content={(<Typography variant="h5" display="block" gutterBottom>{numberOfPaidParticipants}</Typography>)}
						isLoading={isLoadingEventData}
					/>
				</div>
			</Grid>
			
			{/* Participants - Registerd List */}
			<Grid item xs={12}>
				<ParticipantsCard 
					eventData={eventData}
					isLoading={isLoadingEventData}
				/>
			</Grid>

			{/* Waiting List */}
			{eventData.waitingList && eventData.waitingList.length > 0 && 
				<Grid item xs={12}>
					<WaitingListCard 
						eventData={eventData}
						isLoading={isLoadingEventData}
					/>
				</Grid>
			}

			{/* Participants logs */}
			<Grid item xs={12}>
				<ParticipantsLogsCard 
					eventData={eventData}
					isLoading={isLoadingEventData}
				/>
				{/* <Card elevation={0}>
					<CardHeader
						titleTypographyProps={{ variant:'h6' }}
						title={DICTIONARY.EVENT.event.cards.participantsLog}
						classes={{
							'root': classes.cardHeader,
						}}
					/>
					<CardContent
						classes={{
							'root': classes['participantsLogCardContent'],
						}}
					>
						<Box
							display="flex"
							flexWrap="wrap"
							width="100%"
							p={1}
							bgcolor="background.paper"
						>
							<ParticipantsLogCard 
								eventData={eventData}
							/>
						</Box>
					</CardContent>
				</Card> */}
			</Grid>
		</Grid>
	);

  return (
		isFirstLoad && isLoadingEventData
		? <ComponentLoader />
		: !isEventAllowed 
			? <EmptyState 
					icon={<NotInterestedIcon style={{ fontSize: 80, color: blueGrey[500] }}/>}
					title={DICTIONARY.EVENT.event.emptyState}
					padding={10}
				/>
			: <React.Fragment>
					<Helmet>
						<title>{teamDataRef.current.teamName} | {APP_NAME}</title>
					</Helmet>
					<PageHeader 
						title={eventData.parsedDate}
						subTitle={eventData.teamName}
						actions={allowedActions}
						handleBackClick={handleBackClick}
					/>

					{eventData.parsedIsOpenForRegistration 
						?	<TabsContainer 
								tabs={[
									{
										label: DICTIONARY.EVENT.event.tabs.details,
										value: 0,
										key: 'details',
										content: EventPageContentEl(eventData),
									},
									{
										label: DICTIONARY.EVENT.event.tabs.statistics,
										value: 1,
										key: 'statistics',
										content: <EventStatsContainer 
											eventId={eventData.id}	
											{...eventData}
										/>,
									}
								]}
							/>
						: <EmptyState 
								icon={<HourglassEmptyIcon style={{ fontSize: 80, color: blueGrey[500] }}/>}
								title={`${DICTIONARY.EVENT.event.notOpenYet} ${eventData.parsedOpenRegistrationDateAndTime}`}
								padding={10}
							/>
					}
    	</React.Fragment> 
	);
}
import React, { useState, useContext, useEffect, useRef } from "react";
import { AuthContext } from '../../../context';
import {  adminApi } from '../../../api';
import { makeStyles } from '@material-ui/core/styles';
import { EVENT_GROUPS_COLORS } from '../../../utils/consts';
import DICTIONARY from '../../../utils/dictionary';
import useForm from '../../../hooks/formHook';

// App components
import OneActionButton from '../../shared/button/OneActionButton';
import UsersList from '../../shared/userAvatar/UsersList';
import EmptyState from '../../shared/EmptyState';
import ShirtIcon from '../../icon/ShirtIcon';

// Material UI Components
import { Typography, MenuItem, Divider, FormControlLabel, Checkbox } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';

// Colors
import { blueGrey } from '@material-ui/core/colors';

// Icons
import {
	Add as AddIcon,
	People as PeopleIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	dialogContent: {
		paddingTop: theme.spacing(0),
		[theme.breakpoints.down("sm")]: {
			paddingTop: theme.spacing(3),
		},
	},
	groupSectionContainer: {
		marginBottom: theme.spacing(1.5),
		'&:last-child': {
			marginBottom: 0,
		}
	},
	groupHeaderContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	groupTitleContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	groupTitle: {
		fontSize: theme.typography.pxToRem(17),
	},
	addPlayerButton: {
		marginTop: 0,
		marginRight: 0,
		height: '26px',
	},
	groupLineupContainer: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(1.5, 0),
		minHeight: '112px',
		[theme.breakpoints.down("sm")]: {
			minHeight: '104px',
		},
	},
	shirtIconGroup: {
    marginRight: theme.spacing(0.5),
    width: '24px',
    height: '20px',
		stroke: '#313131', strokeWidth: '2px', paintOrder: 'stroke', strokeLinejoin: 'round',
  },
}));

export default function SetLineupsDialog({ eventId, teamId, eventGroups, participants, shouldShowLineups, handleCloseDialog }) {
	const { user, handleUserUpdate } = useContext(AuthContext);
  const classes = useStyles();
	const [selectedGroup, setSelectedGroup] = useState(eventGroups[0]);

	const [participantsList, setParticipantsList] = useState([]); // participants are for the players menu
	const participantsListRef = useRef(null);

	const [lineups, setLineups] = useState({}); // lineups is just for display
	const lineupsRef = useRef(null);

	//#region Init
	useEffect(() => {
		const clonedParticipants = [...participants];
		let parsedParticipants = clonedParticipants.map(participant => {
			let parsedParticipant = {
				name: participant.name,
				avatar: participant.avatar,
				userId: participant.userId,
				group: participant.group || null,
				actions: PLAYER_ACTIONS,
			};

			return parsedParticipant;
		});
		parsedParticipants.sort((a, b) => a.name < b.name);

		updateParticipantsListStateAndRef(parsedParticipants);
	}, []);

	useEffect(() => {
		// Create lineups from participants data (participant.group)
		let lineups = {};
		eventGroups.forEach(group => {
			lineups[group] = [];
		});

		participantsListRef.current.forEach((participant) => {
			try{
				if(participant.group != null) {
					lineups[participant.group].push(participant);
				}
			} catch(e) {}
		});

		updateLineupsStateAndRef(lineups);
	}, []);
	//#endregion

	//#region Players dropdown
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handlePlayersMenuClick = (event, group) => {
    setAnchorEl(event.currentTarget);
		setSelectedGroup(group);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
	//#endregion

	//#region Players actions
	const handleAddParticipantClick = (participant) => {
		try{
			const updatedParticipant = {...participant};

			// We have 2 entities we need to update: 
	
			// 1. Update participants List (this is used in the 'Add players menu') -> so the partaicipant will be disabled in all menus
			let updatedParticipantsList = [...participantsList];
			updatedParticipantsList.forEach((p) => {
				if(p.userId === participant.userId) {
					p.group = selectedGroup;
					updatedParticipant.group = selectedGroup; // relevant for the "handleRemovePlayerFromGroup" action
				}
			});
			updateParticipantsListStateAndRef(updatedParticipantsList);
	
			// 2. Update lineups of selectedGroup
			let updatedLineups = {...lineups};
			updatedLineups[selectedGroup] = [...updatedLineups[selectedGroup], updatedParticipant];
			updateLineupsStateAndRef(updatedLineups);
		} catch(e) {

		}
	};

	const handleRemovePlayerFromGroup = (player) => {
		try{
			const updatedPlayer = {...player};
			const { group: groupToBeRemovedFrom } = player;
	
			// 1. update partipant in "Add players menu"s
			let updatedParticipantsList = [...participantsListRef.current];
			updatedParticipantsList.forEach((participant) => {
				if(participant.userId === player.userId) {
					participant.group = null; // relevant for the "handleRemovePlayerFromGroup" action
				}
			});
			updateParticipantsListStateAndRef(updatedParticipantsList);
	
			// 2. Update player's group lineup
			let updatedLineups = {...lineupsRef.current};
			let relevantGroupLineup = updatedLineups[groupToBeRemovedFrom].filter((p) => {
				return p.userId !== player.userId;
			});
			updatedLineups[groupToBeRemovedFrom] = relevantGroupLineup;
			updateLineupsStateAndRef(updatedLineups);
		} catch(e) {

		}
	};
	//#endregion

	//#region ShouldDisplayLineups checkbox
	const [shouldShowLineupsCheckbox, setShouldShowLineupsCheckbox] = React.useState(shouldShowLineups !== undefined ? shouldShowLineups : true);

	const handleShouldShowLineupsChange = (event) => {
		setShouldShowLineupsCheckbox(!shouldShowLineupsCheckbox);
	};
	//#endregion

	//#region Helpers
	const updateParticipantsListStateAndRef = (participantsList) => {
		setParticipantsList(participantsList);
		participantsListRef.current = participantsList;
	};

	const updateLineupsStateAndRef = (lineups) => {
		setLineups(lineups);
		lineupsRef.current = lineups;
	};

	const PLAYER_ACTIONS = [
		{
			title: 'Remove from group',
			onClick: (player) => {		
				handleRemovePlayerFromGroup(player);
			},
		},
	];
	//#endregion

	//#region Form Hook
	const onSubmit = async () => {
		try {
			let participantsByGroups = {};
			participantsListRef.current.forEach((participant) => {
				const {userId, group} = participant;
				participantsByGroups[userId] = group;
			});
	
			const response = await adminApi.event.updateEventLineups(eventId, teamId, participantsByGroups, shouldShowLineupsCheckbox);
			if (response.hasOwnProperty("error")) {
				throw new Error(DICTIONARY.EVENT.setLineups.form.error.generalError);
			} else {
				return response;
			}
		} catch(e) {
			throw new Error(DICTIONARY.EVENT.setLineups.form.error.generalError);
		}
	}

	const onCloseModal = (isSubmittedSuccessfully) => {
    handleCloseDialog({isSubmittedSuccessfully});
  }

	const {formError, isLoading, handleInputChange, handleSubmit, handleCloseModal} = useForm({
		onSubmit, 
		onCloseModal,
	});
	//#endregion

	const dialogContent = (
		<div className={classes.dialogContent}>
			{participantsList.length > 0 
				? <div>
						{eventGroups.map((group, index) => (
							<div 
								key={group}
								className={classes.groupSectionContainer}
							>
								<div className={classes.groupHeaderContainer}>
									<div className={classes.groupTitleContainer}>
										<ShirtIcon style={{ color: EVENT_GROUPS_COLORS[group].hex }} className={classes.shirtIconGroup} />
										<Typography component="h2" classes={{'root': classes.groupTitle }}>{EVENT_GROUPS_COLORS[group].label}</Typography>
									</div>

									<OneActionButton
										iconElement={<AddIcon />}
										title={DICTIONARY.EVENT.setLineups.addPlayer}
										onClick={(event) => handlePlayersMenuClick(event, group)}
										className={classes.addPlayerButton}
									/>
									<Menu
										id="basic-menu"
										anchorEl={anchorEl}
										open={open}
										onClose={handleClose}
										MenuListProps={{
											'aria-labelledby': 'basic-button',
										}}
									>
										{participantsList.map((participant) => (
											<MenuItem
												key={participant.userId}
												value={participant.name}
												onClick={() => handleAddParticipantClick(participant)}
												disabled={participant.group != null}
											>
												{participant.name}
											</MenuItem>
										))}
									</Menu>
								</div>

								{/* Group lineup */}
								<div className={classes.groupLineupContainer}>
									<UsersList 
										participants={lineups[group]} 
										avatarSize={60}
										emptyStateTitle={DICTIONARY.EVENT.setLineups.groupEmptyState}
										emptyStatePadding={0}
									/>
								</div>

								{/* Divider */}
								<Divider />
								{/* {index < Object.keys(eventGroups).length - 1 && <Divider />} */}
						</div>
					))}

					{/* Checkbox */}
					<FormControlLabel
  					label={DICTIONARY.EVENT.setLineups.form.field.shouldShowLineups}
						control={<Checkbox
							checked={shouldShowLineupsCheckbox}
							onChange={handleShouldShowLineupsChange}
							inputProps={{ 'aria-label': DICTIONARY.EVENT.setLineups.form.field.shouldShowLineups }}
						/>}
					/>

				</div> 
				: <EmptyState 
						icon={<PeopleIcon style={{ fontSize: 80, color: blueGrey[500] }}/>}
						title={DICTIONARY.EVENT.setLineups.emptyState}
						padding={5}
					/>
			}
		</div>
  );

  return {
		dialogTitle: DICTIONARY.EVENT.setLineups.title,
		dialogContent,
		sumbitButtonLabel: DICTIONARY.EVENT.setLineups.form.submitButton,
		handleSubmit,
		handleCloseModal,
		formError,
		isLoading,
	};
}
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DICTIONARY from '../../../../utils/dictionary';
import { containsHebrew } from '../../../../utils/utils';

// App Components
import Squircle from '../../../shared/userAvatar/Squircle';
import LightTooltip from '../../../shared/tooltip/LightTooltip';

// Material UI Components
import { DataGrid } from '@material-ui/data-grid';
import { useMediaQuery, useTheme } from '@material-ui/core';

// Icons
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon
} from '@material-ui/icons';

// Colors
import { pink, teal } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    // border: 'none',
    '&.MuiDataGrid-root': {
      minHeight: '370px !important',
    },
    '& .last-cloumn-header': {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      }
    },
    '& .rendering-zone': {
      width: '100% !important',
    },
    '& .MuiDataGrid-row': {
      width: '100% !important',
    },
    '& .MuiDataGrid-colCell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-colCell': {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 0, 0, 1),
      },
    },
    '& .MuiDataGrid-cell': {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 1),
      },
    },
    '& .MuiDataGrid-columnHeader': {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 0, 0, 0.5),
      },
    },
  },
  mobileTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  mobileText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '95%',
    display: 'block',
  },
}));

export default function SwapsTable({logs}) {
  const classes = useStyles();
  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [swaps, setSwaps] = useState([]);

  //#region Set Swaps data
  useEffect(() => {
    let clonedLogs = [];
    try {
      clonedLogs = JSON.parse(JSON.stringify(logs));
      let swaps = [];
      let i = clonedLogs.length - 1;

      while (i >= 0){
        let currentLog = clonedLogs[i];

        if(currentLog.action === 'Unregister') { 
          // find the first next user who registered and has not been visited yet (= he hasnt marked as swapped yet)
          let j = i - 1;
          let isSwapExist = false;

          while(j >= 0 && !isSwapExist) {
            let potentialLog = clonedLogs[j];
            if(potentialLog.action === 'Register' && !potentialLog.visited) {
              isSwapExist = true;
              let swap = {
                id: i,
                outName: currentLog.name,
                outAvatar: currentLog.avatar,
                inName: potentialLog.name,
                inAvatar: potentialLog.avatar,
                timestamp: potentialLog.timestamp,
                date: potentialLog.date,
              };
              swaps.push(swap);
              clonedLogs[j].visited = true; // ignore this potentialLog (to not get it again)
            } else {
              j = j - 1;
            }
          }
        }

        // continue the next log
        i = i - 1;
      }

      let sortedSwaps = swaps ? swaps.slice().sort((a, b) => b.timestamp - a.timestamp) : [];
      setSwaps(sortedSwaps);
    } catch(e) {}

    return () => {
      clonedLogs = [];
    }
  }, [logs]);
  //#endregion


  // #region DataGrid init
  const [pageSize, setPageSize] = useState(5);
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };
  // #endregion

  //#region Columns
  const columns = [
    {
      field: 'in', 
      headerName: DICTIONARY.EVENT.event.swapsTable.head.in, 
      flex: 1, 
      sortable: false,
      renderHeader: (params) => {
        const wrapperStyle = {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          gap: '4px',
        };
        return (
          <div style={wrapperStyle}>
            <ArrowUpwardIcon style={{ color: teal['A700'], fontSize: '20px' }} />
            {DICTIONARY.EVENT.event.swapsTable.head.in}
          </div>
        )
      },
      renderCell: (params) => {
        const { inName, inAvatar } = params.row;
        const wrapperStyle = {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        };
        let avatarStyle = {
          width: '28px',
          height: '28px',
          objectFit: 'cover',
          marginRight: '8px',
        };

        avatarStyle = isMobile 
          ? {
              ...avatarStyle,
              width: '20px',
              height: '20px',
            }
          : avatarStyle;
  
        const element = isMobile 
          ? <LightTooltip 
              title={inName} 
            >
              <span 
                className={classes.mobileText}
                style={{
                  direction: containsHebrew(inName) ? 'rtl' : 'ltr',
                }}
              >
                {inName}
              </span>
            </LightTooltip>
          : <span>{inName}</span>

        return (
          <div style={wrapperStyle}>
            {/* <ArrowRightIcon style={{ color: teal['A700'] }} /> */}
            <div style={wrapperStyle}>
              <Squircle 
                src={inAvatar}
                alt={inName}
                style={avatarStyle}
              />
              {element}
            </div>
          </div>

        )
      },
    },
    {
      field: 'out', 
      headerName: DICTIONARY.EVENT.event.swapsTable.head.out, 
      flex: 1, 
      sortable: false,
      headerClassName: 'last-cloumn-header',
      renderHeader: (params) => {
        const wrapperStyle = {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          gap: '4px',
        };
        return (
          <div style={wrapperStyle}>
            <ArrowDownwardIcon style={{ color: pink['A700'], fontSize: '20px' }} />
            {DICTIONARY.EVENT.event.swapsTable.head.out}
          </div>
        )
      },
      renderCell: (params) => {
        const { outName, outAvatar } = params.row;
        const wrapperStyle = {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        };
        let avatarStyle = {
          width: '28px',
          height: '28px',
          objectFit: 'cover',
          marginRight: '8px',
        };

        avatarStyle = isMobile 
          ? {
              ...avatarStyle,
              width: '20px',
              height: '20px',
            }
          : avatarStyle;
  
        const element = isMobile 
          ? <LightTooltip 
              title={outName} 
            >
              <span 
                className={classes.mobileText}
                style={{
                  direction: containsHebrew(outName) ? 'rtl' : 'ltr',
                }}
              >
                {outName}
              </span>
            </LightTooltip>
          : <span>{outName}</span>

        return (
          <div style={wrapperStyle}>
            {/* <ArrowLeftIcon style={{ color: pink['A700'] }} /> */}
            <div style={wrapperStyle}>
              <Squircle 
                src={outAvatar}
                alt={outName}
                style={avatarStyle}
              />
              {element}
            </div>
          </div>

        )
      },
    },

  ];
  // #endregion

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={swaps}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={handlePageSizeChange}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        disableSelectionOnClick
        autoHeight
        className={classes.root}
        disableColumnFilter
        disableColumnMenu
        sortingOrder={['desc', 'asc']}
      />
    </div>
  );
}
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '300px',
	},
}));

export default function NotFound() {
  const classes = useStyles();

  return (
  <Grid container justifyContent="center" alignItems="center" className={classes.root}>
    <Grid container item  direction="column" justifyContent="center" alignItems="center" >
      <Typography component='h1' variant='h5'>
        Sorry, Page Not Found
      </Typography>
      <Box mt={2}>
        <Button color="primary" component={RouterLink} to="/">
          Go to Home Page
        </Button>
      </Box>
    </Grid>
  </Grid>
)};

const AUTH = { // Login and Signup pages
  title: {
    login: 'Log in',
    signup: 'Sign up'
  },
  form: {
    field: {
      email: 'Email',
      name: 'Name',
      password: 'Password',
    },
    error: {
      badlyFormattedEmail: 'The email address is badly formatted.',
      incorrectEmailOrPassword: 'Incorrect email or password.',
      emailInUse: 'The email address is already in use by another account.',
      weakPassword: 'Password should be at least 6 characters.',
      nameLength: 'Name must contain a least 2 characters.',
      generalError: 'Error. Please try again or contact support.',
      emailNotVerified: 'Email has not been verified yet.', // Add button to resend email
      resendEmailVerification: 'Resend email verification'
    },
  },
  button: {
    login: 'Log in',
    signup: 'Sign up',
    loginGoogle: 'Log in with Google',
    loginFacebook: 'Log in with Facebook',
    signupGoogle: 'Sign up with Google',
    signupFacebook: 'Sign up with Facebook',
    forgotPassword: 'Forgot password?',
    dontHaveAccount: `Don't have an account?`,
    alreadyHaveAccount: `Already have an account?`,
  },
  snackbar: {
    emailVerification: 'Check your email for verification',
    resetPassowrd: 'An email with reset instructions has been sent',
  },
  forgotPassword: {
    title: 'Forgot password',
    subtitle: 'Fill in your email to reset your password',
    form: {
      field: {
        email: 'Email',
      },
      error: {
        generalError: `Couldn't send a password reset email. Please try again or contact support.`,
        badlyFormattedEmail: 'The email address is badly formatted.',
      },
    },
  },
};

export default AUTH;
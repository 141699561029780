import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DICTIONARY from '../../utils/dictionary';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { Box, TextField, Popover, Typography, IconButton } from "@material-ui/core";

// Material UI Components - Dialog
import LinkIcon from '@material-ui/icons/Link';

import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  invitationLink: {
    color: blue[800],
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default function ShareTeamPageDialog({ link, teamName, handleCloseDialog }) {
  const classes = useStyles();
  const containerRef = useRef(null);

  //#region Popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  //#endregion

  const handleClickCopy = () => {
    handlePopoverOpen(containerRef.current);
  };

  const dialogContent = (
    <React.Fragment>
      <Box mb={1} display="flex" justifyContent="center" alignItems="center">
        <TextField 
          id="invitation-link" 
          value={link} 
          fullWidth
          variant="outlined"
          size="small"
          disabled={true}
          InputProps={{ classes: { disabled: classes.invitationLink } }}
        />
        <CopyToClipboard text={link} onCopy={handleClickCopy}>
          <IconButton 
            aria-label="copy"
            onMouseLeave={handlePopoverClose}
            ref={containerRef}
          >
            <LinkIcon />
          </IconButton>
        </CopyToClipboard>
      </Box>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{DICTIONARY.TEAM.shareTeam.copied}</Typography>
      </Popover>
    </React.Fragment>
  );

  return {
		dialogTitle: DICTIONARY.TEAM.shareTeam.desktopDialog.title,
		dialogContent,
    dialogContentText: (<span><span style={{fontWeight: 'bold', color: 'black'}}>{teamName}</span> {DICTIONARY.TEAM.shareTeam.desktopDialog.subtitle}</span>),
    shouldHideActions: true,
	};
}
import React, { useState, useEffect, useContext, useRef } from "react";
import md5 from "md5";
import { AuthContext } from '../../../context';
import { makeStyles } from "@material-ui/core/styles";
import { adminApi, generalApi } from '../../../api';
import { getActiveAdminsAndMembers } from '../../../utils/utils';
import DICTIONARY from '../../../utils/dictionary';
import useForm from '../../../hooks/formHook';

// App components
import InfoIconTooltip from '../../shared/tooltip/InfoIconTooltip';

// Material UI Components
import { TextField, Box, FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";

// Colors
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  radioInfoContainer: {
		display: 'flex',
    alignItems: 'center',
	},
  guestUserLabel: {
    color: grey[600],
  },
}));

const PARTICIPANT_TYPES = {
  teamMemberToRegister: 'teamMemberToRegister',
  guestUser: 'guestUser',
};

export default function RegisterParticipantDialog({ eventId, teamId, participants, waitingList, handleCloseDialog }) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const [teamMembers, setTeamMembers] = useState([]);

  //#region Get Data
  useEffect(() => {
    const members = getActiveAdminsAndMembers(user?.teamsData[teamId].members);
    let participantsAndWaitingList = [...participants, ...waitingList];

    // filter already registered members
    let teamMembers = [];
    Object.keys(members).forEach(userId => {
      const isRegistered = participantsAndWaitingList.findIndex(user => user.userId === userId) > -1;
      const isMyself = userId === user.userAuth.uid;
      if(!isRegistered && !isMyself) {
        teamMembers.push({
          userId,
          name: members[userId].name,
          avatar: members[userId].avatar,
        });
      }
    });

    setTeamMembers(teamMembers);
  }, [user?.teamsData, user.userAuth, teamId, participants, waitingList]);
  //#endregion

  //#region Form Hook
  const initalFormStateTemplate = {
    participantType: {
			label: 'Participant type',
			value: PARTICIPANT_TYPES.teamMemberToRegister,
			isValid: true,
      required: true,
			validators: [],
    },
		teamMemberToRegister: {
			label: DICTIONARY.EVENT.registerParticipantToEvent.form.field.teamMemberToRegister.label,
      info: DICTIONARY.EVENT.registerParticipantToEvent.form.field.teamMemberToRegister.info,
			value: [],
			isValid: true,
      required: false,
			validators: [
        {
					validate: (value, formState) => {
						if(formState.participantType.value === PARTICIPANT_TYPES.teamMemberToRegister) {
							if(value.length > 0) {
                return true;
              } else {
                return false;
              }
						} else {
              return value.length === 0;
            }
					},
					errorMessage: DICTIONARY.EVENT.registerParticipantToEvent.form.error.teamMemberToRegisterChosen,
				},
      ],
		},
    guestUser: {
      label: DICTIONARY.EVENT.registerParticipantToEvent.form.field.guestUser.label,
      info: DICTIONARY.EVENT.registerParticipantToEvent.form.field.guestUser.info,
			value: '',
			isValid: true,
      required: false,
			validators: [
				{
					validate: (value, formState) => {
						if(formState.participantType.value === PARTICIPANT_TYPES.guestUser) {
              const parsedGuestUserNames = parseGuestUserNames(value);
							return parsedGuestUserNames.length > 0;
						} else {
              return value == '';
            }
					},
					errorMessage: DICTIONARY.EVENT.registerParticipantToEvent.form.error.guestUserLength,
				},
      ],
		},
	};

  const parseGuestUserNames = (names) => {
    let parsedNames = names.split(',');
    return parsedNames.filter(name => name !== '').map(name => name.trim());
  };

	const onSubmit = async () => {
    let participants = [];
    if(formState.participantType.value === PARTICIPANT_TYPES.teamMemberToRegister) {
      participants = formState.teamMemberToRegister.value;
    } else {
      const parsedGuestUserNames = parseGuestUserNames(formState.guestUser.value);
      parsedGuestUserNames.forEach((guestUser) => {
        const generatedUserId = generalApi.generateId();
        participants.push({
          userId: generatedUserId,
          name: `${guestUser} (Guest)`,
          avatar: `https://gravatar.com/avatar/${md5(generatedUserId)}?d=identicon&size=400`,
          isGuest: true,
        })
      });
    };

    const response = await adminApi.event.registerParticipant(eventId, teamId, participants);

    if (response.hasOwnProperty("error")) {
      throw new Error(DICTIONARY.EVENT.registerParticipantToEvent.form.error.generalError);
    }
	}

	const onCloseModal = (isSubmittedSuccessfully) => {
    handleCloseDialog({isSubmittedSuccessfully});
  }

  const [initalFormState, setInitalFormState] = useState(initalFormStateTemplate);

  const [isActiveTeamMemberToRegister, setIsActiveTeamMemberToRegister] = useState(true);
  const [isActiveGuestUser, setIsActiveGuestUser] = useState(false);
  const teamMemberToRegisterInputRef = useRef(null);
  const guestUserInputRef = useRef(null);

  const onCustomInputChange = (key, val) => {
    if(key === 'participantType') {
      switch(val) {
        case PARTICIPANT_TYPES.teamMemberToRegister: {
          // Disable guestUser
          setIsActiveTeamMemberToRegister(true);
          setIsActiveGuestUser(false);

          // Focus on field
          setTimeout(() => {
            teamMemberToRegisterInputRef.current.focus();
          }, 100);

          // Clear guestUser value
          const updatedFormState = {...formState};
					updatedFormState.guestUser.value = '';
					setInitalFormState(updatedFormState);
          
          break;
        }
        case PARTICIPANT_TYPES.guestUser: {
          // Disable teamMemberToRegister
          setIsActiveTeamMemberToRegister(false);
          setIsActiveGuestUser(true);

          // Focus on field
          setTimeout(() => {
            guestUserInputRef.current.focus();
          }, 100);

          // Clear teamMemberToRegister value
          const updatedFormState = {...formState};
          updatedFormState.teamMemberToRegister.value = [];
          setInitalFormState(updatedFormState);
    
          break;
        }
        default: {
          break;
        }
      }
    }
	};

	const {formState, formError, isLoading, handleInputChange, handleSubmit, handleCloseModal} = useForm({
		initalFormState, 
		onSubmit, 
		onCloseModal,
    onCustomInputChange,
	});
  //#endregion

	const dialogContent = (
		<React.Fragment>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup
          aria-label="participantType"
          name="participantType"
          value={formState.participantType.value}
          onChange={(e) => handleInputChange(e, {type: 'radio'})}
        >

          {/* Team member */}
          <FormControlLabel 
            value={PARTICIPANT_TYPES.teamMemberToRegister} 
            control={<Radio color="primary" />} 
            label={(
              <div className={classes.radioInfoContainer}>
                {formState.teamMemberToRegister.label}
                <InfoIconTooltip text={formState.teamMemberToRegister.info}/>
              </div>
              
            )}
            
          />
          <Autocomplete
            id="team-member"
            fullWidth
            multiple
            limitTags={2}
            size="small"
            classes={{'icon': classes.eventGroupsSelectIcon}}
            disabled={!isActiveTeamMemberToRegister}
            options={teamMembers}
            value={formState.teamMemberToRegister.value}
            onChange={(event, newValue) => {
              handleInputChange(event, {
                type: 'autocomplete',
                name: 'teamMemberToRegister',
                value: newValue,
              });
            }}
            getOptionLabel={(member) => member !== '' ? member.name : ''}
            renderInput={(params) => <TextField 
              {...params} 
              autoFocus
              fullWidth
              inputRef={teamMemberToRegisterInputRef}
              label={formState.teamMemberToRegister.label}
              error={!formState.teamMemberToRegister.isValid && formState.participantType.value === 'teamMemberToRegister'}
              required
            />}
          />

          {/* Guest user */}
          <Box mt={5}>
            <FormControlLabel 
              value={PARTICIPANT_TYPES.guestUser} 
              control={<Radio color="primary" />} 
              label={(
                <div className={classes.radioInfoContainer}>
                  {formState.guestUser.label}
                  <InfoIconTooltip text={formState.guestUser.info}/>
                </div>
                
              )}
            />
            <TextField
              label={formState.guestUser.label}
              id="guestUser"
              name='guestUser'
              margin="dense"
              type="text"
              fullWidth
              value={formState.guestUser.value}
              onChange={handleInputChange}
              autoComplete='guestUser'
              disabled={!isActiveGuestUser}
              inputRef={guestUserInputRef}
              error={!formState.guestUser.isValid && formState.participantType.value === 'guestUser'}
            />
          </Box>
        </RadioGroup>
      </FormControl>
		</React.Fragment>
	);

	return {
		dialogTitle: DICTIONARY.EVENT.registerParticipantToEvent.title,
		dialogContent,
    dialogContentText: `${DICTIONARY.EVENT.registerParticipantToEvent.subtitle}`,
		sumbitButtonLabel: DICTIONARY.EVENT.registerParticipantToEvent.form.submit.register,
		handleSubmit,
		handleCloseModal,
		formError,
		isLoading,
	};
}
import React from "react";
import { makeStyles } from '@material-ui/core/styles';

// Material UI Components
import {
  Button, 
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	oneActionButton: {
		borderRadius: '15px',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: 'white',
	},
}));

export default function OneActionButton({iconElement, title, onClick, className, color}) {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      color={color || 'primary'}
      size="small"
      classes={{
        'outlined': classes.oneActionButton
      }}
      startIcon={iconElement}
      onClick={onClick}
      className={className}
    >
      {title}
    </Button>
  );
}

import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { containsHebrew } from '../../../utils/utils';

// Material UI Components
import { Box, Typography, Badge , Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';

// App components
import Squircle from './Squircle';
import LightTooltip from '../tooltip/LightTooltip';

// Material UI Icons
import {
  ArrowDropDown as ArrowDropDownIcon,
  MonetizationOn as MonetizationOnIcon,
  Stars as StarsIcon,
  Loop as LoopIcon,
} from '@material-ui/icons';

import { teal, blue, blueGrey, amber } from '@material-ui/core/colors';

// const LightTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: theme.palette.common.white,
//     color: 'rgba(0, 0, 0, 0.87)',
//     boxShadow: theme.shadows[1],
//     fontSize: 11,
//     marginTop: '-2px',
//   },
// }))(Tooltip);

const useStyles = makeStyles((theme) => ({
  userAvatarContainer: {
    position: 'relative',
	},
  actionsIcon: {
    position: 'absolute',
    top: '-6px',
    right: '-10px',
    cursor: 'pointer',
    zIndex: 1,
    color: blueGrey[700],
    '&:hover, &:focus': {
      color: blueGrey[900],
      transition: 'color 0.3s ease'
    },
    [theme.breakpoints.down("sm")]: {
      right: '-10px',
    },
  },
	name: { // should be trimmed after 9th char
		whiteSpace: 'nowrap',
  	overflow: 'hidden',
  	textOverflow: 'ellipsis',
    width: '80%',
    textAlign: 'center',
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: '10px',
    // },
	},
  isloading: {
    animation: `$spin 1200ms infinite`,
  },
  "@keyframes spin": {
    'from': {
      transform: 'rotate(0deg)'
    },
    'to': {
      transform: 'rotate(360deg)'
    }
  },
  avatar: {
    margin: 'auto',
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    // [theme.breakpoints.down("sm")]: {
    //   width: 'clamp(60px, 5vw, 120px)!important',
    //   height: 'clamp(60px, 5vw, 120px)!important',
    // },
	},
  paid: {
    color: teal['A700'], 
    backgroundColor: 'white',
    borderRadius: '50%',
    // height: '22px', width: '22px',
  },
  prime: {
    color: amber['400'], 
    backgroundColor: 'white',
    borderRadius: '50%',
  }
}));

export default function UserAvatar({ user, avatarSize }) {
	const classes = useStyles();
  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [userData, setUserData] = useState({});
  const [actions, setActions] = useState([]);
  
  useEffect(() => {
    const userData = {...user};
    const actions = userData.actions || [];
    setUserData(userData);
    setActions(actions);
  }, [user])

  const nameLength = !isMobile ? 10 : 7; // Number of chars to show withput tooltip

  //#region Menu
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (action) => {
    action.onClick(userData);
    setMenuAnchorEl(null);
  };
  //#endregion

  const UserName = (name) => {
    return (
      <Typography 
      variant="body2" 
      component="p" 
      className={classes.name}
      style={{
        fontSize: avatarSize < 50 ? '12px' : 'inherit',
        direction: containsHebrew(name) ? 'rtl' : 'ltr',
      }}
    >
      {name}
    </Typography>
    )
  };

  const getBadgeContent = (userData) => {
    // isPaid --> Event page
    if(userData.hasOwnProperty('isPaid')) {
      return userData.isLoading 
      ? <LoopIcon style={{ color: blue[500] }} className={classes.isloading}/> 
      : userData.isPaid 
        ? <MonetizationOnIcon className={classes.paid} /> 
        : ''
    } else if(userData.hasOwnProperty('isPrime') && userData.isPrime) { // isPrime --> Team page
      return <StarsIcon className={classes.prime} />
    }
  };

  const isBadgeInvisible = (userData) => {
    // isPaid --> Event page
    if(userData.hasOwnProperty('isPaid')) {
      return !(userData.isPaid || userData.isLoading);
    } else if(!(userData.hasOwnProperty('isPrime') && userData.isPrime)) { // isPrime --> Team page
      return true;
    }
  };

  return (
    <React.Fragment>
      <Box 
        // m={1} 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center"
        className={classes.userAvatarContainer}        
      >
        {actions.length > 0 && <ArrowDropDownIcon 
          className={classes.actionsIcon} 
          onClick={actions.length > 0 ? handleMenuClick : null}
        />}
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={getBadgeContent(userData)}
          invisible={isBadgeInvisible(userData)}
          style={{ WebkitTransform: 'translateZ(0px)' }} // Forces browser to render the clip-path --> for showing well on iOS and safari..
        >
          <Squircle 
            src={userData.avatar}
            alt={userData.name}
            className={classes.avatar}
            style={{
              width: isMobile? `${avatarSize - 20}px` : `${avatarSize}px`,
              height: isMobile? `${avatarSize - 20}px` : `${avatarSize}px`,
            }}
          />
        </Badge>
        {userData.name && userData.name.length > nameLength
          ? <LightTooltip 
              title={userData.name} 
            >
              {UserName(userData.name)}
            </LightTooltip> 
          : UserName(userData.name)
        }
      </Box>

      {/* Menu */}
      {actions.length > 0 && <Menu
        id="user-actions-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {actions.length > 0 && actions.map((action, id) => (
          <MenuItem 
            key={id}
            onClick={() => handleMenuClose(action)}
          >
          {typeof action.title === 'function' ? action.title(userData) : action.title}
          </MenuItem>
        ))}
      </Menu>}
    </React.Fragment>
  );
}
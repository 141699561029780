
import AUTH from './auth';
import TEAM from './team';
import EVENT from './event';
import MY_ACCOUNT from './myAccount';
import SUPPORT from './support';
import PWA from './pwa';

const DICTIONARY = {
  GENERAL: {
    sidebar: {
      dashboard: 'Home',
      myTeams: 'My teams',
      myEvents: 'My events',
    },
    button: {
      submit: 'Submit',
      cancel: 'Cancel',
      delete: 'Delete',
      remove: 'Remove',
    },
    appHeader: {
      myAccount: 'My account',
      contactUs: 'Contact us',
      logout: 'Logout',
    },
    banners: {
      requestMessagingPermission: {
        title: 'Hi there and welcome! ',
        subtitle: 'We would like to send you notifications when a new spot becomes available for your events.',
        info: 'Check your mobile for the notification approval from Team UP',
        action: {
          approve: 'Great!',
          reject: 'Never'
        },
      },
    },
    form: {
      badlyFormattedEmail: 'The email address is badly formatted.',
    },
    imageUploadInstructions: '(Supports: JPG, PNG. 1 MB max.)',
  },
  AUTH, // Login and Signup pages
  MY_ACCOUNT,
  SUPPORT,
  TEAM,
  EVENT,
  PWA,
};

export default DICTIONARY;
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DICTIONARY from '../../utils/dictionary';
import { EVENT_GROUPS_COLORS } from '../../utils/consts';

// App Components
import EmptyState from '../shared/EmptyState';
import ComponentLoader from '../shared/loader/ComponentLoader';

// Material UI Components
import { DataGrid } from '@material-ui/data-grid';
import { useMediaQuery, useTheme } from '@material-ui/core';

// Icons
import {
  ListRounded as ListRoundedIcon,
} from '@material-ui/icons';
import ShirtIcon from '../icon/ShirtIcon';

// Colors
import { blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    '& .last-cloumn-header': {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      }
    },
    '& .rendering-zone': {
      width: '100% !important',
    },
    '& .MuiDataGrid-row': {
      width: '100% !important',
    },
    '& .MuiDataGrid-colCell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-colCell': {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 0, 0, 1),
      },
    },
    '& .MuiDataGrid-cell': {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 1),
      },
    },
    '& .MuiDataGrid-columnHeader': {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 0, 0, 0.5),
      },
    },
  },
  shirtIconGroup: {
    marginRight: theme.spacing(0.5),
    width: '24px',
    height: '19px',
    stroke: '#313131', strokeWidth: '2px', paintOrder: 'stroke', strokeLinejoin: 'round',
  },
}));

export default function GroupsStatsTable({ isLoading, isError, groupsStats }) {
  const classes = useStyles();
  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // #region DataGrid init
  const [pageSize, setPageSize] = useState(15);
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };
  // #endregion

  const columns = [
    { 
      field: 'id', 
      headerName: DICTIONARY.EVENT.event.stats.playersTable.head.group, 
      type: 'string', 
      flex: 1,
      renderCell: (params) => {
        const { id: group } = params.row;
        const wrapperStyle = {
          display: 'flex',
          alignItems: 'center',
          marginLeft: '4px',
          width: '100%',
        };
        let element = <span>-</span>;

        if(group) {
          element = isMobile 
          ? <span><ShirtIcon style={{ color: EVENT_GROUPS_COLORS[group].hex }} className={classes.shirtIconGroup} /></span>
          : <span style={wrapperStyle}><ShirtIcon style={{ color: EVENT_GROUPS_COLORS[group].hex }} className={classes.shirtIconGroup} /> {group}</span>
        }

        return (
          <div style={wrapperStyle}>
            {element}
          </div>
        )
      },
      sortComparator: (v1, v2, param1, param2) => {
        try{
          const param1Row = param1.api.getRow(param1.id);
          const param2Row = param2.api.getRow(param2.id);
          const diff = param1Row.id - param2Row.id ? 1 : -1;
          return diff;
        } catch(e) {
          return true;
        }
      },
    },
    { field: 'matchesPlayed', headerName: DICTIONARY.EVENT.event.stats.groupsTable.head.matchesPlayed, type: 'string', flex: 1 },
    { field: 'wins', headerName: DICTIONARY.EVENT.event.stats.groupsTable.head.wins, type: 'string', flex: 1 },
    { 
      field: 'ratio', 
      headerName: DICTIONARY.EVENT.event.stats.groupsTable.head.ratio, 
      type: 'string', 
      flex: 1,
      headerClassName: 'last-cloumn-header',
      sortComparator: (v1, v2, param1, param2) => {
        try{
          const param1Row = param1.api.getRow(param1.id);
          const param2Row = param2.api.getRow(param2.id);
          const diff = Number(param1Row.rawRatio) > Number(param2Row.rawRatio) ? 1 : -1;
          return diff;
        } catch(e) {
          return true;
        }
      }, 
    },
  ];

  return (
    <React.Fragment>
      {isLoading
        ? <ComponentLoader wrapperHeight={300}/>
        : (groupsStats.length === 0 || isError)
          ? <EmptyState 
              icon={<ListRoundedIcon style={{ fontSize: 80, color: blueGrey[500] }}/>}
              title={DICTIONARY.EVENT.event.stats.playersTable.emptyState}
              padding={5}
            />
          : <DataGrid
              rows={groupsStats}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={handlePageSizeChange}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
              disableSelectionOnClick
              autoHeight
              autoPageSize
              className={classes.root}
              loading={isLoading}
              disableColumnFilter
              disableColumnMenu
              sortingOrder={['desc', 'asc']}
            />
        }
    </React.Fragment>
  );
}
import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext, SnackbarContext } from '../../../context';
import DICTIONARY from '../../../utils/dictionary';
import { userApi } from '../../../api';
import useIsMount from '../../../hooks/isMountHook';
import { SNACKBAR_SEVERITY } from '../../../utils/consts';

// Material UI Components
import { Switch, FormControlLabel, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	loader: {
		position: 'relative',
		top: '3px',
	},
}));

export default function EmailNotificationsSettings() {
  const { user } = useContext(AuthContext);
	const { handleSnackbarDisplay } = useContext(SnackbarContext);
  const classes = useStyles();
	const isMount = useIsMount();

	const [emailPermissionStatus, setEmailPermissionStatus] = useState(user.userData.enableEmailNotifications || false);
	const [isLoading, setIsLoading] = useState(false);

	const handleEmailStateChange = async (event) => {
		setIsLoading(true);
  };

	useEffect(() => {
		if(!isMount) {
			(async () => {
				const response = await userApi.updateEmailNotificationPermission(user.userAuth.uid, !emailPermissionStatus);

				if(!response.hasOwnProperty("error")){
					setIsLoading(false);
					setEmailPermissionStatus(!emailPermissionStatus);
				} else {
					handleSnackbarDisplay({
						severity: SNACKBAR_SEVERITY.error,
						message: DICTIONARY.MY_ACCOUNT.notificationsSettings.email.error,
					});
					setIsLoading(false);
				}
			})();
		}
	}, [isLoading])

  return (
    <React.Fragment>
			{/* Email Toggle */}
			<FormControlLabel
				control={
					<Switch 
						checked={emailPermissionStatus} 
						color="primary"
						onChange={handleEmailStateChange} 
						name="email" 
					/>
				}
				label={DICTIONARY.MY_ACCOUNT.notificationsSettings.email.title}
				labelPlacement="end"
			/>
			{isLoading && <CircularProgress size={20} className={classes.loader}/> }
    </React.Fragment>
  );
}
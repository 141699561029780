import React from "react";
import { makeStyles } from '@material-ui/core/styles';

// Colors
import { blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  squircleShape: {
    position: 'absolute',
    width: '0',
    height: '0',
  },
}));

export default function Squircle({ src, alt, className, style, shouldShowBorder = false, borderStyle = {} }) {
	const classes = useStyles();
  const random = Math.random();

  const showFallbackImage = (e) => {
    e.target.src = 'https://firebasestorage.googleapis.com/v0/b/team-up-6dbad.appspot.com/o/images%2Fplaceholders%2Fteam.jpeg?alt=media&token=de72a003-9efb-48bd-86f4-760c39f7b954';
  };

  const getWrapperClass = (clipPath) => {
    let retVal = {
      display: 'flex',
    };

    if(style && shouldShowBorder) {
      let {width, height} = style;
      let w = Number(width.replace('px', ''));
      let h = Number(height.replace('px', ''));
      retVal = {
        display: 'flex',
        clipPath,
        padding: '1px',
        width: `${w + 2}px`,
        height: `${h + 2}px`,
        backgroundColor: borderStyle.color || blueGrey[100],
      }
    } 

    return retVal;
  }

  return (
    <React.Fragment>
      <svg className={classes.squircleShape}>
        <defs>
          <clipPath id={`squircle-${random}`} clipPathUnits="objectBoundingBox">
            <path d="M .5 0 C .1 0 0 .1 0 .5 0 .9 .1 1 .5 1 .9 1 1 .9 1 .5 1 .1 .9 0 .5 0 Z" />
          </clipPath>
        </defs>
      </svg>
      <div style={getWrapperClass(`url(#squircle-${random})`)}>
        <img 
          src={src} 
          alt={alt}
          onError={showFallbackImage}
          className={className} 
          style={{...style, clipPath: `url(#squircle-${random})`}}
        />
      </div>
    </React.Fragment>
  );
}
const ROUTES = {
  root: {
    path: '/',
  },
  login: {
    path: '/login'
  },
  signup: {
    path: '/signup'
  },
  myAccount: {
    path: '/my-account'
  },
  myTeams: {
    path: '/my-teams',
    team: {
      path: '/my-teams/:teamId'
    }
  },
  myEvents: {
    path: '/my-events',
    event: {
      path: '/my-events/:eventId',
    }
  }
};

export default ROUTES;
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DICTIONARY from '../../../utils/dictionary';
import { eventApi } from '../../../api/index';
import { formatDate, containsHebrew } from '../../../utils/utils';

// App Components
import RegistrationRecordsTable from './logs/RegistrationRecordsTable';
import SwapsTable from './logs/SwapsTable';
import EmptyState from '../../shared/EmptyState';
import ComponentLoader from '../../shared/loader/ComponentLoader';

// Material UI Components
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

// Icons
import {
  ListRounded as ListRoundedIcon,
  FormatListNumbered as FormatListNumberedIcon,
  SwapVert as SwapVertIcon,
} from '@material-ui/icons';

// Colors
import { blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  cardActions: {
    margin: 0,
  },
  toggleButton: {
    padding: theme.spacing(0.5),
  },
  shirtIconToggleButton: {
    width: '24px',
    height: '17px',
  },
  cardContent: {
    padding: theme.spacing(0, 2),
  },
}));

const CARD_CONTENT = {
  registrations: {
    label: DICTIONARY.EVENT.event.participantsLogsCard.registrations,
    value: 'registrations',
  },
  swaps: {
    label: DICTIONARY.EVENT.event.participantsLogsCard.swaps,
    value: 'swaps',
  },
};

export default function ParticipantsLogsCard({ eventData }) {
  const classes = useStyles();
  const [activeCardContent, setActiveCardContent] = useState(CARD_CONTENT.registrations);

  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // #region Get logs data
  useEffect(() => {
    let isSubscribed = true; // is subscribed to useEffect
    
    (async () => {  
      try { 
        setIsLoading(true);
        const response = await eventApi.logs.get(eventData.id);

        if(isSubscribed) {
          if(!response.hasOwnProperty("error")){
            // Sort by date
            const logs = response.data;
            let sortedLogs = logs ? logs.slice().sort((a, b) => {              
              if (b.timestamp.toDate() > a.timestamp.toDate()) return 1;
              if (b.timestamp.toDate() < a.timestamp.toDate()) return -1;

              // always make the 'action = Unregister' log to be before the log of the one who moved to participants
              if (a.reason === 'Moved from waiting list to participants') return -1; 
            }) : [];
            sortedLogs = sortedLogs.map((log, idx) => {
              return {
                id: idx,
                timestamp: log.timestamp.toDate(),
                date: formatDate(log.timestamp, 'MMM dd, hh:mm:ss a'),
                name: log.name,
                avatar: log.avatar,
                action: log.action,
                reason: log.reason,
                sign: log.action.includes('Unregister') ? 'out' : 'in', // in = registered to participants / waitingList
              }
            });

            setIsLoading(false);
            setLogs(sortedLogs);
          } else {
            setIsLoading(false);
            setIsError(true);
          }
        }
      } catch (e) {
        setIsLoading(false);
        setIsError(true);
      }
    })();

    return () => {
      isSubscribed = false;
    };
  }, [eventData]);
  // #endregion

  const handleChangeCardContent = (event, view) => {
    if(view != null) {
      setActiveCardContent(view);
    }
  };

  const TOGGLE_ACTIONS = (
    <ToggleButtonGroup
      value={activeCardContent}
      exclusive
      size="small"
      onChange={handleChangeCardContent}
      aria-label="diaply mode"
    >
      <ToggleButton 
        value={CARD_CONTENT.registrations} 
        aria-label={CARD_CONTENT.registrations.label}
        classes={{'sizeSmall': classes.toggleButton}}  
      >
        <FormatListNumberedIcon />
      </ToggleButton>
      <ToggleButton 
        value={CARD_CONTENT.swaps} 
        aria-label={CARD_CONTENT.swaps.label}
        classes={{'sizeSmall': classes.toggleButton}}    
      >
        <SwapVertIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );

  return (
    <Card elevation={0}>
      <CardHeader
        titleTypographyProps={{ variant:'h6' }}
        title={activeCardContent.value === CARD_CONTENT.registrations.value ? CARD_CONTENT.registrations.label : CARD_CONTENT.swaps.label}
        classes={{
          'root': classes.cardHeader,
          'action': classes.cardActions,
        }}
        action={TOGGLE_ACTIONS}
      />
      <CardContent classes={{ 'root': classes.cardContent }}>
        {isLoading
          ? <ComponentLoader wrapperHeight={180}/>
          : (logs.length === 0 || isError)
            ? <EmptyState 
                icon={<ListRoundedIcon style={{ fontSize: 80, color: blueGrey[500] }}/>}
                title={DICTIONARY.EVENT.event.participantsLogTable.emptyState}
                padding={5}
              />
            : <div style={{height: 'auto',}}>
                {activeCardContent.value === CARD_CONTENT.registrations.value 
                  ? <RegistrationRecordsTable 
                      logs={logs}
                    />
                  : <SwapsTable 
                      logs={logs}
                    />
                }
            </div>
        }
      </CardContent>
    </Card>
  );
}
const PWA = {
  saveAsPwaExplenationModal: {
    title: 'Save Team UP as a mobile app',
    subtitle: '*The images above are for demonstration; Steps may differ depending on the mobile browser you use (Chrome, Samsung Internet, etc.)',
    info: 'The process may be vary between difrent browsers (chrome, samsung intenrnt, etc)', // TODO
    steps: {
      one: `On your mobile's browser go to "Settings"`,
      two: `Choose "Add to home screen"`,
      three: `Tap "Add"`,
      four: `Done! You should now see "Team Up" on your home screen like any other app`
    }
  }
};

export default PWA;
import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../../context';
import { adminApi } from '../../../api';
import { makeStyles } from '@material-ui/core/styles';
import { getTeamsIdsOfAdmin, isVerbose } from '../../../utils/utils';
import { MAX_EVENT_PARTICIPANTS_LIMIT, EVENT_GROUPS_COLORS, MAX_EVENT_GROUPS_NUMBER_LIMIT } from '../../../utils/consts';
import { Timestamp } from "../../../config/firebase";
import DICTIONARY from '../../../utils/dictionary';
import VALIDATORS from '../../../utils/validators';
import useForm from '../../../hooks/formHook';

// Material UI Components
import { Grid, TextField, Box, useMediaQuery, useTheme } from '@material-ui/core';

// Select input
import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';

// Checkbox input
import { FormControlLabel, Checkbox } from '@material-ui/core';

// Time input
import {getDay, addDays, addHours, getTime, getHours, setHours, getMinutes, setMinutes } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

// Colors
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	formControl: {
    marginTop: theme.spacing(2),
  },
	eventGroupsColorBox: {
		display: 'inline-block',
		height: '16px',
		width: '16px',
		borderRadius: '10px',
		border: `1px solid ${grey[700]}`,
		marginRight: '6px',
	},
}));

export default function CreateEventDialog({ handleCloseDialog, teamId = undefined, eventData = {}, eventId, isEditMode = false }) {
	const { user } = useContext(AuthContext);
	const locationRouter = useLocation();
  const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [isTeamKnown, setIsTeamKnown] = useState(false); // Team is known when: 1. Coming from Team page or 2. When editing event
	
	useEffect(() => {
		setIsTeamKnown(teamId !== undefined);
	}, [teamId])

	//#region Form Hook
	const initalFormStateTemplate = useMemo(() => ({
		teamId: {
			label: DICTIONARY.EVENT.createEvent.form.field.teamId,
			value: '',
			isValid: true,
			required: true,
			validators: [],
		},
		location: {
			label: DICTIONARY.EVENT.createEvent.form.field.location,
			value: eventData?.location || '',
			isValid: true,
			required: true,
			validators: [
				{
					validate: (val) => VALIDATORS.isValidString(val, 2),
					errorMessage: DICTIONARY.EVENT.createEvent.form.error.locationLength,
				},
			],
		},
		maxEventParticipants: {
			label: DICTIONARY.EVENT.createEvent.form.field.maxEventParticipants,
			value: eventData?.maxEventParticipants || '',
			isValid: true,
			required: true,
			validators: [
				{
					validate: (val) => VALIDATORS.isInRange(val, 1, MAX_EVENT_PARTICIPANTS_LIMIT),
					errorMessage: `${DICTIONARY.TEAM.createTeam.form.error.maxEventParticipantsRange} ${MAX_EVENT_PARTICIPANTS_LIMIT}`,
				},
				{
					validate: (val) => {
						// Edit mode - Dont allow setting maxParticipants to be smaller then the current participant amount
						if(!isEditMode) {
							return true;
						} else {
							if(val < eventData.currentParticipantsLength) {
								return false;
							} else {
								return true;
							}
						}
					},
					errorMessage: `${DICTIONARY.EVENT.createEvent.form.error.maxParticipantTooSmall.part1} ${eventData.currentParticipantsLength} ${DICTIONARY.EVENT.createEvent.form.error.maxParticipantTooSmall.part2}. ${DICTIONARY.EVENT.createEvent.form.error.maxParticipantTooSmall.part3}`,
				},
			],
		},
		eventGroups: {
			label: DICTIONARY.EVENT.createEvent.form.field.eventGroups.label,
			info: DICTIONARY.EVENT.createEvent.form.field.eventGroups.info,
			value: eventData?.eventGroups || [],
			isValid: true,
			required: true,
			validators: [
				{
					validate: (val) => VALIDATORS.isInRange(val.length, 1, MAX_EVENT_GROUPS_NUMBER_LIMIT),
					errorMessage: `${DICTIONARY.TEAM.createTeam.form.error.maxEventGroups} ${MAX_EVENT_GROUPS_NUMBER_LIMIT}`,
				},
			],
		},
		eventDateAndTime: {
			label: DICTIONARY.EVENT.createEvent.form.field.eventDateAndTime,
			value: new Date(),
			isValid: true,
			required: true,
			validators: [
				{
					validate: (value) => VALIDATORS.isValidDate(value),
					errorMessage: DICTIONARY.EVENT.createEvent.form.error.dateInvalid,
				},
			],
		},
		isOpenForRegistration: {
			label: DICTIONARY.EVENT.createEvent.form.field.isOpenForRegistration,
			value: true,
			isValid: true,
			required: true,
			validators: [],
		},
		openRegistrationDateAndTime: {
			label: DICTIONARY.EVENT.createEvent.form.field.openRegistrationDateAndTime,
			value: addHours(new Date(), 1),
			isValid: true,
			required: true,
			validators: [
				{
					validate: (value) => VALIDATORS.isValidDate(value),
					errorMessage: DICTIONARY.EVENT.createEvent.form.error.dateInvalid,
				},
				{
					validate: (value, formState) => {
						if(formState.isOpenForRegistration.value) { // if event is open for registration - ignore this validation check
							return true;
						}

						const eventDateAndTime = formState.eventDateAndTime.value;
						if(value < eventDateAndTime) {
							return true;
						}
						return false;
					},
					errorMessage: DICTIONARY.EVENT.createEvent.form.error.openRegistrationDateBeforeEventDate,
				},
			],
		},
	}), []);

	const [teamListData, setTeamListData] = useState([]);
	const [initalFormState, setInitalFormState] = useState(initalFormStateTemplate);

	const setDefaultTeam = useCallback((teamId) => {
		try {
			if(!isEditMode) {
				// Take only the teams that the user is admin
				const teamsData = user?.teamsData || [];
				const userId = user.userAuth.uid;
				const teamsIdsOfAdmin = getTeamsIdsOfAdmin(userId, teamsData);
				const adminTeamsData = {};

				if(teamId) { // if we already know the teamId (coming from Team page) --> its like the admin has only 1 team
					adminTeamsData[teamId] = teamsData[teamId];
				} else { // regular create event (from UpcomingEvents component or My-events page)
					teamsIdsOfAdmin.forEach(adminTeamId => adminTeamsData[adminTeamId] = teamsData[adminTeamId]);
				}
				
				setTeamListData(adminTeamsData);

				if (Object.keys(adminTeamsData).length > 0) {
					const selectedTeamId = Object.keys(adminTeamsData)[0]; // TODO: take team with the most relevancy.. needs to add logic here (like most active team)
					const initalFormState = {...initalFormStateTemplate};
					initalFormState.teamId.value = selectedTeamId;
					initalFormState.location.value = adminTeamsData[selectedTeamId].defaultEvents[0].defaultLocation;
					initalFormState.maxEventParticipants.value = adminTeamsData[selectedTeamId].defaultEvents[0].maxEventParticipants;
					initalFormState.eventGroups.value = adminTeamsData[selectedTeamId].defaultEvents[0].eventGroups || [];
					initalFormState.eventDateAndTime.value = getEventDate(adminTeamsData, selectedTeamId);

					setInitalFormState(initalFormState);
				}
			} else {
				const initalFormState = {...initalFormStateTemplate};
				initalFormState.teamId.value = teamId;
				setInitalFormState(initalFormState);
			}

		} catch(e) {
			isVerbose() && console.log("Error getting document:", e);
		}
	}, [user, initalFormStateTemplate]);

	useEffect(() => {
		setDefaultTeam(teamId);
	}, [setDefaultTeam]);

	const onSubmit = async () => {
		let eventData = {};
		let promise;

		// Create event
		if(!isEditMode) {
			eventData = {
				teamId: formState.teamId.value,
				location: formState.location.value,
				maxEventParticipants: formState.maxEventParticipants.value,
				eventGroups: formState.eventGroups.value,
				date: Timestamp.fromDate(formState.eventDateAndTime.value),
				isOpenForRegistration: formState.isOpenForRegistration.value,
				...(formState.isOpenForRegistration.value === false && {openRegistrationDateAndTime: Timestamp.fromDate(formState.openRegistrationDateAndTime.value)})
			};
			promise = adminApi.event.create(eventData);
		} else { // Edit event
			eventData = {
				location: formState.location.value,
				maxEventParticipants: formState.maxEventParticipants.value.toString(),
				eventGroups: formState.eventGroups.value,
			};

			promise = adminApi.event.edit(eventId, teamId, eventData);
		};

		const response = await promise;

		if (response.hasOwnProperty("error")) {
			if(!isEditMode) {
				throw new Error(DICTIONARY.EVENT.createEvent.form.error.generalError);
			} else {
				throw new Error(DICTIONARY.EVENT.createEvent.form.error.generalErrorEdit);
			}
		}

		return response;
	};

	const onCloseModal = (isSubmittedSuccessfully, response) => {
		let shouldIgnoreHistoryPushBack;

		// Creating event from a team page or from my-events page --> no need to push back after modal is closed, but go to the route created event
		if(locationRouter.pathname.includes('/my-teams/') || locationRouter.pathname.includes('/my-events/create-event')) { 
			shouldIgnoreHistoryPushBack = true;
		}
		
    handleCloseDialog({isSubmittedSuccessfully, response, shouldIgnoreHistoryPushBack});
  };

	const onCustomInputChange = (key, val) => {
		switch(key) {
			case 'teamId': {
				const updatedFormState = {...formState};
				updatedFormState.location.value = teamListData[val].defaultEvents[0].defaultLocation;
				updatedFormState.maxEventParticipants.value = teamListData[val].defaultEvents[0].maxEventParticipants;
				updatedFormState.eventGroups.value = teamListData[val].defaultEvents[0].eventGroups;
				updatedFormState.eventDateAndTime.value = getEventDate(teamListData, val);
				setInitalFormState(updatedFormState);
				break;
			}
			case 'isOpenForRegistration': {
				if(val === true) { // init the value of 'openRegistrationDateAndTime'
					const updatedFormState = {...formState};
					updatedFormState.openRegistrationDateAndTime.value = new Date();
					setInitalFormState(updatedFormState);
				}
				break;
			}
			default: {
				break;
			}
		}
	};

	const {formState, formError, isLoading, handleInputChange, handleSubmit, handleCloseModal} = useForm({
		initalFormState, 
		onSubmit, 
		onCloseModal,
		onCustomInputChange,
	});
	// #endregion
	
	// #region Helper Functions
	const getEventDate = (teamsData, teamId) => {
		// deaultTime contains the correct event time, 
		// and we need to modify the eventDate according to 'defaultDay'
		const defaultDay = Number(teamsData[teamId].defaultEvents[0].defaultDay);
		const eventDeaultTime = teamsData[teamId].defaultEvents[0].defaultTime.toDate();
		const eventHour = getHours(eventDeaultTime);
		const eventMinutes = getMinutes(eventDeaultTime);

		const today = getDay(new Date()); // Sunday = 0, ... , Saturday = 6
		const now = getTime(new Date());
		const nowHour = getHours(now);
		const nowMinutes = getMinutes(now);

		let daysToAdd;
		if (today === defaultDay && ((nowHour < eventHour) || (nowHour === eventHour && nowMinutes < eventMinutes))) {
			daysToAdd = defaultDay - today;
		} else if (today > defaultDay) {
			daysToAdd = 7 - (today - defaultDay);
		} else if(today < defaultDay) {
			daysToAdd = defaultDay - today;
		} else {
			daysToAdd = 7;
		}

		// Set the date of the event
		let eventDate = addDays(new Date(), daysToAdd);

		// Set the time of the event
		eventDate = setHours(eventDate, eventHour);
		eventDate = setMinutes(eventDate, eventMinutes);

		return eventDate;
	}
	//#endregion

	const dialogContent = (
		<React.Fragment>
			<Grid container>
				<Grid item xs={12}>

					{/* Choose Team from list */}
					{!isTeamKnown && (
						<FormControl 
							className={classes.formControl} 
							required 
							fullWidth
							error={!formState.teamId.isValid}
							margin='normal'
						>
							<InputLabel id="teamId">{formState.teamId.label}</InputLabel>
							<Select
								labelId="teamId"
								id='teamId'
								label={formState.teamId.label}
								name='teamId'
								value={formState.teamId.value}
								onChange={(e) => handleInputChange(e, {type: 'select', name: 'teamId'})}
							>
								{Object.keys(teamListData).length && Object.keys(teamListData).map((id) => (
									<MenuItem key={id} value={id}>
										{teamListData[id].teamName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={isMobile ? 0 : 3}>
				{/* Location */}
				<Grid item md={4} xs={12}>
					<TextField
						margin='normal'
						required
						fullWidth
						id='location'
						label={formState.location.label}
						name='location'
						value={formState.location.value}
						onChange={handleInputChange}
						autoComplete='location'
						error={!formState.location.isValid}
					/>
				</Grid>
				
				{/* Max Event Participants Field Field */}
				<Grid item md={4} xs={12}>
					<TextField
						margin='normal'
						required
						fullWidth
						type="number"
						InputProps={{
							inputProps: { 
								max: MAX_EVENT_PARTICIPANTS_LIMIT, // no special reason to restrict to max 25 --> buisness wise - maybe it can be the limit for freemium users(teams)
								min: 0 
							}
						}}
						id='maxEventParticipants'
						label={formState.maxEventParticipants.label}
						name='maxEventParticipants'
						value={formState.maxEventParticipants.value}
						onChange={handleInputChange}
						autoComplete='maxEventParticipants'
						error={!formState.maxEventParticipants.isValid}
					/>
				</Grid>
			
				{/* Groups colors */}
				<Grid item md={4} xs={12}>
					<FormControl 
						className={classes.formControl} 
						margin='normal'
						required 
						fullWidth 
						error={!formState.eventGroups.isValid}
					>
						<InputLabel id="eventGroups">{formState.eventGroups.label}</InputLabel>
						<Select
							labelId="eventGroups"
							id='eventGroups'
							multiple
							classes={{'icon': classes.eventGroupsSelectIcon}}
							label={formState.eventGroups.label}
							name='eventGroups'
							value={formState.eventGroups.value}
							renderValue={(selected) => {
								let retVal = selected.map((color) => {
									return (<div key={EVENT_GROUPS_COLORS[color].value} className={classes.eventGroupsColorBox} style={{backgroundColor: EVENT_GROUPS_COLORS[color].hex}} />)
								});
								return retVal;
							}}
							onChange={(e) => handleInputChange(e, {type: 'select', name: 'eventGroups'})}
							required
						>
							{Object.keys(EVENT_GROUPS_COLORS).map(color => (
								<MenuItem 
									value={EVENT_GROUPS_COLORS[color].value} 
									key={EVENT_GROUPS_COLORS[color].value}
								>
									<Checkbox 
										classes={{'root': classes.groupColorCheckbox}}
										checked={formState.eventGroups.value.indexOf(color) > -1} 
									/>
									<div className={classes.eventGroupsColorBox} style={{backgroundColor: EVENT_GROUPS_COLORS[color].hex}} />
									{EVENT_GROUPS_COLORS[color].label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			</Grid>

			{/* Dates details - Show only for Create event mode */}
			{!isEditMode && (
				<div>
					{/* Date and Time */}
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDateTimePicker
							value={formState.eventDateAndTime.value}
							onChange={(e) => handleInputChange(e, {type: 'date', name: 'eventDateAndTime'})}
							label={formState.eventDateAndTime.label}
							onError={console.log}
							minDate={new Date()}
							format="EEEE, MMM dd, yyyy, hh:mm a"
							required
							fullWidth
							className={classes.formControl}
							error={!formState.eventDateAndTime.isValid}
						/>
					</MuiPickersUtilsProvider>

					{/* Is open to register */}
					<Box mt={1} display="flex" style={{height: '64px'}}>
						<Box flexGrow={1}>
							<FormControlLabel
								control={<Checkbox 
									checked={formState.isOpenForRegistration.value} 
									onChange={(e) => handleInputChange(e, {type: 'checkbox', name: 'isOpenForRegistration'})}
									name="isOpenForRegistration"
									color="primary"
								/>}
								required
								label={formState.isOpenForRegistration.label}
								className={classes.formControl}
							/>
						</Box>

						{!formState.isOpenForRegistration.value && (
							<Box flexGrow={1}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDateTimePicker
										value={formState.openRegistrationDateAndTime.value}
										onChange={(e) => handleInputChange(e, {type: 'date', name: 'openRegistrationDateAndTime'})}
										label={formState.openRegistrationDateAndTime.label}
										onError={console.log}
										minDate={new Date()}
										format="EEEE, MMM dd, yyyy, hh:mm a"
										required
										fullWidth
										className={classes.formControl}
										error={!formState.openRegistrationDateAndTime.isValid}
									/>
								</MuiPickersUtilsProvider>
							</Box>
						)}
					</Box>
				</div>
			)}
		</React.Fragment>
	);

	return {
		dialogTitle: !isEditMode ? DICTIONARY.EVENT.createEvent.title.create : DICTIONARY.EVENT.createEvent.title.edit,
		dialogContent,
		sumbitButtonLabel: DICTIONARY.GENERAL.button.submit,
		handleSubmit,
		handleCloseModal,
		formError,
		isLoading,
	};
}
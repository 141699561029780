import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context';
import { makeStyles } from '@material-ui/core/styles';
import { convertNumberToDay, formatDate, getActiveAdminsAndMembers } from '../../utils/utils';
import ROUTES from '../../routes';
import DICTIONARY from '../../utils/dictionary';

// Material UI Components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// Material UI Components - List
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider, useMediaQuery, useTheme } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

// App Components
import EmptyState from '../shared/EmptyState';

// Icons
import { 
	Security as SecurityIcon 
} from '@material-ui/icons';

// Colors
import {blueGrey} from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	root: {},
	cardContent: {
		'&:last-child': {
			padding: theme.spacing(1, 2),
		}
	},
	listItem: {
		maxWidth: '250px',
	},
	user: {
		borderRadius: '10px',
	},
	teamContainer: {
		padding: theme.spacing(1, 0),
		[theme.breakpoints.up("sm")]: {
			padding: theme.spacing(1, 1),
    }
	}
}));

export default function TeamList() {
	const classes = useStyles();
	const { user } = useContext(AuthContext);
	const history = useHistory();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [teamsData, setTeamsData] = useState({});

	useEffect(() => {
		try{
			const teamsData = user.teamsData || [];

			for(const teamId in teamsData) {
				const teamData = teamsData[teamId];
				const activeMembers = getActiveAdminsAndMembers(teamData.members);
				teamData.users = activeMembers;
			}
			
			setTeamsData(teamsData);
		} catch(e) {
			console.log("Error getting teamsData:", e);
		}
	}, [user.teamsData]);

	const onTeamClicked = (teamId) => {
		history.push({
			pathname: `${ROUTES.myTeams.path}/${teamId}`,
			search: '',
		});
	}
	
  return (
    <Card className={classes.root} elevation={0}>
			<CardContent classes={{'root': classes.cardContent}}>
					{Object.keys(teamsData).length > 0 
						? <List component="nav" aria-label="main mailbox folders">
							{Object.keys(teamsData).map((teamId, index) => (
								<React.Fragment key={teamId} >
									<ListItem 
										button
										onClick={() => onTeamClicked(teamId)}
										classes={{'root': classes.teamContainer}}
									>
										<ListItemAvatar>
											<Avatar variant="rounded" alt={teamsData[teamId].teamName} src={teamsData[teamId].avatar} />
										</ListItemAvatar>

										<ListItemText 
											classes={{'root': classes['listItem'],}}
											primary={teamsData[teamId].teamName} 
											secondary={`${teamsData[teamId].defaultEvents[0]?.defaultLocation} | ${convertNumberToDay(teamsData[teamId].defaultEvents[0]?.defaultDay)} | ${formatDate(teamsData[teamId].defaultEvents[0]?.defaultTime, 'hh:mm a')}`}
										/>

										{!isMobile && <AvatarGroup max={10}>
											{Object.keys(teamsData[teamId].users).length > 0 && Object.keys(teamsData[teamId].users).map((userId) => (
												<Avatar 
													key={userId} 
													alt={teamsData[teamId].users[userId].name} 
													src={teamsData[teamId].users[userId].avatar} 
													variant="rounded"
													classes={{'rounded': classes.user}}
												/>
											))}
										</AvatarGroup>}

										{/* <ListItemSecondaryAction>
											<IconButton edge="end" aria-label="comments">
												<MoreVertIcon />
											</IconButton>
										</ListItemSecondaryAction> */}
									</ListItem>
									{index < Object.keys(teamsData).length - 1 && <Divider variant="inset" component="li" />}
								</React.Fragment>
							))}
						</List>
						: <EmptyState 
								icon={<SecurityIcon style={{ fontSize: 80, color: blueGrey[500] }}/>}
								title={DICTIONARY.TEAM.teamListDashboard.emptyState.title}
								subtitle={DICTIONARY.TEAM.teamListDashboard.emptyState.subtitle}
							/>
					}
				</CardContent>
			
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}
export const APP_NAME = 'Team UP';

export const MAX_EVENT_PARTICIPANTS_LIMIT = 25;

export const MAX_EVENT_GROUPS_NUMBER_LIMIT = 4;

export const WEEK_DAYS = {
  sunday: {
    label: 'Sunday',
    value: '0'
  },
  monday: {
    label: 'Monday',
    value: '1'
  },
  tuesday: {
    label: 'Tuesday',
    value: '2'
  },
  wednesday: {
    label: 'Wednesday',
    value: '3'
  },
  thursday: {
    label: 'Thursday',
    value: '4'
  },
  friday: {
    label: 'Friday',
    value: '5'
  },
  saturday: {
    label: 'Saturday',
    value: '6'
  },
};

export const EVENT_GROUPS_COLORS = {
  green: {
    label: 'Green',
    value: 'green',
    hex: '#64dd17',
  },
  orange: {
    label: 'Orange',
    value: 'orange',
    hex: '#ffab40',
  },
  blue: {
    label: 'Blue',
    value: 'blue',
    hex: '#00b0ff',
  },
  yellow: {
    label: 'Yellow',
    value: 'yellow',
    hex: '#ffeb3b',
  },
  black: {
    label: 'Black',
    value: 'black',
    hex: '#212121',
  },
  white: {
    label: 'White',
    value: 'white',
    hex: '#fff',
  },
  other: {
    label: 'Other',
    value: 'other',
    hex: '#90a4ae',
  },
};

export const SUPPORT_SUBJECTS = {
  auth: {
    label: 'Authentication',
    value: 'auth'
  },
  myAccount: {
    label: 'My account',
    value: 'myAccount'
  },
  team: {
    label: 'Team',
    value: 'team'
  },
  event: {
    label: 'Event',
    value: 'event'
  },
  other: {
    label: 'Other',
    value: 'other'
  },
};

export const IMAGE_PLACEHOLDER = 'https://firebasestorage.googleapis.com/v0/b/team-up-6dbad.appspot.com/o/images%2Fplaceholders%2Fteam.jpeg?alt=media&token=de72a003-9efb-48bd-86f4-760c39f7b954';
// export const RESIZED_IMAGE_SUFFIX = '_400x400';

export const SNACKBAR_SEVERITY = {
  error: 'error',
  warning: 'warning', 
  info: 'info', 
  success: 'success'
};

export const COUNTRY_CALLING_CODES = {
  usa: {
    code: 'US', 
    label: 'United States', 
    value: '1'
  },
  israel: {
    code: 'IL', 
    label: 'Israel', 
    value: '972'
  },
};
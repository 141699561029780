import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// App Components
import HeaderActions from '../shared/button/HeaderActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    }
  },
  title: {
  },
  subTitle: {
  },
  backButton: {
    marginLeft: `-8px`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: `-16px`,
    },
  }
}));

export default function PageHeader({ title, subTitle = '', actions, handleBackClick }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={2} justifyContent="space-between" alignItems="flex-start">
      <Grid item xs={11} sm={7} container justifyContent="flex-start" alignItems="center">
        {/* Back button */}
        {handleBackClick && <IconButton onClick={handleBackClick} className={classes.backButton} size="small" style={{ backgroundColor: 'transparent' }}>
          <ChevronLeftIcon />
        </IconButton>
        }
        
        {/* Page title */}
        <Grid item xs={9} sm={8} container direction="column" justifyContent="flex-start" alignItems="flex-start">
          <Typography variant="h6" component="h1" className={classes.title}>{title}</Typography>
          <Typography variant='body2' component="h2" className={classes.subTitle}>{subTitle}</Typography>
        </Grid>
      </Grid>

      {/* Actions */}
      {actions && <Grid container item xs={1} sm={5} justifyContent="flex-end">
        <HeaderActions 
          actions={actions} 
        />
      </Grid>
      }
    </Grid>
  );
}
import React, { useState, useEffect, useContext } from "react";
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext, DialogContext } from '../../context';
import { getTeamsIdsOfAdmin, isVerbose, formatDate, isTimestampFromThePast, parsedPathname } from '../../utils/utils';
import { getAllEventsData } from '../../utils/helpers/eventHelper';
import DICTIONARY from '../../utils/dictionary';
import ROUTES from '../../routes';
import { APP_NAME } from '../../utils/consts';

// Calendar
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../styles/calender.css';

// App Components
import PageHeader from "../../components/layout/PageHeader";
import ComponentLoader from '../../components/shared/loader/ComponentLoader';

// Material UI Components
import {Grid} from "@material-ui/core";

// Icons
import { 
	Add as AddIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		padding: 0,
    [theme.breakpoints.up("sm")]: {
			padding: theme.spacing(0, 2),
    }
	},
	calendar: {
		// marginTop: '20px',
	}
}));

export default function MyEvents() {
	const classes = useStyles();
	const { user } = useContext(AuthContext);
	const { handleCloseDialog } = useContext(DialogContext);
	const history = useHistory();
	const location = useLocation();

	const [value, onChange] = useState(new Date());

	//#region Load and Parse Events Data
  const [eventListData, setEventListData] = useState([]);
	const [isLoadingAllEvents, setIsLoadingAllEvents] = useState(false); // all events
	const [allowedActions, setAllowedActions] = useState([]);
	const [isLoadingEventParticipants, setIsLoadingEventParticipants] = useState(false);
	const [clickedEventId, setClickedEventId] = useState('');

	useEffect(() => {
		try{
			setIsLoadingAllEvents(true);

			(async function () {
				const teamsData = user?.teamsData;
				const eventsData = await getAllEventsData(teamsData, user.userAuth.uid);

				setEventListData(eventsData);
				setIsLoadingAllEvents(false);
			})();
		} catch(e) {
			setIsLoadingAllEvents(false);
			console.log("Error getting document:", e);
		}
	}, [getAllEventsData]);
  //#endregion Load and Parse Events Data

	//#region Create Event Dialog
  const handleClickOpenCreateEventDialog = () => {
		handleCloseDialog(handleCloseCreateEventDialog); // Update handleCloseDialog API context function

		history.push({
			pathname: `${parsedPathname(location.pathname)}/create-event`,
			state: {
				background: location,
				id: 'createEvent',
			},
		});
  };

  const handleCloseCreateEventDialog = async ({isSubmittedSuccessfully, response}) => {
		if (isSubmittedSuccessfully) {
			try{
				const {eventId} = response;
				history.push({
					pathname: `${ROUTES.myEvents.path}/${eventId}`,
					search: '',
				});
			} catch(e) {
				isVerbose() && console.error("Error creating event:", e);
			}
		}
	};

	useEffect(() => {
		const userId = user.userAuth.uid;
		const teamsData = user.teamsData;
		const teamsIdsOfAdmin = getTeamsIdsOfAdmin(userId, teamsData);

		if(teamsIdsOfAdmin.length > 0) {
			setAllowedActions(ADMIN_ACTIONS);
		}
	}, [user.userAuth.uid, user.teamsData]);
	//#endregion Create Event Dialog

	//#region My Events Actions
	const ADMIN_ACTIONS = {
		0: {
			actions: 	[
				{
					key: 'createEvent',
					iconElement: <AddIcon />, 
					title: DICTIONARY.EVENT.myEvents.actions.createEvent,
					onClick: handleClickOpenCreateEventDialog,
				},
			]
		}
	} ;
	//#endregion

	//#region Calendar 
	// const onDayClick = (date) => {
	// 	const day = date.toDateString();
	// 	const event = eventListData.find((event) => {
	// 		const eventDate = event.date.toDate().toDateString();
	// 		return eventDate === day;
	// 	});

	// 	if (event) {
	// 		history.push({
	// 			pathname: `${ROUTES.myEvents.path}/${event.id}`,
	// 		});
	// 	}
	// };

	const onEventClick = (event) => {
		if (event) {
			history.push({
				pathname: `${ROUTES.myEvents.path}/${event.id}`,
			});
		}
	};

	const createTileContent = ({ date, view }) => {
	 try {
			const day = date.toDateString();
		
			const eventsInDay = eventListData.filter((event) => {
				if(event) {
					const eventDate = event.date.toDate().toDateString();
					return eventDate === day;
				} else {
					return false;
				}
			});
			
			return eventsInDay.length > 0 && eventsInDay.map((event) => {
				let className = 'teamup-event';

				if(event) {
					const isPastEvent = isTimestampFromThePast(event.date);
					className = isPastEvent ? `${className} past` : `${className}`;
					return (<div 
						key={event.id}
						className={className}
						onClick={() => onEventClick(event)}
					>
						{`${formatDate(event.date, 'hh:mm a')} - ${event.teamName}`}
					</div>);
				};
			});
		} catch(error) {
			debugger;
		}
	};
	//#endregion

  return (
		<React.Fragment>
			<Helmet>
				<title>My events | {APP_NAME}</title>
			</Helmet>

			<PageHeader 
				title={DICTIONARY.EVENT.myEvents.title} 
				actions={allowedActions}
			/>

			<Grid container className={classes.root} spacing={2} justifyContent="flex-start">
				<Grid item xs={12}>
					{isLoadingAllEvents 
						? <ComponentLoader wrapperHeight={200}/> 
						: <React.Fragment>

								<Calendar
									onChange={onChange}
									value={value}
									// onClickDay={(date) => onDayClick(date)}
									tileContent={({ date, view }) => createTileContent({ date, view })}
									className={classes.calendar}
									calendarType={"Hebrew"}
								/>
							</React.Fragment>
					}
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

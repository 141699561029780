import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Material UI Components
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginTop: '-2px',
  },
}));

export default function LightTooltip(props) {
  const classes = useStyles();

  return (
    <Tooltip 
      title={props.title} 
      enterTouchDelay={50}
      classes={{'tooltip': classes.tooltip}}
    >
      {props.children}
    </Tooltip> 
  );
}
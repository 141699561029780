import React from "react";
import { SvgIcon } from '@material-ui/core';

export default function (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M23.4,7.1l-5.2-5.2c-0.2-0.2-0.5-0.3-0.8-0.3c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0h-1.4c-0.4,0-0.8,0.2-1,0.5
        c-0.5,0.7-1.5,1.2-2.7,1.2c-1.1,0-2.2-0.5-2.7-1.2c-0.2-0.3-0.6-0.5-1-0.5H6.9c0,0-0.1,0-0.1,0c-0.4,0-0.7,0.1-1,0.3L0.6,7.1
        c-0.5,0.5-0.5,1.2,0,1.6l2.9,2.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3l0.7-0.6v10.4c0,0.6,0.5,1.2,1.2,1.2h10.2
        c0.6,0,1.2-0.5,1.2-1.2V10.9l0.7,0.6c0.2,0.2,0.5,0.3,0.8,0.3c0,0,0,0,0,0c0.3,0,0.6-0.1,0.8-0.3l2.9-2.8
        C23.9,8.3,23.9,7.5,23.4,7.1z"/>
    </SvgIcon>
  );
};
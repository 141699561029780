import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { EVENT_GROUPS_COLORS } from '../../../utils/consts';
import DICTIONARY from '../../../utils/dictionary';

// App Components
import UsersList from '../../shared/userAvatar/UsersList';

// Material UI Components
import { Box, Typography, Card, CardHeader, CardContent } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

// Icons
import {
  People as PeopleIcon,
} from '@material-ui/icons';
import ShirtIcon from '../../icon/ShirtIcon';

// Colors
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  cardActions: {
    margin: 0,
  },
  toggleButton: {
    padding: theme.spacing(0.5),
  },
  shirtIconToggleButton: {
    width: '24px',
    height: '17px',
  },
  cardContent: {
    padding: theme.spacing(0, 2),
  },
	participantsBox: {
		padding: theme.spacing(0),
		[theme.breakpoints.up("sm")]: {
			padding: theme.spacing(1),
    }
	},
  lineupsContainer: {
    display: 'flex',
    flexDirection: 'column',
		[theme.breakpoints.up("sm")]: {
			flexDirection: 'row',
    }
  },
  groupContainer: {
    borderBottom: `1px solid ${grey[300]}`,
		padding: theme.spacing(2, 1, 1, 0),
    '&:first-child': {
      paddingTop: 0,
    },
		[theme.breakpoints.up("sm")]: {
			padding: theme.spacing(0, 3, 1, 2),
      flex: 1,
      borderBottom: 'none',
      borderRight: `1px solid ${grey[300]}`,
    },
    '&:last-child': {
      border: 'none',
    }
	},
	groupTitleContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	groupTitle: {
		fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(17),
    },
	},
  shirtIconGroup: {
    marginRight: theme.spacing(0.5),
    width: '24px',
    height: '19px',
    stroke: '#313131', strokeWidth: '2px', paintOrder: 'stroke', strokeLinejoin: 'round',
  },
	groupLineupContainer: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(1.5, 0),
		minHeight: '112px',
		[theme.breakpoints.down("sm")]: {
			minHeight: '84px',
		},
	},
}));

const CARD_CONTENT = {
  lineups: {
    label: DICTIONARY.EVENT.event.participantsCard.lineups,
    value: 'lineups',
  },
  registered: {
    label: DICTIONARY.EVENT.event.participantsCard.registered,
    value: 'registered',
  },
};

const UNASSIGNED = 'unassigned';

export default function ParticipantsCard({ eventData, isLoading }) {
  const classes = useStyles();
  const [activeCardContent, setActiveCardContent] = useState(CARD_CONTENT.registered);
  const [shouldDisplayActions, setShouldDisplayActions] = useState(false);

  useEffect(() => {
    if(eventData.shouldShowLineups && eventData.participants.length > 0) {
      setActiveCardContent(CARD_CONTENT.lineups);
      setShouldDisplayActions(true);
    } else {
      setActiveCardContent(CARD_CONTENT.registered);
      setShouldDisplayActions(false);
    }
  }, [isLoading]);

  const handleChangeCardContent = (event, view) => {
    if(view != null) {
      setActiveCardContent(view);
    }
  };

  const TOGGLE_ACTIONS = (
    <ToggleButtonGroup
      value={activeCardContent}
      exclusive
      size="small"
      onChange={handleChangeCardContent}
      aria-label="diaply mode"
    >
      <ToggleButton 
        value={CARD_CONTENT.lineups} 
        aria-label={CARD_CONTENT.lineups.label}
        classes={{'sizeSmall': classes.toggleButton}}  
      >
        <ShirtIcon className={classes.shirtIconToggleButton}/>
      </ToggleButton>
      <ToggleButton 
        value={CARD_CONTENT.registered} 
        aria-label={CARD_CONTENT.registered.label}
        classes={{'sizeSmall': classes.toggleButton}}    
      >
        <PeopleIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const ParticipatnsEl = (eventData) => (
		<Box
			display="flex"
			flexWrap="wrap"
			width="100%"
			classes={{'root': classes.participantsBox}}
			bgcolor="background.paper"
		>
			<UsersList 
				participants={eventData.participants} 
				isLoading={isLoading}
			/>
		</Box>
	);

	const LineupsEl = (eventData) => {
		let lineups = {};
		eventData.eventGroups.forEach(group => {
			lineups[group] = [];
		});

		eventData.participants.forEach((participant) => {
      try{
        if(participant.group != null) {
          lineups[participant.group].push(participant);
        } else {
          if(lineups[UNASSIGNED]) {
            lineups[UNASSIGNED].push(participant);
          } else {
            lineups[UNASSIGNED] = [participant];
          }
        }
      } catch(e) {}
		});

		return (
      <div className={classes.lineupsContainer}>
        {
          Object.keys(lineups).map((group, index) => (
            <div
              key={group} 
              className={`${classes.groupContainer}`}
              // style={{borderLeft: `8px solid ${EVENT_GROUPS_COLORS[group].hex}`}}
            >
              {/* Group title */}
              {group !== UNASSIGNED
                ? (
                  <div className={classes.groupTitleContainer}>
                    <ShirtIcon style={{ color: EVENT_GROUPS_COLORS[group].hex }} className={classes.shirtIconGroup} />
                    <Typography component="h2" classes={{'root': classes.groupTitle }}>{EVENT_GROUPS_COLORS[group].label}</Typography>
                  </div>
                  )
                : <Typography component="h2" classes={{'root': classes.groupTitle }}>{DICTIONARY.EVENT.event.participantsCard.unassigned}</Typography>
              }
      
              {/* Group users */}
              <div className={classes.groupLineupContainer}>
                <UsersList	
                  participants={lineups[group]} 
                  avatarSize={60}
                  isLoading={isLoading}
                  emptyStateTitle={DICTIONARY.EVENT.setLineups.groupEmptyState}
                  emptyStatePadding={0}
                />
              </div>
      
              {/* Divider */}
              {/* {index < Object.keys(lineups).length - 1 && <Divider />} */}
            </div>
          ))
        }
      </div>
    )
	};

  return (
    <Card elevation={0}>
      <CardHeader
        titleTypographyProps={{ variant:'h6' }}
        title={activeCardContent.value === CARD_CONTENT.lineups.value ? CARD_CONTENT.lineups.label : CARD_CONTENT.registered.label}
        classes={{
          'root': classes.cardHeader,
          'action': classes.cardActions,
        }}
        action={
          shouldDisplayActions 
            ? TOGGLE_ACTIONS
            : ''
        }
      />
      <CardContent classes={{ 'root': classes.cardContent }}>
        {activeCardContent.value === CARD_CONTENT.lineups.value 
          ? LineupsEl(eventData)
          : ParticipatnsEl(eventData)
        }
      </CardContent>
    </Card>
  );
}
import { useState } from 'react';
import { isVerbose } from '../utils/utils';

const useDeleteForm = ({initalFormState, validationValue, onSubmit, onCloseModal}) => {
  const [formState, setFormState] = useState(initalFormState);
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
	const [isValidToDelete, setIsValidToDelete] = useState(false);

	const handleInputChange = async (e) => {
		const { id, value } = e.target;

		setFormState((prevState) => {
			return { 
				...prevState,
				[id]: {
					...prevState[id],
					value,
					isValid: true,
				}
			};
		});

    if(value === validationValue) {
      setIsValidToDelete(true);
    } else {
      setIsValidToDelete(false);
    }
 
	  setFormError("");
	};

  const handleSubmit = async (e) => {
		e && e.preventDefault();

		try {
			setIsLoading(true);
			setFormError("");
			await onSubmit();
			setIsLoading(false);
			onCloseModal && handleCloseModal(true);
		} catch (e) {
			setIsLoading(false);
			setFormError(e.message);
			isVerbose() && console.error(e.message);
		}
	};

  const handleCloseModal = async (isSubmittedSuccessfully) => {
    setFormState(initalFormState);
    onCloseModal && await onCloseModal(isSubmittedSuccessfully);
  }

  return {formState, formError, isLoading, isValidToDelete, handleInputChange, handleSubmit, handleCloseModal};
};

export default useDeleteForm;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext, DialogContext } from '../../context';
import { teamApi } from '../../api';
import { isUserAdminOfTeam, getActiveAdmins, getActiveAdminsAndMembers, isVerbose, parsedPathname } from '../../utils/utils';
import { APP_NAME } from '../../utils/consts';
import DICTIONARY from '../../utils/dictionary';
import ROUTES from '../../routes';

// App Components
import PageHeader from "../../components/layout/PageHeader";
import TeamDetailsCard from '../../components/team/cards/TeamDetailsCard';
import NextEventCard from '../../components/team/cards/NextEventCard';
import MembersCard from '../../components/team/cards/MembersCard';
import AdminsCard from '../../components/team/cards/AdminsCard';
import ComponentLoader from '../../components/shared/loader/ComponentLoader';
import EmptyState from '../../components/shared/EmptyState';

import TabsContainer from '../../components/shared/tab/TabsContainer';
import TeamStatsContainer from '../../components/team/TeamStatsContainer';

// Material UI Components 
import { Grid } from "@material-ui/core";

// Material UI Icons
import { 
	Edit as EditIcon, 
	Delete as DeleteIcon,
	PersonAdd as PersonAddIcon,
	ExitToApp as ExitToAppIcon,
	Link as LinkIcon,
	Share as ShareIcon,
	Event as EventIcon,
} from '@material-ui/icons';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

// Colors
import {blueGrey} from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		padding: 0,
    [theme.breakpoints.up("sm")]: {
			padding: theme.spacing(0, 2),
    }
	},
	topCards: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		columnGap: '16px',
		rowGap: '16px',
		[theme.breakpoints.up("md")]: {
			gridTemplateColumns: '2fr 1fr 1fr',
    }
	},
	teamDetailsCard: {
		// height: '100px',
	},
	teamDescription: {
		marginLeft: theme.spacing(5),
		marginTop: '-22px',
	}
}));

export default function Team(props) {
	const classes = useStyles();
	const { user, handleUserUpdate } = useContext(AuthContext);
	const { handleCloseDialog, getDialogProps } = useContext(DialogContext);
	const history = useHistory();
	const location = useLocation();

	// States and refs
	const [teamData, setTeamData] = useState({});
	const teamDataRef = useRef();
	const [teamId, setTeamId] = useState('');
	const teamIdRef = useRef();
	const [isLoadingTeamData, setIsLoadingTeamData] = useState(false);
	const [isTeamAllowed, setIsTeamAllowed] = useState(false);
	const [teamAdmins, setTeamAdmins] = useState([]);
	const [teamMembers, setTeamMembers] = useState([]);
	const [allowedActions, setAllowedActions] = useState([]);

	const memberToAssignAsAdminRef = useRef();
	const memberToDefineAsPrimeRef = useRef();
	const memberToRemoveRef = useRef();

	//#region Get Team Data
	useEffect(() => {
		try{
			setIsLoadingTeamData(true);
			// Check if the requested the user belongs to this teamId
			const teamIdParam = props?.match?.params.teamId || '';
			setTeamId(teamIdParam);
			teamIdRef.current = teamIdParam;
			const userTeamsIds = user?.userData?.teams || [];
			const teamIndex = userTeamsIds.indexOf(teamIdParam);

			// Team exists in user's teams
			if(teamIndex > -1) {
				setIsTeamAllowed(true);
				const teamData = user?.teamsData[teamIdParam];
				const teamAdmins = getActiveAdmins(teamData.members);
				const teamMembers = getActiveAdminsAndMembers(teamData.members);
				const amIAdmin = isUserAdminOfTeam(user.userAuth.uid, teamMembers);

				// Set Admin / Member actions - in Team page scopr
				if(amIAdmin) {
					setAllowedActions(ADMIN_ACTIONS);
				} else {
					setAllowedActions(MEMBER_ACTIONS);
				}

				// Parse Members
				Object.keys(teamMembers).forEach(userId => {
					teamMembers[userId].isPrime = teamMembers[userId].isPrime || false;	
					if(amIAdmin) {
						if(isUserAdminOfTeam(userId, teamMembers)) { // Actions on other admins
							teamMembers[userId].actions = [MEMBERS_ACTIONS_BY_ADMIN.definePrimeMember];
						} else if(userId === user.userAuth.uid) { // Acions on My self
							teamMembers[userId].actions = [MEMBERS_ACTIONS_BY_ADMIN.definePrimeMember];
						} else { // Actions on regular members
							teamMembers[userId].actions = [MEMBERS_ACTIONS_BY_ADMIN.assignAsAdmin, MEMBERS_ACTIONS_BY_ADMIN.removeMember, MEMBERS_ACTIONS_BY_ADMIN.definePrimeMember]; 	
						}
					}
				}); 

				// Parse Admins - hide isPrime - just visually - so there will be no 'star' id admins card
				Object.keys(teamAdmins).forEach(userId => {
					teamAdmins[userId].isPrime = undefined;	
				}); 

				setTeamMembers(teamMembers);
				setTeamAdmins(teamAdmins);
				setTeamData(teamData);
				teamDataRef.current = teamData;
				setIsLoadingTeamData(false);
			} else {
				setIsTeamAllowed(false);
				setIsLoadingTeamData(false);
			}
		} catch(e) {
			setIsTeamAllowed(false);
			setIsLoadingTeamData(false);
		}
	}, [props?.match?.params.teamId, user])
	//#endregion

	//#region Create(edit) Team Dialog
  const handleClickOpenCreateTeamDialog = () => {
		handleCloseDialog(handleCloseCreateTeamDialog); // Update handleCloseDialog API context function
		getDialogProps({
			teamId: props?.match?.params.teamId,
			teamData: teamDataRef.current,
			isEditMode: true,
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}/edit-team`,
			state: {
				background: location,
				id: 'createTeam',
			},
		});
  };

  const handleCloseCreateTeamDialog = async ({isSubmittedSuccessfully}) => {
		getDialogProps({});
	};
	//#endregion

	//#region Add User To Team Dialog
  const handleClickOpenAddUserToTeamDialog = () => {
		handleCloseDialog(handleCloseAddUserToTeamDialog); // Update handleCloseDialog API context function
		getDialogProps({
			teamId: props?.match?.params.teamId,
			teamName: teamDataRef.current.teamName,
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}/add-member`,
			state: {
				background: location,
				id: 'addMemberToTeam',
			},
		});
  };

  const handleCloseAddUserToTeamDialog = async ({isSubmittedSuccessfully}) => {
		getDialogProps({});

		if (isSubmittedSuccessfully) {
			try{
				setIsLoadingTeamData(true);
				const teamDataResponse = await teamApi.get(props?.match?.params.teamId);

				const updatedTeam = {};
				updatedTeam[props?.match?.params.teamId] = teamDataResponse;
				handleUserUpdate({
					teamsData: {...user.teamsData, ...updatedTeam}
				});

				setTeamData(teamDataResponse);
				setIsLoadingTeamData(false);
			} catch(e) {
				isVerbose() && console.error("Error getting teamsData:", e);
				setIsLoadingTeamData(false);
			}
		}
	};
	//#endregion

	//#region Assign Admin To Team Dialog
  const handleClickOpenAssignAdminToTeamDialog = () => {
		handleCloseDialog(handleCloseAssignAdminToTeamDialog); // Update handleCloseDialog API context function

		getDialogProps({
			teamId: props?.match?.params.teamId,
			teamName: teamDataRef.current.teamName,
			member: memberToAssignAsAdminRef.current,
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}/assign-admin`,
			state: {
				background: location,
				id: 'assignAdminToTeam',
			},
		});
  };

  const handleCloseAssignAdminToTeamDialog = async ({isSubmittedSuccessfully}) => {
		getDialogProps({});
		memberToAssignAsAdminRef.current = {};
	};
	//#endregion

	//#region Define member as prime
  const handleClickOpenDefinePrimeMemberDialog = () => {
		handleCloseDialog(handleCloseDefinePrimeMemberDialog); // Update handleCloseDialog API context function

		getDialogProps({
			teamId: props?.match?.params.teamId,
			member: memberToDefineAsPrimeRef.current,
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}/define-prime-member`,
			state: {
				background: location,
				id: 'definePrimeMember',
			},
		});
  };

  const handleCloseDefinePrimeMemberDialog = async ({isSubmittedSuccessfully}) => {
		getDialogProps({});
		memberToDefineAsPrimeRef.current = {};
	};
	//#endregion	

	//#region Delete Team Dialog
  const handleClickDeleteTeamDialog = () => {
		handleCloseDialog(handleCloseDeleteTeamDialog); // Update handleCloseDialog API context function

		getDialogProps({
			teamId: props?.match?.params.teamId,
			teamName: teamDataRef.current.teamName,
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}/delete-team`,
			state: {
				background: location,
				id: 'deleteTeam',
			},
		});
	};
	
	const handleCloseDeleteTeamDialog = async ({isSubmittedSuccessfully}) => {
		getDialogProps({});

		if(isSubmittedSuccessfully) {
      history.push({
        pathname: ROUTES.myTeams.path,
        search: '',
      });
    }
	};
	//#endregion

	//#region Remove User From Team Dialog
  const handleClickDeleteUserFromTeamDialog = () => {
		handleCloseDialog(handleCloseRemoveMemberFromTeamDialog); // Update handleCloseDialog API context function

		getDialogProps({
			teamId: props?.match?.params.teamId,
			teamName: teamDataRef.current.teamName,
			member: memberToRemoveRef.current,
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}/remove-member`,
			state: {
				background: location,
				id: 'removeMemberFromTeam',
			},
		});
	};
	
	const handleCloseRemoveMemberFromTeamDialog = async ({isSubmittedSuccessfully}) => {
		memberToRemoveRef.current = {};
		getDialogProps({});
	};
	//#endregion

	//#region Leave Team Dialog
  const handleClickOpenLeaveTeamDialog = () => {
		handleCloseDialog(handleCloseLeaveTeamDialog); // Update handleCloseDialog API context function

		const userId = user.userAuth.uid;
    const isAdmin = isUserAdminOfTeam(userId, teamDataRef.current.members);

		getDialogProps({
			teamId: props?.match?.params.teamId,
			teamName: teamDataRef.current.teamName,
			isAdmin: isAdmin,
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}/leave-team`,
			state: {
				background: location,
				id: 'leaveTeam',
			},
		});
	};
	
	const handleCloseLeaveTeamDialog = async ({isSubmittedSuccessfully}) => {
		getDialogProps({});

		if(isSubmittedSuccessfully) {
      history.push({
        pathname: ROUTES.myTeams.path,
        search: '',
      });
    }
	};
	//#endregion

	//#region Invite to team Dialog
  const handleClickInviteUsersToTeamDialog = () => {
		handleCloseDialog(handleCloseInviteUsersToTeamDialog); // Update handleCloseDialog API context function

		getDialogProps({
			teamId: props?.match?.params.teamId,
			teamName: teamDataRef.current.teamName,
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}/share-team`,
			state: {
				background: location,
				id: 'shareTeam',
			},
		});
	};
	
	const handleCloseInviteUsersToTeamDialog = async ({isSubmittedSuccessfully}) => {
		getDialogProps({});
	};
	//#endregion

	//#region Create Event Dialog
  const handleClickOpenCreateEventDialog = () => {
		handleCloseDialog(handleCloseCreateEventDialog); // Update handleCloseDialog API context function
		getDialogProps({
			teamId: props?.match?.params.teamId,
		});

		history.push({
			pathname: `${parsedPathname(location.pathname)}/create-event`,
			state: {
				background: location,
				id: 'createEvent',
			},
		});
  };

  const handleCloseCreateEventDialog = async ({isSubmittedSuccessfully, response}) => {
		getDialogProps({});

		if (isSubmittedSuccessfully) {
			const {eventId} = response;
      history.push({
        pathname: `${ROUTES.myEvents.path}/${eventId}`,
        search: '',
      });
		}
	};
	//#endregion Create Event Dialog

	//#region Participant actions for admin
	const MEMBERS_ACTIONS_BY_ADMIN = {
		assignAsAdmin: {
			title: DICTIONARY.TEAM.team.actionsOnMembers.assignAsAdmin,
		 	onClick: async (user) => {
				memberToAssignAsAdminRef.current = user;
				handleClickOpenAssignAdminToTeamDialog();
			}
	 	},
	 	removeMember: {
			title: DICTIONARY.TEAM.team.actionsOnMembers.removeMember,
			onClick: (user) => {
				memberToRemoveRef.current = user;
				handleClickDeleteUserFromTeamDialog();
			},
		},
		definePrimeMember: {
			title: (user) => {
				return !user.isPrime ? DICTIONARY.TEAM.team.actionsOnMembers.definePrimeMember : DICTIONARY.TEAM.team.actionsOnMembers.cancelPrimeMember;
			},
			onClick: async (user) => {
				memberToDefineAsPrimeRef.current = user;
				handleClickOpenDefinePrimeMemberDialog();
			}
		},
	};
	//#endregion

	// #region - Share team page (Dialog for desktop and native behavior for mobile)
	const handleClickShareEvent = async () => {
		try {
			if(navigator.share) { // Mobile
				const shareData = {
					title: 'Team UP',
					text: `${DICTIONARY.TEAM.shareTeam.mobileNvigatorShare.title.part1} ${teamDataRef.current.teamName} ${DICTIONARY.TEAM.shareTeam.mobileNvigatorShare.title.part2}`,
					url: window.location.href,
				};

				await navigator.share(shareData);
			} else { // Desktop
				handleCloseDialog(handleCloseShareEventDialog); // Update handleCloseDialog API context function
				getDialogProps({
					link: window.location.href,
					teamName: teamDataRef.current.teamName
				});
		
				history.push({
					pathname: `${parsedPathname(location.pathname)}/share-team`,
					state: {
						background: location,
						id: 'shareTeamPage',
					},
				});
			}
		} catch(e) {}
	};

	const handleCloseShareEventDialog = async ({isSubmittedSuccessfully}) => {
		getDialogProps({});
	};
	//#endregion

	//#region Team Actions
	const ADMIN_ACTIONS = {
		team: {
			title: 'Team',
			actions: [
				{
					key: 'editTeamDetails',
					title: DICTIONARY.TEAM.team.actions.edit,
					iconElement: <EditIcon />,
					color: 'secondary',
					onClick: handleClickOpenCreateTeamDialog,
				},
				{
					key: 'inviteViaLink',
					title: DICTIONARY.TEAM.team.actions.invite,
					iconElement: <LinkIcon />,
					color: 'secondary',
					onClick: handleClickInviteUsersToTeamDialog,
				},
				{
					key: 'shareTeamPage',
					title: DICTIONARY.TEAM.team.actions.share,
					iconElement: <ShareIcon />,
					color: 'secondary',
					onClick: handleClickShareEvent,
				},
				{
					key: 'deleteTeam',
					title: DICTIONARY.TEAM.team.actions.delete,
					iconElement: <DeleteIcon />,
					color: 'secondary',
					onClick: handleClickDeleteTeamDialog,
				},
			]
		},
		event: {
			title: 'Event',
			actions: [
				{
					key: 'createEvent',
					iconElement: <EventIcon />, 
					color: 'secondary',
					title: DICTIONARY.TEAM.team.actions.createEvent,
					onClick: handleClickOpenCreateEventDialog,
				},
			],
		},
		members: {
			title: 'Members',
			actions: [
				{
					key: 'addNewMember',
					title: DICTIONARY.TEAM.team.actions.addMemeber,
					iconElement: <PersonAddIcon />,
					color: 'secondary',
					onClick: handleClickOpenAddUserToTeamDialog,
				},
			]
		},
		me: {
			title: 'Me',
			actions: [
				{
					key: 'leaveTeam',
					title: DICTIONARY.TEAM.team.actions.leave,
					iconElement: <ExitToAppIcon/>,
					color: 'secondary',
					onClick: handleClickOpenLeaveTeamDialog,
				},
			]
		}
	};

	const MEMBER_ACTIONS = {
		team: {
			title: 'Team',
			actions: [
				{
					key: 'shareTeamPage',
					title: DICTIONARY.TEAM.team.actions.share,
					iconElement: <ShareIcon />,
					color: 'secondary',
					onClick: handleClickShareEvent,
				},
			]
		},
		me: {
			title: 'Me',
			actions: [
				{
					key: 'leaveTeam',
					title: DICTIONARY.TEAM.team.actions.leave,
					iconElement: <ExitToAppIcon />,
					color: 'secondary',
					onClick: handleClickOpenLeaveTeamDialog,
					color: 'secondary',
				},
			]
		}
	};
	//#endregion Team Actions

	//#region Helpers
	const handleBackClick = () => {
		if (props.history.location.state !== undefined) {
			props.history.goBack();
		} else {
			props.history.push('/my-teams')
		}
	};
	//#endregion

	const TeamPageContentEl = () => (
		<Grid container className={classes.root} spacing={2} justifyContent="flex-start">
			{/* Top cards */}
			<Grid item xs={12} >
				<div className={classes.topCards}>
					<TeamDetailsCard 
						teamData={teamData}  
					/>
					<NextEventCard 
						teamId={teamId}
					/>
					<AdminsCard 
						title={DICTIONARY.TEAM.team.card.title.admins}
						admins={teamAdmins} 
					/>
				</div>
			</Grid>

			{/* Team members */}
			<Grid item xs={12}>
				<MembersCard 
					members={teamMembers} 
				/>
			</Grid>							
		</Grid>
	);

  return (
		isTeamAllowed 
			? <React.Fragment>
					<Helmet>
						<title>{teamData.teamName} | {APP_NAME}</title>
					</Helmet>
					<PageHeader 
						title={teamData.teamName}
						subTitle={teamData.description}
						handleBackClick={handleBackClick}
						actions={allowedActions}
					/>

					{isLoadingTeamData 
						? <ComponentLoader />
						: <TabsContainer 
								tabs={[
									{
										label: DICTIONARY.TEAM.team.tabs.details,
										value: 0,
										key: 'details',
										content: TeamPageContentEl(),
									},
									{
										label: DICTIONARY.TEAM.team.tabs.statistics,
										value: 1,
										key: 'statistics',
										content: <TeamStatsContainer 
											teamId={teamId}
											members={teamMembers} 
										/>,
									}
								]}
							/>
					}
				</React.Fragment>
			:	<EmptyState 
					icon={<NotInterestedIcon style={{ fontSize: 80, color: blueGrey[500] }}/>}
					title={DICTIONARY.TEAM.team.emptyState}
					padding={8}
				/>
	);
}

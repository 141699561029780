import React, { useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from '../../context';
import { generalApi } from '../../api';

// Material UI Components
import {Button} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

}));

export default function TestPwa() {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const [responseMessage, setResponseMessage] = useState('');

  const handleTestPwaClick = async () => {
    const response = await generalApi.testPwa(user.userAuth.uid);
    if (response.hasOwnProperty("error")) {
      setResponseMessage(response.error);
		} else {
      setResponseMessage('Good!');
    }
  };

  return (
    <React.Fragment>
      <div>
        <Button 
          onClick={handleTestPwaClick}
        >
          Test PWA
        </Button>
        {responseMessage && <p>{responseMessage}</p>}
      </div>
    </React.Fragment>
  );
}

const TEAM = {
  //#region My-teams page
  myTeams: {
    title: 'My teams',
    actions: {
      createTeam: 'Create new team'
    }
  },
  //#endregion

  //#region Team page
  team: {
    emptyState: `Team doesn't exist.`,
    card: {
      title: {
        admins: 'Admins',
        nextEvent: 'Next event',
        location: 'Location',
        eventDay: 'Event day',
        eventTime: 'Event time',
        paymentLink: 'Payment link',
      },
      noNextEvent: 'No future events'
    },
    tabs: {
      details: 'Team details',
      statistics: 'Statistics',
    },
    mambersCard: {
      members: 'Members',
      statistics: 'Statistics',
      emptyState: 'Team has no members yet',
    },
    actions: {
      edit: 'Edit team details',
      delete: 'Delete team',
      invite: 'Invite via link',
      share: 'Share team page',
      createEvent: 'Create new event',
      addMemeber: 'Add new member',
      leave: 'Leave team',
    },
    actionsOnMembers: {
      assignAsAdmin: 'Assign as admin',
      removeMember: 'Remove member',
      definePrimeMember: 'Define as a prime member',
      cancelPrimeMember: 'Cancel prime membership'
    }
  },
  //#endregion

  //#region Dashbaord
  teamListDashboard: {
    title: 'My teams',
    createTeam: 'Create new team',
    emptyState: {
      title: 'You have no teams',
      subtitle: 'Create your first team and share it with your friends',
    },
  },
  //#endregion

  //#region Dialogs
  createTeam: { // create / edit team dialog
    title: {
      create: 'Create new team',
      edit: 'Edit team details'
    },
    innerSection: {
      title: `Event template`,
      subtitle: {
        lineOne: `Newly created events will automatically apply these details.`,
        lineTwo: `You can still update the details for each event you will create later on.`
        // lineTwo: `You can still update the details for each event you will create and/or add other options for default events later on.`
      },
    },
    form: {
      field: {
        teamName: 'Name',
        description: 'Description',
        paymentLink: {
          label: 'Payment link',
          info: 'You can share here the payment service link of your events.',
        },
        defaultLocation: 'Location',
        defaultDay: 'Event day',
        maxEventParticipants: {
          label: '# Participants',
          info: 'The maximum amount of participants in the event.',
        },
        eventGroups: {
          label: 'Event groups',
          info: `Choose the color for each group in your event. The groups will represent the event's lineups.`,
        },
        defaultTime: 'Event time',
        days: {
          sunday: 'Sunday',
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
        },
        avatar: 'Team image',
      },
      error: {
        generalError: `Couldn't create team. Please try again or contact support.`,
        generalErrorEdit: `Couldn't edit team. Please try again or contact support.`,
        teamNameLength: 'Team name must contain a least 3 characters.',
        teamDescriptionLength: 'Description can contain up to 50 characters.',
        paymentLink: 'Payment link must be valid.',
        defaultLocationLength: 'Default location must contain a least 2 characters.',
        maxEventParticipantsRange: 'Number of participants in event must be between 1 and', // max numuber will come from consts.js file
        maxEventGroups: 'Number of groups in event can be between 1 and',
        defaultTimeInvalid: 'Time is not valid.',
        imageType: 'File must be of an image file type',
        imageUpload: `Couldn't upload image. Please try again or contact support.`,
      },
    },
  },
  deleteTeam: {
    title: 'Delete team',
    subtitle: 'Please confirm deleting the team by typing its name: ',
    form: {
      field: {
        teamName: 'Team name',
      },
      error: {
         generalError: `Couldn't delete team. Please try again or contact support.`,
      },
    },
  },
  leaveTeam: {
    title: 'Leave team',
    subtitle: 'Please confirm leaving the team by typing its name: ',
    form: {
      field: {
        teamName: 'Team name',
      },
      error: {
         generalError: `Couldn't leave team. Please try again or contact support.`,
      },
      submit: 'Leave',
    },
  },
  addMemberToTeam: {
    title: 'Add new members',
    subtitle: '*To add multiple members at once, separate the emails by commas.',
    form: {
      field: {
        emails: 'Emails',
      },
      error: {
        invalidEmail: 'Invalid email.',
        invalidEmails: 'One or more of the emails is invalid.'
      },
    }
  },
  assignAdminToTeam: {
    title: 'Assign admin to ',
    subtitle: {
      part1: 'Are you sure you want to assign',
      part2: 'as admin?'
    },
    form: {
      submit: 'Assign',
    },
  },
  primeMembership: {
    defineMemberAsPrime: {
      title: 'Define prime member',
      subtitle: {
        part1: 'Are you sure you want to define',
        part2: 'as a prime member?'
      },
      form: {
        submit: 'Submit',
      },
    },
    cancelPrime: {
      title: 'Cancel prime membership',
      subtitle: {
        part1: 'Are you sure you want to cancel prime membership of',
        part2: '?'
      },
      form: {
        submit: 'Cancel membership',
      },
    },
  },
  removeMemberFormTeam: {
    title: 'Remove member ',
    subtitle: 'Please confirm removing the team member by typing its name:',
    form: {
      field: {
        memberName: 'Member name',
      },
      error: {
         generalError: `Couldn't remove team member. Please try again or contact support.`,
      },
    },
  },
  inviteUsersToTeam: {
    title: 'Invitate via link',
    subtitle: {
      part1: 'Invite users to',
      part2: 'via the link below.'
    },
    mobileShare: {
      part1: 'Follow this link to join',
      part2: `team`,
    },
    error: 'Error getting invitation link. Please load again or contact support',
    copied: 'Copied',
  },
  shareTeam: {
    desktopDialog: {
      title: 'Share team page',
      subtitle: 'team.'
    },
    mobileNvigatorShare: {
      title: {
        part1: 'Check out',
        part2: 'team',
      },
    },
    copied: 'Copied',
  },
  //#endregion

  //#region Invitation related
  invitation: {
    userAlreadyInTeam: 'Hey! You are already a member of this team.'
  }
  //#endregion
};

export default TEAM;
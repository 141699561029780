import React, { useContext } from "react";
import { AuthContext } from '../../context';
import { makeStyles } from "@material-ui/core/styles";
import { eventApi } from '../../api';
import DICTIONARY from '../../utils/dictionary';
import useNoFieldsForm from '../../hooks/noFieldsFormHook';

// Colors
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
}));

export default function UnregisterDialog({ eventId, eventDate, teamId, userData, handleCloseDialog }) {
  const { user } = useContext(AuthContext);
  const classes = useStyles();

  //#region Form Hook
	const onSubmit = async () => {
    const response = await eventApi.unregister(eventId, teamId, userData);

    if (response.hasOwnProperty("error")) {
      throw new Error(DICTIONARY.EVENT.unregisterFromEvent.form.error.generalError);
    }
	}

	const onCloseModal = (isSubmittedSuccessfully) => {
    handleCloseDialog({isSubmittedSuccessfully});
  }

	const {formError, isLoading, handleSubmit, handleCloseModal} = useNoFieldsForm({
		onSubmit, 
		onCloseModal,
	});
  //#endregion

  const dialogContent = (
    <span></span>
  );

  return {
		dialogTitle: DICTIONARY.EVENT.unregisterFromEvent.title,
		dialogContent,
    dialogContentText: (<span>{DICTIONARY.EVENT.unregisterFromEvent.subtitle} <span style={{fontWeight: 'bold', color: 'black'}}>{eventDate}</span> ?</span>),
		sumbitButtonLabel: DICTIONARY.EVENT.unregisterFromEvent.form.submit.unregister,
    submitButtonStyle: {backgroundColor: red[400]},
		handleSubmit,
		handleCloseModal,
		formError,
		isLoading,
	};
}